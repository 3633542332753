import React, { Component } from 'react'
import Footer from '../../../common-components/Footer/Footer'
import Header from '../../../common-components/Header/Header'
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg'; 
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import { blobService } from '../../../common-components/Blob';
import { RouteComponentProps } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import Check from '../../../Services/Check';
import './viewCustomerProject.scss';
import Modal from "react-bootstrap/Modal";
import CreateQuote from '../../../common-components/CreateQuote/CreateQuote';
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';

interface ViewCustomerProjectProps {
    name?: any;
    value?: any;
}
interface ViewCustomerProjectState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    errors: {};
    project: any,
    nextIcon: any,
    prevIcon: any,
    showModal: boolean,
    header: string,
    body: any,
    typeOfProject: any,
    cost: any,
    name: any,
    nextDisabledIcon: any,
    prevDisabledIcon: any,
    quoteModal: boolean,
    projectId: any[],
    showUpload: boolean,
    isPublic: boolean,
    isEditQuote: boolean,
    quoteDetails: any[],
}

export default class ViewCustomerProject extends React.Component<ViewCustomerProjectProps & RouteComponentProps, ViewCustomerProjectState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            error:false,
            ErrMsg:"",
            isLoading: false,
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            nextDisabledIcon: <span className="icon-button-disabled "><img src={RightArrow}></img></span>,
            prevDisabledIcon: <span className="icon-button-disabled "><img src={LeftArrow}></img></span>,
            errors: {},
            project:{},
            showModal: false,
            header: '',
            body:{},
            typeOfProject:'',
            cost:'',
            name:'',
            quoteModal: false,
            projectId: [],
            showUpload: false,
            isPublic: true,
            isEditQuote: false,
            quoteDetails: [],
        };
        this.state = initialState;
    }
    componentDidMount() {
        this.init();
    }
    init(){
        let p = this.props.location.state ? this.props.location.state.data: "";
        this.state.projectId.push(this.props.location.state.data.id) ;
        this.setState({
            projectId : this.state.projectId
        })
        this.ViewCustomerProject(p.id); 
    }
    async ViewCustomerProject(id: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchProjectAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ project: response.data, isLoading: false });
                if(localStorage.userType && localStorage.userType == "Builder") {
                    let pId = this.props.location.state.data;
                    this.fetchQuoteDetails(pId.id? pId.id : pId.projectId);
            }
                this.findUserById(this.state.project.createdBy);

            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        }
        )
    };

    onShowModal(event:any){
        this.setState({ showModal: true, header: "Contact Details" });
    }
    onHideModal(event:any){
        this.setState({ showModal: false });
    }

    async findUserById(id: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchUserDetailsAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let name;
                if(response.data.customerName){
                    name = response.data.customerName;
                }
                this.setState({name: name})
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        }
        )
    };

    findArchitects = () =>{
        localStorage.setItem("tab",'3');
    }

    openQuoteModal = () =>{
        this.setState({
            quoteModal: true
        })
        
    }
    quoteSuccess(event: any) {
        if(event == true) {
            this.setState({ quoteModal: false  })
        }else{
            this.setState({ quoteModal: false, showUpload: true, body: event })    
        }  
      }
    onHideUpload = async(event:any) => {
        this.setState({
            showUpload:false, 
            isEditQuote: true,
        });
        this.init();
 
    }
    setPublic =() =>{
        //this.setState({ isPublic: !this.state.isPublic });
    }
    fetchQuoteDetails =(projectId: any)=>{
        //     let pId = this.props.location.state.data;
        // let projectId = pId.id? pId.id : pId.projectId ;
          let  userId = localStorage.getItem("id");
           let userType = localStorage.getItem("userType");
            Check.checkToken()
            .then(() => {
                const options = {
                    url: constants.fetchQuoteDetails,
                    data: {
                            "projectId": projectId,
                            "createdBy": userType,
                            "userId": userId,
                            "status": ""
                    }
                };
               
                userService
                .postApiRequest(options)
                .then((response) => {
                    if(response){
                        this.setState({
                            isEditQuote: true,
                            quoteDetails: response
                        })
                    }
                   
                })
                .catch((error) => {
                });
    
            })
    
        }


    render() {
        let items:any=[];
        let subItems:any=[];
        let projectImages:any=[];
        let projectName, address, mobile, description, builderType, landSize, measurementUnit, landSaleContract, architectualDrawings, engineeringPlans, LandScapingDesign, builderName, architectName, typeOfProject, cost, isPublic;
        let project = this.props.location.state ? this.props.location.state.data: "";
        const{ error, ErrMsg, isLoading, nextIcon, prevIcon, showModal, header, prevDisabledIcon, nextDisabledIcon, projectId, showUpload, body, isEditQuote, quoteDetails} = this.state;
        if(project){
            projectImages = project.projectDetails.projectImages;
            projectName = project.name;
            address = project.address ? project.address.addressLine1+", "+project.address.addressLine2+", "+project.address.city+", "+project.address.state+", "+project.address.country: "";
            mobile = project.mobile;
            description = project.description;
            builderType = project.projectDetails.builderType.builderType;
            landSize = project.projectDetails.landSize;
            measurementUnit = project.projectDetails.measurementUnit.description;
            landSaleContract = project.projectDetails.projectDocs.landSaleContract;
            architectualDrawings = project.projectDetails.projectDocs.architectDrawings;
            engineeringPlans = project.projectDetails.projectDocs.engineeringPlans;
            LandScapingDesign = project.projectDetails.projectDocs.landscapingDesigns;
            builderName = project.builder ? project.builder.builderName: "";
            architectName = project.architect ? project.architect.archtectName: "";
            typeOfProject = project.typeOfProject;
            cost = project.cost;
            isPublic = project.public;
        }

        if(projectImages.length>0){
            items = projectImages.map(
                (item:any) => (
                    (<Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={blobService.createUrl(item)}
                        width="100%" height="350px"
                        />
                    </Carousel.Item>)          
                ),
            );
        }else{
            items = (<Carousel.Item>
            <img
            className="d-block w-100"
            src={ProjectPlaceholder}
            width="100%" height="350px"
            />
            </Carousel.Item>)
        }
        for(let i = 0; i<4; i++){
            if(projectImages[i]){
                subItems.push(
                    <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                        <img src={blobService.createUrl(projectImages[i])} alt="" width="100%" height="100px"/>
                    </div>
                )               
            }
        }
            
        return !project ? (<><Header/><br/><div className="text-center">No Data</div><br/><Footer/></>): 
        (
        <>
        <Header/>
            <div className="bg-img">
            <div className="overlay">
            <div className="container">
            <div className="col-md-6 col-12"> 
                <h1 style={{fontWeight:600}}>{projectName}</h1>
                <p className="my-3"><Link to="MyBuilderProjects" className="link-text">My Projects</Link> / {projectName}</p>
            </div>
            </div>
            </div>
            </div>

            <div className="aboutProject">
            <div className="container py-5">
            <h4 style={{position:"absolute"}}>About</h4>
                <div className="row mt-5">
                <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <span>
                                    {(projectImages.length>1)?
                                        <Carousel  nextIcon={nextIcon} prevIcon={prevIcon} >
                                            {items}
                                        </Carousel>
                                        :
                                        <Carousel className = "disabled-arrow" nextIcon={nextDisabledIcon} prevIcon={prevDisabledIcon} >
                                            {items}
                                        </Carousel>
                                    }
                                    <br/>
                                    </span>
                                    <div className="row">
                                            {subItems}
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    <div className="col-md-6">
                    <div className="projectInfo">
                        <h3>{projectName}</h3>
                        <div>
                            <ToggleSwitch
                                id="public"
                                small
                                checked={isPublic}
                                onChange={this.setPublic}
                                />
                                <label className="toggletext" htmlFor="daily">Public Project</label>
                        </div>
                        <p>{typeOfProject === "Contract" && (<> by {this.state.name} </>)}</p>
                        <div className="col-md-5 row">
                            <span style = {{marginRight: "9px"}}>  Status: </span>
                            <span>   
                                {(project.projectStatus.projectStatus === "In Progress") && 
                                    <div className=" btn-text pending-btn">
                                        In Progress
                                    </div>
                                }
                                {(project.projectStatus.projectStatus === "Completed") && 
                                    <div className=" btn-text completed-btn">
                                        Completed
                                    </div>
                                }
                                {(project.projectStatus.projectStatus === "Cancelled") && 
                                    <div className=" btn-text cancel-btn">
                                        Cancelled
                                    </div>
                                }
                                {(project.projectStatus.projectStatus === "Pending") && 
                                    <div className=" btn-text pending-btn">
                                        Yet to Assign
                                    </div>
                                }
                            </span>
                        </div>
                        <h5 className="addresshead">Address &amp; Contact</h5>
                        <div>{address}</div>
                        <div>{mobile}</div>

                        <h5 className="mt-4">Builder Assigned</h5>
                        <div>{builderName ? builderName :"None"}</div>

                        <h5 className="mt-4">Architect Assigned</h5>
                        <div>{architectName ? architectName: <div>None  <Link onClick={this.findArchitects} to="/Architects">(Find Architects)</Link></div>}</div>
                        {/* {(isPublic == false)?
                                (isEditQuote == true ? 
                                    <button 
                                        className="button2 ml-5 hire" 
                                        onClick={this.openQuoteModal}>
                                        Edit Quote 
                                        <img src={RightArrow} className="ml-3"/>
                                    </button>
                                    :
                                    <button 
                                        className="button2 ml-5 hire" 
                                        onClick={this.openQuoteModal}>
                                        Give Quote 
                                        <img src={RightArrow} className="ml-3"/>
                                    </button>
                                    )
                            :
                            ""
                        } */}

                        <br/>

                    </div>
                    </div>
                </div>

                <div className="my-5">
                    <h4>About the Project</h4>
                    <p style={{lineHeight:"30px"}}>{description}</p>
                </div>

                <div>
                    <h4>Project Details</h4>    
                    <div className="row mt-4">
                        <div className="col-md-3">
                            <h5>Builder Type</h5>
                            <p>{builderType}</p>
                        </div>
                        <div className="col-md-3">
                            <h5>Land Size</h5>
                            <p>{landSize} {measurementUnit}</p>
                        </div>
                    </div>
             
                    <div className="row mt-4">
                        <div className="col-md-3">
                            <h5>Type of Project</h5>
                            <p>{typeOfProject}</p>
                        </div>
                        {typeOfProject === "Sale" &&
                        <div className="col-md-3">
                            <h5>Cost</h5>
                            <p className="light-content"><CurrencyFormat value={cost} displayType={'text'} thousandSeparator={true} prefix={'$'} /></p>
                        </div>
                        }
                    </div>
                </div>

                {/* <div className="my-5">
                <h4>Documents</h4>  
                <div className="row mt-4">
                <div className="col-md-6">
                    <h6>Contract of Sale of Land</h6>
                    <div className="pdfblock file-name">
                        <img src={DownloadIcon} />  <a href={blobService.createUrl(landSaleContract)} target="_blank"> Contract of Sale of Land.pdf</a>
                    </div>
                </div>
                {builderType ==="Custom Builder" &&
                <div className="col-md-6">
                    <h6>Architechtural Drawings</h6>
                    <div className="pdfblock file-name">
                        <img src={DownloadIcon} />  <a href={blobService.createUrl(architectualDrawings)} target="_blank"> Architechtural Drawings.pdf</a>
                    </div>
                </div>
                }
                </div>
                {builderType ==="Custom Builder" &&
                <div className="row mt-4">
                    <div className="col-md-6">
                        <h6>Engineering Plans</h6>
                        <div className="pdfblock file-name">
                            <img src={DownloadIcon} />  <a href={blobService.createUrl(engineeringPlans)} target="_blank"> Engineering Plans.pdf</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h6>LandScaping Designs</h6>
                        <div className="pdfblock file-name">
                            <img src={DownloadIcon} />  <a href={blobService.createUrl(LandScapingDesign)} target="_blank"> LandScaping Designs.pdf</a>
                        </div>
                    </div>
                </div>
                }
                </div> */}
            </div>
            </div>
            <div>
    </div>
            <CustomModal 
                isAlert={true}
                show={showModal} 
                header={header} 
                body={<div>Address : {address}<br/>Call on : {mobile}</div>} 
                onHide={(event:any)=>this.onHideModal(event)}
            />
        {(localStorage.userType && localStorage.userType == "Builder") ?
            <Modal  
            show={this.state.quoteModal}  
              >
                <CreateQuote 
                isPublicCheck = {project.public} 
                projectName ={project.name} 
                id={projectId}
                isEditQuoteCheck = {this.state.isEditQuote} 
                quoteDetailsData= {quoteDetails} 
                result={(event: any) => this.quoteSuccess(event)}
                />
            </Modal>
            : ""}
            <CustomModal 
                isAlert={true} 
                show={showUpload} 
                header= "Success"
                body={body} 
                onHide={(event: any)=>this.onHideUpload(event)}  
             /> 
        <Footer/>
        </>
        );
    }
}
