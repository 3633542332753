import React from "react";
import { constants } from '../../../constants/constants';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import CustomModal from '../CustomModal/CustomModal';
import { RouteComponentProps } from 'react-router';
import axios from 'axios';
import './ResetPassword.scss';
import passwordLock from '../../../assets/images/passwordLock.svg';

interface ResetPasswordprops {
    
}

interface ResetPasswordstate {
    token:string,
    newPassword: string,
    errors: {
        newPassword: boolean,
        confirmNewPassword: string,
        newupperCase: boolean,
        newlowerCase: boolean,
        newdigit: boolean,
        newspecialChar: boolean,
        newminLength: boolean,
    },
    showUpload: boolean,
    header: string,
    body: string,
    confirmNewPassword: string;
    isEnablePassword: boolean
}

class ResetPassword extends React.Component<RouteComponentProps, ResetPasswordstate & any>{
    constructor(props: any) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token')
        const initialState = {
            token:token,
            newPassword: "",
            errors: {
                newPassword: false,
                confirmNewPassword: '',
                newupperCase: false,
                newlowerCase: false,
                newdigit: false,
                newspecialChar: false,
                newminLength: false,
            },
            showUpload: false,
            header: '',
            body: '',
            confirmNewPassword: '',
            isEnablePassword: true
            }
            this.state = initialState;
            this.handleChange = this.handleChange.bind(this)
        }

        handleChange = (event: any) => {
            event.preventDefault();
            const { name, value } = event.target;
            let errors = this.state.errors;

            const uppercaseRegExp   = /(?=.*?[A-Z])/;
            const lowercaseRegExp   = /(?=.*?[a-z])/;
            const digitsRegExp      = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp   = /.{8,}/;
            switch (name) {
                case 'newPassword':
                    errors.newupperCase = uppercaseRegExp.test(value) ? true : false;
                    errors.newlowerCase = lowercaseRegExp.test(value)? true : false;
                    errors.newdigit = digitsRegExp.test(value) ? true : false;
                    errors.newspecialChar = specialCharRegExp.test(value) ? true : false;
                    errors.newminLength = minLengthRegExp.test(value) ? true : false;
                    break;
                case 'confirmNewPassword':
                    errors.confirmNewPassword = this.state.newPassword != value ? 'Make Sure Your Password is Same as New Password' : '';
                    break;

            }
            this.setState(Object.assign(this.state, { errors, [name]: value }));
            if((errors.newupperCase && 
                errors.newlowerCase && errors.newdigit && 
                errors.newspecialChar && errors.newminLength &&
                (this.state.confirmNewPassword == this.state.newPassword)
                ) == true ){
                        this.setState({
                            isEnablePassword: false
                        })
            }else{
                this.setState({
                    isEnablePassword: true
                })
            }
        };

        openUpload = async(header:any, body: any) => {
            this.setState({showUpload:true, header: header, body: body});
        }
        onHideUpload = async(event:any) => {
            this.setState({showUpload:false});
            this.props.history.push('/');
        }

        saveNewPassword = (event:any) =>{
            const {token,newPassword}:any = this.state
                event.preventDefault();
    
                    // const options = {
                    //     url: constants.setNewPassword,
                    //     data: {
                    //         "token":token,
                    //         "newPassword": newPassword,
                    //     }
                    // };
                    //  userService.postApiRequest(options)
                    let data= {
                                 "token":token,
                                 "newPassword": newPassword,
                             }
                             const key = constants.subscriptionKey;
                             const url =  constants.setNewPassword;
                        axios.post(url,data, { headers: { "Ocp-Apim-Subscription-Key" :key} })
                        .then(response=>{
                            this.openUpload('Success', 'Password Changed Successfully');
                            
                        })
                        .catch(error=>{
                            this.openUpload('Failed to Change Password', error.response.data.message);
                        });
                
            
        }

        render() {
            const { errors,  showUpload,header, body, newPassword, isEnablePassword } = this.state;
            return (
                <>
                <Header />  
                    <div className="container-fluid"  style={{marginTop: '40vh'}}>
                        <form> 
                             <h2 style={{marginBottom: '30px'}}>Reset Password</h2>  
                                <div  className="Reset-password-container">
                                   
                                            <p  >New Password</p>      
                                            <input
                                                type="password"
                                                name="newPassword"
                                                placeholder="New Password"
                                                onChange={this.handleChange}
                                                value= {newPassword}
                                                required
                                                onFocus = {()=>{errors.newPassword = true}}
                                                onBlur = {()=>{errors.newPassword = false}}
                                            />
                                            <br />
                                            {(errors.newPassword == true)?
                                                 <div className='error-message mt-2 border p-3 '>
                                                    <p className='invalid'><img src= {passwordLock} style = {{marginRight: "10px"}}/><b>Your password needs to:</b></p>
                                                    <div className='p-1'>   
                                                        <p  className= {(errors.newupperCase == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newupperCase == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Uppercase
                                                        </p>
                                                        <p  className= {(errors.newlowerCase == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newlowerCase == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Lowercase
                                                        </p>
                                                        <p  className= {(errors.newdigit == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newdigit == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one digit
                                                        </p>
                                                        <p  className= {(errors.newspecialChar == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newspecialChar == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Special Characters
                                                        </p>
                                                        <p  className= {(errors.newminLength == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newminLength == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least minimum 8 characters</p>
                                                    </div>
                                             </div>
                                             :
                                             ""
                                            }
                                </div>

                                <div className="Reset-password-container">
                                             <p >Confirm Password</p>  
                                            <input
                                                type="password"
                                                name="confirmNewPassword"
                                                placeholder=" Confirm Password"
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <br />
                                            {errors.confirmNewPassword.length > 0 && (
                                                <span style={{ color: 'red' }}>{errors.confirmNewPassword}</span>
                                            )}
                                </div>
                                        <button  style={{marginBottom: '20px'}} className= {isEnablePassword ? "change-password-button-disable": "change-password-button"} disabled = {isEnablePassword} onClick={(event:any)=>this.saveNewPassword(event)}>Reset Password</button>

                        </form>
                    </div>
                    <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any)=>this.onHideUpload(event)}/>
                <Footer />
                </>
            )
        }

}
export default ResetPassword;