import React, { Component } from 'react';
import axios from 'axios';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import { constants } from '../../../../constants/constants';
import Carousel from "react-bootstrap/Carousel";
import Modal from 'react-awesome-modal';
import ruler from '../../../../assets/images/ruler.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import Linkedinwithround from '../../../../assets/images/Linkedinwithround.svg';
import Rating from '@material-ui/lab/Rating';
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import Image from "../../../common-components/Image";
import Upload from "../../../../assets/images/upload.svg";
import { blobService } from '../../../common-components/Blob';
import {Card } from "react-bootstrap";
import rightarrow from '../../../../assets/images/rightarrow.svg';
import { Link } from 'react-router-dom';
import Loader from '../../../common-components/Loader';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import Project1 from '../../../../assets/images/projects1.jpg';
import Project3 from '../../../../assets/images/project3.jpg';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import './ViewArchitect.scss';
import ViewArchitectPortfolio from '../ViewArchitectPortfolio/ViewArchitectPortfolio';
interface ViewArchitectProps{
    location?:any
      name?: any;
    value?: any;
    project?: any;
    isView?: boolean;
}
interface ViewArchitectState{
    error:boolean;
    ErrMsg:any;
    isLoading: boolean;
    architect: any;
    projects: any;
    images: any[];
    errors: {};
    nextIcon: any;
    prevIcon: any;
    listItems: any;
    isEdit: boolean;
    type: string,
    architectLogo: string,
    showProject: boolean,
    showUpload: boolean,
    header: string,
    body: string,
    architectName:string,
    city: string,
    twitterLink: string,
    facebookLink: string,
    instagramLink: string,
    about: string,
    portfolio: any,
    selectedProject: any,
    action: string,
    bannerImages: any[],
    architectModal: boolean,
    showModal: boolean,
    addressLine1: string, 
    addressLine2: string,
    state: string, 
    country: string, 
    pincode: number,
    openHire: boolean,
    quoteModal: boolean,
    projectId: number,
    nextDisabledIcon: any,
    prevDisabledIcon: any
}
class ViewArchitect extends Component <ViewArchitectProps,ViewArchitectState>{
   constructor(props:ViewArchitectProps) {
       super(props)
   
       this.state = {
        isLoading: false,
        architect: {},
        projects:[],
        type: 'text',
        errors: {},
            city: '',
            images: [],
            bannerImages: [],
            addressLine1: "",
            addressLine2: "", 
            state: "", 
            country: "",
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            nextDisabledIcon: <span className="icon-button-disabled "><img src={RightArrow}></img></span>,
            prevDisabledIcon: <span className="icon-button-disabled "><img src={LeftArrow}></img></span>,
            pincode: 0,
            listItems: [],
            isEdit: false,
            architectLogo:'',
            showUpload: false,
            showProject: false,
            header: '',
            body: '',
            architectName: '',
            twitterLink: '',
            facebookLink: '',
            instagramLink: '',
            about: '',
            portfolio:[],
            selectedProject:{},
            action:'',
            architectModal: false,
            showModal: false,
            error:false,
            ErrMsg:"",
            openHire: false,
            quoteModal: false,
            projectId: 0,
          
       }
   }
   
    componentDidMount(){
        this.init();
    }
    openHireModal = ()=>{
        this.setState({
            openHire : true
        })
    }
    init=()=>{
       
        this.fetchArchitect((this.props.location.state.data.userId));
        
    }
    onShowModal(event:any){
        this.setState({ showModal: true, header: "Contact Details" });
    }
    onHideModal(event:any){
        this.setState({ showModal: false });
    }
    async loadImages(path:string){
        return await blobService.fetch(path);
    };
    openProject = async(item:any) => {
        this.setState({showProject:true, selectedProject: item, action: "View"});
    }
    onHideArchitectModal = async(event:any) => {
        this.setState({architectModal:false});
    }
    fetchArchitect=(id:any)=>{
    const user = JSON.parse(localStorage.getItem('user')!); 
    let token;
    if(user){
        token = 'Bearer ' + user;
      
   }
   const key = constants.subscriptionKey;
  const url=constants.fetchArchitectAPI+id;
   axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
   .then((response) => {
    let a = response.data;
    if( a.address.addressLine1 !== null || a.address.addressLine2 !== null || a.address.state !== null ||  a.address.country !== null ||  a.address.city !== null ){
        this.setState({
        city:  a.address.city , 
        addressLine1:  a.address.addressLine1, 
        addressLine2:  a.address.addressLine2 , 
        state:  a.address.state, 
        country: a.address.country ,
        // pincode: a.address.pincode,
        })
    }

    this.setState({ 
        architect: a, 
        architectLogo: a.profileImage, 
        architectName: a.architectName, 
        twitterLink: a.socialLinks.twitterLink,
        facebookLink: a.socialLinks.facebookLink,
        instagramLink: a.socialLinks.instagramLink,
        about:a.about,
        bannerImages: a.bannerImages
    });
    this.fetchProjects(id);
    this.fetchPortfolio(id);
})
.catch((error) => {
    this.setState({ ErrMsg: error, error:true });
});


};
fetchPortfolio=(id:any)=>{
    const user = JSON.parse(localStorage.getItem('user')!); 
    let token;
     if(user ){
             token = 'Bearer ' + user;
           
        }
    const key = constants.subscriptionKey;    
    const url =  constants.getBuilderProtfolioProjectsAPI + id  +"?status=Completed";
    axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
        .then((response) => {
            this.setState({ portfolio: response.data });
        })
        .catch((error) => {
            this.setState({ ErrMsg: error, error:true });
        });

    }
fetchProjects=(id: any)=> {
    const user = JSON.parse(localStorage.getItem('user')!); 
    let token;
     if(user){
             token = 'Bearer ' + user;
        }

        const key = constants.subscriptionKey;
        const url = constants.getBuilderProtfolioProjectsAPI + id  +"?status=In Progress";
        axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
        .then((response) => {
            this.setState({ projects: response.data, isLoading: false });
        })
        .catch((error) => {
            this.setState({ ErrMsg: error, error:true });
        });

};
   onHideProject = async(event:any) => {
    this.setState({showProject:false});
    this.init();
   }
    render() {
        
        var items:any=[];
        var pItems:any=[];
        var subItems:any=[];
        var pList=[];
    
        const { isLoading, architect,bannerImages, projects,prevDisabledIcon,selectedProject, action, nextDisabledIcon, nextIcon, prevIcon, isEdit, architectLogo, header,architectName, city, twitterLink, facebookLink, about, portfolio,  showModal, addressLine1, addressLine2, state, country, showProject,pincode, instagramLink } = this.state;

        let address:any;
        if(addressLine1 == "" || addressLine1 == null){
            address = []
        }else{
            address =  addressLine1+","+addressLine2 +", "+city+", "+state+", "+country;
        }
 
        if(Object.keys(architect).length){

            if(bannerImages.length>0){
                items = bannerImages.map(
                    (item:any) => (
                        (<Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={blobService.createUrl(item)}
                            width="100%" height="350px"
                            />
                        </Carousel.Item>)          
                    ),
                );
            }else{
                items = (<Carousel.Item>
                <img
                className="d-block w-100"
                src={ProjectPlaceholder}
                width="100%" height="350px"
                />
                </Carousel.Item>)
            }
            for(let i = 0; i<bannerImages.length; i++){
                if(bannerImages[i]){
                    subItems.push(
                        <>
                      
                        <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                           
                            <span> <Image id={"bannerImage"+i} src={blobService.createUrl(bannerImages[i])} errorImg={Upload} alt="" width="100%" height="100px"/></span>
                        </div>
                      
                        </>
                    )
                }
                else{
                                   
                }
                
            }
          
    
        }

        if(portfolio.length > 0){
            pItems = portfolio.map(
                (item: any) => (
                    <Card className="left-buffer bottom-buffer portfolio-image" style={{ minHeight:"300px" }} onClick={(event:any)=>this.openProject(item)}>
       
                        {item.projectDetails.projectImages[0] ?
                        <Card.Img variant="top" src={blobService.createUrl(item.projectDetails.projectImages[0])} width="100%" height="150px" />
                        :
                        <Card.Img variant="top" src={blobService.createUrl(architectLogo)} width="100%" height="150px" />
                        }
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text>
                               {item.address? item.address.city:''}
                            </Card.Text>
                        </Card.Body>
                        </Card>
                )
            );}

        for(let i=0; i<projects.length; i++){
            var p = projects[i];
            
            pList.push(
         
                <li className="row no-gutters" >
                <div className="col-md-3">
                    {p.projectDetails.projectImages[0] && 
                    <img src={blobService.createUrl(p.projectDetails.projectImages[0])} alt=""  width="100%" height="100px"/>
                    ||
                    <img src={ProjectPlaceholder} alt=""  width="100%" height="100px"/>
                    }
                </div>
                <div className="col-md-7 p-4">
                <h4 className="projectname">{p.name}</h4>
                <h6>Location : {p.address ? p.address.city : "NA"}</h6>
                <p>{p.description}</p>
                </div>
                 <div className="col-md-2 row">
                    {/* <span className="align-self-end ml-auto mb-3">
                        <Link className="text-center my-3" to={{ pathname: "/ViewArchitectProject", state:{data: p}}}><h6 className="text-center viewp-font">View Project 
                        <img className = "view-pro-arrow" src={rightarrow} alt="" /></h6></Link>
                    </span> */}
                </div>
            </li> 
            )
        }
        return isLoading ? (<Loader/>): 
        (
            <>
            <Header/>
            <div className="bg-img">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}}>{architectName}</h1>
                                <p className="my-3"><Link to="/Architects" className="link-text">Architect</Link> / {architectName}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aboutArchitectProfile">
                    <div className="container py-3">
                        <div className="row mt-3">
                        <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                            <span>
                              { (items.length>1)? 
                              <Carousel  nextIcon={nextIcon} prevIcon={prevIcon} >
                                 {items}
                             </Carousel>
                              :
                             <Carousel className = "disabled-arrow" nextIcon={nextDisabledIcon} prevIcon={prevDisabledIcon} >
                                 {items}
                             </Carousel> 
                                 }
                                    </span>
                                <div className="row">
                                        {subItems}
                                </div>
                            </div>
                        </div>
                            
                        </div>

                            <div className="col-md-6">
                                <div className="projectInfo">
                                   <span>
                                            <div className="col-md-4 compyLogo">
                                                <Image src={blobService.createUrl(architectLogo)} className="other-image" alt="" width="100%"  />
                                            </div>
                                            <h3 className="mt-3">{architectName}</h3>
                                            <p>{city}</p>
                                            
                                     </span>

                                    
                                    {/* <p className="d-flex align-items-center">
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        (3 Reviews)
                                    </p> */}
                                
                                        <div>
                                            {(twitterLink == null || twitterLink == "") ?
                                                <a  target="_blank">
                                                    <img  src={Twitterwithround} alt="" />
                                                </a>
                                            :
                                                <a href={architect.socialLinks.twitterLink} target="_blank">
                                                    <img  src={Twitterwithround} alt="" />
                                                </a>
                                             }
                                            {(facebookLink == null || facebookLink == "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={FBwithround} alt="" />
                                            </a>
                                                :
                                                <a href={architect.socialLinks.facebookLink} target="_blank" className="left-buffer">
                                                    <img  src={FBwithround} alt="" />
                                                </a>
                                            }
                                            {(instagramLink == null || instagramLink == "") ?
                                                <a  target="_blank" className="left-buffer">
                                                    <img  src={Linkedinwithround} alt="" />
                                                </a>
                                            :
                                                <a href={ architect.socialLinks.instagramLink} target="_blank" className="left-buffer">
                                                    <img  src={Linkedinwithround} alt="" />
                                                </a>
                                             }
                                               
                                        </div>

                        <div className="mt-4">
                            <button className="button1 contact" onClick={(event:any)=>this.onShowModal(event)} >Contact <img src={RightArrow} style={{height:"12px",width:"20px",marginLeft:"0.25rem"}}/></button>
                            
                        </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="my-5">
                            <h4>About</h4>
                          { ( about===null || about==="")?
                          <p style={{ lineHeight: '30px' }}>{""}</p> 
                             :   <p style={{ lineHeight: '30px' }}>{about}</p>
                             
                             }
                    </div>
                     
                  
                        <div className="my-5">
                            <h4 className="my-3 ">Portfolio</h4>
                            {pItems.length > 0 &&
                            <div className="row">
                            {pItems}
                            </div>
                           ||
                            <div className="buildersBlock py-5 empty-Findproject-Container">
                                    <div className="container-fluid empty-Findproject-Content2">
                                            <img src={ruler} alt="" />
                                        <p className="text-center my-3">There are no portfolio. We will soon have some for you.</p>
                                    </div>
                            
                                </div>}
                              </div>
                        
                        <div className="my-5">
                            <h4 className="my-3">Projects</h4>
                            {projects.length < 1 ? 
                                <div className="architectsBlock py-5 empty-Findproject-Container">
                                    <div className="container-fluid empty-Findproject-Content2">
                                        <img src={ruler} alt="" />
                                        <p className="text-center my-3">There are no live projects. We will soon have some for you.</p>
                                    </div>
                                </div>
                                : 
                                <ul>{pList}</ul>
                            }

                        </div>
                        {/* <div className="my-5">
                            <h4 className="my-3">Reviews</h4>
                            <ul>
                                <li className="row no-gutters">
                                    <div className="col-md-3">
                                        <img src={Project1} alt="" />
                                    </div>
                                    <div className="col-md-7 p-4">
                                        <h4>Johnie Deo</h4>
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed consetetur
                                            sadipscing elitr,{' '}
                                        </p>
                                    </div>
                                </li>

                                <li className="row no-gutters">
                                    <div className="col-md-3">
                                        <img src={Project3} alt="" />
                                    </div>
                                    <div className="col-md-7 p-4">
                                        <h4>House side villa</h4>
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed consetetur
                                            sadipscing elitr,{' '}
                                        </p>
                                    </div>
                                </li>
                            </ul>

                        </div> */}
                        </div>
                        <CustomModal isAlert={true} show={showModal} header={header} 
                        body=
                        { 
                        (address.length == 0  && architect.mobile == '' ) 
                        ? <div><p>Address & Contact Details Are Not Available. </p><p>Email : {architect.email}</p></div> : 
                        <div>{( address.length == 0 || address == null) ? "Address Is Not Available."
                        : <p>Address : {address}</p> }
                      
                        {( architect.mobile == '' || architect.mobile == 'Not available') ?"Contact Details Is Not Available." 
                        : <p>Call on : {architect.mobile}</p> }{( architect.email == null) ?"Email Id Is Not Available." : <p>Email : {architect.email}</p> }</div>
                     }
                       
                           onHide={(event:any)=>this.onHideModal(event)}/>
             </div>
            <Modal width="80%"  class="aboutBuilderProfile" effect="fadeInUp" 
                onClickAway={(event:any) => this.onHideProject(event)}
                visible={showProject}  >
                {showProject && action==="View" &&
                         <ViewArchitectPortfolio project={selectedProject} onHideProject={this.onHideProject} />
                        } 
            </Modal>
            <Footer/>
            </>
        )
    }
}
export default ViewArchitect;