import React, { Component, Fragment } from 'react';
import Footer from '../../../common-components/Footer/Footer' ;
import Header from '../../../common-components/Header/Header';
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Loader from '../../../common-components/Loader';
import { Link } from 'react-router-dom';
import Edit from '../../../../assets/images/edit.svg';
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import Info from "../../../../assets/images/info.svg";
import Modal from "react-bootstrap/Modal";
import { blobService } from '../../../common-components/Blob';
import Carousel from "react-bootstrap/Carousel";
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import Delete from "../../../../assets/images/delete.svg";
import Image from "../../../common-components/Image";
import Upload from "../../../../assets/images/upload.svg";
import { RouteComponentProps } from 'react-router';
import Check from '../../../Services/Check';
import './viewMyProject.scss';
import RejectButton from '../../../../assets/images/RejectButton.svg';
import AcceptButton from '../../../../assets/images/AcceptButton.svg';
import longarrowaltright from '../../../../assets/images/longarrowaltright.svg';
import QuoteConfirmationModal from '../../../common-components/QuoteConfirmationModal/QuoteConfirmationModal';
import emptyQuote from '../../../../assets/images/emptyQuote.svg';
import downloadfile from '../../../../assets/images/downloadfile.svg';
import HireMultipleBuilders from '../../Customer/HireMultipleBuilders/HireMulipleBuilders';
import CreateQuote from '../../../common-components/CreateQuote/CreateQuote';
import Vector from  "../../../../assets/images/Vector.svg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface ViewMyProjectProps {
    name?: any;
    value?: any;
    id?: string;
    location?:any
}
interface ViewMyProjectState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    isEdit: boolean,
    isPublic: boolean,
    projectName: string,
    type: string,
    errors: {
        file1:string,
        file2:string,
        file3:string,
        file4:string,
        description: string,
        projectName: string,
        adLine1: string,
        adLine2: string,
        landSize: string,
        mobile: string,
        pincode: string
    };
    nextIcon: any,
    prevIcon: any,
    project: any,
    projectModal: boolean,
    projectHeader: string,
    projectBody: string,
    builderType: string,
    description: string,
    landSize: string,
    measurementUnit: string,
    adLine1: string,
    adLine2: string,
    city: string,
    state: string,
    country: string,
    pincode: string,
    mobile: string,
    landSaleContract: string,
    architectDrawings: string,
    engineeringPlans: string,
    landscapingDesigns: string,
    projectImages: any,
    showUpload: boolean,
    header: string,
    body: string,
    selectedFile1: any,
    selectedFile2: any,
    selectedFile3: any,
    selectedFile4: any,
    stateList: any,
    pincodeList: any,
    cityList:any,
    formAddressData: any,
    formOtherData: any,
    activeli: any,
    builderQuotes: any[],
    architectQuotes: any[];
    myQuotesBuilder: any[];
    myQuotesArchitect: any[];
    quoteConfirmationModal: boolean;
    quoteStatus: string;
    quoteCreatedBy: string;
    userId: any;
    name: string;
    quoteUpdatedStatus: string;
    projectStatus: string;
    openHire: boolean;
    quoteModal: boolean;
    builderId: any;
    projectIds: any;
    openRequestChangeModal: boolean,
    changeRequest: string,
    isSubmitted: boolean,
}
const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
export default class ViewMyProject extends React.Component<ViewMyProjectProps & RouteComponentProps, ViewMyProjectState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            error:false,
            ErrMsg:"",
            isLoading: true,
            isEdit: false,
            projectName: '',
            isPublic: false,
            type: 'text',
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            errors: {
                file1:'',
                file2:'',
                file3:'',
                file4:'',
                description: '',
                projectName: '',
                adLine1: '',
                adLine2: '',
                landSize: '',
                mobile: '',
                pincode: '',
            },
            project:{},
            projectModal: false,
            projectHeader: '',
            projectBody: '',
            builderType:'',
            description:'',
            landSize: '',
            measurementUnit:'',
            adLine1: '',
            adLine2: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            mobile: '',
            landSaleContract: '',
            architectDrawings: '',
            engineeringPlans: '',
            landscapingDesigns: '',
            projectImages:[],
            showUpload: false,
            header: '',
            body: '',
            selectedFile1: '',
            selectedFile2: '',
            selectedFile3: '',
            selectedFile4: '',
            stateList: [],
            pincodeList: [],
            cityList:[],
            formAddressData: [],
            formOtherData: [],
            activeli : [true,false,false,false,false],
            builderQuotes: [],
            architectQuotes: [],
            myQuotesBuilder: [],
            myQuotesArchitect: [],
            quoteConfirmationModal: false,
            quoteStatus: '',
            quoteCreatedBy: '',
            userId: '',
            name: '',
            quoteUpdatedStatus: '',
            projectStatus: '',
            openHire: false,
            quoteModal: false,
            builderId: [],
            projectIds: [],
            openRequestChangeModal: false,
            changeRequest: "",
            isSubmitted: true
        };
        this.state = initialState;
    }

    componentDidMount() {
        this.init();
    }

    init(){
        if(this.props.location.state){
            this.state.projectIds.push(this.props.location.state.id);
            this.setState({
                projectIds : this.state.projectIds
            })
            this.viewProject(this.props.location.state.id); 
        }else{
            this.props.history.push('/Projects')
        }
        this.fetchStateList();
        this.fetchCityList();
    }
    async fetchStateList(): Promise<any> {
        Check.checkToken()
        .then(
           async () => {
        const options = {
            url: constants.fetchStatesListAPI,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({stateList: response.data})
                
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        })
    }
    async fetchCityList(): Promise<any> {
        Check.checkToken()
        .then(
           async () => {
            const options = {
                url: constants.fetchAllCity,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    if(response.status == 200 ){
                        let cityList = response.data.list;
                        cityList.map((item:any)=>{
                            this.state.cityList.push(item);
                        })
                        this.setState({cityList: this.state.cityList});
                    }
                })
                .catch((error) => {
                    this.setState({ cityList: [] })
                    this.setState({ ErrMsg: error, error:true });
                });
    })

    }

    async viewProject(id: any): Promise<any> {
        Check.checkToken()
        .then(
           async () => {
        const options = {
            url: constants.fetchProjectAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let p = response.data;
                this.setState({ project: p, isLoading: false });
                this.setState({ isPublic: p.public, description: p.description, landSize: p.projectDetails.landSize, measurementUnit: p.projectDetails.measurementUnit.description, projectName: p.name,
                adLine1 : p.address.addressLine1,
                adLine2 : p.address.addressLine2,
                city : p.address.city,
                state : p.address.state,
                country : p.address.country,
                pincode : p.address.pincode,
                mobile : p.mobile,
                landSaleContract: p.projectDetails.projectDocs.landSaleContract,
                architectDrawings: p.projectDetails.projectDocs.architectDrawings,
                engineeringPlans: p.projectDetails.projectDocs.engineeringPlans,
                landscapingDesigns: p.projectDetails.projectDocs.landscapingDesigns,
                projectImages: p.projectDetails.projectImages,
                formAddressData: p.address, 
                formOtherData: p ,
                builderType:  p.projectDetails.builderType.builderType,
                projectStatus: p.projectStatus.projectStatus,
                })
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        })
    };

    showProjectModal = (head: any, body: any ) => {
        this.setState({projectModal: true, projectHeader: head, projectBody: body});
    };
    hideProjectModal = () => {
        this.setState({projectModal: false});
        this.init();  
    };

    updateProject = async() => {
        const { projectName, description, adLine1, adLine2, city, state, pincode, mobile, landSize}:any = this.state;

        if(projectName!==""&&description!==""&&adLine1!==""&&adLine2!==""&&city!==""&&state!=="" && mobile!== ""&& landSize!== "" &&pincode !== ""){
        Check.checkToken()
        .then(
           async () => {
        let userId = localStorage.getItem("id");
        let validity = true;
        Object.values(this.state.errors).forEach(
            (val: any) => val.length > 0 && (validity = false)
        );
          if(validity == true){
             var p = this.state.project;
             p.name = this.state.projectName;   
             const options = {
              url: constants.updateProjectAPI,
              data: {
                "id":p.id,
                "name": this.state.projectName || p.name,
                "description": this.state.description || p.description,
                 "address": {
                    "addressLine1": this.state.adLine1 || p.address.addressLine1,
                    "addressLine2": this.state.adLine2 || p.address.addressLine2,
                    "city": this.state.city || p.address.city,
                    "state": this.state.state || p.address.state,
                    "country": 'India',
                    "pincode": this.state.pincode || p.address.pincode,
                    "latitude":0,
                    "longitute":0
                },
                "mobile": this.state.mobile ||p.mobile,
                "projectDetails":{
                    "builderType": this.state.builderType || p.projectDetails.builderType.builderType,
                    "landSize": this.state.landSize || p.projectDetails.landSize,
                    "measurementUnit": this.state.measurementUnit || p.projectDetails.measurementUnit.description,
                    "projectDocs":{
                        "landSaleContract": this.state.landSaleContract || p.projectDetails.projectDocs.landSaleContract,
                        "architectDrawings": this.state.architectDrawings || p.projectDetails.projectDocs.architectDrawings,
                        "engineeringPlans": this.state.engineeringPlans || p.projectDetails.projectDocs.engineeringPlans,
                        "landscapingDesigns": this.state.landscapingDesigns || p.projectDetails.projectDocs.landscapingDesigns
                    },
                    "projectImages": this.state.projectImages || p.projectDetails.projectImages
                },
                "minBudget":p.minBudget,
                "maxBudget":p.maxBudget,
                "durationInMonths":p.durationInMonths,
                "publicProject": this.state.isPublic,
                "createdBy": p.createdBy,
                "updatedBy": userId,
                "builder": p.builder,
                "architect":p.architect
              }
            }
            var response = await userService.updateApiRequest(options);
            if(response){
                this.showProjectModal("Success", "Project Updated Successfully");              
                this.editProject();
            }
            else{
                this.showProjectModal("Failed", "Failed to Update Project");
            }
            
          }else{
          }
        }
        )
    }else{
    }
       }

    
    cancelProject(){
        this.clearErrorMsg();
        this.init();                
        this.editProject();
    }
    editProject=()=>{
        this.setState({
            isEdit: !this.state.isEdit
         });
    }
    clearErrorMsg = () =>{
        let errors = this.state.errors;
        errors.description = '';
        errors.mobile= '';
        errors.adLine1= '';
        errors.adLine2= '';
        errors.projectName= '';
        errors.landSize= '';

    }
    setPublic(event:any){
        if(this.state.isEdit == true){
            this.setState({isPublic: !this.state.isPublic});
        }
    }
    handleChange = (event : any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
          case 'name':
             break;
        case 'pincode':
            errors.pincode = (value.length < 6 || value.length > 6) ? 'Pincode must be 6 digit long' : '';
            break;
          case 'description':
                     errors.description = value.length > 250 ? 'You have reached your maximum limit of characters allowed': '';
            break;
          case 'projectName':
                errors.projectName = value.length > 255 ? 'You have reached your maximum limit of characters allowed': '';
            break;
          case 'adLine1':
                errors.adLine1 = value.length > 100 ? 'You have reached your maximum limit of characters allowed': '';
            break;
          case 'adLine2':
                errors.adLine2 = value.length > 150 ? 'You have reached your maximum limit of characters allowed': '';
            break;
          case 'landSize':
                errors.landSize = value.length > 4 ? 'You have reached your maximum limit of characters allowed': '';
            break;
          case 'mobile':
                errors.mobile = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
            break;
          default:
            break;
        }
       this.setState(Object.assign(this.state, { errors,[name]: value }));
    }
     
    openUpload = async(header:any, body: any) => {
        this.setState({showUpload:true, header: header, body: body});
    }
    removeImage = async(event:any, path:string) => {
        let id = event?.target.id;
        let pImages = this.state.projectImages;
        const i = pImages.indexOf(path);
        if (i > -1) {
            pImages.splice(i, 1);
        }
        this.setState({
            projectImages: pImages,      
        })
    }
    onFileChange = async (event: any) => {
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        
        if(file){
            formData.append('id', userId?userId:'');
            formData.append('type', "Project");
            formData.append('file', file);    
            const options = {
                url: constants.uploadFilesAPI,
                data: formData
            }
            Check.checkToken();
            const response = await userService.postFileApiRequest(options);
                if(response){
                    this.state.projectImages.push(response);
                    this.setState({projectImages: this.state.projectImages});
                    this.openUpload("Success", "File Uploaded SuccessFully");
                }else{
                    this.openUpload("Failed", "Failed to Upload File");
                }
        }else{
            // let errors = this.state.errors;
            // errors.file1 = 'Please Select File to Upload';
            // this.setState({errors: errors});
        }
    };

    onPDFFileChange = async (event: any) => { 
            let file = event.target.files[0];
            let id = event.target.id;
            if(id === "file1"){
                if(file && file.type != 'application/pdf'){
                    let errors = this.state.errors;
                    errors.file1 = 'Please upload only PDF file';
                    this.setState({errors: errors});
                }else if(file && file.size > 4000000){
                    let errors = this.state.errors;
                    errors.file1 = 'Please upload file below 4MB';
                    this.setState({errors: errors});
                }else{
                    let errors = this.state.errors;
                    errors.file1 = '';
                    this.setState({ selectedFile1: file, errors: errors}); 
                }
            }
            if(id === "file2"){
                if(file && file.type != 'application/pdf'){
                    let errors = this.state.errors;
                    errors.file2 = 'Please upload only PDF file';
                    this.setState({errors: errors});
                }else if(file && file.size > 4000000){
                    let errors = this.state.errors;
                    errors.file1 = 'Please upload file below 4MB';
                    this.setState({errors: errors});
                }else{
                    let errors = this.state.errors;
                    errors.file2 = '';
                    this.setState({ selectedFile2: file, errors: errors}); 
                }
            }
            if(id === "file3"){
                if(file && file.type != 'application/pdf'){
                    let errors = this.state.errors;
                    errors.file3 = 'Please upload only PDF file';
                    this.setState({errors: errors});
                }else if(file && file.size > 4000000){
                    let errors = this.state.errors;
                    errors.file1 = 'Please upload file below 4MB';
                    this.setState({errors: errors});
                }else{
                    let errors = this.state.errors;
                    errors.file3 = '';
                    this.setState({ selectedFile3: file, errors: errors}); 
                }
            }
            if(id === "file4"){
                if(file && file.type != 'application/pdf'){
                    let errors = this.state.errors;
                    errors.file4 = 'Please upload only PDF file';
                    this.setState({errors: errors});
                }else if(file && file.size > 4000000){
                    let errors = this.state.errors;
                    errors.file1 = 'Please upload file below 4MB';
                    this.setState({errors: errors});
                }else{
                    let errors = this.state.errors;
                    errors.file4 = '';
                    this.setState({ selectedFile4: file, errors: errors}); 
                }
            }
           
        };

    onFileUpload = async(e: any) => {
        let userId = localStorage.getItem("id");
        let id = e.target.id;
        if(id ==="file1"){
            let errors = this.state.errors;
            if(this.state.selectedFile1){
                errors.file1 = '';
                this.setState({errors: errors});
                const fileData = this.state.selectedFile1;
                const formData = new FormData();
                formData.append('id', userId?userId:'');
                formData.append('type', "ProjectDocument");
                formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data:formData
                }
                const response = await userService.postFileApiRequest(options);
    
                if(response){
                    this.setState({landSaleContract: response});
                    this.openUpload("Success", "File Uplaoded Successfully");
                }else{
                    this.openUpload("Failed", "Failed to Upload File");
                    this.setState({landSaleContract: ''});
                }
            }else{
                let errors = this.state.errors;
                errors.file1 = 'Please Select File to Upload';
                this.setState({errors: errors});
            }  
        }
        if(id ==="file2"){
            let errors = this.state.errors;
            if(this.state.selectedFile2){
                errors.file2 = '';
                this.setState({errors: errors});
                const fileData = this.state.selectedFile2;
                const formData = new FormData();
                formData.append('id', userId?userId:'');
                formData.append('type', "ProjectDocument");
                formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data:formData
                }
                const response = await userService.postFileApiRequest(options);
                if(response){
                    this.openUpload("Success", "File Uplaoded Successfully");
                    this.setState({architectDrawings: response});
                }else{
                    this.openUpload("Failed", "Failed to Upload File");
                    this.setState({architectDrawings: ''});
                }
            }else{
                let errors = this.state.errors;
                errors.file2 = 'Please Select File to Upload';
                this.setState({errors: errors});
            }

            
        }
        if(id ==="file3"){

            let errors = this.state.errors;
            if(this.state.selectedFile3){
                errors.file3 = '';
                this.setState({errors: errors});
                const fileData = this.state.selectedFile3;
                const formData = new FormData();
                formData.append('id', userId?userId:'');
                formData.append('type', "ProjectDocument");
                formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data:formData
                }
                const response = await userService.postFileApiRequest(options);
                if(response){
                    this.openUpload("Success", "File Uplaoded Successfully");
                    this.setState({engineeringPlans: response});
                }else{
                    this.openUpload("Failed", "Failed to Upload File");
                    this.setState({engineeringPlans: ''});
                }
            }else{
                let errors = this.state.errors;
                errors.file3 = 'Please Select File to Upload';
                this.setState({errors: errors});
            }

           
        }
        if(id ==="file4"){

            let errors = this.state.errors;
            if(this.state.selectedFile4){
                errors.file4 = '';
                this.setState({errors: errors});
                const fileData = this.state.selectedFile4;
                const formData = new FormData();
                formData.append('id', userId?userId:'');
                formData.append('type', "ProjectDocument");
                formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data:formData
                }
                const response = await userService.postFileApiRequest(options);
                if(response){
                    this.openUpload("Success", "File Uplaoded Successfully");
                    this.setState({landscapingDesigns: response});
                }else{
                    this.openUpload("Failed", "Failed to Upload File");
                    this.setState({landscapingDesigns: ''});
                }

            }else{
                let errors = this.state.errors;
                errors.file4 = 'Please Select File to Upload';
                this.setState({errors: errors});
            }

            
        }
    }; 
    remove = (fileNm: any)=>{
            if(fileNm === "file1"){
                this.setState({landSaleContract: '', selectedFile1:''});
            }
            if(fileNm === "file2"){
                this.setState({architectDrawings: '', selectedFile2:''});
            }
            if(fileNm === "file3"){
                this.setState({engineeringPlans: '', selectedFile3:''});
            }
            if(fileNm === "file5"){
                this.setState({landscapingDesigns: '', selectedFile4:''});
            }
    }

    hasChangedSinceInitialState = () => {
            const { formAddressData, formOtherData } = this.state;
            return (formAddressData.city === this.state.city && 
                formAddressData.state === this.state.state &&
                formAddressData.pincode == this.state.pincode &&
                formAddressData.addressLine1 === this.state.adLine1 &&
                formAddressData.addressLine2 === this.state.adLine2 &&
                formOtherData.name === this.state.projectName &&
                formOtherData.description === this.state.description &&
                formOtherData.projectDetails.landSize == this.state.landSize &&
                formOtherData.projectDetails.measurementUnit.description  == this.state.measurementUnit &&
                formOtherData.mobile == this.state.mobile &&
                formOtherData.projectDetails.builderType.builderType == this.state.builderType &&
                formOtherData.public == this.state.isPublic 
                );
                
    }
//Changing tabs 
    changeActive=(e:any)=>{
            const {activeli}:any = this.state 
            let newArrey = []
            for (let index = 0; index < activeli.length; index++) {
            if(index===e){
                newArrey.push(true)
            }else{
                newArrey.push(false)
            }
            }
            this.setState({
            activeli:newArrey
            })
            if( e == 1 || e == 2 ){
                this.fetchCustomerQuotes(e);
            }
           
    }

//Fetch Myquote, BuilderQuotes and ArchitectQuotes      
    async fetchCustomerQuotes(activeli: any): Promise<any> {
            let projectId = this.props.location.state.id;
            let createdBy: any;
            if( activeli == 1){
                    createdBy = "Customer"

            }else if( activeli == 2){
                    createdBy = "Builder"
            }
            // else if( activeli == 3){
            //         createdBy = "Architect"
            // }
                const options = {
                    url: constants.fetchCustomerQuotes + projectId +"?createdBy="+createdBy  ,
                };
                userService
                .getApiRequest(options)
                .then((response: any) => {
                if(response.data.builders || response.data.architects){
                    if( activeli == 1){
                        this.setState({ 
                            myQuotesBuilder: response.data.builders,
                            myQuotesArchitect: response.data.architects,
                        })
    
                    }else if( activeli == 2){
                        this.setState({ 
                            builderQuotes: response.data.builders,
                        })
                    }
        
                }         
                })
                .catch((error) => {
                    this.setState({ ErrMsg: error, error:true });
                });
        }

// After Accepting/Rejecting any Builder dispaly confirmation modal
    showQuoteConfirmationModal  (status:string, createdBy:string, id:any, names:string){
                this.setState({
                    quoteConfirmationModal: true, 
                    quoteStatus: status,
                    userId: id,
                    quoteCreatedBy: createdBy,
                    name: names,              
                })    
        } 

// After Accepting/Rejecting any Builder (updating quote status Successfully)    
    quoteConfirmsuccess(event: any) {
            if(event == true) {
                this.setState({ 
                    quoteConfirmationModal: false  
                })
            }else{
                this.setState({ 
                    quoteConfirmationModal: false, 
                    showUpload: true, 
                    header: "Successful", 
                    body: event  
                });

            }
        }
// After Accepting/Rejecting any Builder (failed to update quote status) 
    quoteConfirmFailure(event: any) {
                this.setState({ 
                    quoteConfirmationModal: false, 
                    showUpload: true,  
                    header: "Failure",
                    body: event
                    });
        }

    onHideUpload = async(event:any) => {
            this.setState({showUpload:false});
            if(this.state.quoteCreatedBy == "builder"){
                 this.fetchCustomerQuotes(2);
                 this.init();

            }
            
        }
        
        openHireBuilderModal = ()=>{
            this.setState({
                openHire : true
            })
        }

        hiresuccess(event: any) {
            this.setState({ openHire: false, quoteModal: true, builderId: event  })   
            
          }
          onHideMultipleBuilders= async(event:any) => {
                this.setState({
                    openHire:false
                })
            }
          quoteSuccess (event: any){
            if(event == true) {
                this.setState({ quoteModal: false  })
            }else{
                this.setState({ quoteModal: false, showUpload: true, header: "Successful", body: event })    
            }   
          }
          quoteFailure = (event: any) =>{
                this.setState({ quoteModal: false, showUpload:true, header: "Failure", body: event });
          }

          showRequestChangeModal = (createdBy:string, id:any, names:string, quoteStatus: string) =>{
                this.setState({
                    openRequestChangeModal: true, 
                    userId: id,
                    quoteCreatedBy: createdBy,
                    name: names, 
                    quoteStatus: quoteStatus
                })
          }
          closeRequestChangeModal= ()=>{
            this.setState({
                openRequestChangeModal: false, 
            })
          }

          sendRequestChange = () =>{
            let { quoteCreatedBy, userId, changeRequest, quoteStatus}:any = this.state
            Check.checkToken()
            .then(
               async () => {

                 const options = {
                  url: constants.manageQuoteStatus,
                  data: {
                    "projectId": this.props.location.state.id,
                    "createdBy": quoteCreatedBy,
                    "userId":  userId,
                    "status": quoteStatus,
                    "requestChange": changeRequest
                  }
                }
            await userService.postApiRequest(options)
            .then((response)=>{
              
                this.openUpload("Success", "Request Change Sent SuccessFully");
                this.setState({
                     openRequestChangeModal: false
                })
               
            })
            .catch((error)=>{
                this.openUpload("Failed", "Request Change Failed");
            })      
            })
          }

          selectCity = (value: any)=>{
            if(value){
                this.setState({
                    city: value,
                   
                })
            }else{
                this.setState({
                    city: ''
                })
            }
        }

    render() {
        let { errors, isEdit, isPublic, isLoading, project, projectModal, projectImages, nextIcon, prevIcon, projectHeader, projectBody, builderType, description, landSize, measurementUnit, projectName, adLine1, adLine2, state, city, country, pincode, mobile, landSaleContract, architectDrawings, engineeringPlans, landscapingDesigns, showUpload, header, body, stateList, pincodeList,cityList, activeli,builderQuotes, architectQuotes, quoteConfirmationModal, quoteStatus, quoteCreatedBy, userId,name, myQuotesBuilder, myQuotesArchitect, projectStatus,  openHire, changeRequest, isSubmitted } = this.state;
        var addressObj;
        var address;
        var builder;
        var architect;
        let items:any=[];
        let subItems:any=[];
        let builders:any = [];
        // let architects:any = [];
        let isEnabled = true;
        if(projectName !== "" && description !== "" && adLine1 !== "" 
           && adLine2 !== "" && mobile !== ""  &&  landSize !== "" && pincode !== "" 
           && state !== "" && city !== ""&&  this.hasChangedSinceInitialState() == false){
                isEnabled = false
       }

        if(activeli[2] == true ){
            builders = builderQuotes
        }else{
            builders = myQuotesBuilder
        }
        // if(activeli[3] == true ){
        //     architects = architectQuotes
        // }else{
        //     architects = myQuotesArchitect
        // }


          
        let builderList = builders.map((list: any) =>{
            return (
                   <>
                   <li className = "no-gutters" style = {{display: "flex", width: "100%"}}>
                       <div className = "col-sm-2">
                           <Image src={list.logoPath? blobService.createUrl(list.logoPath):ProjectPlaceholder} alt="" width="100%" height="auto" errorImg={ProjectPlaceholder}/>
                       </div>
                       <div className = "col-sm-6 p-4 quote-page-content">
                          <h5>{list.name? list.name : ""}</h5>
                          <h6 style = {{fontWeight: 500}}>{list.cityName ? list.cityName : "NA"}</h6>
                          {(activeli[2]===true || activeli[3]===true)?
                          <h6>Quote:  <span>{list.price ? list.price : ""} INR </span> in <span>{list.durationInMonths? list.durationInMonths : ""} Months</span> <span>{list.provisionType? list.provisionType : ""}</span></h6>
                          :
                          ""
                          }
                          <p>{list.description? list.description : " "}</p>
                          {(activeli[2]===true || activeli[3]===true)?
                          <>
                          {(list.attachmentPath)?
                            <>
                                 <img src= {downloadfile}></img>
                          <a href= "javascript:void(0)" onClick = {() => {window.location.href = blobService.createUrl(list.attachmentPath) }} >{list.attachmentPath ? list.attachmentPath.split("/")[3] : "" }</a>
                            </>
                            :
                            ""
                          }
                          </>
                          :
                          ""
                        }
                       </div>
                       <div className = "col-sm-4 quote-page-buttons" >
                       {(activeli[2]===true || activeli[3]===true)?
                              <p>
                                  {(list.quote_status == "Pending")&&
                                  <>
                                    <span style = {{cursor: "pointer"}} onClick = {()=>{this.showRequestChangeModal("builder", list.userId, list.name, list.quote_status )}}>
                                        Request change
                                    </span>
                                    <span >
                                        <img style = {{cursor: "pointer"}} src = {RejectButton} onClick = {()=>{this.showQuoteConfirmationModal("reject","builder", list.userId, list.name)}}/>
                                    </span>
                                    <span>
                                        <img style = {{cursor: "pointer"}} onClick = {()=>{this.showQuoteConfirmationModal("accept","builder", list.userId, list.name)}} src = {AcceptButton}/>
                                    </span>
                                    </>
                                    ||
                                    (list.quote_status == "accept")&&
                                        <span style ={{color: "#00A824"}}>
                                            Accepted
                                        </span>  
                                     ||
                                    (list.quote_status == "reject")&&
                                        <span style = {{color: "#FF4949"}}>
                                                Rejected
                                        </span>
                                }   
                              </p>
                           :
                           <p></p>
                           }
                            <span>
                            <Link className="text-center my-3" to={{ pathname: "/ViewBuilder", state:{id: list.userId}}}><h6 className="text-center">View Builder  <img className = "view-Project-Arrow" src={longarrowaltright} alt="" /></h6></Link>
                            </span>
                       </div>
                   </li>
                   </>
               )
                   
           })
             
        let architectList = myQuotesArchitect.map((list: any) =>{
            return (
                   <>
                   <li className = "no-gutters" style = {{display: "flex", width: "100%"}}>
                       <div className = "col-sm-3">
                           <Image src={list.logoPath? blobService.createUrl(list.logoPath):ProjectPlaceholder} alt="" width="100%" height="auto" errorImg={ProjectPlaceholder}/>
                       </div>
                       <div className = "col-sm-7 p-4 quote-page-content">
                          <h5>{list.architectName}</h5>
                          <h6>{list.cityName ? list.cityName : "NA"}</h6>
                          <p>{list.about}</p>
                       </div>
                       <div className = "col-sm-2 quote-page-buttons" >
                       {(activeli[2]===true || activeli[3]===true)?
                           <p><span ><img src = {RejectButton}/></span><span><img src = {AcceptButton}/></span></p>
                           :
                           <p></p>
                           }
                       <Link className="text-center my-3" to={{ pathname: "/ViewArchitect", state:{id: list.userId}}}><h6 className="text-center">View Architect  <img className = "view-Project-Arrow" src={longarrowaltright} alt="" /></h6></Link>
                       </div>
                   </li>
                   </>
               )
                   
           })
 
        if(Object.keys(project).length){
            addressObj = project.address;
            address = addressObj.addressLine1 +", "+ addressObj.addressLine2 +", "+ addressObj.city +", "+ addressObj.state +", "+ addressObj.country + "-" + pincode;
            builder = project.builder;
            architect = project.architect;
            isPublic = project.public;
            // builderType = project.projectDetails.builderType.builderType;
        }

        if(projectImages.length>0){
            items = projectImages.map(
                (item:any) => (
                    (<Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={blobService.createUrl(item)}
                        width="100%" height="350px"
                        />
                    </Carousel.Item>)          
                ),
            );
        }else{
            items = (<Carousel.Item>
            <img
            className="d-block w-100"
            src={ProjectPlaceholder}
            width="100%" height="350px"
            />
            </Carousel.Item>)
        }
        for(let i = 0; i<4; i++){
            if(projectImages[i]){
                subItems.push(
                    <>
                    {isEdit && 
                    <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                        <span>
                            <img className="close" id={"projectImage"+i} src={Delete} onClick={(event)=>{this.removeImage(event, projectImages[i])}}/>
                            <Image id={"projectImage"+i} src={blobService.createUrl(projectImages[i])} errorImg={Upload} alt="" width="100%" height="100px"/>
                        </span>    
                    </div>
                    || 
                    <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                        <span> <Image id={"projectImage"+i} src={blobService.createUrl(projectImages[i])} errorImg={Upload} alt="" width="100%" height="100px"/></span>
                    </div>
                    }
                    </>
                    // <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                    //     <img src={blobService.createUrl(projectImages[i])} alt="" width="100%" height="100px"/>
                    // </div>
                )               
            }else{
                subItems.push(
                    <>
                        {isEdit && 
                            <div className="col-md-3 bottom-buffer compyLogo img-wrap"> 
                             <label htmlFor={"bannerImages"}>
                                <span><h6 className="left-tab"><img src={Upload}/>Logo</h6></span>
                                </label>
                                <input id={"bannerImages"} type="file" style={{display:"none"}}  onChange={(event)=>this.onFileChange(event)}/>
                                </div>   
                        || <div/> }
                    </>
                )                       
            }
        }

        return isLoading ? (<Loader/>): 
        (
        <>
        <Header/>
        {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
            {/* <div className="bg-img">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-2 col-12">
                                <h1>{project.name}</h1>
                                <p className="my-3">My Projects / {project.name}</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {!isEdit ?
                            <div className="col-md">
                                <button className="button2 ml-1 contact" onClick={()=>{this.editProject()}}>Edit Project<img src={Edit}/></button>
                            </div> :
                            <div className="col-md">
                                <button className="button2 contact" onClick={()=>{this.updateProject()}}>Save</button>
                                <button className="button2 contact" onClick={()=>{this.cancelProject()}}>Cancel</button>
                            </div>}
            </div> */}

                <div className="bg-img">                   
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}}>{project.name}</h1>
                                {activeli[0]===true ?<p className="my-3"><Link to="/MyProjects" className="link-text">My Projects</Link> / {project.name}</p>:""}
                                 {activeli[1]===true ?<p className="my-3"><Link to="/MyProjects" className="link-text"  >My Projects</Link>/ {project.name} /Quotes/ My Quotes</p>: ""}
                                 {activeli[2]===true ?<p className="my-3"><Link to="/MyProjects" className="link-text">My Projects</Link>/ {project.name} /Quotes/ Builder Quotes</p>: ""}
                                 {/* {activeli[3]===true ?<p className="my-3"><Link to="/MyProjects" className="link-text">My Projects</Link>/ {project.name} /Quotes/ Architect Quotes</p>: ""} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {activeli[0]===true ?
                    <>
                        {!isEdit ? (
                                        <div className="col-md">
                                            <button
                                                className="button3 newBut contact"
                                                onClick={() => {
                                                    this.editProject();
                                                }}
                                            >
                                                Edit Project
                                                <img src={Edit} className="ml-1" />
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="col-md">
                                            <button
                                                className= {isEnabled == true ? "button5 save2 contact disabled-button" :  "button5 save2 contact"} 
                                                onClick={() => {
                                                    this.updateProject();
                                                }} disabled={isEnabled}>
                                                Save
                                            </button>
                                            <button
                                                className="button5 cancel2 contact "
                                                onClick={() => {
                                                    this.cancelProject();
                                                }}>
                                                Cancel
                                            </button>
                                        </div>
                                    )}
                    </>
                                :
                                ""
                            }
                </div>

            <div className="aboutProject">
            <div className="container py-5">
                <div className="row align-items-center top-navbar">
                        <h4 className={activeli[0]===true ? 'active' : ''} style={{marginBottom:'-2%',color:"#16213E"}} onClick={()=>{this.changeActive(0)}}>About</h4>
                        <h4 className={activeli[1]===true ? 'active' : ''} onClick={()=>{this.changeActive(1)}} style = {{marginLeft: "40px", marginRight: "40px",marginBottom:'-2%'}}>Quotes</h4>
                </div>
                <div style={{display:activeli[0]===true?"":"none"}}>
                <div className="row mt-5">
                <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <img
                                    className="d-block w-100"
                                    src={ProjectPlaceholder}
                                    width="100%" height="350px"
                                    />
                                    {/* <span>
                                    <Carousel nextIcon={nextIcon} prevIcon={prevIcon} >
                                        {items}
                                    </Carousel><br/>
                                    </span>
                                    <div className="row">
                                            {subItems}
                                    </div> */}
                                </div>
                            </div>
                    </div>
                    
                    <div className="col-md-6">
                    <div className="projectInfo">
                        {this.state.isEdit===true &&
                        <>
                        <input 
                            name="projectName"
                            type={this.state.type}
                            value={projectName}
                            autoFocus
                            placeholder= "* Project name"
                            onChange={this.handleChange}
                        />
                        {errors.projectName.length > 0 && (
                            <p style={{ color: 'red' }}>{errors.projectName}</p>
                        )}
                        </>
                        ||
                        <span>
                             <h4 className="projectname">{projectName}</h4>
                        </span>}
                        
                        <div style = {{ marginTop: "5px"}} className="col-md-5 row"> 
                        <span style = {{marginRight: "9px", marginTop: "5px"}}>  Status: </span>
                        <span>    
                            {(project.projectStatus.projectStatus === "In Progress") && 
                                <div className=" btn-text pending-btn">
                                    In Progress
                                </div>
                            }
                            {(project.projectStatus.projectStatus === "Completed") && 
                                <div className=" btn-text completed-btn">
                                    Completed
                                </div>
                            }
                            {(project.projectStatus.projectStatus === "Cancelled") && 
                                <div className=" btn-text cancel-btn">
                                    Cancelled
                                </div>
                            }
                            {(project.projectStatus.projectStatus === "Pending") && 
                                <div className=" btn-text pending-btn">
                                    Yet to Assign
                                </div>
                            }
                            {(project.projectStatus.projectStatus === "InitiateContract") && 
                                <div className="btn-text pending-btn">
                                   Initiate Contract 
                                </div>
                            }
                        </span>
                        </div>
                        <br/>
                        
                        <div>
                        <ToggleSwitch
                            id="public"
                            small
                            checked={this.state.isPublic}
                            onChange={(event)=>this.setPublic(event)}
                            />
                            <label className="toggletext" htmlFor="daily">Public Project</label>
                        </div>
                        <div className="px-5">
                            <p className="light-content"><img src={Info}/> A builder will be able to view and quote to your project if selected public.</p>
                        </div>
                        <br/>
                        <h5>Address &amp; Contact</h5>
                        {isEdit && 
                        <div>
                        <div className='address'>
                        <input type="text" name="adLine1" className="px-3 py-1 adLine1" placeholder="* Door No" onChange={this.handleChange} value={adLine1} required />
                        {errors.adLine1.length > 0 && (
                                     <p style={{ color: 'red', width: '50%'}}>{errors.adLine1}</p>
                        )}
                            <span className="left-buffer"/>
                        <input type="text" name="adLine2" className="px-3 py-1 adLine2" placeholder="* Street" onChange={this.handleChange} value={adLine2} required />
                        {errors.adLine2.length > 0 && (
                                     <p style={{ color: 'red', width: '50%', marginLeft: '10px'}}>{errors.adLine2}</p>
                        )}

                    </div><br/>
                    <div className='city d-flex'>
                        <Autocomplete
                            id="combo-box-demo"
                            onChange={(event, newValue) => {
                                this.selectCity(newValue);
                            }}
                            style = {{width : "43%"}}
                            options={cityList}
                            getOptionLabel={(option:any) => option}
                            value = {city}
                            renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" name = "city"/>}
                        />
                        <span className="left-buffer"/>
                        <select name="state" id="state"  className="py-1 px-3 city" onChange={this.handleChange} value={state} required style={{width: '44%'}}>
                                                        <option value="">* State</option>
                                                        {stateList.map((item:any) => (
                                                            <option value={item.name} key={item.name}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                </select>
                        
                    </div>
                    <br/>
                            <div className="py-1 ">
                                <input type = "number" name="pincode" id="pincode"  className="py-1 px-3" onChange={this.handleChange} value={pincode} placeholder = "* Pincode" required onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }></input>
                                {errors.pincode.length > 0 && (
                                    <p style={{ color: 'red'}}>{errors.pincode}</p>
                                )}
                            </div><br/>
                    <div className='phoneNumber'>
                        <input type="number" name="mobile" className="px-3 py-1" placeholder="* Phone" value={mobile} onChange={this.handleChange} required onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }/>
                        {errors.mobile.length > 0 && (
                            <p style={{ color: 'red' }}>{errors.mobile}</p>
                        )}
                    </div>
                    </div> ||
                        <div>
                            <div className="light-content">{address}</div>
                            <div className="light-content">{project.mobile}</div>
                        </div>
                        }

                        <br/>
                        <h5 className="mt-4">Builder Assigned</h5>
                    <div className="light-content d-flex">
                        {(projectStatus == "Pending" || projectStatus == "InitiateContract")?
                             <>
                                {builder && builder.builderName || <div>None  <Link to="/Builders">(Find Builders</Link>/  <span className= "link-span" style = {{ color: "#007bff", cursor: "pointer"}} onClick={this.openHireBuilderModal} >Hire Builders</span>)</div>}
                            </>
                                :
                            <>
                                {builder && builder.builderName || <div>None  <Link to="/Builders">(Find Builders)</Link></div>}
                            </>
                        }
                    </div>
                       
                        <br/>
                        <h5 className="mt-4">Architect Assigned</h5>
                        <div className="light-content">{architect && architect.architectName || <div>None  <Link to="/Architects">(Find Architects)</Link></div>}</div>
                    </div>
                    </div>
                </div>

                <div className="my-5">
                    <h4 className="about">About the Project</h4>
                    {isEdit ? 
                    <div className='aboutProject '>
                        <textarea id="about" name="description" className="px-3" placeholder="* About the Project" rows={4} cols={50} onChange={this.handleChange} value={description} required />
                        {errors.description.length > 0 && (
                                     <span style={{ color: 'red' }}>{errors.description}</span>
                        )}
                    </div>
                    :
                    <p className="light-content" style={{lineHeight:"30px"}}>{project.description}</p>
                        }
                </div>

                <div>
                    <h4 className="projectdetail">Project Details</h4>    
                    <div className="row mt-4">
                        <div className="col-md-3">
                            <h5 className="buildertype mt-1">Builder Type</h5>
                                { isEdit? <select name="builderType" id="builderType"  className="px-3 py-1" onChange={this.handleChange} value={builderType} required>
                                    <option value="Commercial Building">Commercial Building</option>
                                    <option value="Residential Building">Residential Building</option>
                                    {/* <option value="IndependentHouse">Independent House</option> */}
                                    <option value="Apartment">Apartment</option>
                                    {/* <option value="GatedCommunity">Gated Community</option> */}
                                </select>
                                :
                                    <p className="light-content">{project.projectDetails.builderType.builderType}</p>
                                }
                           
                        </div>
                        <div className="col-md-6 landsizecontainer">
                            <h5 className="landsize mt-1">Land Size</h5>
                            { isEdit? 
                                <>
                                    <div className='landSize'>
                                        <input type="number" name="landSize" className="px-3" placeholder="* Land Size" onChange={this.handleChange} value={landSize} required onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }/>
                                    
                                        <span className="left-buffer">in</span>
                                        <select name="measurementUnit" id="measurementUnit"  className="px-3 py-1 no-border" onChange={this.handleChange} value={measurementUnit} required>
                                            <option value="Square Meter">Sq Meters</option>
                                            <option value="Square Feet">Sq Ft</option>
                                        </select> 
                                    </div>
                                    {errors.landSize.length > 0 && (
                                        <p style={{ color: 'red', width: '40%'}}>{errors.landSize}</p>
                                    )}
                                </>
                                :
                                <p className="light-content">{landSize} {measurementUnit}</p>
                            }

                        </div>
                    </div>
                </div>

                {/* <div className="my-5">
                <h4>Documents</h4>  
                {isEdit &&
                <>
                <div className="pdfblock file-name">
                Contract of Sale of Land <span className="left-tab"/>
                {!landSaleContract ?
                    <span>
                  <input type="file" id="file1" onChange={(event)=>this.onPDFFileChange(event)} /> 
                  <input type="button" className="button2 hire" id="file1" value="Upload" onClick={(event)=>this.onFileUpload(event)}/>
                  <span className="left-buffer"/> 
                  {errors.file1.length > 0 && <span style={{ color: 'red' }}>{errors.file1}</span>}
                  </span> 
                  : <span>
                    File Uploaded Successfully  <span className="left-tab"/><span className="warning" onClick={()=>this.remove("file1")}><img src={Delete}/>Delete</span>
                  </span>}
                  
            </div>
            {builderType ==="Custom Builder" && 
                <div>
                <div className="pdfblock file-name">
                    Architectural Drawings <span className="left-tab"/>
                    {!architectDrawings ?
                    <span>
                  <input type="file" id="file2" onChange={(event)=>this.onPDFFileChange(event)} /> 
                  <input type="button" className="button2 hire" id="file2" value="Upload" onClick={(event)=>this.onFileUpload(event)}/>
                  <span className="left-buffer"/>
                  {errors.file2.length > 0 && <span style={{ color: 'red' }}>{errors.file2}</span>}
                  </span> 
                  : <span>
                   File Uploaded Successfully  <span className="left-tab"/><span className="warning" onClick={()=>this.remove("file2")}><img src={Delete}/>Delete</span>
                  </span>}
                </div>
                <div className="pdfblock file-name">
                    Engineering Plans <span className="left-tab"/><span className="left-buffer"/><span className="left-buffer"/><span className="left-buffer"/>
                    {!engineeringPlans ?
                    <span>
                  <input type="file" id="file3" onChange={(event)=>this.onPDFFileChange(event)} /> 
                  <input type="button" className="button2 hire" id="file3" value="Upload" onClick={(event)=>this.onFileUpload(event)}/>
                  <span className="left-buffer"/>
                  {errors.file3.length > 0 && <span style={{ color: 'red' }}>{errors.file3}</span>}
                  </span> 
                  : <span>
                    File Uploaded Successfully  <span className="left-tab"/><span className="warning" onClick={()=>this.remove("file3")}><img src={Delete}/>Delete</span>
                  </span>}
                </div>
                <div className="pdfblock file-name">
                    Landscaping Designs <span className="left-tab"/>
                    {!landscapingDesigns ?
                    <span>
                  <input type="file" id="file4" onChange={(event)=>this.onPDFFileChange(event)} /> 
                  <input type="button" className="button2 hire" id="file4" value="Upload" onClick={(event)=>this.onFileUpload(event)}/>
                  <span className="left-buffer"/>
                  {errors.file4.length > 0 && <span style={{ color: 'red' }}>{errors.file4}</span>}
                  </span> 
                  : <span>
                    File Uploaded Successfully  <span className="left-tab"/><span className="warning" onClick={()=>this.remove("file4")}><img src={Delete}/>Delete</span>
                  </span>}
                </div>
                </div>
            
            }
            </>
             ||
                <>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <h6>Contract of Sale of Land</h6>
                            <div className="pdfblock file-name">
                                <img src={DownloadIcon} /> <a href={blobService.createUrl(landSaleContract)} target="_blank"> Contract of Sale of Land.pdf</a>
                            </div>
                        </div>
                        {builderType ==="Custom Builder" &&
                        <div className="col-md-6">
                            <h6>Architechtural Drawings</h6>
                            <div className="pdfblock file-name">
                                <img src={DownloadIcon} /> <a href={blobService.createUrl(architectDrawings)} target="_blank"> Architechtural Drawings.pdf</a>
                            </div>
                        </div>
                        }
                    </div>
                        {builderType ==="Custom Builder" &&
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <h6>Engineering Plans</h6>
                                <div className="pdfblock file-name">
                                    <img src={DownloadIcon} /> <a href={blobService.createUrl(engineeringPlans)} target="_blank"> Engineering Plans.pdf</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h6>LandScaping Designs</h6>
                                <div className="pdfblock file-name">
                                    <img src={DownloadIcon} /> <a href={blobService.createUrl(landscapingDesigns)} target="_blank"> LandScaping Designs.pdf</a>
                                </div>
                            </div>
                        </div>
                        }
                        </>
                    }
                </div>
                 */}
            </div>
            <div className = "quote-top-heading" style={{display:activeli[1]===true || activeli[2]===true ?"":"none"}}>
                <div className="row align-items-center quotes-heading">
                        <h6 className={activeli[1]===true ? 'active-quote' : ''}  onClick={()=>{this.changeActive(1)}}>My Quotes</h6>
                        <h6 className={activeli[2]===true ? 'active-quote' : ''} onClick={()=>{this.changeActive(2)}} >Builder Quotes</h6>
                        {/* <h6 className={activeli[3]===true ? 'active-quote' : ''} onClick={()=>{this.changeActive(3)}}>Architect Quotes</h6> */}
                </div>
                <div className = "Projects"  style={{display:activeli[1]===true?"":"none"}}>
                {(myQuotesArchitect.length ) > 0 || (myQuotesBuilder.length) > 0 ?
                    <ul >
                        {builderList}
                        {architectList}
                    </ul>
                    :
                    <div className="buildersBlock py-5 empty-Message-Container">
                        <div className="container-fluid empty-Message-Content">
                            <img src={emptyQuote} alt="" />
                            <p className="text-center my-3">There are no quotes on your project yet. You will be able to see them once you ask a quote.</p>
                        </div>
                    </div>
                    }
                </div>
                <div className = "Projects" style={{display:activeli[2]===true?"":"none"}}>
                {(builderQuotes.length)> 0 ?
                    <ul >{builderList}</ul>
                    :
                    <div className="buildersBlock py-5 empty-Message-Container">
                            <div className="container-fluid empty-Message-Content">
                                <img src={emptyQuote} alt="" />
                                <p className="text-center my-3">There are no quotes on your project yet. You will be able to see them once any builder sends you a quote.</p>
                                <Link to="/Builders" style = {{margin: "auto"}}> <button  className="button2 hire">Find Builders</button></Link> 
                            </div>      
                        </div>
                    }
                </div>
                {/* <div className = "Projects" style={{display:activeli[3]===true?"":"none"}}>
                    {(architectQuotes.length)> 0 ?
                        <ul >{architectList}</ul>
                        :
                        <div className="buildersBlock py-5 empty-Message-Container">
                            <div className="container-fluid empty-Message-Content">
                                <img src={emptyQuote} alt="" />
                                <p className="text-center my-3">There are no quotes on your project yet. You will be able to see them once any architect sends you a quote.</p>
                            </div>
                        </div>
                    }
                    
                </div> */}
            </div>
            </div>
            </div>
            <div>
            </div>
            <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any)=>this.onHideUpload(event)}/>
            <Modal show={projectModal} onHide={this.hideProjectModal}>
                <Modal.Header>
                <Modal.Title className="text-center">{projectHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{projectBody}</Modal.Body>
                <Modal.Footer>
                <button className="ok-button" onClick={this.hideProjectModal}> 
                    OK
                </button>
                </Modal.Footer>
            </Modal>
            <Modal show = {quoteConfirmationModal}>
                 <QuoteConfirmationModal
                  projectId = {this.props.location.state.id}
                  quoteStatus = {quoteStatus} 
                  quoteCreatedBy = {quoteCreatedBy}
                  userId = {userId}
                  projectName = {projectName}
                  userName = {name}
                  status = {projectStatus}
                  result={(event: any) => this.quoteConfirmsuccess(event) }
                  error ={(event: any) => this.quoteConfirmFailure(event)}
                 />
            </Modal>

            {(projectStatus == "Pending" || projectStatus == "InitiateContract")?
                    <Modal 
                        show = {openHire} 
                       
                    >
                        <HireMultipleBuilders 
                            id = {this.props.location.state.id} 
                            result={(event: any) => this.hiresuccess(event)} 
                            onHideMultipleBuilders={this.onHideMultipleBuilders}
                        />
                    </Modal>
                : ""
                }
                 {(projectStatus == "Pending" || projectStatus == "InitiateContract")?
                    <Modal 
                        show={this.state.quoteModal} 
                    >
                        <CreateQuote 
                            id = {this.state.projectIds} 
                            builderId = {this.state.builderId} 
                            result={(event: any) => this.quoteSuccess(event)} 
                            error ={(event: any) => this.quoteFailure(event)}
                        />
                    </Modal>
                    : ""}
                     {(projectStatus == "Pending" || projectStatus == "InitiateContract")?
                    <Modal 
                        show={this.state.openRequestChangeModal} 
                    >
                        <Fragment>
                                <div className = "container-fluid quote-container">
                                    <div className = "quote-header">
                                        <p>Request Change</p>
                                        <img src = {Vector}  onClick={this.closeRequestChangeModal}/>
                                    </div>
                                    <div className = "quote-confirmation-content">

                                                <p>Message</p>
                                                <textarea
                                                    name = "changeRequest"
                                                    value = {changeRequest}
                                                    placeholder = "Add additional info" 
                                                    onChange={this.handleChange}
                                                    maxLength= {255}
                                                >
                                                </textarea>
                                    
                                    </div>
                                    <div className = "d-flex quote-confirmation-button">
"                                        <button  onClick={()=>{this.sendRequestChange()}}>Send</button>
"                                        <button onClick={this.closeRequestChangeModal}>Cancel</button>
                                    </div>
                                </div>
                        </Fragment>
                    </Modal>
                    : ""}

        <Footer/>
        </>
        );
    }
}
