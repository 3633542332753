import React, { Component } from 'react'
import Footer from '../../../common-components/Footer/Footer'
import Header from '../../../common-components/Header/Header'
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg'; 
import "./Pricing.scss"
import { RouteComponentProps } from "react-router-dom";


interface PricingProps {
    name?: any;
    value?: any;
}
interface PricingState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    errors: {};
    project: any,
    nextIcon: any,
    prevIcon: any,
    showModal: boolean,
    header: string,
    body: any,
}
export default class Pricing extends React.Component<PricingProps & RouteComponentProps, PricingState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            error:false,
            ErrMsg:"",
            isLoading: false,
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            errors: {},
            project:{},
            showModal: false,
            header: '',
            body:{}
        };
        this.state = initialState;
    }

    componentDidMount() {
        //this.viewProject(1); 
    }

    render() {
     return(
        <>
        <Header/>
        {
                 
               }
        <div className="bg-img">
            <div className="overlay">
            <div className="container">
                <h1 className="font-weight-bold">Pricing</h1>
                <p className="my-3">Select Subscription</p>
            </div>
            </div>
            </div>
             <div className="container">
                         <div className="row mt-4">
                             <div className="col-sm-4">
                             <h2 className="font-weight-bold">Pricing</h2>
                                 <p style={{fontSize:"18px"}} >select the plan which is most suitable for you and your team.You can switch back anytime.</p>
                             </div>
                             <div className="col-sm-3"></div>
                             <div className="col-sm-5">
                             <div style={{display:"flex",marginLeft:"14px"}} >
                    <div className="button-one first-section">
                        <h2 className="para-text mt-5">Free</h2>
                        <h1 className="font-weight-bold">$0</h1>
                        <p className="month-text">per month</p>
                        <button className="plan-button">Current Plan</button>
                    </div>
                    <div className="button-one second-section">
                    <h2 className="para-text font mt-5">Plus</h2>
                    <h1 className="font-weight-bold">$50</h1>
                        <p className="month-text font">per month</p>
                        <button className="plan-button-two  font">Choose Plan</button>
                    </div>
                    </div> 
                             </div>
                         </div>
                         <table className="table table-striped" style={{marginBottom:"150px"}}>
 
  <tbody>
    <tr>
      <td style={{width: "62%"}} className="font-weight-bold">Find Projects</td>
      <td  className="text-center">
</td>
      <td  className="text-center"><i className="fa fa-check-circle" aria-hidden="true"></i></td>
    </tr>
     <tr>
      <td style={{width: "62%"}} className="font-weight-bold">Architects</td>
      <td  className="text-center">
</td>
      <td  className="text-center"><i className="fa fa-check-circle" aria-hidden="true"></i></td>
    </tr>
    <tr>
      <td style={{width: "62%"}} className="font-weight-bold">My Projects</td>
      <td  className="text-center">
</td>
      <td  className="text-center"><i className="fa fa-check-circle" aria-hidden="true"></i></td>
    </tr>
    <tr>
      <td style={{width: "62%"}} className="font-weight-bold">Portiofolio</td>
      <td  className="text-center"><i className="fa fa-check-circle" aria-hidden="true"></i>
</td>
      <td  className="text-center"><i className="fa fa-check-circle" aria-hidden="true"></i></td>
    </tr>
    <tr>
      <td style={{width: "62%"}} className="font-weight-bold">Edit Profile</td>
      <td  className="text-center"><i className="fa fa-check-circle" aria-hidden="true"></i>
</td>
      <td  className="text-center"><i className="fa fa-check-circle" aria-hidden="true"></i></td>
    </tr> 
  </tbody>
</table>
            </div>
            <Footer/>
        </>
)

    }

}
