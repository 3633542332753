import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import './OurServices.scss';
import CompleteEvaluate from "../../../../assets/images/CompleteEvaluate.svg";
import Marktheprop from  "../../../../assets/images/Marktheprop.svg";
import Screening2 from  "../../../../assets//images/Screening2.svg";
import Interaction2 from  "../../../../assets//images/Interaction2.svg";
import Maintainance2 from  "../../../../assets//images/Maintainance2.svg";
import Loan2 from  "../../../../assets//images/Loan2.svg";

interface OurServicesProps {
    name?: any;
    value?: any;
}
interface OurServicesState {
    errors: {};
}

export default class OurServices extends React.Component<OurServicesProps, OurServicesState> {
    constructor(props: OurServicesProps) {
        super(props);
        const initialState = {
            errors: {},
        };
        this.state = initialState;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
      }
      
    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 ourservices-offset-md-2 col-12">
                                <h1>Our Services</h1>
                            </div>             
                            </div>
                        </div>
                    </div>
                    {/* <div className="input-cntr">
                         <input type="text" placeholder="Search" name="searchTxt" onChange={this.handleChange} className="px-3 py-2"/><img className="search-icon" onClick={(event:any)=>this.searchBuilder(event)}src={SearchIcon} alt=""/>
                     </div> */}
                </div>
            <br/><br/>
            <div className = "container">
                <div className = 'row content-box'>
                     <div className= 'col-md-6 sm-6 evaluation' >
                         <div>
                         <img src ={CompleteEvaluate}></img>
                         </div>
                        
                         <div className = 'text-container'>
                            <h4 >Complete Evaluation </h4>
                            <p>We perform detailed documentation and determine the optimal value of the property, 
                             ensuring we provide good ROI.  </p>
                         </div>
                     </div>
                     <div className= 'col-md-6 sm-6 mark-the-property'>
                        <div >
                            <div>
                                <img src ={Marktheprop}></img>
                            </div>
                            <div  className = 'text-container'>
                                <h4 >Market the Property </h4>
                                <p> We prepare your home for rent and work closely with our team to find you the best prospective tenant or buyer or seller. We take care of everything from advertising your property,
                                finding the right prospect, and support, until the deal is closed. </p>
                            </div>
                           
                        </div>
                     </div>
                </div>
                <div className = 'row content-box'>
                    <div className= 'col-md-6 sm-6 screening-selection'>
                        <div >
                            <div>
                                <img src ={Screening2}></img>
                            </div>
                            <div className = 'text-container'>
                                <h4 >Screening & Selection </h4>
                                <p>On your behalf, we perform a background check to verify and grade tenant or customer according to our pre-defined criteria. Only once we are satisfied,
                                we provide them with legally compliant applications.  </p> 
                            </div>
                        </div>
                    </div>
                    <div className= 'col-md-6 sm-6 interaction-documentation'>
                        <div >
                            <img src ={Interaction2}></img>
                        </div>
                        <div className = 'text-container'>
                            <h4 >Interactions & Documentation </h4>
                            <p>We collect all documentation on your behalf, ensuring you don’t have to spend your valuable time on time consuming and tedious processes.
                             We also make it convenient for the customer to upload all documents on the app ensuring its hassle free and glitch free.  </p>
                        </div>
                    </div>            
                </div>
                <div className = 'row content-box'>
                    <div className= 'col-md-6 sm-6 maintenance-remodelling'>
                        <div >
                            <div>
                                <img src ={Maintainance2}></img>
                            </div>
                            <div className = 'text-container'>
                                <h4 >Maintenance & Remodelling </h4>
                                <p>Aashray Homes establishes a preventative maintenance policy to identify and deal with repair needs, 
                                both indoor and outdoor, on your behalf. We assign the maintenance or repair to professional contractors who assure to do a great job.</p>  
                            </div>
                        </div>
                    </div>
                    <div className= 'col-md-6 sm-6 loanProcessing'>
                        <div >
                            <div>
                                <img src ={Loan2}></img>
                            </div>
                            <div className = 'text-container'>
                                <h4 >Loan Processing  </h4>
                                <p>With Aashray Homes, you don’t just get to find the perfect home you’ve always dreamed of.
                                But we also provide you a bouquet of customised home loan plans to choose from, for a seamless and pleasurable home-buying experience. </p>   
                            </div>
                        </div>
                    </div>
               </div>
            </div>
            <br/><br/>
            <Footer/>
            </>
         );
    }
}
