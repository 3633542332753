import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import map from '../../../../assets/images/location.svg';
import './ContactUs.scss';
import phone from '../../../../assets/images/phone.svg';
import message from '../../../../assets/images/message.svg';
import dot from '../../../../assets/images/dot.svg';
import {withRouter} from 'react-router-dom';
import emailicon from '../../../../assets/images/email.svg';
import { constants } from '../../../../constants/constants';
import axios from 'axios';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
interface ContactUsProps {
    name?: any;
    value?: any;
}
interface ContactUsState {
    name:string;
    toEmail:string;
    description:string;
    data:{};
    header: string,
    body: string,
    showAlert:boolean,
    validate:boolean;
    errors: {
        name: string,
        toEmail:string,
        description: string
    };
}
const Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default class ContactUs extends React.Component<ContactUsProps, ContactUsState> {
    constructor(props: ContactUsProps) {
        super(props);
        const initialState = {
            name:"",
            toEmail:"",
            description:"",
            showAlert:false,
            validate:false,
            header: "",
            body:"",
            data:{},
            errors: {
            name: '',
            toEmail:'',
            
            description: ''
        },
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
      }
      postMessage=()=>{
        let validity = true;
        Object.values(this.state.errors).forEach(
           (val: any) => val.length > 0 && (validity = false)
       );
      let data={
        name:this.state.name,
        toEmail:this.state.toEmail,
        description:this.state.description
    }
       if(validity === true){
        const key = constants.subscriptionKey;
        const url=constants.contactUs;
        axios.post(url,data, { headers: {"Ocp-Apim-Subscription-Key" :key} }).then((response)=>{
            this.openUpload("Success", "Message sent SuccessFully");
        }).catch((error)=>
        {    
            this.openUpload("Error", "Message send failed");
        })
       
    }
      }
      handleChange(event:any){
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name){
                case 'name':
                    errors.name = value.length == 0 ? 'Name can not be empty': '';
              break;    
              case 'toEmail':
                    errors.toEmail =Regex.test(value)? '': 'Please enter valid Email';
              break; 
              case 'description':
                errors.description =value.length==0 ? "Description can not be empty" : ""  ;
              break; 
            }
            this.setState(Object.assign(this.state, { errors,[name]: value }));
      }
      validData=()=>{
        const { name, toEmail,description } = this.state;
       if(name==="" || toEmail==="" || description===""){
           return true
       }
       else{
           return false
       }
      }
      openUpload = async(header:any, body: any) => {
        this.setState({showAlert:true, header: header, body: body});
    }
    onHideUpload = async(event:any) => {
        this.setState({showAlert:false});
        this.clearFields()
    }
    clearFields=()=>{
        this.setState({name:"",description:"",toEmail:""})
        let errors=this.state.errors;
        errors.description="";
        errors.name="";
        errors.toEmail="";
    }
    render() {
        const {name,toEmail,errors,validate,header,body,description,showAlert}=this.state;
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}}>Contact Us</h1>
                            </div>             
                            </div>
                        </div>
                    </div>
        
                </div>
            <br/><br/>
            <div className="container">
            <div className="column my-4">
                <div className='newmap-sctn'>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d650.9170243526261!2d78.39361150312097!3d17.436103967100557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917a0655e829%3A0x9095dc303f01d89f!2sGolden%20Future%20Technologies!5e0!3m2!1sen!2sin!4v1634121159967!5m2!1sen!2sin"  style={{width: '100%', border:'0px'}} loading="lazy"></iframe>
				</div>
                </div>
                </div>
                
				<div className='container  my-5'>
                <div className='row'>
				<div className='col-md'>
                <div className="contactus">
                <h3 className="message">
                Send us a Message 
                </h3>
                <input className="name mt-2"  onChange={this.handleChange} value={name} type="text"  
                 name="name" placeholder="Full Name" required></input>
                      {errors.name.length > 0 && (
                                                        <p style={{ color: 'red', marginLeft: '50px' }}>{errors.name}</p>   )}
                <input className="email mt-4"  onChange={this.handleChange} 
                value={toEmail} name="toEmail" type="email" placeholder="Email Address" required></input>
                {errors.toEmail.length > 0 && (
                                                        <p style={{ color: 'red', marginLeft: '50px' }}>{errors.toEmail}</p>   )}
                <textarea  style={{resize:'none'}} onChange={this.handleChange}   className="mt-4" value={description} name="description" placeholder="Enter Your Message" required></textarea>
                {errors.description.length > 0 && (
                                                        <p style={{ color: 'red', marginLeft: '50px' }}>{errors.description}</p>   )}
                <button style={{textAlign:"center",opacity:1,fontWeight:600}} className={ this.validData() ? "button-send mt-4 contactus-disble": "button-send mt-4" } onClick={this.postMessage}
                disabled={this.validData()}>Send</button>
                </div>
                </div>
                <div className='col-md'>
                <div className='col-md-4 col-12 address-container  new-address'>
							<div className='address-image-sctn'>
							</div>
							<div className = 'add-text-container address-textbox'>
                                <h5>COMPANY ADDRESS</h5>
                                <p>inani House, Plot No: 264, Level, 2, Kavuri Hills Phase 2 Rd,<br/>
                                Madhapur, Telangana<br/>
                                500033, India </p>
							</div>		
						</div>
                        <div className='phone-sctn d-flex mt-4'>
							<div className='phn-image-sctn'>
								<img src={phone} alt='phone' />
							</div>
							<div className='phn-text-sctn'>
								<p className='newphn'>COMPANY PHONES</p>
								<p className='newnum'>+91 4035653622</p>
								<p className='num'></p>
							</div>
						</div>
                        <div className='email-sctn d-flex mt-4'>
							<div className='email-image-sctn'>
								<img src={emailicon} alt='message' />
                                
							</div>
							
							
							<div className='email-text-sctn'>
								<p className='newphn'>Dustro Emails</p>
								<p className='num'>marketing@ahsrayhomes.com</p>
								<p className='num'>sales@ashrayhomes.com</p>
								<p className='num'>marketing@gftglobal.net</p>
								<p className='num'>sales@gftglobal.net</p>
								

                                <p className='num'></p>
							</div>
						</div>
                </div>
                </div>
                </div>
            <br/><br/>
            <CustomModal show={showAlert}
            isAlert={true}
            header={header}
            body={body}
            onHide={(event:any)=>this.onHideUpload(event)}
            ></CustomModal>
            <Footer/>
            </>
         );
    }
}
