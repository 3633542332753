import React, { Component } from 'react';
import Header from '../../../../common-components/Header/Header';
import Footer from '../../../../common-components/Footer/Footer';
import dreamhomeblog from '../../../../../assets/images/dream-home-blog.png';
import { Link } from 'react-router-dom';

export default class DreamHomeBlog extends React.Component {

    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-12 ">
                                <h1  className="ourMissionHead">Blog</h1>
                                <p style = {{fontSize: "14px"}} className="my-3"><Link to="/Blog" className="link-text">Blogs</Link> / Dream Home Or Investment Asset Which Can Be The Right Choice?</p>
                            </div>             
                            </div>
                        </div>
                    </div>
                </div>
            <br/><br/>
            <div className='container blog-container p-3'>     
                   <div className='blog-box  '>
                        <h3 style={{color: "#033b64 "}}>Dream Home Or Investment Asset Which Can Be The Right Choice? </h3>
                        <img style = {{width: "50%" , height: "40%"}} src={dreamhomeblog}/>
                        <div  className=' mt-3'>
                            <p>Are you diverting your extra money into constructing a new home and 
                                confused whether it can be utilized for your own family to stay or can be in 
                                it be converted to a rental property and act as an asset class, 
                                that will in return fetch you more money? 
                                In this blog <Link to = "/"><b>Aashray Homes</b></Link> will provide you with some input in making your final decision.
                            </p>
                            <p><b>Difference between dream home and home as an asset class</b></p>
                            <p>Own house for a family to stay be it first or the second is always special for everyone, as it provides intangible benefits such as, peace of mind, security and ownership pride. A good home built with extreme care and attention always ends up being a dear one. 
                                This promotes a positive growth in the family relationship.
                            </p>

                            <p>Considering your lifestyle now and in years to come becomes a key aspect for a perfect home. Our source of idea can be from the house 
                                that we are already staying in or house belonging to our friends and relatives. 
                                Based on which we take outmost care in building our own.
                            </p>
                            <p>Now what if we already have our dream home and can we use the next one to act as an asset class. 
                                This brings up the point on how we can get a better return on investment.
                            </p>
                            <p>Let's get into the detail, As the pandemic engulfed the world, the economy became unstable. All the sectors including real-estate took a hit. The prices fell drastically. As time passed the market was quick enough to recover making it a perfect time for investment in real-estate. 
                                If you start constructing a house now which can be rented for tenants it not only secures your hard-earned money but also your home's value (property) will almost certainly increase several times over time.  You also get to decide on how many families can stay in the segregated portions you allot.
                            </p>
                            <p><b>Single Family:</b>
                                Designed for one family who can make use of most of the space available in the plot.
                            </p>
                            <p><b>Multiple Families:</b> Different Portions allotted for different families who are bound to the particular space bedrooms, hall & kitchen and make use of the common area like parking, garden etc. So, here rent for a single family has to be framed in such a way that it is   in accordance to market price in that particular area where the house is built and also it does not burden the tenant. For Multifamily you utilise most of the space efficiently & not compromising the comfort of the tenants. This by default will decrease the burden of the tenants of different portions as the rent is distributed. 
                                Hence construction of home in either way as mentioned above becomes a stable and risk-free investment for you as well as the future generation.
                            </p>
                            <p> How<Link to = "/"><b>Aashray Homes</b></Link> can help you achieve this goal? </p>
                            <p>We are here with our user free online platform which connects you to the top professionals of real-estate like builders, architects, raw material suppliers and many more.
                                 All of the with years of expertise in their respective field. We screen them on behalf of you so your money is in safe hands. 
                                 Also, our team of expertise are available 24x7 to assist you.  
                                
                            </p>
                            <p> <Link to = "/"><b>Aashray Homes</b></Link> provides the right solution to anything real-estate and help you build your home from scratch and help maintaining it. </p>
                        </div>
                       
                    </div>
			</div>
            <Footer/>
            </>
         );
    }
}
