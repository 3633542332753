import React from 'react';
import Modal from "react-bootstrap/Modal";


class MembershipModal extends React.Component {
  render() {
    return <div>
    <Modal show={false}>
        <Modal.Body>
            <div className="textcontent-section mt-5 mb-5">
            <h4>Get Aashray plus</h4>
            <p>Get Aashray plus and Find projects<br></br>to work on</p> 
            <button className="btn-continue">Continue</button> 
            </div>      
        </Modal.Body>
    </Modal>
</div>
   
    }
}

export default MembershipModal;