import React, { Component } from "react";
import './UserContact.scss';
import whiteCrossIcon from "../../../assets/images/whiteCrossIcon.svg";
import axios from "axios";
import { constants } from "../../../constants/constants";
import userContactSuccess from "../../../assets/images/userContactSuccess.png";


interface userContactprops {
    //   result: any;
    closeModal: any;
}

interface userContactstate {
    name: string,
    email: string,
    phoneNumber: string,
    message: string,
    errors: {
        name: string,
        email: string,
        phoneNumber: string,
        message: string
    },
    isSubmitted: boolean
}
const RegexPhn = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
const Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
class UserContact extends React.Component<userContactprops, any & userContactstate>{
    constructor(props: any) {
        super(props);
        const initialState = {
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
            errors: {
                name: "",
                email: "",
                phoneNumber: "",
                message: ""
            },
            isSubmitted: false,
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount() {
        this.init();
    }

    init() {


    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'name':
                errors.name = value.length == 0 ? 'Please enter valid Name' : '';
                break;
            case 'email':
                errors.email = Regex.test(value) ? '' : 'Please enter valid Email';
                break;
            case 'phoneNumber':
                errors.phoneNumber = (RegexPhn.test(value) || value.length == 0) ? '' : 'Please Enter A Valid Number';
                break;
            case 'message':

                break;

            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));

    }
    closeUserContactModal = () => {

        this.props.closeModal(false);
    }

    handleSubmit = (event: any) => {

        this.setState({ errors: this.state.errors });
        event.preventDefault();
        let validity = true;
        Object.values(this.state.errors).forEach(
            (val: any) => val.length > 0 && (validity = false)
        );
        if (validity == true) {
            let data = {
                "name": this.state.name,
                "emailId": this.state.email,
                "phoneNumber": this.state.phoneNumber,
                "description": this.state.message,
            }

            const key = constants.subscriptionKey;
            const url = constants.saveUserData;
            axios.post(url, data, { headers: { "Ocp-Apim-Subscription-Key": key } })
                .then((response: any) => {
                    this.setState({
                        isSubmitted: true
                    })


                })
                .catch((error) => {

                });
        } else {

        }
    }


    render() {
        const { name, email, phoneNumber, message, errors, isSubmitted } = this.state

        return (
            <div >
                <div className="userContact-header p-3 d-flex">
                    <p>Aashray Homes</p>
                    <img src={whiteCrossIcon} onClick={this.closeUserContactModal} />
                </div>
                {(isSubmitted == false) ?
                    <div className="container p-3 userContact-container ">
                        <div>
                            <label className="mt-4">Name</label>
                            <input
                                type="text"
                                name="name"
                                className="w-100 px-3 py-2"
                                placeholder="Enter your name"
                                onChange={this.handleChange}
                                required
                                value={name}
                            />
                            {errors.name.length > 0 && <span style={{ color: 'red' }}>{errors.name}</span>}
                        </div>
                        <div>
                            <label className="mt-4">Email</label>
                            <input
                                type="text"
                                name="email"
                                className="w-100 px-3 py-2"
                                placeholder="Enter your email id"
                                onChange={this.handleChange}
                                required
                                value={email}
                            />
                            {errors.email.length > 0 && <span style={{ color: 'red' }}>{errors.email}</span>}
                        </div>
                        <div>
                            <label className="mt-4">Phone Number</label>
                            <input
                                type="number"
                                name="phoneNumber"
                                className="w-100 px-3 py-2"
                                placeholder="Phone number"
                                onChange={this.handleChange}
                                required
                                value={phoneNumber}
                                maxLength={10}
                                onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                            />
                            {errors.phoneNumber.length > 0 && <span style={{ color: 'red' }}>{errors.phoneNumber}</span>}
                        </div>
                        <div>
                            <label className="mt-4">Enter Message(Optional)</label>
                            <input
                                type="text"
                                name="message"
                                className="w-100 px-3 py-2"
                                placeholder="Enter message"
                                onChange={this.handleChange}
                                required
                                value={message}
                            />
                            {errors.message.length > 0 && <span style={{ color: 'red' }}>{errors.message}</span>}
                        </div>
                        <button onClick={this.handleSubmit} className="  p-2 mt-5"  >
                            Submit

                        </button>

                    </div>
                    :
                    <div className=" userContact-success d-flex p-4 mt-5">
                        <img src={userContactSuccess}></img>
                       
                    </div>
                }
            </div>


        );
    }




}

export default UserContact;