import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
// import "./BuilderContentPage.scss";
import { Link } from 'react-router-dom';
import { constants } from '../../../../constants/constants';
import axios from 'axios';
import { blobService } from '../../../common-components/Blob';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import architectContentOne from '../../../../assets/images/architectContentOne.png';
import architectContentTwo from '../../../../assets/images/architectContentTwo.png';

interface ArchitectsState {
    architects: any,
}
export default class ArchitectContentPage extends React.Component{
    constructor(props: any) {
        super(props);
        const initialState = {
            architects: []
        }
        this.state = initialState;
    }
    componentDidMount(){
        this.fetchArchitects();
    }

    async fetchArchitects(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        const key = constants.subscriptionKey;
        const url =  constants.getArchitectListAPI + "?cityName=&stateName=";
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                if(response.data){
                    this.setState({ architects: response.data, isLoading: false });
                }
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    }

    render() {
        const {architects}: any = this.state;
        const listItems = architects.map(
            (item:any) => (
                <div className="col-md-3 builderList">
                    <div className="builderContentcard">
                        <Link to={{ pathname: "/ViewArchitect", state:{id: item.userId}}}>
                            { item.profileImage && 
                                <img src={blobService.createUrl(item.profileImage)} alt="" width="100%" height="200px" />
                            ||
                                <img src={ProjectPlaceholder} alt=""  width="100%" height="200px"/>
                            }
                        </Link>
                    </div>
                    <div className="buildername">{item.architectName}</div>
                    <div className="location">{item.address ? item.address.city : ""}</div>
                </div>
            )
        );
        return (
            <>
                <Header/>
                <div className='BuilderContentHeader py-2'>
                            <div className="container mt-3"> 
                                <p>Architects</p>
                                <p><Link to ="/Architects">Architects</Link> / Why  choose Architects</p>
                            </div>  
                </div>
                <div className="container BuilderContent">
                            <div className = "row">
                                    <div className='col-md-6 mt-3'>
                                        <h4>Why to hire a Architect at Aashray homes?</h4>
                                        <p>
                                            Aashray Homes provides you access to the top architects from across the nation who can create a perfect plan for your dream home. 
                                            We proffer architects of peace! Getting the design, you want
                                        </p>
                                        <p>
                                            Residential, Commercial, Restoration, Landscape, Interior, Green Design Architects and many more under one roof.
                                        </p>
                                        <p>
                                            Let’s take a walk around the overall process involving the architects.
                                        </p>
                                        <ul className='px-0'>
                                            <li>
                                                Connect and Hire a No-broker home architect for your property. 
                                                We provide live chat with your preferred architects and home designers. 
                                            </li>
                                            <li>
                                                Get free consultation with the best handpicked architect.
                                            </li>
                                            <li>
                                                Develop, design, decor and style a glamorous house.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-md-6'>
                                           <img className='w-100' src = {architectContentOne}/>
                                    </div>
                            </div>
                        </div>
                        <div className='thirdFold mt-3'>
                                <div className='background-opacity'>
                                    <div className='container'>
                                        <div className='row no-gutters  p-4 thirdFold-container mt-3'>
                                            <div className='col-md-5'>
                                                <p>Aashray Homes Architects Feature</p>
                                                <p>
                                                    Home developers at Aashray will have to abide by the building guidelines to be followed by 
                                                    a Architects are documented in National Building Code (NBC).
                                                </p>
                                                <Link to= "/AboutCompany">
                                                    <button className ="p-3">Learn More</button>
                                                </Link>
                                            </div>
                                            <div className='col-md-7 d-flex thirdFold-smallBox-container' style = {{flexWrap: "unset"}}>
                                                <div className='mr-2'>
                                                        <div  className='thirdFold-smallBox w-100 p-2'>
                                                                <p>Computer Literacy</p>
                                                                <p>
                                                                        Architects at Aashray will help you in Developing agility with various architectural drafting software's
                                                                </p>
                                                                <ul>
                                                                    <li>
                                                                        Solid works
                                                                    </li>
                                                                    <li>
                                                                        AutoCAD
                                                                    </li>
                                                                    <li>
                                                                        3ds Max
                                                                    </li>
                                                                    <li>
                                                                        Inventor
                                                                    </li>
                                                                    <li>
                                                                        MicroStation
                                                                    </li>
                                                                    <li>
                                                                        BIM360
                                                                    </li>
                                                                    <li>
                                                                        Revit
                                                                    </li>
                                                                    <li>
                                                                        ArchiCAD
                                                                    </li>
                                                                </ul>
                                                        </div>
                                                        <div  className='thirdFold-smallBox mt-2 p-2 w-100'>
                                                                <p>Knowledge of building Laws and codes</p>
                                                                <ul>
                                                                    <li>
                                                                        Architectural Codes
                                                                    </li>
                                                                    <li>
                                                                        Zoning Codes
                                                                    </li>
                                                                    <li>
                                                                        Fire Safety
                                                                    </li>
                                                                    <li>
                                                                        Building Codes
                                                                    </li>
                                                                    <li>
                                                                        Building Construction
                                                                    </li>
                                                                    <li>
                                                                        Building Systems
                                                                    </li>
                                                                    <li>
                                                                        Planning
                                                                    </li>
                                                                    <li>
                                                                        Abstraction
                                                                    </li>
                                                                    <li>
                                                                        Leadership in Energy & Environmental Design (LEED)
                                                                    </li>
                                                                </ul>
                                                        </div>
                                                </div>
                                            
                                            <div className='thirdFold-smallBox p-2 w-100'  style={{height: "60%", justifyContent: "flex-start"}}>
                                                    <p>Advance Maths and Design</p>
                                                    <p>
                                                        Architectural training helps in nurturing and developing the artistic side, as well as the technical ability.
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            Calculations, Estimating, Budgeting, Converting scales from blueprints, Conceptualization, Design to delivery, Drafting, Drawing, Imagination, Industrial Design, Architectural Rendering.
                                                        </li>
                                                       
                                                    </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="container BuilderContent">
                                <div className = "row">
                                        <div className='col-md-6'>
                                            <img className='w-100' src = {architectContentTwo}/>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <h4>This how the architect follows a transparent process at Aashray homes</h4>
                                            <ul className='px-0'>
                                                <li>
                                                    Architects at Aashray will support you with bespoke architectural design practice to successfully accomplish the blue-chip projects.
                                                </li>
                                                <li>
                                                    Based on your input architectural designs will be created.
                                                </li>
                                                <li>
                                                    Contracts will be prepared and executed pursuant to specific consultation.
                                                </li>
                                                <li>
                                                    Adhere to all industry standards in design.
                                                </li>
                                                <li>
                                                    Negotiate the alternative solutions with a major stakeholder who originally blocked progress.
                                                </li>
                                                <li>
                                                    Lead architect will maintain strict control over the construction process.
                                                </li>
                                                <li>
                                                    Will subcontract bricklayers, carpenters, electricians, plumbers, etc while constructing a house and make sure that those subcontracted are qualified for their job.
                                                </li>
                                            </ul>
                                        </div>      
                                </div>
                            </div>
                            <div className="container">
                                <div style={{textAlign: "center"}}>
                                    <h4  className='mt-2'>Our Top Architects</h4>
                                    <Link to = '/Architects'>
                                        <button className='py-2 mb-3' style={{float: "right"}}>View All</button>
                                    </Link>
                                </div>
                                <div className='row scrollableContent' style={{height: "50vh", width: "100%", overflow: "scroll", flexWrap: "unset" }}>
                                        {listItems}
                                </div>
                            </div>
                <Footer/>
                </>
         );
    }
}