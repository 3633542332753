import React, { Component } from 'react'
import Footer from '../../../common-components/Footer/Footer'
import Header from '../../../common-components/Header/Header'
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg'; 
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import { blobService } from '../../../common-components/Blob';
import Carousel from "react-bootstrap/Carousel";
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import Check from '../../../Services/Check';
import './ViewArchitectProject.scss';

interface ViewProjectProps {
    name?: any;
    value?: any;
    location?:any;
    history?:any;
}
interface ViewProjectState {
    error:boolean;
    ErrMsg:any;
    errors: {};
    project: any,
    nextIcon: any,
    prevIcon: any,
    projectId: any[],
    showModal: boolean,
    header: string,
    body: any,
    name: string,
    architectName:string,
    quoteModal:boolean,
    showUpload: boolean,
    contactPhone: string,
    addressLine1: string, 
    addressLine2: string,
    state: string, 
    country: string, 
    pincode: number,
    city: string,
    nextDisabledIcon: any,
    prevDisabledIcon: any
}
class ViewArchitectProject extends Component<ViewProjectProps,ViewProjectState> {
   constructor(props:any) {
       super(props)
   
       const initialState = {
        error:false,
        ErrMsg:"",
        nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
        prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
        nextDisabledIcon: <span className="icon-button-disabled "><img src={RightArrow}></img></span>,
        prevDisabledIcon: <span className="icon-button-disabled "><img src={LeftArrow}></img></span>,
        errors: {},
        project:{},
        showModal: false,
        header: '',
        body:{},
        name:'',
        architectName:'',
        projectId: [],
        quoteModal: false,
        showUpload: false,
        contactPhone: '',
        addressLine1: "",
        addressLine2: "", 
        state: "", 
        country: "",
        pincode: 0,
        city: '',
    };
    this.state = initialState;
   }
   
    componentDidMount(){
        let p = this.props.location.state ? this.props.location.state.data: "";
        
        this.viewArchitectProject(p.id? p.id : p.projectId); 
       

    }
    async viewArchitectProject(id: any) {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
           
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchProjectAPI + id,
        };

        
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ project: response.data });
                this.findUserById(this.state.project.createdBy);

            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        }
        )
    }
  
 
    async findUserById(id: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchUserDetailsAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let name;
                if(response.data.customerName){
                    name = response.data.customerName;
                }
                this.setState({name: name})
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        }
        )
    };
    findArchitects = () =>{
        localStorage.setItem("tab",'3');
    }
    findBuilders = () =>{
        localStorage.setItem("tab",'2');
    }
    onHideModal(event:any){
        this.setState({ showModal: false });
    }
    render() {
        let items:any=[];
        let subItems:any=[];
        let projectImages:any=[];
        let projectName, address, mobile, description, builderType, landSize, measurementUnit, landSaleContract, architectualDrawings, engineeringPlans, LandScapingDesign, builderName, architectName, typeOfProject, cost;
        let project = this.props.location.state ? this.props.location.state.data: "";
        const{ nextIcon, prevIcon, showModal, header, prevDisabledIcon, nextDisabledIcon} = this.state;
        if(project){
            projectImages = project.projectDetails.projectImages;
            projectName = project.name;
            address = project.address ? project.address.addressLine1+", "+project.address.addressLine2+", "+project.address.city+", "+project.address.state+", "+project.address.country: "";
            mobile = project.mobile;
            description = project.description;
            builderType = project.projectDetails.builderType.builderType;
            landSize = project.projectDetails.landSize;
            measurementUnit = project.projectDetails.measurementUnit.description;
            landSaleContract = project.projectDetails.projectDocs.landSaleContract;
            architectualDrawings = project.projectDetails.projectDocs.architectDrawings;
            engineeringPlans = project.projectDetails.projectDocs.engineeringPlans;
            LandScapingDesign = project.projectDetails.projectDocs.landscapingDesigns;
            builderName = project.builder ? project.builder.builderName: "";
            architectName = project.architect ? project.architect.archtectName: "";
            typeOfProject = project.typeOfProject;
            cost = project.cost;
        }
        if(projectImages.length>0){
            items = projectImages.map(
                (item:any) => (
                    (<Carousel.Item>
                        <img
                        className="d-block w-100 buildingImage"
                        src={blobService.createUrl(item)}
                        width="100%" height="350px"
                        />
                    </Carousel.Item>)          
                ),
            );
        }else{
            items = (<Carousel.Item>
            <img
            className="d-block w-100"
            src={ProjectPlaceholder}
            width="100%" height="350px"
            />
            </Carousel.Item>)
        }
        for(let i = 0; i<4; i++){
            if(projectImages[i]){
                subItems.push(
                    <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                        <img src={blobService.createUrl(projectImages[i])} alt="" className="sub-image" width="100%" height="100px"/>
                    </div>
                )               
            }
        }
        return !project ? (<><Header/><br/><div className="text-center">No Data</div><br/><Footer/></>): 
         (
             <>
             <Header/>
             <div className="bg-img">
            <div className="overlay">
            <div className="container">
            <div className=" col-12"> 
                <h1 style={{fontWeight:600}}>{projectName}</h1>
                <p className="my-3"><Link to="MyArchitectProjects" className="link-text">My Projects</Link> / {projectName}</p>
            </div>
            </div>
            </div>
            </div>
            <div className="aboutProject">
            <div className="container py-5">
            <h4 style={{position:"absolute"}}>About</h4>
            <div className="row mt-5">
            <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <span>
                                    {(projectImages.length>1)?
                                        <Carousel  nextIcon={nextIcon} prevIcon={prevIcon} >
                                            {items}
                                        </Carousel>
                                        :
                                        <Carousel className = "disabled-arrow" nextIcon={nextDisabledIcon} prevIcon={prevDisabledIcon} >
                                            {items}
                                        </Carousel>
                                    }
                                    <br/>
                                    </span>
                                    <div className="row">
                                            {subItems}
                                    </div>
                                </div>
                            </div>
                    </div>  
                    <div className="col-md-6">
                    <div className="projectInfo">
                        <h3>{projectName}</h3>
                        <p>{typeOfProject === "Contract" && (<> by {this.state.name} </>)}</p>
                        <div className="col-md-3 row">
                        <span>   
                    
                            {(project.projectStatus.projectStatus === "In Progress") && 
                                <div className="card btn-text pending-btn newbtn-pending">
                                    In Progress
                                </div>
                            }
                            {(project.projectStatus.projectStatus === "Completed") && 
                                <div className="card btn-text completed-btn">
                                    Completed
                                </div>
                            }
                            {(project.projectStatus.projectStatus === "Cancelled") && 
                                <div className="card btn-text cancel-btn">
                                    Cancelled
                                </div>
                            }
                        </span>
                        </div>
                        <h5 className="addresshead">Address &amp; Contact</h5>
                        <div>{address}</div>
                        <div>{mobile}</div>

                        <h5 className="mt-4">Builder Assigned</h5>
                        <div>{builderName ? builderName :<div>None  <Link onClick={this.findBuilders} to="/Builders">(Find Builders)</Link></div>}</div>

                        <h5 className="mt-4">Architect Assigned</h5>
                        <div>{architectName ? architectName: <div>None  <Link onClick={this.findArchitects} to="/Architects">(Find Architects)</Link></div>}</div>

                        <br/>

                    </div>
                    </div>      
            </div>
           

                <div>
                    <h4>Project Details</h4>   
                  
                  
                
                    <div className="row mt-4">
                    <div className="col-md">
                            <h5>About the project</h5>
                            <p style={{lineHeight:"30px"}}>{description}</p>
                        </div>
                        </div>
                        <div className="row mt-4">
                        <div className="col-md-3">
                            <h5>Builder Type</h5>
                            <p>{builderType}</p>
                        </div>
                        <div className="col-md-3">
                            <h5>Land Size</h5>
                            <p>{landSize} {measurementUnit}</p>
                        </div>
                    </div>
             
                    <div className="row mt-4">
                        <div className="col-md-3">
                            <h5>Type of Project</h5>
                            <p>{typeOfProject}</p>
                        </div>
                        {typeOfProject === "Sale" &&
                        <div className="col-md-3">
                            <h5>Cost</h5>
                            <p className="light-content"><CurrencyFormat value={cost} displayType={'text'} thousandSeparator={true} prefix={'$'} /></p>
                        </div>
                        }
                    </div>
                </div>
            </div>   
            </div>
            <CustomModal isAlert={true} show={showModal} header={header} body={<div>Address : {address}<br/>Call on : {mobile}</div>} onHide={(event:any)=>this.onHideModal(event)}/>
        <Footer/>
            </>
        )
    }
}
export default ViewArchitectProject;