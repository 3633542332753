import React, { Fragment } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import '../../../feature-components/theme.scss';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import Linkedinwithround from '../../../../assets/images/Linkedinwithround.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import { Link } from 'react-router-dom';
import RightArrow from '../../../../assets/images/rightarrow1.svg';
import { constants } from '../../../../constants/constants';
import { blobService } from '../../../common-components/Blob';
import Loader from '../../../common-components/Loader';
import Image from "../../../common-components/Image";
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import PersonPlaceholder from '../../../../assets/images/person-placeholder.svg';
import Line from '../../../../assets/images/line-vertical.svg';
import '../Profile/Profile.scss';
import Vector from  "../../../../assets/images/Vector.svg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import asterisk from '../../../../assets/images/asterisk.svg';


interface ViewFinancialBrokerProps {
    name?: any;
    value?: any;
    id?: string;
    location?: any
}
interface ViewFinancialBrokerState {
    isLoading: boolean;
    financialBrokerName: string;
    description: string;
    city: string;
    state: string;
    addressLine1: string,
    addressLine2: string,
    country: string,
    pincode: number,
    twitterLink: string;
    facebookLink: string;
    instagramLink: string;
    financeBrokers: any;
    showModal: boolean;
    header: string;
    body: string;
    financialBrokerLogo: string;
    financialBrokerProfileImage: string;
    userSelectBank: any;
    showApplyLoanModal: boolean;
    name: string;
    emailId: string;
    contactNumber: string;
    preferredBank: string;
    propertyLocation: string;
    checked: boolean;
    onboardingChecked: boolean;
    errors: {
        email: string;
        contactNumber: string;
        terms: string;
    };
    bankList: any;
    selectedBank: string;
    financeBrokerId: string;
    banks: any;
    showUpload: boolean;
    serviceLocation: string;
    isSubmitted: boolean,
}

const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
const RegexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class ViewFinancialBroker extends React.Component<ViewFinancialBrokerProps, ViewFinancialBrokerState> {
    constructor(props: ViewFinancialBrokerProps) {
        super(props);
        const initialState = {
            isLoading: true,
            financialBrokerName: '',
            description: '',
            city: '',
            state: '',
            addressLine1: "",
            addressLine2: "",
            country: "",
            pincode: 0,
            twitterLink: '',
            facebookLink: '',
            instagramLink: '',
            financeBrokers: {},
            showModal: false,
            body: '',
            header: '',
            financialBrokerLogo: '',
            financialBrokerProfileImage: '',
            userSelectBank: [],
            showApplyLoanModal: false,
            name: '',
            emailId: '',
            contactNumber: '',
            preferredBank: '',
            propertyLocation: '',
            checked: false,
            onboardingChecked: false,
            errors: {
                email: "",
                contactNumber: "",
                terms: '',
            },
            bankList: [],
            selectedBank: '',
            financeBrokerId: '',
            banks: [],
            showUpload: false,
            serviceLocation: '',
            isSubmitted: false,
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.init();
    }

    init() {
        this.setState({
            financeBrokerId: this.props.location.state.id
        })
        this.fetchFinancialBroker(this.props.location.state.id);
        this.fetchBanks();
    }

    async fetchFinancialBroker(id: any): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        const key = constants.subscriptionKey;
        const url = constants.fetchFinancialBrokerAPI + id;
        axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key}})
            .then((response) => {
                let financeBrokerData = response.data;
                this.setState({
                    isLoading: false,
                    financeBrokers: financeBrokerData,
                    // financialBrokerLogo: b.logoPath, 
                    financialBrokerName: financeBrokerData.financeBrokersName,
                    city: (financeBrokerData.address.city ? financeBrokerData.address.city : ''),
                    state: (financeBrokerData.address.state ? financeBrokerData.address.state : ''),
                    country: (financeBrokerData.address.country ? financeBrokerData.address.country : ''),
                    pincode: (financeBrokerData.address.pincode ? financeBrokerData.address.pincode : ''),
                    addressLine1: (financeBrokerData.address.addressLine1 ? financeBrokerData.address.addressLine1 : ''),
                    addressLine2: (financeBrokerData.address.addressLine2 ? financeBrokerData.address.addressLine2 : ''),
                    twitterLink: financeBrokerData.socialLinks.twitterLink,
                    facebookLink: financeBrokerData.socialLinks.facebookLink,
                    instagramLink: financeBrokerData.socialLinks.instagramLink,
                    description: financeBrokerData.description,
                    financialBrokerProfileImage: (financeBrokerData.profileImage ? financeBrokerData.profileImage : ''),
                    userSelectBank: financeBrokerData.userSelectBank,
                    serviceLocation: financeBrokerData.serviceLocation,
                    // bannerImages: b.bannerImages
                });
            })
            .catch((error) => {
                // this.setState({ ErrMsg: error, error:true });
            });
    };

    async fetchBanks (){
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        const key = constants.subscriptionKey;
        const url = constants.fetchBankAPI;
        axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key}})
            .then((response) => {
                if(response){
                    let bankList = response.data;
                    bankList.map((item:any)=>{
                        this.state.bankList.push(item.bankName);
                    })
                    this.setState({
                        bankList: this.state.bankList,
                        banks: bankList
                    });
                }
            })
    }

    async loadImages(path: string) {
        return await blobService.fetch(path);
    };

    onShowModal(event: any) {
        this.setState({ showModal: true, header: "Contact Details" });
    }

    onHideModal(event: any) {
        this.setState({ showModal: false });
    }

    openApplyLoanModal = ()=>{
        this.setState({ showApplyLoanModal: true });
    }

    hideApplyLoanModal = ()=>{
        this.clearFields();
        this.setState({ showApplyLoanModal: false });
    }

    clearFields = ()=>{
        let errors = this.state.errors;
        errors.terms = '';
        errors.contactNumber = '';
        errors.email = '';
        this.setState({
            contactNumber: "",
            emailId: "",
            name: "",
            preferredBank: "",
            propertyLocation: "",
            checked: false,
            onboardingChecked: false,
            isSubmitted: false,
            selectedBank: ""
        })
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'emailId':
                errors.email = RegexEmail.test(value) ? '' : 'Please Enter Valid Email';
                break;
            case 'contactNumber':
                errors.contactNumber = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
                break;
            default:
                break;
            }
        this.setState(Object.assign(this.state, {  [name]: value }));
        const { contactNumber,emailId,propertyLocation, selectedBank } = this.state;
        if(localStorage.getItem("id")){
            if ( propertyLocation != "") {
                this.setState({
                    isSubmitted: true,
                     
                })
               
            }else{
                this.setState({
                    isSubmitted: false,
                     
                })
            }
 
        }else{
            if ( this.state.name != "" && contactNumber != "" && emailId != ""&& propertyLocation != "") {
                this.setState({
                    isSubmitted: true,
                     
                })
               
            }else{
                this.setState({
                    isSubmitted: false,
                     
                })
            }
        } 
    };

    handleCheckBox = () => {
        this.setState({ 
            checked: !this.state.checked,
         });
    };

    handleOnboardingCheckBox = () => {
        this.setState({ 
            onboardingChecked: !this.state.onboardingChecked,

         });
    };

    submitLoanDetails = ()=>{
        const {name, contactNumber,emailId,preferredBank,propertyLocation, selectedBank, onboardingChecked, financeBrokerId } = this.state;
        let userId = localStorage.getItem("id");
        this.state.errors.terms = !this.state.checked ? 'Please click on the check box' : '';
        this.setState({ errors: this.state.errors });
        let validity = true;
        Object.values(this.state.errors).forEach((val) => val.length > 0 && (validity = false));
        if (validity == true) {
            let bankId; 
            this.state.banks.map((item:any)=>{ 
                if(item.bankName == selectedBank){
                    bankId= item.id
                }
                
            }) 
            let data = {
                "name" : name,
                "phoneNumber": contactNumber,
                "emailId": emailId,
                "banks": {
                    "id": bankId, 
                    "bankName": "",
                    "bankLogoPath": ""
                },
                "propertyLocation": propertyLocation,
                "userId": userId,
                "register": onboardingChecked,
                "financeBrokerUserId": financeBrokerId
            }
            const user = JSON.parse(localStorage.getItem('user')!);
            let token;
            if (user) {
                token = 'Bearer ' + user;
    
            }
            const key = constants.subscriptionKey;
            const url = constants.applyForLoan;
            axios.post(url,data, { headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key}})
                .then((response) => {
                    if(response){
                        this.hideApplyLoanModal();
                        this.openUpload("Success", "Information Sent SuccessFully to the Broker");
                    }
                })
                .catch((error)=>{
                    this.hideApplyLoanModal();
                    this.openUpload("Failed", " Failed to sent Information to the Broker");
            })
        } else {
        }
    }

    searchBanks = (value:any)=>{      
        if(value){
            this.setState({
                selectedBank: value
            })
        }else{
            this.setState({
                selectedBank: ''
            })
        } 
    }

    openUpload = async (header: any, body: any) => {
        this.setState({ showUpload: true, header: header, body: body });
    }
    onHideUpload = async (event: any) => {
        this.clearFields();
        this.setState({ showUpload: false });
    }

    render() {
        var items: any = [];
        var subItems: any = [];
        const {
            isLoading,
            financialBrokerName,
            description,
            twitterLink,
            facebookLink,
            instagramLink,
            city,
            addressLine1,
            addressLine2,
            state,
            country,
            pincode,
            financeBrokers,
            showModal,
            body,
            header,
            financialBrokerLogo,
            financialBrokerProfileImage,
            userSelectBank,
            showApplyLoanModal,
            name,
            emailId,
            contactNumber,
            preferredBank,
            propertyLocation,
            checked,
            onboardingChecked,
            errors,
            bankList,
            showUpload,
            serviceLocation,
            isSubmitted,
            selectedBank
        } = this.state;
        let address: any;

        let isEnabled = true;
        if(isSubmitted == true && selectedBank != ""){
            isEnabled = false
        };
        if (financeBrokers.address == null || addressLine1 == '') {
            address = []
        } else {
            address = addressLine1 + ", " + addressLine2 + ", " + city + ", " + state + ", " + country;
        }

        return isLoading ? (<Loader />) :
            (
                <>
                    <Header />
                    {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                    <div className="bg-img">
                        <div className="overlay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <h1 style={{ fontWeight: 600 }}>{financialBrokerName}</h1>
                                        <p className="my-3"><Link to="/FinancialBrokers" className="link-text">Financial Broker</Link> / {financialBrokerName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="aboutBuilderProfile">
                        <div className="container py-3">
                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span>
                                                <div className="profilecard">
                                                    <span>
                                                        {
                                                            financialBrokerProfileImage.length > 0 && financialBrokerProfileImage != "" ?
                                                                <Image id="logo" src={blobService.createUrl(financialBrokerProfileImage)} alt="" width="100%" height="300px" />
                                                                :
                                                                <Image id="logo" src={PersonPlaceholder} alt="" width="100%" height="300px" />
                                                        }
                                                    </span>
                                                </div>
                                                <br />
                                            </span>
                                            <div className="row">
                                                {subItems}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-8">
                                    <div className="projectInfo">
                                        <span>
                                            {/* <div className="col-md-4 compyLogo">
                                                <Image src={blobService.createUrl(financialBrokerLogo)} alt="" width="100%" height="100px" />
                                            </div> */}
                                            <h3 className="mt-3">{financialBrokerName}</h3>
                                            <p style={{ lineHeight: '30px', fontSize: '24px', color: '#00215B' }} >{city}</p>
                                            {/* <p style={{ lineHeight: '30px' }}>{description}</p> */}
                                        </span>
                                        {/* <p className="d-flex align-items-center">
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        (3 Reviews)
                                    </p> */}
                                    
                                        <div>
                                            {(twitterLink == null || twitterLink == "") ?
                                                <a  target="_blank">
                                                    <img  src={Twitterwithround} alt="" />
                                                </a>
                                            :
                                                <a href={ financeBrokers.socialLinks.twitterLink} target="_blank">
                                                    <img  src={Twitterwithround} alt="" />
                                                </a>
                                             }
                                            {(facebookLink == null || facebookLink == "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={FBwithround} alt="" />
                                            </a>
                                                :
                                                <a href={financeBrokers.socialLinks.facebookLink} target="_blank" className="left-buffer">
                                                    <img  src={FBwithround} alt="" />
                                                </a>
                                            }
                                            {(instagramLink == null || instagramLink == "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={Linkedinwithround} alt="" />
                                            </a>
                                                :
                                                <a href={financeBrokers.socialLinks.instagramLink} target="_blank" className="left-buffer">
                                                    <img  src={Linkedinwithround} alt="" />
                                                </a>
                                            }
                                        </div> 

                                        <div className="mt-4">
                                            <button className="button1 contact" onClick={(event: any) => this.onShowModal(event)} >Contact <img src={RightArrow} style={{ height: "12px", width: "20px", marginLeft: "0.25rem" }} /></button>
                                            <span className='left-buffer'/>
                                            {( localStorage.userType == "FinanceBrokers") ?
                                                ""
                                                :
                                                <button className="button1 contact" onClick={this.openApplyLoanModal} >
                                                    Apply For Loan 
                                                    <img src={RightArrow} style={{ height: "12px", width: "20px", marginLeft: "0.25rem" }} />
                                                </button>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="my-5">
                                <h4>About</h4>
                                <p style={{ lineHeight: '30px' }}>{description? description : ""}</p>
                                <h4>Service Location</h4>
                                <p>{serviceLocation? serviceLocation : "No Service Location" }</p>
                            </div>
                            <div className="my-5">
                                <h4>Banks Managed</h4>
                                <div className="row">
                                {userSelectBank.length != 0 && userSelectBank.map((item: any, i: any) => (
                                        <div className="grid " key={i}>
                                            <div className="row bank-managed">
                                                <div>
                                                    <img src={Line} alt='' />
                                                    <img src={blobService.createUrl(item.img)} alt="" />
                                                </div>
                                                <div style={{ margin: 'auto' }}>
                                                    <h6>{item.bankName}</h6>
                                                </div>
                                            </div>

                                        </div>
                                    ))  }
                                </div>
                                {userSelectBank.length === 0 ? <p>There Is No Service Bank Available As Of Now</p> : ""}
                            </div>
                        </div>
                    </div>
                    <CustomModal isAlert={true} show={showModal} header={header} body={(address.length == 0 && financeBrokers.mobile == null) ? <div><p>Address & Contact Details Are Not Available. </p><p>Email : {financeBrokers.email}</p></div> : <div>{(address.length == 0 || address == null) ? "Address Is Not Available." : <p>Address : {address}</p>}{(financeBrokers.mobile == null) ? "Contact Details Is Not Available." : <p>Call on : {financeBrokers.mobile}</p>} {( financeBrokers.email == null) ?"Email Id Is Not Available." : <p>Email : {financeBrokers.email}</p> }</div>} onHide={(event: any) => this.onHideModal(event)} />
                    <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any) => this.onHideUpload(event)} />
                    <Modal show = {showApplyLoanModal} onHide = {this.hideApplyLoanModal}>
                    <Fragment>
                        <div className='container p-4 projectInfo apply-loan-modal'>
                            <div className='d-flex' style={{justifyContent:"space-between"}}>
                                <h3>Apply for Loan</h3>
                                <img src = {Vector}  onClick={this.hideApplyLoanModal}/>
                            </div>
                            <div className='mt-2 apply-loan-container'>
                                {(localStorage.getItem("id"))?
                                    <>
                                         <p>
                                            My Property Location
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='text'
                                            name = "propertyLocation"
                                            value = {propertyLocation}
                                            placeholder='Location'
                                            className='w-80  px-3'
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <p>
                                            preferred Bank
                                            <img src= {asterisk}/>
                                        </p>
                                        <Autocomplete
                                                // multiple
                                                id="tags-outlined"
                                                onChange={(event, newValue) => {
                                                    this.searchBanks(newValue);
                                                }}
                                                // style = {{width : "100%"}}
                                                options={bankList}
                                                getOptionLabel={(option:any) => option}
                                                renderInput={(params) => <TextField {...params} placeholder="Search Location" variant="outlined" name = "preferredBank" />}
                                                />
                                                <div className='mt-2 '>
                                                        <input
                                                            type= "checkbox"
                                                            onChange={this.handleCheckBox}
                                                            checked={checked}
                                                        />
                                                        <span className='left-buffer'/>
                                                        <span>will share the above information with Broker</span>
                                                </div>
                                                    {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                                                <button className = {isEnabled == true ? "disabled-button mt-2 hire" : "mt-2 hire"}   disabled = {isEnabled}   onClick={this.submitLoanDetails}>Submit</button>
                                    </>
                                    :
                                    <>
                                        <p>
                                            Name
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='text'
                                            name = "name"
                                            value =  {name}
                                            className='w-80  px-3'
                                            placeholder='Name'
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <p>
                                            Email Id
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='text'
                                            name = "emailId"
                                            value = {emailId}
                                            className='w-80  px-3'
                                            placeholder='Email Id'
                                            onChange={this.handleChange}
                                            required
                                        />
                                         {errors.email.length > 0 &&  <p style={{color: "red"}}>{errors.email}</p>}
                                        <p>
                                            Contact Number
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='number'
                                            name = "contactNumber"
                                            value = {contactNumber}
                                            className='w-80  px-3'
                                            placeholder='Contact Number'
                                            onChange={this.handleChange}
                                            required
                                            maxLength = {10}
                                            onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                        />
                                        {errors.contactNumber.length > 0 && (
                                                        <p style={{ color: 'red' }}>{errors.contactNumber}</p>
                                                    )}
                                        <p>
                                            My Property Location
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='text'
                                            name = "propertyLocation"
                                            value = {propertyLocation}
                                            placeholder='Location'
                                            className='w-80  px-3'
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <p>
                                            preferred Bank
                                            <img src= {asterisk}/>
                                        </p>
                                        <Autocomplete
                                                // multiple
                                                id="tags-outlined"
                                                onChange={(event, newValue) => {
                                                    this.searchBanks(newValue);
                                                }}
                                                // style = {{width : "100%"}}
                                                options={bankList}
                                                getOptionLabel={(option:any) => option}
                                                renderInput={(params) => <TextField {...params} placeholder="Search Location" variant="outlined" name = "preferredBank" />}
                                                />
                                                 <div className='mt-2 '>
                                                    <input
                                                        type= "checkbox"
                                                        onChange={this.handleCheckBox}
                                                        checked={checked}
                                                    />
                                                    <span className='left-buffer'/>
                                                    <span>will share the above information with Broker</span>
                                                </div>
                                                {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                                                <div className='mt-2 '>
                                                    <input
                                                        type= "checkbox"
                                                        onChange={this.handleOnboardingCheckBox}
                                                        checked={onboardingChecked}
                                                    />
                                                    <span className='left-buffer'/>
                                                    <span>Create account with above information</span>
                                                </div>
                                                <button className = {isEnabled == true ? "disabled-button mt-2 hire" : "mt-2 hire"}   disabled = {isEnabled}    onClick={this.submitLoanDetails}>Submit</button>
                                    </>
                                }
                            </div>
                        </div>
                    </Fragment>
                    </Modal>
                    <Footer />
                </>
            );
    }
}
