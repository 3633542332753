import axios from 'axios';
import {constants} from "../../constants/constants";


export function authHeader() {
    axios.interceptors.request.use( config => {
      const user = JSON.parse(localStorage.getItem('user')!);  
      const key = constants.subscriptionKey;
        if(user){
          const token = 'Bearer ' + user;
          config.headers.Authorization =  token;
          config.headers["Ocp-Apim-Subscription-Key"] = key;
        }
        if( config.headers.Authorization ) return config;
        throw ({message:"the token is not available"});
      });

//response interceptor to refresh token on receiving token expired error


axios.interceptors.response.use(
  (response) => {
    return response;
    
  },
  function (error) {
    const originalRequest = error.config;
    const user = localStorage.getItem('user')!;
if (
      error.response.status === 400 || error.response.status === 403 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const key = constants.subscriptionKey;
      const url =  constants.refreshToken;
      return axios
        .get(url, { headers: {"isRefreshToken" : 'true', "Authorization" : 'Bearer ' + user, "Ocp-Apim-Subscription-Key" :key} })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("user", JSON.stringify(res.data.jwt));
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);
}


