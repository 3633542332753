import React from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import '../../../feature-components/theme.scss';
import Rating from '@material-ui/lab/Rating';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import Linkedinwithround from '../../../../assets/images/Linkedinwithround.svg';
import { Link } from 'react-router-dom';
import Project1 from '../../../../assets/images/projects1.jpg';
import Project3 from '../../../../assets/images/project3.jpg';
import RightArrow from '../../../../assets/images/rightarrow1.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import { blobService } from '../../../common-components/Blob';
import Loader from '../../../common-components/Loader';
import Delete from "../../../../assets/images/delete.svg"
import Carousel from "react-bootstrap/Carousel";
import Image from "../../../common-components/Image";
import Upload from "../../../../assets/images/upload.svg"
import {Card, Button} from "react-bootstrap";
import CustomModal from '../../../common-components/CustomModal/CustomModal';
// import Modal from 'react-awesome-modal';
import Modal from "react-bootstrap/Modal";
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import UpdateBuilderProject from '../../../feature-components/Builder/UpdateBuilderProject/UpdateBuilderProject';
import axios from 'axios';
import ruler from '../../../../assets/images/ruler.svg';
import CreateQuote from '../../../common-components/CreateQuote/CreateQuote';
import Hire from '../../../feature-components/Customer/Hire/Hire';


interface ViewBuilderProps {
    name?: any;
    value?: any;
    id?: string;
    location?:any
}
interface ViewBuilderState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean;
    builder: any;
    projects: any;
    images: any[];
    errors: {
        twitterLink: string,
        facebookLink: string,
        instagramLink: string,
    };
    nextIcon: any;
    prevIcon: any;
    listItems: any;
    isEdit: boolean;
    type: string,
    builderLogo: string,
    showProject: boolean,
    showUpload: boolean,
    header: string,
    body: string,
    builderName: string,
    city: string,
    twitterLink: string,
    facebookLink: string,
    instagramLink: string,
    about: string,
    portfolio: any,
    selectedProject: any,
    action: string,
    bannerImages: any[],
    builderModal: boolean,
    showModal: boolean,
    addressLine1: string, 
    addressLine2: string,
    state: string, 
    country: string, 
    pincode: number,
    openHire: boolean,
    quoteModal: boolean,
    projectId: number,
    nextDisabledIcon: any,
    prevDisabledIcon: any,
    selectedUserId: any,
    builderIds: any;

}

export default class ViewBuilder extends React.Component<ViewBuilderProps, ViewBuilderState> {
    constructor(props: ViewBuilderProps) {
        super(props);
        const initialState = {
            isLoading: true,
            builder: {},
            projects:[],
            type: 'text',
            images: [],
            errors: {
                    twitterLink: '',
                    facebookLink: '',
                    instagramLink: '',
                
            },
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            nextDisabledIcon: <span className="icon-button-disabled "><img src={RightArrow}></img></span>,
            prevDisabledIcon: <span className="icon-button-disabled "><img src={LeftArrow}></img></span>,
            listItems: [],
            isEdit: false,
            builderLogo:'',
            showUpload: false,
            showProject: false,
            header: '',
            body: '',
            builderName: '',
            city: '',
            twitterLink: '',
            facebookLink: '',
            instagramLink: '',
            about: '',
            portfolio:[],
            selectedProject:{},
            action:'',
            bannerImages: [],
            builderModal: false,
            showModal: false,
            error:false,
            ErrMsg:"",
            addressLine1: "",
            addressLine2: "", 
            state: "", 
            country: "",
            pincode: 0,
            openHire: false,
            quoteModal: false,
            projectId: 0,
            selectedUserId: '',
            builderIds: []
        };
        this.state = initialState;
    }
    componentDidMount() {
        this.init(); 
    }

    init(){
        this.state.builderIds.push(this.props.location.state.id) ;
        this.setState({
            builderIds : this.state.builderIds
        })
        this.fetchBuilder(this.props.location.state.id); 
    }

    async fetchBuilder(id: any): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }

            // const options = {
            //     url: constants.fetchBuilderAPI + id,
            // };
            const key = constants.subscriptionKey;
            const url =  constants.fetchBuilderAPI + id;
            axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                let b = response.data;
                if( b.address.addressLine1 !== null || b.address.addressLine2 !== null || b.address.state !== null ||  b.address.country !== null || b.address.city !== null ){
                    this.setState({
                         city:  b.address.city , 
                    addressLine1:  b.address.addressLine1, 
                    addressLine2:  b.address.addressLine2 , 
                    state:  b.address.state, 
                    country: b.address.country ,
                    // pincode: b.address.pincode,
                    })
                }
              
              
                this.setState({ builder: b, builderLogo: b.logoPath, 
                    builderName: b.builderName, 
                    twitterLink: b.socialLinks.twitterLink,
                    facebookLink: b.socialLinks.facebookLink,
                    instagramLink: b.socialLinks.instagramLink,
                    about:b.description,
                    bannerImages: b.bannerImages
                });

                this.fetchProjects(this.props.location.state.id);
                this.fetchPortfolio(this.props.location.state.id);
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    };
    async fetchProjects(id: any): Promise<any> {

        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
            const key = constants.subscriptionKey;
            //const url =  constants.getBuilderProjectsAPI + id;
            const url = constants.getBuilderProtfolioProjectsAPI + id  +"?status=In Progress";
            axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                this.setState({ projects: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    };

    async fetchPortfolio(id: any): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user ){
                 token = 'Bearer ' + user;
               
            }
        const key = constants.subscriptionKey;    
        //const url =  constants.getBuilderPortfolioAPI + id; 
        const url =  constants.getBuilderProtfolioProjectsAPI + id  +"?status=Completed";
        axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                this.setState({ portfolio: response.data });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    };

     async loadImages(path:string){
        return await blobService.fetch(path);
    };

  
    removeImage = async(event:any, path:string) => {
        let id = event?.target.id;
        if(id === "logo"){
            this.setState({builderLogo:''})
        }else{
            let bImages = this.state.bannerImages;
            const i = bImages.indexOf(path);
            if (i > -1) {
                bImages.splice(i, 1);
             }
             this.setState({
                bannerImages: bImages,      
            })
        }
    }

    onFileChange = async (event: any) => {
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        
        if(file){
            if(id === "logo" || id === "bannerImages" ){
                formData.append('id', userId?userId:'');
                formData.append('type', "Profile");
                formData.append('file', file);    
            }
            const options = {
                url: constants.uploadFilesAPI,
                data: formData
            }
            
            const response = await userService.postFileApiRequest(options);
                if(response){
                    if(id === "logo"){
                        this.setState({builderLogo: response});
                    }else{
                        this.state.bannerImages.push(response);
                        this.setState({bannerImages: this.state.bannerImages});
                    }
                    this.openUpload("Success", "File Uploaded SuccessFully");
                }else{
                    if(id === "logo"){
                        this.setState({builderLogo: ''});
                    }
                    this.openUpload("Failed", "Failed to Upload File");
                }
        }else{
            // let errors = this.state.errors;
            // errors.file1 = 'Please Select File to Upload';
            // this.setState({errors: errors});
        }
    };

    handleChange = (event : any) => {
        event.preventDefault();
        const { name, value } = event.target;
        const urlRegEx =  RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        let errors = this.state.errors;
        switch (name) {
          case 'name':
            //  errors.name = value.length == 0 ? 'Please enter valid Name': '';
             break;
          case 'twitterLink':
                errors.twitterLink = urlRegEx.test(value) ? '' :  'Please Enter A Valid Link' ;
             break;
          case 'facebookLink':
                errors.facebookLink = urlRegEx.test(value) ? '' :  'Please Enter A Valid Link' ;
             break;
             case 'instagramLink':
                errors.instagramLink = urlRegEx.test(value) ? '' :  'Please Enter A Valid Link' ;
            break;
          default:
            break;
        }
       this.setState(Object.assign(this.state, { errors,[name]: value }));
       }

    openProject = async(item:any) => {
        this.setState({showProject:true, selectedProject: item, action: "View"});
    }
    newProject = async() => {
        this.setState({showProject:true, action: "Create"});
    }
    onHideProject = async(event:any) => {
        this.setState({showProject:false});
        // this.fetchBuilder(this.props.location.state.id);
         this.init();
    }
    openUpload = async(header:any, body: any) => {
        this.setState({showUpload:true, header: header, body: body});
    }
   
    
    openBuilderModal = async(header:any, body: any) => {
        this.setState({builderModal:true, header: header, body: body});
    }
    onHideBuilderModal = async(event:any) => {
        this.setState({builderModal:false, isEdit: false});
        this.init();
    }

    onShowModal(event:any){
        this.setState({ showModal: true, header: "Contact Details" });
    }
    onHideModal(event:any){
        this.setState({ showModal: false });
    }

    openHireModal = ()=>{
        this.setState({
            openHire : true
        })
    }
    hiresuccess(event: any) { 
            this.setState({ openHire: false, quoteModal: true, projectId: event  })    
        
      }
      onHideHireModal= async(event:any) => {
            this.setState({
                openHire:false
            })
        }
      quoteSuccess (event: any){
        if(event == true) {
            this.setState({ quoteModal: false  })
        }else{
            this.setState({ quoteModal: false, showUpload: true, header: "Successful", body: event })    
        }   
      }
      quoteFailure = (event: any) =>{
            this.setState({ quoteModal: false, showUpload:true, header: "Failure", body: event });
      }
      onHideUpload = async(event:any) => {
        this.setState({showUpload:false});
        window.location.replace("/MyProjects");
        localStorage.setItem("tab",'5');
        
    }
    
    render() {
        var items:any=[];
        var pItems=[];
        var subItems:any=[];
        var pList=[];
        const { isLoading, builder, projects, images, bannerImages, nextIcon, prevIcon, isEdit, builderLogo, showProject, showUpload, body, header, builderName, city, twitterLink, facebookLink, about, portfolio, selectedProject, action, builderModal, showModal, error, ErrMsg, addressLine1, addressLine2, state, country, pincode, openHire, projectId, prevDisabledIcon, nextDisabledIcon, errors, selectedUserId , instagramLink, } = this.state;
        let address:any;
        if(addressLine1 == "" || addressLine1 == null){
            address = []
        }else{
            address =  addressLine1+", "+addressLine2 +", "+city+", "+state+", "+country;
        }
       
        if(Object.keys(builder).length){
            if(bannerImages.length>0){
                items = bannerImages.map(
                    (item:any) => (
                        (<Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={blobService.createUrl(item)}
                            width="100%" height="350px"
                            />
                        </Carousel.Item>)          
                    ),
                );
            }else{
                items = (<Carousel.Item>
                <img
                className="d-block w-100"
                src={ProjectPlaceholder}
                width="100%" height="350px"
                />
                </Carousel.Item>)
            }
            for(let i = 0; i<4; i++){
                if(bannerImages[i]){
                    subItems.push(
                        <>
                        {isEdit && 
                        <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                            <span>
                                <img className="close" id={"bannerImage"+i} src={Delete} onClick={(event)=>{this.removeImage(event, bannerImages[i])}}/>
                                <Image id={"bannerImage"+i} src={blobService.createUrl(bannerImages[i])} errorImg={Upload} alt="" width="100%" height="100px"/>
                            </span>    
                        </div>
                        || 
                        <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                            <span> <Image id={"bannerImage"+i} src={blobService.createUrl(bannerImages[i])} errorImg={Upload} alt="" width="100%" height="100px"/></span>
                        </div>
                        }
                        </>
                    )
                }else{
                    subItems.push(
                        <>
                            {isEdit && 
                                <div className="col-md-3 bottom-buffer compyLogo img-wrap"> 
                                 <label htmlFor={"bannerImages"}>
                                    <span><h6 className="left-tab"><img src={Upload}/>Logo</h6></span>
                                    </label>
                                    <input id={"bannerImages"} type="file" style={{display:"none"}}  onChange={(event)=>this.onFileChange(event)}/>
                                    </div>   
                            || <div/> }
                        </>
                    )                       
                }
            }
            if(portfolio.length > 0){
            pItems = portfolio.map(
                (item: any) => (
                    <Card className="left-buffer bottom-buffer" style={{ width: '16rem' }} onClick={(event:any)=>this.openProject(item)}>
                        {item.projectDetails.projectImages[0] ?
                        <Card.Img variant="top" src={blobService.createUrl(item.projectDetails.projectImages[0])} width="100%" height="150px" />
                        :
                        <Card.Img variant="top" src={blobService.createUrl(builderLogo)} width="100%" height="150px" />
                        }
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text>
                               {item.address? item.address.city:''}
                            </Card.Text>
                        </Card.Body>
                        </Card>
                )
            );}
            for(let i=0; i<projects.length; i++){
                var p = projects[i];
                pList.push(
                    <li className="row no-gutters">
                    <div className="col-md-3">
                        <img src={Project1} alt="" />
                    </div>
                    <div className="col-md p-4">
                        <h4>{p.name}</h4>
                        <h6>Builder: {p.builder ? p.builder.builderName: ""}</h6>
                        <p>{p.description}</p>
                        {/* <span className="right-end">
                            <Link className="text-center my-3" to="#"><h6 className="text-center">View Project</h6></Link>
                        </span> */}
                    </div>
                </li>
                )
            }
        }
          
        return isLoading ? (<Loader/>): 
         (
            <>
                <Header />
                {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                <div className="bg-img">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}}>{builderName}</h1>
                                <p className="my-3"><Link to="/Builders" className="link-text">Builders</Link> / {builderName}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                <div className="aboutBuilderProfile">
                    <div className="container py-3">
                        <div className="row mt-3">
                        <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                                <span>
                                {(bannerImages.length>1)?
                                    <Carousel  nextIcon={nextIcon} prevIcon={prevIcon} >
                                        {items}
                                    </Carousel>
                                    :
                                    <Carousel className = "disabled-arrow" nextIcon={nextDisabledIcon} prevIcon={prevDisabledIcon} >
                                        {items}
                                    </Carousel>
                                 }
                                <br/>
                                </span>
                                <div className="row">
                                        {subItems}
                                </div>
                            </div>
                        </div>
                            
                        </div>

                            <div className="col-md-6">
                                <div className="projectInfo">
                                  
                                    {this.state.isEdit===true &&
                                    
                                        <div>
                                            <div className="col-md-4 compyLogo img-wrap">
                                                {builderLogo && 
                                                <span>
                                                    <img className="close" id="logo" src={Delete} onClick={(event)=>{this.removeImage(event, "")}}/>
                                                    <Image id="logo" src={blobService.createUrl(builderLogo)} errorImg={Upload} alt="" width="100%" height="100px"/>
                                                </span>
                                                ||
                                                <span>
                                                <label htmlFor="logo">
                                                    <span><h6 className="left-tab"><img src={Upload}/>Logo</h6></span>
                                                </label>
                                                <input id="logo" type="file" style={{display:"none"}}  onChange={(event)=>this.onFileChange(event)}/>
                                                </span>
                                                }
                                              
                                            </div>
                                            <br/>
                                            <div>
                                                <input 
                                                    name="builderName"
                                                    type={this.state.type}
                                                    value={builderName}
                                                    autoFocus
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <br/>
                                            <div>
                                                <input 
                                                    name="city"
                                                    type={this.state.type}
                                                    value={city}
                                                    autoFocus
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <br/>
                                        </div>
                                        ||
                                        
                                        <span>
                                            <div className="col-md-4 compyLogo">
                                                <Image src={blobService.createUrl(builderLogo)} alt="" width="100%" height="100px" />
                                            </div>
                                            <h3 className="mt-3">{builderName}</h3>
                                            <p>{city}</p>
                                        </span>}

                                    
                                    {/* <p className="d-flex align-items-center">
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        (3 Reviews)
                                    </p> */}
                                    {this.state.isEdit===true &&
                                        <div>
                                            <br/>
                                        <div>
                                            <img src={Twitterwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="twitterLink"
                                                type={this.state.type}
                                                value={twitterLink}
                                                autoFocus
                                                onChange={this.handleChange}
                                            />
                                            {errors.twitterLink.length > 0 && (
                                                        <span style={{ color: 'red' }}>{errors.twitterLink}</span>
                                            )}
                                        </div>
                                        <br/>
                                        <div>
                                            <img  src={FBwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="facebookLink"
                                                type={this.state.type}
                                                value={facebookLink}
                                                autoFocus
                                                onChange={this.handleChange}
                                            />
                                            {errors.facebookLink.length > 0 && (
                                                        <span style={{ color: 'red' }}>{errors.facebookLink}</span>
                                            )}
                                        </div>
                                        <br/>
                                        <div>
                                            <img  src={Linkedinwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="instagramLink"
                                                type={this.state.type}
                                                value={instagramLink}
                                                placeholder="LinkedIn Link"
                                                onChange={this.handleChange}
                                            />
                                             {errors.instagramLink.length > 0 && (
                                                <p style={{ color: 'red',  marginLeft: '50px' }}>{errors.instagramLink}</p>
                                            )}
                                        </div>
                                        <br/>
                                        </div>
                                        ||
                                        <div>
                                            {(twitterLink == null || twitterLink == "") ?
                                                <a  target="_blank">
                                                    <img  src={Twitterwithround} alt="" />
                                                </a>
                                            :
                                                <a href={ builder.socialLinks.twitterLink} target="_blank">
                                                    <img  src={Twitterwithround} alt="" />
                                                </a>
                                             }
                                            {(facebookLink == null || facebookLink == "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={FBwithround} alt="" />
                                            </a>
                                                :
                                                <a href={builder.socialLinks.facebookLink} target="_blank" className="left-buffer">
                                                    <img  src={FBwithround} alt="" />
                                                </a>
                                            }
                                             {(instagramLink == null || instagramLink ==  "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={Linkedinwithround} alt="" />
                                            </a>
                                            :
                                            <a href={ builder.socialLinks.instagramLink} target="_blank" className="left-buffer">
                                                <img  src={Linkedinwithround} alt="" />
                                            </a>
                                        }
                                               
                                        </div>
                                        }

                        <div className="mt-4">
                            <button className="button1 contact" onClick={(event:any)=>this.onShowModal(event)} >Contact <img src={RightArrow} style={{height:"12px",width:"20px",marginLeft:"0.25rem"}}/></button>
                            {(localStorage.userType && localStorage.userType == "Customer") ?
                                <button 
                                    className="button2 ml-5 hire" 
                                    onClick={this.openHireModal}>
                                        Hire 
                                    <img src={RightArrow} className="ml-3"/>
                                </button>
                                        : ""
                            }
                        </div>
                                   
                                </div>
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    {subItems}
                                </div>
                            </div>
                        </div> */}


                        <div className="my-5">
                            <h4>About</h4>
                            {isEdit ? 
                                <div className='description'>
                                    <textarea id="about" name="about" className="px-3" placeholder="About the Project" rows={5} cols={50} onChange={this.handleChange} value={about} required/>
                                </div>
                                :
                                <p style={{ lineHeight: '30px' }}>{about}</p>
                            }
                      
                        </div>

                        <div className="my-5 portfolio-section">
                            <h4 className="my-3">Portfolio</h4>
                            <div className="row">
                            {/* { isEdit && 
                            <Card className="left-buffer bottom-buffer" style={{ width: '16rem' }} onClick={(event:any)=>this.newProject()}>
                                <Card.Body>
                                    <p className="text-center vertical-center">+ Add Projects</p>
                                </Card.Body>
                            </Card>} */}
                            {pItems}
                            </div>
                            { pItems.length === 0 &&
                                <div className="buildersBlock py-5 empty-Findproject-Container">
                                    <div className="container-fluid empty-Findproject-Content">
                                        <img src={ruler} alt="" />
                                        <p className="text-center my-3">There are no portfolio. We will soon have some for you.</p>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="my-5">
                            <h4 className="my-3">Projects</h4>
                            {projects.length < 1 ? 
                                <div className="buildersBlock py-5 empty-Findproject-Container">
                                    <div className="container-fluid empty-Findproject-Content">
                                        <img src={ruler} alt="" />
                                        <p className="text-center my-3">There are no live projects. We will soon have some for you.</p>
                                    </div>
                                </div>
                                : 
                                <ul>{pList}</ul>
                            }
                            {projects.length > 2 ? <h6 className="text-center my-3">See More</h6>:""}
                        </div>

                        {/* <div className="my-5">
                            <h4 className="my-3">Reviews</h4>
                            <ul>
                                <li className="row no-gutters">
                                    <div className="col-md-3">
                                        <img src={Project1} alt="" />
                                    </div>
                                    <div className="col-md-7 p-4">
                                        <h4>Johnie Deo</h4>
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed consetetur
                                            sadipscing elitr,{' '}
                                        </p>
                                    </div>
                                </li>

                                <li className="row no-gutters">
                                    <div className="col-md-3">
                                        <img src={Project3} alt="" />
                                    </div>
                                    <div className="col-md-7 p-4">
                                        <h4>House side villa</h4>
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed consetetur
                                            sadipscing elitr,{' '}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <h6 className="text-center my-3">See More</h6>
                        </div> */}
                    </div>
                </div>

            
                <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any)=>this.onHideUpload(event)}/>
                <CustomModal isAlert={true} show={builderModal} header={header} body={body} onHide={(event: any)=>this.onHideBuilderModal(event)}/>

                <Modal 
                    width="80%" 
                    scrollable={true} 
                    show ={showProject} 
                // effect="fadeInUp" 
                // onClickAway={(event:any) => this.onHideProject(event)}
                >
                        {/* {showProject && action==="Create" &&
                         <NewBuilderProject closeModal={(event:any)=>this.onHideProject(event)}/>
                        } */}
                        {showProject && action==="Update" &&
                         <UpdateBuilderProject 
                         project={selectedProject} 
                         isView={false} 
                         onHideProject={this.onHideProject}
                         />
                        }
                        {showProject && action==="View" &&
                         <UpdateBuilderProject 
                         project={selectedProject} 
                         isView={true} 
                         onHideProject={this.onHideProject}
                         />
                        }
                </Modal>
                <CustomModal isAlert={true} show={showModal} header={header} body=
                    { (address.length == 0  && builder.mobile == 'Not available') ? <div><p>Address & Contact Details Are Not Available. </p><p>Email : {builder.email}</p></div> 
                     : <div>{( address.length == 0 || address == null) ? "Address Is Not Available.": 
                     <p>Address : {address}</p> }{( builder.mobile == 'Not available') ? "Contact Details Is Not Available." : <p>Call on : {builder.mobile}</p> } {( builder.email == null) ?"Email Id Is Not Available." : <p>Email : {builder.email}</p> }</div> }onHide={(event:any)=>this.onHideModal(event)}/>

                {(localStorage.userType && localStorage.userType == "Customer") ?
                    <Modal show = {openHire} >
                        <Hire 
                            id = {this.props.location.state.id} 
                            result={(event: any) => this.hiresuccess(event)}
                            onHideHireModal={this.onHideHireModal}
                        />
                    </Modal>
                : ""
                }

                {(localStorage.userType && localStorage.userType == "Builder" || localStorage.userType == "Customer") ?
                    <Modal show ={this.state.quoteModal}>
                        <CreateQuote 
                            id = {projectId} 
                            builderId = {this.state.builderIds} 
                            result={(event: any) => this.quoteSuccess(event)} 
                            error ={(event: any) => this.quoteFailure(event)}
                        />
                    </Modal>
                    : ""}
                <Footer />
            </>
        );
    }
}
