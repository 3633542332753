import React, {useState, useRef} from 'react'
import IdleTimer from 'react-idle-timer';


function IdelTimerContainer() {
 let [isLoggedIn, setIsLoggedIn] = useState(false);
 const idelTimerRef =useRef(null);
//  const user = JSON.parse(localStorage.getItem('user'));
 const user = localStorage.getItem('user');
 

 const onIdle =()=>{
   localStorage.clear();
   window.location.replace("/");
   window.sessionStorage.clear();
 }
 if(user){
  isLoggedIn = true;
}
      
      return(
       
          <div>
             {isLoggedIn == true ?
          <IdleTimer ref = {idelTimerRef} timeout = {1000 * 60 * 5} onIdle = {onIdle}></IdleTimer>
          :
          ""  
          }
        </div>
        // <div>
        //   <IdleTimer ref = {idelTimerRef} timeout = {5*1000} onIdle = {onIdle}></IdleTimer>
        // </div>
           

      )

 }


export default IdelTimerContainer