import React, { Component } from 'react';
import Header from '../../../../common-components/Header/Header';
import Footer from '../../../../common-components/Footer/Footer';
import RealEstatePrices from '../../../../../assets/images/RealEstatePrices.png';
import { Link } from 'react-router-dom';

export default class RealEstatePriceBlog extends React.Component {

    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-12 ">
                                <h1  className="ourMissionHead">Blog</h1>
                                <p className="my-3"><Link to="/Blog" className="link-text">Blogs</Link> / COVID-19 AND ITS IMPACT ON REAL ESTATE</p>
                            </div>             
                            </div>
                        </div>
                    </div>
                </div>
            <br/><br/>
            <div className='container blog-container p-3'>     
                   <div className='blog-box  '>
                        <h3 style={{color: "#033b64 "}}>COVID-19 AND ITS IMPACT ON REAL ESTATE </h3>
                        <img style = {{width: "70%" , height: "40%"}} src={RealEstatePrices}/>
                        <div  className=' mt-3'>
                            <p> The pandemic had a terrible effect on business and day to day expenses. 
                                Covid19 played a major role in effecting the prices of various products and services,
                                where few of them had to skyrocket and few had to crash. 
                                This crisis had a similar effect on the real-estate. 
                                As a result of the first wave the prices of the properties fell down and after the
                                second wave the prices shot up. Making the very affordability of land and houses very 
                                steep. The following content will highlight the key points on how things worked out 
                                in during and after the pandemic. 
                            </p>
                            <h4>PROMINENT CHANGES </h4>
                            <p> The initial stage saw a 40% to 50% decline in the value of real estate properties.
                                To overcome this sharp decline government of India has sorted measures such as low interest rates,
                                lesser registration fees, deduction of tax rate etc. 
                                The RBI announced that the repo rate will remain at 4% and the reverse repo rate
                                will remain at 3.5 percent till the market stabilises. 
                            </p>

                            <p> Also, Covid-19 gave a boost to the digitalization where the transfer of money from
                                one bank account to another happened from the mobile devices. 
                                This provided scope to seamless transaction on multiple accounts at fingertips. 
                            </p>
                            <p> This change in the technology made it possible for contacting the real-estate dealers,
                                online registration of plots and online payments. 
                            </p>
                            <p> The second part of the pandemic where there was recovery from the damage taken, 
                                helped prices of properties stabilize. Now, everyone was interested in purchasing new properties, 
                                plots, flats, villas and adopting the updated ways of transactions.  
                                This increase in demand made the prices to surge be it in the urban or rural areas.
                                With urban localities the plots coming to saturation people looking for alternative 
                                in the outskirts of city where the property's value is affordable as compared 
                                to the price inside the city. 
                            </p>
                            <h4>IMPACT ON REAL ESTATE PROFESSIONALS </h4>
                            <p>
                                 Builders initially seeked the help of government to have their unsold stock relieved 
                                 into the market at an appropriate price.  Everything came to a halt during the lockdown 
                                 and made it more miserable. But the government of India initiated coronavirus specific 
                                 stimulus package that offered some relief to the community of builders.
                            </p>
                            <p>
                                The situation is now quite opposite as both builders and clients are thinking way
                                ahead of the time when pandemic crisis is over and the demand as usual for the real-estate 
                                and its services increases exponentially. Keeping it in mind many are now investing a large 
                                part of their income in purchasing and securing plots, residential properties and building it 
                                right now where the prices are low and may spike any time. 
                            </p>
                            <p>
                                Real estate is currently seen as a secure investment opportunity,
                                and it continues to be the field of choice for many investors.
                                This trend of real estate investment is expected to accelerate as political 
                                and economic stability improves. In the foreseeable future, 
                                real estate analysts predict a substantial shift in commercial and retail space investment.
                                With global organisations growing more receptive to flexible working circumstances, 
                                demand in this category is projected to rise. 
                            </p>
                            <p>
                                Taking the situation into consideration <Link to = "/"><b>Aashray Homes</b></Link> has been involved and has taken up several
                                projects in real-estate sector and connected many clients to their desired professional whose service 
                                they are seeking. Making it more convenient for people to have these services at their fingertips.
                                The main advantage of the online platform is to help the clients who are wanting to have their dream home
                                built and are confused from where to begin. With the team of expertise, 
                                it provides you access to the top real-estate professionals who are ready to render their services.
                            </p>
                            <p> <Link to = "/"><b>Aashray Homes</b></Link> screen all the professionals beforehand, 
                                so you do not need to take the stress of finding the right builder,
                                architect, raw materials supplier and other personnel who are involved 
                                in the construction of your house. </p>
                        </div>
                       
                    </div>
			</div>
            <Footer/>
            </>
         );
    }
}
