import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import { constants } from '../../../../constants/constants';
import axios from 'axios';
interface PartnersProps {
   
}
interface PartnersState {
    name: string;
    email: string;
    description: string;
    data: {};
    header: string,
    body: string,
    showAlert: boolean,
    validate: boolean;
    errors: {
        name: string,
        email: string,
        description: string
    };  
}

const Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class Partners extends React.Component<PartnersProps & any, PartnersState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            name: "",
            email: "",
            description: "",
            showAlert: false,
            validate: false,
            header: "",
            body: "",
            data: {},
            errors: {
                name: '',
                email: '',
                description: ''
            }
        };
        this.state = initialState;
    }

    handleChange(event: any) {
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'name':
                errors.name = value.length == 0 ? 'Name can not be empty' : '';
                break;
            case 'email':
                errors.email = Regex.test(value) ? '' : 'Please enter valid Email';
                break;
            case 'description':
                errors.description = value.length == 0 ? "Description can not be empty" : "";
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    }

    openUpload = async (header: any, body: any) => {
        this.setState({ showAlert: true, header: header, body: body });
    }
    onHideUpload = async (event: any) => {
        this.setState({ showAlert: false });
        this.clearFields()
    }
    clearFields = () => {
        this.setState({ name: "", description: "", email: "" })
        let errors = this.state.errors;
        errors.description = "";
        errors.name = "";
        errors.email = "";
    }

    sendQuery = () => {
        let validity = true;
        Object.values(this.state.errors).forEach(
            (val: any) => val.length > 0 && (validity = false)
        );
        let data = {
            name: this.state.name,
            toEmail: this.state.email,
            description: this.state.description
        }
        if (validity === true) {
            const key = constants.subscriptionKey;
            const url = constants.contactUs;
            axios.post(url, data, { headers: { "Ocp-Apim-Subscription-Key": key } }).then((response) => {
                this.openUpload("Success", "Message sent SuccessFully");
            }).catch((error) => {
                this.openUpload("Error", "Message send failed");
            })

        }
    }
    validData = () => {
        const { name, email, description } = this.state;
        if (name === "" || email === "" || description === "") {
            return true
        }
        else {
            return false
        }
    }
      render() {
        const { name, email, errors, validate, header, body, description, showAlert } = this.state;
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 offset-md-2 col-12">
                                <h1>Partners</h1>
                            </div>             
                            </div>
                        </div>
                    </div>
                    {/* <div className="input-cntr">
                         <input type="text" placeholder="Search" name="searchTxt" onChange={this.handleChange} className="px-3 py-2"/><img className="search-icon" onClick={(event:any)=>this.searchBuilder(event)}src={SearchIcon} alt=""/>
                     </div> */}
                </div>
            <br/><br/>

            <div className='container mt-3 support'>
                    <div className='row'>
                        <div className='col-md-6 mt-5'>
                            <p >
                            At Aashray Homes, we strive to maintain develop honest, professional, and long-lasting relationships. 
            If your ideas are the same, join us and partner with us. Reach out to know more.
                            </p>
                        </div>
                        <div className='col-md-6'>
                            <h3>What is your query?</h3>
                            <div className='query-container p-5 mb-4'>
                                <div className='query-container-box d-flex'>
                                    <p>Full Name</p>
                                    <input
                                        className="name mb-3 "
                                      onChange={this.handleChange}
                                        value={name} type="text"
                                        name="name"
                                        placeholder="Full Name"
                                        required
                                    />
                                    {errors.name.length > 0 && (
                                        <p style={{ color: 'red', marginLeft: '50px' }}>{errors.name}</p>
                                    )}
                                    <p>Email Address</p>
                                    <input
                                        className="email mb-3"
                                        onChange={this.handleChange}
                                        value={email}
                                        name="email"
                                        type="email"
                                        placeholder="Email Address"
                                        required
                                    />
                                    {errors.email.length > 0 && (
                                        <p style={{ color: 'red', marginLeft: '50px' }}>{errors.email}</p>
                                    )}

                                    <p>Message</p>
                                    <textarea
                                        onChange={this.handleChange}
                                        className="mb-3"
                                        value={description}
                                        name="description"
                                        placeholder="Enter your Message"
                                        required
                                        style={{resize:'none'}}
                                    />
                                    {errors.description.length > 0 && (
                                        <p style={{ color: 'red', marginLeft: '50px' }}>{errors.description}</p>
                                    )}
                                    <button
                                    style={{textAlign:"center",opacity:1,fontWeight:600}}
                                        className={this.validData() ? "button-send mt-4 contactus-disble" : "button-send mt-4"}
                                        onClick={this.sendQuery}
                                        disabled={this.validData()}
                                    >
                                        Send query
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <Footer/>
            </>
         );
    }


}