import React, { useState, useEffect } from 'react';

 

const RedirectPage: React.FC = () => {

  const [isLoading, setIsLoading] = useState(true);

 

  useEffect(() => {

    const userAgent = navigator.userAgent || navigator.vendor;

 

    if (/android/i.test(userAgent)) {

      window.location.href = 'https://play.google.com/store/apps/details?id=au.com.aashrayhomes';

    } else if (/iPad|iPhone|iPod/.test(userAgent)) {

      window.location.href = 'https://apps.apple.com/in/app/aashray-homes/id1593356411';

    } else {

      window.location.href = 'https://www.ashrayhome.in/';

    }

  }, []);

 

  useEffect(() => {

    if (!isLoading) {

      // Hide loader or perform additional actions

    }

  }, [isLoading]);

 

  return (

    <div className="loading-container">

      {isLoading && <div>Loading...</div>}

    </div>

  );

};

 

export default RedirectPage;
