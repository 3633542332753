import React from 'react';
import './MyPortfolio.scss';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import Edit from '../../../../assets/images/edit.svg';
import Modal from 'react-awesome-modal';
import { RouteComponentProps } from 'react-router';
import Loader from '../../../common-components/Loader';
import LinkedInwithround from '../../../../assets/images/Linkedinwithround.svg';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import Delete from "../../../../assets/images/delete.svg"
import { Card } from "react-bootstrap";
import Upload from "../../../../assets/images/upload.svg"
import ruler from '../../../../assets/images/ruler.svg';
import { blobService } from '../../../common-components/Blob';
import Review_Client from '../../../../assets/images/Mask1.svg';
import Review_Client2 from '../../../../assets/images/Mask2.svg';
import { constants } from '../../../../constants/constants';
import rightarrow from '../../../../assets/images/rightarrow.svg';
import { Link, withRouter } from 'react-router-dom';
import Check from '../../../Services/Check';
import Plus from '../../../../assets/images/plus-down.svg';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import { userService } from '../../../Services/CommonService';
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import UpdateArchitectProject from '../UpdateArchitectProject/UpdateArchitectProject';

interface MyPortfolioProps {
    name?: any;
    value?: any;
    history?: any;
}
interface MyPortfolioState {
    isLoading: boolean;
    architect: any;
    projects: any;
    images: any[];
    errors: {
        architectName: string,
        city: string,
        twitterLink: string,
        facebookLink: string,
        instagramLink: string,
        linkedInLink: string,
        about: string,
    };
    nextIcon: any;
    prevIcon: any;
    newAbout: string;
    listItems: any;
    newImage: any;
    newName: string;
    isEdit: boolean;
    type: string;
    architectLogo: string,
    showProject: boolean,
    showUpload: boolean,
    header: string,
    body: string,
    architectName: string,
    architectId: string,
    city: string,
    twitterLink: string,
    facebookLink: string,
    instagramLink: string,
    about: string,
    portfolio: any,
    selectedProject: any,
    action: string,
    error: boolean;
    ErrMsg: any;
    bannerImages: any[],
    architectModal: boolean,
    formOtherData: any,
    formImage: any
}

class Portfolio extends React.Component<MyPortfolioProps & RouteComponentProps, MyPortfolioState> {
    constructor(props: any) {
        super(props);

        const initialState = {
            isLoading: true,
            architect: {},
            projects: [],
            type: 'text',
            images: [],
            errors: {
                architectName: '',
                city: '',
                twitterLink: '',
                facebookLink: '',
                instagramLink: "",
                linkedInLink: "",
                about: '',
            },
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            listItems: [],
            isEdit: false,
            architectLogo: '',
            showUpload: false,
            showProject: false,
            newImage: "",
            newName: "",
            newAbout: "",
            header: '',
            body: '',
            architectName: '',
            architectId: "",
            city: '',
            twitterLink: '',
            facebookLink: '',
            instagramLink: '',
            linkedInLink: "",
            about: '',
            portfolio: [],
            selectedProject: {},
            action: '',
            bannerImages: [],
            architectModal: false,
            error: false,
            ErrMsg: "",
            formOtherData: [],
            formImage: []
        };
        this.state = initialState;
    }
    componentDidMount() {
        this.init();
    }
    cancel() {
        this.editPortfolio();
        this.init();
        this.clearErrorMsg();
    }
    editPortfolio = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        });
    };
    init() {

        let userId = localStorage.getItem("id");
        this.fetchArchitect(userId);

    }
    fetchPortfolio = (id: any) => {
        const options = {
            url: constants.getBuilderProtfolioProjectsAPI + id + "?status=Completed"
        };
        userService.getApiRequest(options).then((response) => {
            this.setState(
                {
                    portfolio: response.data
                })
        })
            .catch((error) => {
                this.setState({ ErrMsg: error, error: true });
            });
    }
    async fetchProjects(id: any): Promise<any> {
        const options = {
            url: constants.getBuilderProtfolioProjectsAPI + id + "?status=In Progress"
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ projects: response.data, isLoading: false });
            })

            .catch((error) => {
                this.setState({ ErrMsg: error, error: true });
            });

    };
    async fetchArchitect(id: any): Promise<any> {

        Check.checkToken()
            .then(
                () => {

                    const options = {
                        url: constants.fetchArchitectAPI + id,
                    };
                    userService.getApiRequest(options).then((response) => {
                        let a = response.data;
                        let images;
                        images = JSON.stringify(a.bannerImages);
                        this.setState({
                            architect: a,
                            architectLogo: a.profileImage,
                            newImage: a.profileImage,
                            architectName: a.architectName,
                            newName: a.architectName,
                            newAbout: a.about,
                            city: a.address ? a.address.city : '',
                            twitterLink: a.socialLinks ? a.socialLinks.twitterLink : "",
                            facebookLink: a.socialLinks ? a.socialLinks.facebookLink : "",
                            instagramLink: a.socialLinks ? a.socialLinks.instagramLink : "",
                            about: a.about,
                            bannerImages: a.bannerImages,
                            formOtherData: a,
                        });

                        localStorage.setItem("images", images);
                        this.fetchPortfolio(id);
                        this.fetchProjects(id);
                    })
                        .catch((error) => {
                            this.setState({ ErrMsg: error, error: true });
                        });
                })

    };
    onHideProject = async (event: any) => {
        this.setState({ showProject: false });
        this.init();
    }
    hasChangedSinceInitialState = () => {
        const { formOtherData } = this.state;

        return (
            formOtherData.about === this.state.about &&
            formOtherData.architectName === this.state.architectName &&
            formOtherData.socialLinks.twitterLink === this.state.twitterLink &&
            formOtherData.socialLinks.facebookLink === this.state.facebookLink &&
            formOtherData.socialLinks.instagramLink === this.state.instagramLink &&
            formOtherData.address.city === this.state.city &&
            formOtherData.profileImage == this.state.architectLogo ||
            this.state.architectName === ""

        );
    }
    onFileChange = async (event: any) => {


        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        var formData = new FormData();
        if (file) {
            if (id === "logo" || id === "architectLogo") {
                formData.append('id', userId ? userId : '');
                formData.append('type', "Profile");
                formData.append('file', file);
            }

            const options = {
                url: constants.uploadFilesAPI,
                data: formData
            }
            Check.checkToken();
            const response = await userService.postFileApiRequest(options);

            if (response) {
                if (id === "architectLogo") {
                    this.setState({ architectLogo: response });
                }
                else {
                    this.setState({ architectLogo: this.state.architectLogo });
                }
                this.openUpload("Success", "File Uploaded SuccessFully");
            } else {
                if (id === "architectLogo") {
                    this.setState({ architectLogo: '' });
                }
                this.openUpload("Failed", "Failed to Upload File");
            }
        }
        else {
        }
    };

    openUpload = async (header: any, body: any) => {
        this.setState({ showUpload: true, header: header, body: body });
    }
    onHideArchitectModal = async (event: any) => {
        this.setState({ architectModal: false, isEdit: false });
        window.location.replace("/ArchitectPortfolio");
    }
    onHideUpload = async (event: any) => {
        this.setState({ showUpload: false });
    }
    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        const urlRegEx = RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        let errors = this.state.errors;
        switch (name) {
            case 'architectName':
                errors.architectName = value.length > 70 ? 'You Have Reached Your maximum Limit Of Characters Allowed' : '';
                break;
            case 'about':
                errors.about = (value.length < 251 || value.length == 0) ? '' : 'You Have Reached Your maximum Limit Of Characters Allowed';
                break;
            case 'twitterLink':
                errors.twitterLink = (urlRegEx.test(value) || value.length == 0) ? '' : 'Please Enter A Valid Link';
                break;
            case 'facebookLink':
                errors.facebookLink = (urlRegEx.test(value) || value.length == 0) ? '' : 'Please Enter A Valid Link';
                break;
            case 'instagramLink':
                errors.instagramLink = (urlRegEx.test(value) || value.length == 0) ? '' : 'Please Enter A Valid Link';
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    }

    removeImage = async (event: any, path: string) => {
        let id = event?.target.id;
        if (id === "logo") {
            this.setState({ architectLogo: "" });
            this.openUpload("Success", "File Successfully Deleted");
        }

        else {
            this.setState({ architectLogo: this.state.architectLogo })
            this.openUpload("Failed", "File Not Deleted");

        }
    }

    openArchitectModal = async (header: any, body: any) => {
        this.setState({ architectModal: true, header: header, body: body });

    }
    clearErrorMsg = () => {
        let errors = this.state.errors;
        errors.about = '';
        errors.twitterLink = '';
        errors.facebookLink = '';
        errors.instagramLink = "";
        errors.architectName = '';



    }
    toNewArchitect = () => {
        this.props.history.push("/NewArchitectProject");
    }
    savePortfolio = async () => {
        Check.checkToken()
            .then(
                async () => {
                    let userId = localStorage.getItem("id");
                    let validity = true;
                    Object.values(this.state.errors).forEach(
                        (val: any) => val.length > 0 && (validity = false)
                    );
                    if (validity == true) {
                        var p = this.state.architect;
                        const options = {
                            url: constants.updateArchitectAPI,
                            data: {
                                "userId": userId,
                                "architectId": this.state.architectId,
                                "address": {
                                    "id": p.address.id,
                                    "addressLine1": p.address.addressLine1,
                                    "addressLine2": p.address.addressLine2,
                                    "city": this.state.city,
                                    "state": p.address.state,
                                    "country": p.address.country,
                                    "pincode": p.address.pincode,
                                    "latitude": p.latitude,
                                    "longitude": p.longitude,
                                    "active": true
                                },
                                "profileImage": this.state.architectLogo,
                                "architectName": this.state.architectName,
                                "about": this.state.about,
                                "email": p.email,
                                "mobile": p.mobile,
                                "alternativeMobile": p.alternativeMobile,
                                "alternativeEmail": p.alternativeEmail,
                                "website": p.website,
                                "socialLinks": {
                                    "id": p.socialLinks.id,
                                    "facebookLink": this.state.facebookLink,
                                    "instagramLink": this.state.instagramLink,
                                    "twitterLink": this.state.twitterLink,
                                    "youtubeLink": p.socialLinks.youtubeLink
                                },
                                "gender": p.gender,
                                "houseHoldIncome": p.householdIncome,
                                "active": p.active
                            },

                        }
                        var response = await userService.updateApiRequest(options);

                        if (response) {
                            this.openArchitectModal("Success", "Portfolio Updated Successfully");
                            this.init();

                        }
                        else {
                            this.openArchitectModal("Failed", "Failed to Update Portfolio");
                        }
                    }
                    else {
                    }
                }
            )

    }

    openProject = (item: any) => {
        this.setState({ showProject: true, selectedProject: item, action: "Update" })
    }
    render() {
        var pItems = [];
        var pList = [];
        const { isLoading, architect, projects, isEdit, architectLogo, showProject, showUpload, body, header, architectName, instagramLink, city, twitterLink, facebookLink, about, portfolio, selectedProject, action, architectModal, errors } = this.state;
        if (Object.keys(architect).length) {
            if (portfolio.length > 0) {
                pItems = portfolio.map(
                    (item: any) => (

                        <Card key={item.id} className="left-buffer bottom-buffer portfolio-image" style={{ minHeight: "300px" }} onClick={(event: any) => this.openProject(item)} >
                            {item.projectDetails.projectImages[0] ?
                                <Card.Img variant="top" src={blobService.createUrl(item.projectDetails.projectImages[0])} width="100%" height="150px" />
                                : <Card.Img variant="top" src={ProjectPlaceholder} width="100%" height="150px" />
                            }
                            <Card.Body>
                                <Card.Title>{item.name}</Card.Title>
                                <Card.Text>
                                    {item.address ? item.address.city : ''}


                                </Card.Text>
                            </Card.Body>
                        </Card>
                    )
                );
            }

            for (let i = 0; i < projects.length; i++) {
                var p = projects[i];
                pList.push(

                    <li className="row no-gutters">
                        <div className="col-md-3">
                            {p.projectDetails.projectImages[0] &&
                                <img src={blobService.createUrl(p.projectDetails.projectImages[0])} alt="" width="100%" height="100px" />
                                ||
                                <img src={ProjectPlaceholder} alt="" width="100%" height="100px" />
                            }
                        </div>
                        <div className="col-md-7 p-4">
                            <h4 className="projectname">{p.name}</h4>
                            <h6>Location : {p.address ? p.address.city : "NA"}</h6>
                            <p>{p.description}</p>

                        </div>
                        <div className="col-md-2 row">
                            <span className="align-self-end ml-auto mb-3">
                                <Link className="text-center my-3" to={{ pathname: "/ViewArchitectProject", state: { data: p } }}><h6 className="text-center viewp-font">View Project <img className="view-Project-Arrow" src={rightarrow} alt="" /></h6></Link>
                            </span>
                        </div>
                    </li>
                )
            }
        }
        return isLoading ? (<Loader />) : (
            <div>
                <Header />
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h1 style={{ fontWeight: 600 }}>My Portfolio</h1>
                                    <p className="my-3"><Link to="#" className="link-text">Portfolio</Link></p>
                                </div>

                                {!isEdit ? (
                                    <div className="col-md">

                                 {/* <div className="col-md buttons-container"> */}
                                            <button
                                                className="button3 contact"
                                                // className="button8 ml-5 contact edit-archbut"
                                                onClick={() => {
                                                    this.editPortfolio()
                                                }}
                                            >
                                                Edit Portfolio
                                                <img src={Edit} className="ml-1" />
                                            </button>
                                        </div>
                                        ) : (
                                        <div className="col-md buttons-container ">
                                            <button
                                                className={this.hasChangedSinceInitialState() ?
                                                    "button8 ml-5 update-contact disbledSaveButton" : "button8 ml-5 update-contact save-btn"}
                                                onClick={() => { this.savePortfolio() }}
                                                disabled={this.hasChangedSinceInitialState()}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="button8 ml-5 but-cancel updatecontact"
                                                onClick={() => {
                                                    this.cancel();
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                )}
                                    </div>
                        </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="container">
                        <div className="row">
                            {isEdit ?
                                <>
                                    <div className="col-md-4 client_img">
                                        {architectLogo.length && <img src={blobService.createUrl(architectLogo)} ></img>
                                            || <img src={ProjectPlaceholder} ></img>
                                        }
                                        {(architectLogo === "" || architectLogo === null) && <label className="uploadLogo" htmlFor={"architectLogo"}>
                                            <span className='cursor'><h6 ><img src={Upload} />Logo</h6></span>
                                        </label>}
                                        <input id={"architectLogo"} type="file" style={{ display: "none" }} onChange={(event) => this.onFileChange(event)} />


                                    </div>

                                    <div className="col-md-8">
                                        {architectLogo && <span> <img className="delete-img" id="logo" height="20px" width="20px" src={Delete}
                                            onClick={(event) => { this.removeImage(event, architectLogo) }} /> </span>}
                                        <div className="edit-architectname text-margin">
                                            <input
                                                value={architectName}
                                                name="architectName"
                                                onChange={this.handleChange}
                                                type={this.state.type} ></input>
                                            {errors.architectName.length > 0 && <p style={{ color: "red" }}>{errors.architectName}</p>}
                                        </div>
                                        <br />

                                        <p className="para-text text-margin">{city}</p>


                                        <br />

                                        <br />
                                        <div className="social-Links">
                                            <br />
                                            <div >
                                                <img src={Twitterwithround} alt="" />
                                                <input
                                                    className="left-buffer"
                                                    name="twitterLink"
                                                    type={this.state.type}
                                                    value={twitterLink}
                                                    autoFocus
                                                    onChange={this.handleChange}
                                                />
                                                {errors.twitterLink.length > 0 && (
                                                    <p style={{ color: 'red', marginLeft: '50px' }}>{errors.twitterLink}</p>
                                                )}
                                            </div>
                                            <br />
                                            <div>
                                                <img src={FBwithround} alt="" />
                                                <input
                                                    className="left-buffer"
                                                    name="facebookLink"
                                                    type={this.state.type}
                                                    value={facebookLink}
                                                    autoFocus
                                                    onChange={this.handleChange}
                                                />
                                                {errors.facebookLink.length > 0 && (
                                                    <p style={{ color: 'red', marginLeft: '50px' }}>{errors.facebookLink}</p>
                                                )}
                                            </div>
                                            <br />
                                            <div>
                                                <img src={LinkedInwithround} alt="" />
                                                <input
                                                    className="left-buffer"
                                                    name="instagramLink"
                                                    type={this.state.type}
                                                    value={instagramLink}
                                                    autoFocus
                                                    onChange={this.handleChange}
                                                />
                                                {errors.instagramLink.length > 0 && (
                                                    <p style={{ color: 'red', marginLeft: '50px' }}>{errors.instagramLink}</p>
                                                )}
                                            </div>
                                            <br />
                                        </div>


                                    </div>
                                </>

                                : <>
                                    <div className="col-md-4 client_img">
                                        <img src={blobService.createUrl(architectLogo)} />
                                    </div>
                                    <div className="col-md-8">

                                        <h4 className="heading_text">{architectName}</h4>
                                        <p className="para-text">{city}</p>
                                        <div className="social_icon">
                                            {(twitterLink == null || twitterLink == "") ?
                                                <a target="_blank">
                                                    <img src={Twitterwithround} alt="" />
                                                </a>
                                                :
                                                <a href={architect.socialLinks ? architect.socialLinks.twitterLink : ""} target="_blank">
                                                    <img src={Twitterwithround} alt="" />
                                                </a>
                                            }
                                            {(facebookLink == null || facebookLink == "") ?
                                                <a target="_blank">
                                                    <img src={FBwithround} alt="" style={{ marginLeft: '10px' }} />
                                                </a>
                                                :
                                                <a href={architect.socialLinks ? architect.socialLinks.facebookLink : ""} target="_blank">
                                                    <img src={FBwithround} alt="" style={{ marginLeft: '10px' }} />
                                                </a>
                                            }
                                            {(instagramLink == null || instagramLink == "") ?
                                                <a target="_blank">
                                                    <img src={LinkedInwithround} alt="" style={{ marginLeft: '10px' }} />
                                                </a>
                                                :
                                                <a href={architect.socialLinks ? architect.socialLinks.instagramLink : ""} target="_blank">
                                                    <img src={LinkedInwithround} alt="" style={{ marginLeft: '10px' }} />
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </>
                            }



                        </div>

                        <br />
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="overlay_text">About</h4>
                                {(isEdit == true) ?
                                    <div className='description'>
                                        <textarea id="about" name="about" className="px-3 about-text" placeholder="About the Project" rows={5}
                                            cols={50} onChange={this.handleChange} value={about} required />
                                        {errors.about.length > 0 && <p style={{ color: "red" }}>{errors.about}</p>}
                                    </div>
                                    :
                                    <p className="description_text"> {(about) ? about : ""} </p>

                                }
                            </div>
                        </div>
                        <br />
                        <br />


                        <div className="my-5">
                            <h4 className="my-3 overlay_text">Portfolio</h4>
                            {

                                (pItems.length > 0) ?

                                    <div className="row">
                                        <div className="left-buffer bottom-buffer addproject-button" >
                                            <span style={{ cursor: "pointer" }} onClick={this.toNewArchitect}>
                                                <img src={Plus} /> Add Projects
                                            </span>

                                        </div>
                                        {pItems}
                                    </div>
                                    : <div className="row">
                                        <div className="left-buffer bottom-buffer addproject-button" >
                                            <span style={{ cursor: "pointer" }} onClick={this.toNewArchitect}>
                                                <img src={Plus} /> Add Projects
                                            </span>
                                        </div>
                                    </div>
                            }
                        </div>


                        <div className="my-5">
                            <div className="Projects">
                                <h4 className="my-3 overlay_text">Projects</h4>
                                {projects.length < 1 ?
                                    <div className="buildersBlock py-5 empty-Findproject-Container">
                                        <div className="container-fluid empty-Findproject-Content2">
                                            <img src={ruler} alt="" />
                                            <p className="text-center my-3">There are no live projects. We will soon have some for you.</p>
                                        </div>
                                    </div>
                                    :
                                    <ul >{pList}</ul>
                                }

                            </div>
                        </div>
                        {/* <h4 className="overlay_text">Reviews</h4>
                    <br />
                    <div className="row project-slider">
                        <div className="col-md-3 projects_size">
                            <img className="projectImages" src={Review_Client} />
                        </div>
                        <div className="col-md-7">
                            <h4>John Doe</h4>
                            <div className="star_color">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star-o" aria-hidden="true"></i>
                            </div>

                            <p className="description_text">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloremque sapiente laboriosam
                                blanditiis, cumque facere laborum distinctio, eaque providentRepellat.
                            </p>
                        </div>
                       
                    </div>
                    <br />
                    <div className="row project-slider">
                        <div className="col-md-3 projects_size">
                            <img className="projectImages" src={Review_Client2} />
                        </div>
                        <div className="col-md-7">
                            <h4>Johnie Doe</h4>
                            <div className="star_color">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star-o" aria-hidden="true"></i>
                            </div>
                            <p className="description_text">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloremque sapiente laboriosam
                                blanditiis, cumque facere laborum distinctio, eaque providentRepellat.
                            </p>
                        </div>
                    
                    </div>
                <br /> */}


                    </div>
                    <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any) => this.onHideUpload(event)} />
                    <CustomModal isAlert={true} show={architectModal} header={header} body={body} onHide={(event: any) => this.onHideArchitectModal(event)} />
                    <Modal width="80%" class="aboutBuilderProfile" effect="fadeInUp"
                        onClickAway={(event: any) => this.onHideProject(event)}
                        visible={showProject}  >
                        {showProject && action === "Update" &&
                            <UpdateArchitectProject project={selectedProject} onHideProject={this.onHideProject} />
                        }
                    </Modal>
                    <Footer />

                </div>

                );
    }
}
                export default Portfolio;
