import React, { Component, useEffect } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import './AboutCompany.scss'
interface AboutCompanyProps {
    name?: any;
    value?: any;
}
interface AboutCompanyState {
    errors: {};
}


export default class AboutCompany extends React.Component<AboutCompanyProps, AboutCompanyState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            errors: {},
        };
        this.state = initialState;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
      }
    render() {
        return (
            <>
                <Header />
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 d-flex align-items-center">
                                    <h1 style={{fontWeight:600}}>About Us</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="input-cntr">
                         <input type="text" placeholder="Search" name="searchTxt" onChange={this.handleChange} className="px-3 py-2"/><img className="search-icon" onClick={(event:any)=>this.searchBuilder(event)}src={SearchIcon} alt=""/>
                     </div> */}
                </div>
                <br /><br />
                <div className="container">
                            {/* <div className="row"> */}
                                {/* <div className="offset-md-2 col-md-6 align-items-center"> */}
                <p className = "aboutuspara">
                With an intuitive interface, Aashray Homes enables you to market your properties effectively and the best deal
                 possible. From buying and selling, rent and vacancy tracking, documentation and home loan approvals - we deal
                  with every aspect of the consumers’ needs in the real estate industry. Our expertise and experience helps us make the
                   entire transaction seamless. With a wide range of listings on our portal, you can get a better insight into the market
                   , and choose specifically based on your need, requirement and choice.     <br/>
                   <br/>
                  Our solutions help you maintain and organize payments, tenants, and take virtual tours of the property - in the
                comfort of your own space. As an added advantage, our property maintenance services come handy and keep your
               property in the best working condition. Get real updates, detailed information, photos, and additional notes from any
               part of the globe. Smooth, Simple and Easy - <span className="asharyawebsite"> Aashray Homes </span> is your one stop solution for all things real- estate.</p>
                <br /><br />
                {/* </div> */}
                {/* </div> */}
                </div>
                <Footer />
            </>
        );
    }
}
function componentDidMount() {
    throw new Error('Function not implemented.');
}

