import React, { useEffect, useState } from 'react';
import defaultimg from '../../../assets/images/defaultimg.svg';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    placeholderImg?: string;
    errorImg?: string;
    isLoading?: boolean;
}
export default ({
    src,
    placeholderImg = 'https://i.gifer.com/7SMw.gif',
    errorImg= defaultimg,
    // errorImg = 'https://image.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg',
    isLoading = false,
    ...props
}: ImageProps) => {
    const [imgSrc, setSrc] = useState(placeholderImg || src);
    let errSrc = placeholderImg;
    useEffect(() => {
        const img = new Image();
        img.src = src as string;
        img.addEventListener('load', () => {
            setSrc(src);
        });
        if (src == '404' && !isLoading) {
            errSrc = errorImg;
        }
        img.addEventListener('error', () => {
            setSrc(errSrc);
        });
    }, [src, placeholderImg, errorImg]);
    return <img {...props} src={imgSrc} />;
};
