import React, { Component } from 'react'
import Project1 from '../../../../assets/images/projects1.jpg' 
import Project3 from '../../../../assets/images/project3.jpg' 
import Project4 from '../../../../assets/images/project4.jpg' 
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Loader from '../../../common-components/Loader';
import Edit from '../../../../assets/images/edit.svg';
import { blobService } from '../../../common-components/Blob';
import Carousel from "react-bootstrap/Carousel";
import Image from "../../../common-components/Image";
import Delete from "../../../../assets/images/delete.svg"
import Upload from "../../../../assets/images/upload.svg"
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import Check from '../../../Services/Check';
import './UpdateBuilderProject.scss';
import Cross from "../../../../assets/images/VectorCross.png";

interface UpdateBuilderProjectProps {
    name?: any;
    value?: any;
    project?: any;
    isView?: boolean;
    onHideProject?: any;
}
interface UpdateBuilderProjectState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    Images: any[],
    errors: {
        description: string,
        projectName: string,
        adLine1: string,
        adLine2: string,
        landSize: string,
        mobile : string,
        cost: string,
        pincode: string
    };
    project: any,

    type: string,
    projectName: string,
    description: string,
    adLine1: string,
    adLine2: string,
    city: string,
    state: string,
    country: string,
    pincode: string,
    mobile: string,
    cost: string,
    landSize: string,
    measurementUnit: string,
    projectImages: any[],
    isEdit: boolean,
    nextIcon: any,
    prevIcon: any,
    showUpload: boolean,
    header: string,
    body: string,
    stateList: any,
    pincodeList: any,
    cityList:any,
    formAddressData: any,
    formOtherData: any,
    nextDisabledIcon: any,
    prevDisabledIcon: any
}

const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
export default class UpdateBuilderProject extends React.Component<UpdateBuilderProjectProps, UpdateBuilderProjectState> {
    constructor(props: UpdateBuilderProjectProps) {
        super(props);
        const initialState = {
            isLoading: true,
            Images:[
                Project1,Project3,Project4,Project1
             ],
            errors: {
                description: '',
                projectName: '',
                adLine1: '',
                adLine2: '',
                landSize: '',
                mobile : '',
                cost: '',
                pincode: ''
            },
            project:{},
            type: 'text',
            projectName: "",
            description: "",
            adLine1: "",
            adLine2: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
            mobile: "",
            cost:"",
            landSize: "",
            measurementUnit: "",
            projectImages: [],
            isEdit: false,
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            nextDisabledIcon: <span className="icon-button-disabled "><img src={RightArrow}></img></span>,
            prevDisabledIcon: <span className="icon-button-disabled "><img src={LeftArrow}></img></span>,
            showUpload: false,
            header: '',
            body: '',
            stateList: [],
            pincodeList: [],
            cityList:[],
            error:false,
            ErrMsg:"",
            formAddressData: [],
            formOtherData: []
        };
        this.state = initialState;
    }
    componentDidMount() {
       
        let p = this.props.project;
        let images;
         images = JSON.stringify(p.projectDetails.projectImages);
        if(p){
            this.setState({
                projectName: p.name,
                description: p.description,
                adLine1: p.address.addressLine1,
                adLine2: p.address.addressLine2,
                city: p.address.city,
                state: p.address.state,
                country: p.address.country,
                // pincode: p.address.pincode,
                mobile : p.mobile,
                cost: p.cost,
                landSize: p.projectDetails.landSize,
                measurementUnit: p.projectDetails.measurementUnit.description,
                projectImages: p.projectDetails.projectImages,
                isLoading: false,
                formAddressData: p.address, 
                formOtherData: p
            })
            
            if(localStorage.userType){
                localStorage.setItem("images" , images);
                this.fetchStateList();
               this.fetchCityList(p.address.state);
            //    this.fetchPicodeList(p.address.city);
               
            }
        }
        this.setState({project: this.props.project});
    }
    async fetchStateList(): Promise<any> {
        // const options = {
        //     url: constants.fetchStatesListAPI,
        // };
        // userService
        //     .getApiRequest(options)
        Check.checkToken()
        .then(
            () => {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
            const key = constants.subscriptionKey;
            const url =  constants.fetchStatesListAPI;
            axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                this.setState({stateList: response.data})
                
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        })
    }

    async fetchCityList(stateNm: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        if (stateNm) {
            // const options = {
            //     url: constants.fetchCitiesListAPI + stateNm,
            // };
            // userService
            //     .getApiRequest(options)
            const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
            const key = constants.subscriptionKey;
        const url =  constants.fetchCitiesListAPI + stateNm;
            axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                    let dataArr: any = [];
                    response.data.map((item: any) => (
                        dataArr.push(item.city)
                    
                   ),
                   )
                      
                    const dataArrWithSet = Array.from(new Set(dataArr));
                    const resultArr = [... dataArrWithSet];
                    this.setState({ cityList: resultArr })
                })
                .catch((error) => {
                    this.setState({ cityList: [] })
                    this.setState({ ErrMsg: error, error:true });
                });
        } else {
            this.setState({ cityList: [] })
        }
    })
    }

    // async fetchPicodeList(cityNm:any): Promise<any> {
    //     Check.checkToken()
    //     .then(
    //         () => {
    //     if(cityNm){
    //         const user = JSON.parse(localStorage.getItem('user')!); 
    //         let token;
    //          if(user){
    //                  token = 'Bearer ' + user;
                   
    //             }
    //             const key = constants.subscriptionKey;
    //         const url =  constants.fetchPincodesListAPI + cityNm;
    //             axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
    //             .then((response) => {
    //                 this.setState({pincodeList: response.data})
    //             })
    //             .catch((error) => {
    //                 this.setState({pincodeList: []})
    //                 this.setState({ ErrMsg: error, error:true });
    //             });
    //     }else{
    //         this.setState({pincodeList: []})
    //     }
    // })
    // }

    init(){
        this.viewProject(this.state.project.id); 
    }

    async viewProject(id: any): Promise<any> {
        const options = {
            url: constants.fetchProjectAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let p =  response.data;
                this.setState({ 
                    project: p, 
                    isLoading: false, 
                    projectName: p.name, 
                    adLine1: p.address.addressLine1, 
                    adLine2: p.address.addressLine2, 
                    city: p.address.city, 
                    state: p.address.state, 
                    country: p.address.country, 
                    // pincode: p.address.pincode, 
                    mobile: p.mobile, 
                    description: p.description, 
                    landSize: p.projectDetails.landSize, 
                    measurementUnit: p.projectDetails.measurementUnit.description,});
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    };

    cancelProject(){
        this.clearErrorMsg();
        this.init();                
        this.editProject();
    }
    editProject=()=>{
        this.setState({
            isEdit: !this.state.isEdit
         });
    }
    clearErrorMsg = () =>{
        let errors = this.state.errors;
        errors.description = '';
        errors.mobile= '';
        errors.adLine1= '';
        errors.adLine2= '';
        errors.projectName= '';
        errors.landSize= '';
        errors.cost= '';

    }
    saveProject=async()=>{
        const { projectName, description, adLine1, adLine2, city, state, pincode, mobile, landSize, cost}:any = this.state;

        if(projectName!==""&&description!==""&&adLine1!==""&&adLine2!==""&&city!==""&&state!=="" && mobile!== ""&& landSize!== "" &&cost!== ""&&pincode !== ""){
                    Check.checkToken();
                    let userId = localStorage.getItem("id");
                    let validity = true;
                    Object.values(this.state.errors).forEach(
                        (val: any) => val.length > 0 && (validity = false)
                    );
                    if(validity == true){
                        var p = this.state.project;
                        p.name = this.state.projectName;
                        const options = {
                        url: constants.updateProjectAPI,
                        data: {
                            "id":p.id,
                            "name": this.state.projectName,
                            "description": this.state.description,
                            "address": {
                                "addressLine1": this.state.adLine1,
                                "addressLine2": this.state.adLine2 ,
                                "city": this.state.city ,
                                "state": this.state.state ,
                                "country": "India",
                                // "pincode": this.state.pincode,
                                "latitude":0,
                                "longitute":0
                            },
                            "mobile": this.state.mobile,
                            "projectDetails":{
                                "builderType": "Custom Builder",
                                "landSize": this.state.landSize,
                                "measurementUnit": this.state.measurementUnit,
                                "projectDocs":{
                                    "landSaleContract": "",
                                    "architectDrawings": "",
                                    "engineeringPlans": "",
                                    "landscapingDesigns": ""
                                },
                                "projectImages":this.state.projectImages 
                            },
                            "cost":this.state.cost,
                            "minBudget":0,
                            "maxBudget":0,
                            "durationInMonths":0,
                            "isPublic": "false",
                            "createdBy": userId,
                            "typeOfProject": "Sale"
                        }
                        }
                        var response = await userService.updateApiRequest(options);
                        if(response){
                            //this.openUpload("Success", "Project Created Successfully");
                               this.init();             
                            this.editProject();
                        }
                        else{
                            //this.showProjectModal("Failed", "Failed to Create Project");
                        }
                        
                    }else{
                    }
                }else{
                    
                }
       
    }
    removeImage = async(event:any, path:string) => {

        let pImages = this.state.projectImages;
        let id = event?.target.id;
        const i = pImages.indexOf(path);
        if (i > -1) {
            pImages.splice(i, 1);
         }
         this.setState({
          projectImages: pImages,      
        })
    }
    openUpload = async(header:any, body: any) => {
        this.setState({showUpload:true, header: header, body: body});
    }
    onHideUpload = async(event:any) => {
        this.setState({showUpload:false});
    }

    onFileChange = async (event: any, index:number) => {
        let projectId = this.state.project.id;
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        formData.append('id', userId?userId:'');
        formData.append('type', "Project");
        formData.append('projectId', projectId);
        formData.append('file', file);

        if(file){
            if(id === "projectImages"){
                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                Check.checkToken();
                const response = await userService.postFileApiRequest(options);
                if(response){
                    this.state.projectImages.push(response);
                    this.setState({projectImages: this.state.projectImages});
                    // this.openUpload("Success", "File Uploaded SuccessFully");
                }else{
                    // this.openUpload("Failed", "Failed to Upload File");
                }

            }else{
            }

        }else{
            // let errors = this.state.errors;
            // errors.file1 = 'Please Select File to Upload';
            // this.setState({errors: errors});
        }
    };
    
    handleChange = (event : any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
        case 'projectName':
                errors.projectName = value.length == 255 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
            break;
        case 'description':
                errors.description = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
            break;
        case 'adLine1':
                errors.adLine1 = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
            break;
        case 'adLine2':
                errors.adLine2 = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
            break;
        case 'mobile':
                errors.mobile = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
            break;
        case 'landSize':
                errors.landSize = value.length > 255 ? 'You have reached your maximum limit of characters allowed': '';
            break;
        case 'cost':
                errors.cost = value.length > 10 ? 'You have reached your maximum limit of characters allowed': '';
            break;
          case 'name':
            //  errors.name = value.length == 0 ? 'Please enter valid Name': '';
             break;
          case 'password':
            //  errors.password = value.length < 8 ? 'Password must be 8 characters long': '';
             break;
          case 'usertype':
            //  errors.usertype = value.length == 0 ? 'Please select valid option': '';
            break;
          case 'state':
                this.fetchCityList(value);
                break;
        //   case 'city':
        //         this.fetchPicodeList(value);
        //         break;
        case 'pincode':
            errors.pincode = (value.length < 6 || value.length > 6) ? 'Pincode must be 6 digit long' : '';
            break;
          default:
            break;
        }
       this.setState(Object.assign(this.state, { errors,[name]: value }));
       };

       hasChangedSinceInitialState = () => {
        const { formAddressData, formOtherData } = this.state;
        return (formAddressData.city === this.state.city && 
            formAddressData.state === this.state.state &&
            formAddressData.pincode == this.state.pincode &&
            formAddressData.addressLine1 === this.state.adLine1 &&
            formAddressData.addressLine2 === this.state.adLine2  &&
            formOtherData.name === this.state.projectName &&
            formOtherData.description === this.state.description &&
            formOtherData.projectDetails.landSize == this.state.landSize &&
            formOtherData.projectDetails.measurementUnit.description  == this.state.measurementUnit &&
            formOtherData.mobile == this.state.mobile &&
            formOtherData.cost == this.state.cost &&
            JSON.parse(localStorage.images).length == this.state.projectImages.length 
            // formOtherData.projectDetails.projectImages == this.state.projectImages
            // formOtherData.projectDetails.builderType.builderType == this.state.builderType &&
            
            );
      }
    closeProject=(event:any)=>{
        this.props.onHideProject();
    }

    render() {
        const { error, ErrMsg, errors, Images, cost, stateList,cityList, pincodeList, isLoading, project,nextIcon, prevIcon, projectImages, isEdit, projectName, adLine1, adLine2, city, state, country, pincode, mobile, description, landSize, measurementUnit, showUpload, header, body,  prevDisabledIcon, nextDisabledIcon } = this.state;
        var addressObj;
        var address;
        var builder;
        var architect;
        var items:any=[];
        var subItems:any=[];
        if(Object.keys(project).length){
            addressObj = project.address;
            address = addressObj.addressLine1 +", "+ addressObj.addressLine2 +", "+ addressObj.city +", "+ addressObj.state +", "+ addressObj.country;
            builder = project.builder;
            architect = project.architect;
        }

        if(projectImages.length>0){
            items = projectImages.map(
                (item:any) => (
                    (<Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={blobService.createUrl(item)}
                        width="100%" height="350px"
                        />
                    </Carousel.Item>)          
                ),
            );
        }
        for(let i = 0; i<4; i++){
            if(projectImages[i]){
                subItems.push(
                    <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                        {isEdit ?
                        <span>
                            <img className="close" id={"projectImage"+i} src={Delete} onClick={(event)=>{this.removeImage(event, projectImages[i])}}/>
                            <Image id={"projectImage"+i} src={blobService.createUrl(projectImages[i])} errorImg={Upload} alt="" width="100%" height="100px"/>
                        </span>
                        :
                        <img src={blobService.createUrl(projectImages[i])} alt="" width="100%" height="100px"/>
                        }
                    </div>
                )
            }else{
                subItems.push(
                    <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                    {isEdit ?
                        <div>
                        <label htmlFor={"projectImages"}>
                        <span><h6 className="left-tab"><img src={Upload}/>Logo</h6></span>
                        </label>
                        <input id={"projectImages"} type="file" style={{display:"none"}}  onChange={(event)=>this.onFileChange(event, i)}/>
                        </div>
                    : <div></div>
                    }
                    </div>        
                )                       
            }
            
        }
        return isLoading ? (<Loader/>): 
        (
        <>
             {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
            <div className="aboutProject scrollableContent" >
            <div className="container py-5">
            <div className="cross-img" ><img src={Cross} onClick={this.closeProject}/></div>
                        <div className="row">
                            <div className="col-md-6 col-12">
                            <h4>About</h4>
                            </div>
                            {!this.props.isView && 
                            <div>
                            {!isEdit ?
                            <div className="col-md">
                                <button className="button7 butupdate" onClick={()=>{this.editProject()}}>Edit Project<img src={Edit} className="ml-1"/></button>
                            </div> :
                            <div className="col-md buttoncontainer">
                                <button className={this.hasChangedSinceInitialState() ? "button7 ml-5 save-but updatecontact disbledSaveButton" : "button7 save-but ml-5 updatecontact"}  disabled={this.hasChangedSinceInitialState()} onClick={()=>{this.saveProject()}} >Save</button>
                                <button className="button7 ml-5 butcancel updatecontact" onClick={()=>{this.cancelProject()}}>Cancel</button>
                            </div>}
                            </div>}
                        </div>
                        <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    {projectImages.length>0 &&
                                    <span>
                                     {(projectImages.length>1)?
                                             <Carousel  nextIcon={nextIcon} prevIcon={prevIcon} >
                                                     {items}
                                             </Carousel>
                                                :
                                            <Carousel className = "disabled-arrow" nextIcon={nextDisabledIcon} prevIcon={prevDisabledIcon} >
                                                     {items}
                                            </Carousel>
                                    }<br/>
                                    </span>
                                    ||<div/>
                                    }
                                    <div className="row">
                                            {subItems}
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className="col-md-6">
                    <div className="projectInfo">
                    {this.state.isEdit===true &&
                        <>
                            <input 
                                className="projectnameup"
                                name="projectName"
                                placeholder= "* Project name"
                                type={this.state.type}
                                value={projectName}
                                autoFocus
                                onChange={this.handleChange}
                                maxLength = {255}
                            />
                             {errors.projectName.length > 0 &&  <p style={{color: "red"}}>{errors.projectName}</p>}
                        </>

                        ||
                        <span>
                             <h4>{projectName}</h4>
                        </span>}
                        <h6 className="mt-4">Address &amp; Contact</h6>
                        {isEdit && 
                        <div>
                        <div className='address'>
                        <input type="text" name="adLine1" className="px-3 adLine1Up" placeholder="* Door No" onChange={this.handleChange} value={adLine1} required maxLength = {255}/>
                        {errors.adLine1.length > 0 &&  <p  style={{color: "red"}}>{errors.adLine1}</p>}
                            <span className="left-buffer"/>
                        <input type="text" name="adLine2" className="px-3 adLine2Up" placeholder="* Street" onChange={this.handleChange} value={adLine2} required maxLength = {255}/>
                        {errors.adLine2.length > 0 &&  <p  style={{color: "red"}}>{errors.adLine2}</p>}

                    </div><br/>
                    <div className='city'>
                        {/* <input type="text" name="city" className="px-3" placeholder="City" onChange={this.handleChange} value={city} required/> */}
                        <select name="city" id="city" className="py-1 citydropdown px-3" onChange={this.handleChange} value={city} required>
                                                    <option value="">* City</option>
                                                    {/* {cityList.map((item: any) => (
                                                        <option value={item.city} key={item.city}>
                                                            {item.city}
                                                        </option>
                                                    ))} */}
                                                    {cityList.map((item: any) => (
                                                        <option value={item} key={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                            <span className="left-buffer"/>
                        <select name="state" id="state"  className="py-1 statedropdown px-3" onChange={this.handleChange} value={state} required>
                                                        <option value="">* State</option>
                                                        {stateList.map((item:any) => (
                                                            <option value={item.name} key={item.name}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                </select>
                    </div>

                    <div className='pincode'>
                                                <span className="left-buffer" />
                                                {/* <select name="pincode" id="pincode"  className="py-1 pincode" onChange={this.handleChange} value={pincode} required>
                                                    <option value="">Postcode</option>
                                                    {pincodeList.map((item:any) => (
                                                        <option value={item.pincode} key={item.pincode}>
                                                        {item.pincode}
                                                        </option>
                                                    ))}
                                                </select> */}
                        {/* {errors.name.length > 0 &&  <span style={{color: "red"}}>{errors.name}</span>} */}
                            <div className="py-1 ">
                                <input type = "number" name="pincode" id="pincode"  className="py-1 px-3" onChange={this.handleChange} value={pincode} placeholder = "* Pincode" required  onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }></input>
                                {errors.pincode.length > 0 && (
                                    <p style={{ color: 'red'}}>{errors.pincode}</p>
                                )}
                            </div>
                    </div><br/>
                    <div className='phoneNumber'>
                        <input type="number" name="mobile" className="px-3 phoneNum" placeholder="* Phone" value={mobile} onChange={this.handleChange} required maxLength = {10}  onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }/>
                        {errors.mobile.length > 0 &&  <p style={{color: "red"}}>{errors.mobile}</p>}
                    </div>
                    </div> ||
                        <div>
                            <div className="light-content">{adLine1+", "+adLine2+", "+city+", "+state+", "+country}</div>
                            <div className="light-content">{mobile}</div>
                        </div>
                        }
                        <div className="mt-3">
                            <h6>About the Project</h6>
                            {isEdit ? 
                    <div className='aboutProject'>
                        <textarea id="about" name="description" className="px-3 aboutproject" placeholder="* About the Project" rows={5} onChange={this.handleChange} value={description} required maxLength = {255}/>
                        {errors.description.length > 0 &&  <p style={{color: "red"}}>{errors.description}</p>}
                    </div>
                    :
                    <p className="light-content" style={{lineHeight:"30px"}}>{description}</p>
                        }
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-10">
                                <h6>Land Size</h6>
                                { isEdit? 
                                <div className='landSize'>
                                <input type="number" name="landSize" className="px-3 landsizeupdate" placeholder="* Land Size" onChange={this.handleChange} value={landSize} required  onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }/>
                                    {errors.landSize.length > 0 &&  <p  style={{color: "red", width: "50%"}}>{errors.landSize}</p>}
                                    <span className="left-buffer">in</span>
                                    <select name="measurementUnit" id="measurementUnit"  className="px-3 py-1 no-border" onChange={this.handleChange} value={measurementUnit} required>
                                        <option value="Square Meter">Sq Meters</option>
                                        <option value="Square Feet">Sq Ft</option>
                                    </select>
                            </div>
                                :
                                <p className="light-content">{landSize} {measurementUnit}</p>
                            }
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-9">
                                <h6>Cost</h6>
                                { isEdit? 
                                <div className='cost'>
                                <input type="number" name="cost" className="px-3 cost" placeholder="* Cost in $" onChange={this.handleChange} value={cost} required onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }/>
                                {errors.cost.length > 0 &&  <p  style={{color: "red", width: "50%"}}>{errors.cost}</p>}
                            </div>
                                :
                                <p className="light-content"><CurrencyFormat value={cost} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </p>
                            }
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
            </div>
            <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any)=>this.onHideUpload(event)}/>
        </>
        );
    }
}
