import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';

interface LearnMoreProps {
    name?: any;
    value?: any;
}
interface LearnMoreState {
    errors: {};
    activeIndex: string;
}

export default class LearnMore extends React.Component<LearnMoreProps, LearnMoreState> {
    constructor(props: LearnMoreProps) {
        super(props);
        const initialState = {
            errors: {},
            activeIndex: '',
        };
        this.state = initialState;
    }
    componentDidMount() {
        let activeIndex = localStorage.setItem("tab",'0');
        this.setState({
			activeIndex: '0'
		});
        window.scrollTo(0, 0);
      }
    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 offset-md-2 col-12">
                                <h1>Learn More</h1>
                            </div>             
                            </div>
                        </div>
                    </div>
                    {/* <div className="input-cntr">
                         <input type="text" placeholder="Search" name="searchTxt" onChange={this.handleChange} className="px-3 py-2"/><img className="search-icon" onClick={(event:any)=>this.searchBuilder(event)}src={SearchIcon} alt=""/>
                     </div> */}
                </div>
            <br/><br/>
            <p className="text-center">Learn More</p>
            <br/><br/>
            <Footer/>
            </>
         );
    }
}
