import React from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import Check from '../../../Services/Check';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import Carousel from "react-bootstrap/Carousel";
import Image from "../../../common-components/Image";
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import { blobService } from '../../../common-components/Blob';
import Delete from "../../../../assets/images/delete.svg";
import Upload from "../../../../assets/images/upload.svg";

interface NewArchitectProjectState {
    stateList: any,
    pincodeList: any,
    cityList: any,
    projectName: string,
    description: string,
    adLine1: string,
    adLine2: string,
    city: string,
    state: string,
    country: string,
    pincode: string,
    mobile: string,
    landSize: string,
    measurementUnit: string,
    projectImages: any[],
    cost: string,
    showUpload: boolean,
    header: string,
    body: string,
    nextDisabledIcon: any,
    prevDisabledIcon: any,
    nextIcon: any,
    prevIcon: any,
    errors: {
        description: string,
        projectName: string,
        adLine1: string,
        adLine2: string,
        landSize: string,
        mobile : string,
        cost: string,
        pincode: string,
    },
    isSubmitted: boolean,
}
interface NewArchitectProjectProps {

}

const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/); 

class NewArchitectProject extends React.Component<NewArchitectProjectProps & RouteComponentProps , NewArchitectProjectState>{
    constructor(props: any){
        super(props);
        const initialState = {
            stateList: [],
            pincodeList: [],
            cityList:[],
            city: '',
            state: '',
            country: '',
            projectName: '',
            description: '',
            adLine1: '',
            adLine2: '',
            pincode: '',
            mobile: "",
            landSize: '',
            measurementUnit: "Square Meter",
            projectImages: [],
            cost: "",
            showUpload: false,
            header: '',
            body: '',
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            nextDisabledIcon: <span className="icon-button-disabled "><img src={RightArrow}></img></span>,
            prevDisabledIcon: <span className="icon-button-disabled "><img src={LeftArrow}></img></span>,
            errors: {
                description: '',
                projectName: '',
                adLine1: '',
                adLine2: '',
                landSize: '',
                mobile : '',
                cost: '',
                pincode: ''
            },
            isSubmitted: true,
        };
        this.state = initialState;
    }

    componentDidMount  (){
        this.fetchStateList();
    }
    

    fetchStateList= () =>{
        Check.checkToken()
        .then(()=>{
            const options = {
                url: constants.fetchStatesListAPI,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    this.setState({ stateList: response.data })
    
                })
                .catch((error) => {
                   
                });
        })
    }

    async fetchCityList(stateNm: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        if (stateNm) {
            const options = {
                url: constants.fetchCitiesListAPI + stateNm,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    let dataArr: any = [];
                    response.data.map((item: any) => (
                        dataArr.push(item.city)
                    
                   ),
                   )
                      
                    const dataArrWithSet = Array.from(new Set(dataArr));
                    const resultArr = [... dataArrWithSet];
                    this.setState({ cityList: resultArr })
                })
                .catch((error) => {
                    this.setState({ cityList: [] })
                    
                });
        } else {
            this.setState({ cityList: [] })
        }

    }
        )

    }


    saveArchitectProject = async () => {
        const { projectName, description, adLine1, adLine2, city, state, country, pincode, mobile, landSize, cost}:any = this.state;

        if(projectName!==""&&description!==""&&adLine1!==""&&adLine2!==""&&city!==""&&state!==""&&pincode!=="" && mobile!== ""&& landSize!== "" &&cost!== ""){
        Check.checkToken()
        .then(
            async() => {
        let userId = localStorage.getItem("id");
        let userType = localStorage.getItem("userType");
        let validity = true;
        Object.values(this.state.errors).forEach(
            (val: any) => val.length > 0 && (validity = false)
        );
        if (validity == true) {

            const options = {
                url: constants.updateProjectAPI,
                data: {
                    "name": this.state.projectName,
                    "description": this.state.description,
                    "address": {
                        "addressLine1": this.state.adLine1,
                        "addressLine2": this.state.adLine2,
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": "India",
                        // "pincode": this.state.pincode,
                        "latitude": 0,
                        "longitute": 0
                    },
                    "mobile": this.state.mobile,
                    "projectDetails": {
                        "builderType": "Custom Builder", 
                        "landSize": this.state.landSize,
                        "measurementUnit": this.state.measurementUnit,
                        "projectDocs": {
                            "landSaleContract": "",
                            "architectDrawings": "",
                            "engineeringPlans": "",
                            "landscapingDesigns": ""
                        },
                         "projectImages": this.state.projectImages
                    },
                    "cost": this.state.cost,
                    "minBudget": 0,
                    "maxBudget": 0,
                    "durationInMonths": 0,
                    "publicProject": "false",
                    "createdBy": userId,
                    "typeOfProject": "Sale"
                }
            }
            await userService.postApiRequest(options)
            .then((response)=>{
                if (response) {
                     this.openUpload("Success", "Project Created Successfully And Went For Verification");
                }
            })
            .catch((error)=>{
                this.openUpload("Failed", "Failed to Create Project");
            })

        } else {
            }
        }
        )
    }
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'projectName':
                    errors.projectName = value.length == 255 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
                break;
            case 'description':
                    errors.description = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'adLine1':
                    errors.adLine1 = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'adLine2':
                    errors.adLine2 = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'landSize':
                    errors.landSize = value.length > 4 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'cost':
                    errors.cost = value.length > 8 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'mobile':
                    errors.mobile = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
                break;
            case 'state':
                this.fetchCityList(value);
                break;
            case 'pincode':
                errors.pincode = (value.length < 6 || value.length > 6) ? 'Pincode must be 6 digit long' : '';
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, {errors,  [name]: value }));
        const { state, city,  projectName, adLine1, adLine2, cost, mobile, description, landSize, pincode } = this.state;
        if(projectName !== "" && description !== "" && adLine1 !== "" 
            && adLine2 !== "" &&  cost !== "" && mobile !== "" 
            &&  landSize !== "" && pincode !== "" && state !== "" && city !== ""){
            this.setState({
                isSubmitted: false
            })
        }else{
            this.setState({
                isSubmitted: true
            })
        }
    }

    onFileChange = async (event: any) => {
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        formData.append('id', userId ? userId : '');
        formData.append('type', "Project");
        formData.append('file', file);

        if (file) {
            if (id === "projectImages") {
                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                Check.checkToken();
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.state.projectImages.push(response);
                    this.setState({ projectImages: this.state.projectImages });
                } else {
                }

            } else {
            }

        } else {
          
        }

    }

    removeImage = async (event: any, path: string) => {
        let pImages = this.state.projectImages;
        let id = event?.target.id;
        const i = pImages.indexOf(path);
        if (i > -1) {
            pImages.splice(i, 1);
        }
        this.setState({
            projectImages: pImages,
        })
    }

    cancelArchitectProject = ()=>{
        this.props.history.push('/ArchitectPortfolio') 
    }

    openUpload = async (header: any, body: any) => {
        this.setState({ showUpload: true, header: header, body: body });
    }
    onHideUpload = async (event: any) => {
        this.setState({ showUpload: false });
        this.props.history.push('/MyArchitectProjects')
    }
    render(){
        const { stateList, cityList, pincodeList,  projectName, adLine1, adLine2, cost, mobile, description, landSize, measurementUnit, showUpload, header, body, projectImages, nextIcon, prevIcon, prevDisabledIcon, nextDisabledIcon, errors, pincode, isSubmitted } = this.state;
        var subItems: any = [];
        var items: any = [];

        if (projectImages.length > 0) {
            items = projectImages.map(
                (item: any) => (
                    (<Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={blobService.createUrl(item)}
                            width="100%" height="350px"
                        />
                    </Carousel.Item>)
                ),
            );
        }
        for (let i = 0; i < 4; i++) {
            if (projectImages[i]) {
                subItems.push(
                    <div className="col-md-3 bottom-buffer compyLogo  builderproject-img-wrap">
                        <span>
                            <img className="close" id={"projectImage" + i} src={Delete} onClick={(event) => { this.removeImage(event, projectImages[i]) }} />
                            <Image id={"projectImage" + i} src={blobService.createUrl(projectImages[i])}  alt="" width="100%" height="100px" />
                        </span>
                    </div>
                )
            } else {
                subItems.push(
                    <div  className="bottom-buffer compyLogo  builderproject-img-wrap">
                        <div >
                            <label htmlFor={"projectImages"} style = {{cursor: "pointer"}}>
                                <span><h6 className="left-tab"><img id="Upload" src= {Upload}/>Add Image</h6></span>
                            </label>
                            <input id={"projectImages"} type="file" style={{ display: "none" }} onChange={(event) => this.onFileChange(event)} />
                        </div>
                    </div>
                )
            }
        }
        return(
            <>
            <Header/>
                <div className="bg-img">
                        <div className="overlay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <h1 style={{fontWeight:600}}>Add New Project</h1>
                                        <p className="my-3"><Link to="/MyArchitectProjects" className="link-text">My Projects</Link>/ Add New Project</p>
                                    </div>                          
                                </div>
                            </div>
                        </div>
                </div>
                <div className="aboutProject">
                        <div className="container py-5">
                            <div >
                                <div>
                                    <div className="projectInfo-form">
                                        <h4 className="newProject">Start a New Project</h4>
                                        <h5 className="details">Basic Details </h5>
                                        <div className="row">
                                            <div className="col-md-6">
                                                    {projectImages.length > 0 &&
                                                        <span>
                                                            {(projectImages.length>1)?
                                                                    <Carousel  nextIcon={nextIcon} prevIcon={prevIcon} >
                                                                        {items}
                                                                    </Carousel>
                                                                    :
                                                                    <Carousel className = "disabled-arrow" nextIcon={nextDisabledIcon} prevIcon={prevDisabledIcon} >
                                                                        {items}
                                                                    </Carousel>
                                                                }
                                                            <br />
                                                        </span>
                                                        || <div />
                                                    }
                                                    <div  className="upload-container">
                                                        {subItems}
                                                    </div>
                                               
                                            </div>
                                        </div>
                                        <div className="builder-project">
                                            <div  className="mt-3">
                                                <input
                                                    name="projectName"
                                                    type= "text"
                                                    placeholder="* Project Name"
                                                    autoFocus
                                                    value = {projectName}
                                                    onChange={this.handleChange}
                                                    maxLength = {255}
                                                    className = "px-3 w-50 py-1"
                                                />
                                                    {errors.projectName.length > 0 &&  <p style={{color: "red"}}>{errors.projectName}</p>}
                                            </div>
                                            <div  className="mt-3">
                                                <div className='aboutProject'>
                                                    <textarea id="about" name="description" className="px-3 w-50 py-1" placeholder="* About the Project" rows={5} onChange={this.handleChange} value = {description} required maxLength = {255}/>
                                                    {errors.description.length > 0 &&  <p style={{color: "red"}}>{errors.description}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='address builder-project'>
                                                    <div>
                                                        <input type="text" name="adLine1" className="px-3 mb-3 w-50 py-1" placeholder="* Door No" value = {adLine1} onChange={this.handleChange} required maxLength = {255} />
                                                        {errors.adLine1.length > 0 &&  <p  style={{color: "red"}}>{errors.adLine1}</p>}
                                                    </div>
                                                    <div>
                                                        <input type="text" name="adLine2" className="px-3  w-50 py-1" placeholder="* Street" value = {adLine2} onChange={this.handleChange} required  maxLength = {255}/>
                                                        {errors.adLine2.length > 0 &&  <p  style={{color: "red"}}>{errors.adLine2}</p>}
                                                    </div>
                                        </div>
                                                <br />     
                                            <div className='row'>
                                                <div className="col-md-7">
                                                    <select name="state" id="state" className="py-1" required onChange={this.handleChange} style = {{width: "43%"}}>
                                                        <option value="">* State</option>
                                                        {stateList.map((item: any) => (
                                                            <option value={item.name} key={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="left-buffer" />
                                                    <select name="city" id="city" className="py-1"  required style = {{width: "43%"}}  onChange={this.handleChange}>
                                                        <option value="">* City</option>
                                                        {cityList.map((item: any) => (
                                                            <option value={item} key={item}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>   
                                                <div className="col-md-5">  
                                                </div> 
                                            </div>
                                        <br />
                                        <div className="row">
                                            <div  className="col-md-7 d-flex">
                                                <div  style = {{width: "43%"}}>
                                                    <input  type = "number" name="pincode" id="pincode"  className="py-1 px-3" onChange={this.handleChange} value={pincode} placeholder = "* Pincode" required style = {{width: "100%"}} ></input>
                                                    {errors.pincode.length > 0 && (
                                                        <p style={{ color: 'red'}}>{errors.pincode}</p>
                                                    )}
                                                </div>
                                                <span className="left-buffer" />
                                                <div  style = {{width: "43%"}} className='phoneNumber'>
                                                    <input type="number" name="mobile" className="py-1 px-3" placeholder="* Phone" onChange={this.handleChange} value = {mobile} required maxLength = {10} style = {{width: "100%"}}/>
                                                    {errors.mobile.length > 0 &&  <p style={{color: "red"}}>{errors.mobile}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-5">  
                                            </div>
                                        </div>
                                        <div className="row mt-2 mt-3">
                                            <div className="col-md-7">
                                                <div className='landSize'>
                                                    <input type="number" name="landSize" className="px-3 py-1" placeholder="* Land Size" onChange={this.handleChange} value = {landSize} required style = {{width: "43%"}}/>
                                                    <span className="left-buffer">in</span>
                                                    <select name="measurementUnit" id="measurementUnit" className="px-3 py-1 no-border" onChange={this.handleChange} value={measurementUnit} required>
                                                        <option value="Square Meter">Sq Meters</option>
                                                        <option value="Square Feet">Sq Ft</option>
                                                    </select>
                                                </div>
                                                {errors.landSize.length > 0 &&  <p  style={{color: "red", width: "30%"}}>{errors.landSize}</p>}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-7">
                                                <div className='cost'>
                                                    <input type="number" name="cost" className="px-3" placeholder="* Cost in INR" onChange={this.handleChange} value={cost}  required style = {{width: "43%"}} />
                                                    {errors.cost.length > 0 &&  <p  style={{color: "red", width: "50%"}}>{errors.cost}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button className= {isSubmitted == true ? "button2 hire disabled-button" :  "button2 hire"} disabled = {isSubmitted} onClick={() => {this.saveArchitectProject();}}>Publish Project <img src={RightArrow} className="contactbut" /></button>
                                </div>  
                            </div>

                        </div>
                    </div>
                    <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any) => this.onHideUpload(event)} />                                        
            <Footer/>
            </>
        )
    }

}

export default NewArchitectProject;