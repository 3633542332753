import React, { Component } from "react";
import "./Hire.scss";
import Vector from  "../../../../assets/images/Vector.svg";
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import quotearrow from  "../../../../assets/images/quotearrow.svg";
import HireRectangleBlue from  "../../../../assets/images/HireRectangleBlue.svg";
import HireRectangle from  "../../../../assets/images/HireRectangle.svg";
import Loader from '../../../common-components/Loader';
import Check from '../../../Services/Check';

interface hireprops {
     result: any;
     id: any;
     onHideHireModal?: any;
}

interface hirestate {
    projectList: any,
    selectedValue: any[],
    quoteModal: boolean,
    // closeHireModal: boolean,
    isSelected: boolean,
    isLoading: boolean,
}

class Hire extends React.Component<hireprops , any & hirestate>{
    constructor(props:any){
        super(props);
        const initialState = {
            projectList: [],
            selectedValue: [],
            quoteModal: false,
            // closeHireModal: true,
            isLoading: true,

        };
        this.state = initialState;


    }
    componentDidMount () {   
         this.init();
    }

    init(){
          let userId = localStorage.getItem("id");
         this.getProjectList(userId); 
    }
    handleCheckBox = (event: any) => {
        let isChecked = event.target.checked;
        let item = event.target.value;
        if(isChecked){
            this.setState({
                    selectedValue: [...this.state.selectedValue, parseInt(event.target.value)],
                    
                })
        }else{
                const index = this.state.selectedValue.indexOf( event.target.value);
                this.state.selectedValue.splice(index,1);
                this.setState({
                    selectedValue: this.state.selectedValue,
                })


        }
    };

    closeHireModal = ()=>{
        this.setState({
            selectedValue: []
        })
        this.props.onHideHireModal();
    }

    getProjectList = (id: any) =>{
        Check.checkToken()
        .then(() => {
            let builderUserId = this.props.id;
            const options ={
            url : constants.getBuilderProtfolioProjectsAPI + id  +"?status=Pending&builderUserId="+ builderUserId
            }
            userService.getApiRequest(options)
            .then((response) => {
                if(response.data){
                    this.setState({
                        isLoading: false,
                        projectList: response.data,
                    })
                }
            })
            .catch((error) => {

            });
    })
    }
    openQuoteModal = () =>{
        const {selectedValue} = this.state
        if(selectedValue.length !== 0){
            this.props.result(this.state.selectedValue);
        }else{
        }
    }


    render(){
        const {projectList, selectedValue, isLoading} = this.state
        let projectNames = projectList.map((list: any) =>{
            let projectId =  list.id;
            let isChecked;
            for(let i = 0; i < selectedValue.length; i++ ){
                    if(projectId == selectedValue[i]){
                        isChecked = true
                    }
            }
            return(
                <>
                <div className = "d-flex">
                    <input
                        type = "checkbox"
                        checked ={isChecked}
                        onChange={this.handleCheckBox}
                        value = {list.id}
                        className = "radio-custom"
                          
                    />
                    <p> {list.name}</p>
                </div>
                <hr/>
                </>
            )
          
        })
        return isLoading ? (<Loader/>): 
        (
            <>
            <div className = "container-fluid hire-container ">
                <div className = "hire-header">
                    <p>Ask a Quote</p>
                    <img src = {Vector}  onClick={this.closeHireModal}/>
                </div>
                <div style= {{marginBottom : "10px"}}>
                    <img style = {{marginRight : "5px"}} src = {HireRectangleBlue}/>
                    <img src = {HireRectangle}/>
                </div>
                {(projectList.length > 0 )?
                    <div >
                        <p className = "hire-content">Select a project for which you are hiring the builder for.</p>
                            <div className = "hire-content-list" >
                                <p>Your Projects</p>
                                <div className = "scrollableContent" style = {{height: "23vh"}}>
                                    {projectNames}
                                </div>
                                
                            </div>
                    </div>
                    :
                    <div style = {{padding: "50px"}}>
                        <h5>No project started yet.</h5>
                    </div>
                }
               
                <button className = {(selectedValue.length !== 0)? 'enableHireButton' : 'disabledHireButton' } onClick={this.openQuoteModal}>Next</button>
            </div>
                   
            </>
        )
    }

       
    

}

export default Hire;