    const APP_BUILDER_API = process.env.REACT_APP_BUILDER_API;
    const APP_USER_API = process.env.REACT_APP_USER_API;
    const APP_ARCHITECT_API = process.env.REACT_APP_ARCHITECT_API;
    const APP_JWT_API = process.env.REACT_APP_JWT_API;
    const APP_Finance_Brokers_API = process.env.REACT_APP_Finance_Brokers_API;
    const APP_SUBSCRIPTION_KEY = process.env.REACT_APP_SUBSCRIPTION_KEY

    
    export const constants = {
        subscriptionKey:`${APP_SUBSCRIPTION_KEY}`,
        registrationAPI:`${APP_USER_API}/users/register`,
        verifyEmailAPI:`${APP_USER_API}/users/verify/email`,
        loginAPI:`${APP_JWT_API}/api/auth/signin/`,
        refreshToken: `${APP_JWT_API}/api/auth/refreshToken`,
        // isTokenValid: `${APP_JWT_API}/api/auth/isTokenValid`,
        fetchUserAPI:`${APP_USER_API}/users/`,
        updateUserAPI:`${APP_USER_API}/users/manage/`,
        forgotPasswordAPI:`${APP_USER_API}/users/passwords/forgot`,
        resetPasswordAPI:`${APP_USER_API}/users/passwords/reset/`,
        setNewPassword:`${APP_USER_API}/users/passwords/forgot`,
        fetchBuilderAPI: `${APP_BUILDER_API}/builder/`,
        fetchCustomerAPI: `${APP_BUILDER_API}/customer/`,
        createBuilderAPI: `${APP_BUILDER_API}/builder`,
        updateBuilderAPI: `${APP_BUILDER_API}/builder`,
        updateCustomerAPI:`${APP_BUILDER_API}/customer`,
        getBuilderListAPI: `${APP_BUILDER_API}/builder/list`,
        searchBuilderAPI: `${APP_BUILDER_API}/builder/search`,
        fetchPublicProjectListAPI: `${APP_BUILDER_API}/project/list`, 
        fetchProjectAPI: `${APP_BUILDER_API}/project/`,
        createProjectAPI: `${APP_BUILDER_API}/project`,
        updateProjectAPI: `${APP_BUILDER_API}/project`,
        getQuoteList: `${APP_BUILDER_API}/quote/list/`,
        
        getBuilderProjectsAPI: `${APP_BUILDER_API}/project/builder/`,
        getBuilderProtfolioProjectsAPI: `${APP_BUILDER_API}/project/builderProtfolio/`,
        fetchBuilderAllProjectList: `${APP_BUILDER_API}/project/viewMyProjects/`,
        
        getCustomerProjectAPI:`${APP_BUILDER_API}/project/customer/`,
        assignBuilderProjectAPI: `${APP_BUILDER_API}/project/builder/assign`,
        makePublicProjectAPI: `${APP_BUILDER_API}/project/public`,
        makePrivateProjectAPI: `${APP_BUILDER_API}/project/private`,
        fetchBuilderProjectsAPI: `${APP_BUILDER_API}/project/builder/list`,
        fetchBuilderTypesAPI: `${APP_BUILDER_API}/builderTypes`,
        fetchServiceTypesAPI: `${APP_BUILDER_API}/serviceTypes`,
        fetchServiceSubTypesAPI: `${APP_BUILDER_API}/serviceTypes/`,
        fetchTopBuildersAPI: `${APP_BUILDER_API}/topBuilders`,
        fetchStatesListAPI: `${APP_BUILDER_API}/states`,
        fetchCitiesListAPI: `${APP_BUILDER_API}/cities/`,
        fetchPincodesListAPI: `${APP_BUILDER_API}/pincodes/`,
        fetchMeasurementUnitsAPI: `${APP_BUILDER_API}/measurementUnits`,
        uploadProjectFilesAPI: `${APP_BUILDER_API}/uploadProjects/`,
        uploadFilesAPI: `${APP_BUILDER_API}/upload`,
        saveContractDoc: `${APP_BUILDER_API}/project/saveDocument/`,

        getBuilderPortfolioAPI: `${APP_BUILDER_API}/builder/project/`,
        filterByState: `${APP_BUILDER_API}/project/state`,
        filterByCity: `${APP_BUILDER_API}/project/city`,
        fetchAllCity:  `${APP_BUILDER_API}/citys`,

        createQuote: `${APP_BUILDER_API}/quote/`,
        fetchCustomerQuotes: `${APP_BUILDER_API}/quote/customerQuotes/`,
        fetchBuilderQuotes: `${APP_BUILDER_API}/quote/builderQuotes/`,
        manageQuoteStatus: `${APP_BUILDER_API}/quote/manageQuote`,
        fetchQuoteDetails: `${APP_BUILDER_API}/quote/viewQuote`,
        
        //Achitect APIs
        getArchitectListAPI: `${APP_ARCHITECT_API}/architect/list`,
        fetchArchitectAPI: `${APP_ARCHITECT_API}/architect/`,
        updateArchitectAPI: `${APP_ARCHITECT_API}/architect`,

        //Raw material supplier APIs
        getRawMaterialSupplierListAPI: `${APP_ARCHITECT_API}/rawMaterialSupplier/list`,
        fetchRawMaterialSupplierAPI: `${APP_ARCHITECT_API}/rawMaterialSupplier/`,
        fetchRawMaterialList:  `${APP_ARCHITECT_API}/rawMaterial/list`,
        updateRawMaterialAPI:  `${APP_ARCHITECT_API}/rawMaterialSupplier`,

         //Financial Broker APIs
         getFinancialBrokerListAPI: `${APP_Finance_Brokers_API}/financeBrokers/list`,
         fetchFinancialBrokerAPI: `${APP_Finance_Brokers_API}/financeBrokers/`,
         updateFinancialBrokerAPI: `${APP_Finance_Brokers_API}/financeBrokers`,
         fetchBankAPI : `${APP_Finance_Brokers_API}/financeBrokers/banks/?financeBrokersId=`,
         applyForLoan: `${APP_Finance_Brokers_API}/financeBrokers/loan`,

        //fetchUserDetails
        fetchUserDetailsAPI:`${APP_USER_API}/users/`,

        //HomePage
        fetchProjectCountAPI: `${APP_BUILDER_API}/project/count`,
        //ContactUs
        contactUs:`${APP_USER_API}/users/contactUs`,
         //userData
         saveUserData: `${APP_USER_API}/users/save/userData`
    }
