import React, { Component, useEffect } from 'react';
import serviceProviderimg1 from '../../../../assets/images/serviceProviderimg1.png';
import serviceProviderimg2 from '../../../../assets/images/serviceProviderimg2.png';
import serviceProviderimg3 from '../../../../assets/images/serviceProviderimg3.png';
import serviceProviderimg4 from '../../../../assets/images/serviceProviderimg4.png';
import Rating from '@material-ui/lab/Rating';
interface serviceProviderProps {

}
interface serviceProviderState {

}


export default class ServiceProvider extends React.Component<serviceProviderProps, serviceProviderState> {
    constructor(props: any) {
        super(props);
        const initialState = {

        };
        this.state = initialState;
    }


    render() {
        return (
            <>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={serviceProviderimg1} alt="" />
                    </div>
                    <div className="col-md-10 p-3 service-provider-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Project Name:Santoshi Nilayam</p>
                                <p>Builder Name: Rakesh Gowda</p>
                            </div>
                            <div className='rating-text d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <p>Rating</p>
                                <Rating name="read-only" value={5} style={{ color: "green" }} readOnly className="rating" />
                            </div>
                        </div>
                        <p>I'm proud to have offered comprehensive construction services to Santoshi Nilayam. From project planning to execution, I've been dedicated to making this project a reality, ensuring top-notch quality and attention to detail.
                            My experience with Aashray Homes has been remarkable. They consistently match builders like me with projects that align with our expertise. I highly recommend their platform for both service providers and project seekers. Connect with Aashray Homes and discover how I can help build your dream home today!</p>
                    </div>
                </li>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={serviceProviderimg2} alt="" />
                    </div>
                    <div className="col-md-10 p-3 service-provider-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Project Name:Design Excellence Apartments</p>
                                <p>Architect Name: Architect Supriya Reddy</p>
                            </div>
                            <div className='rating-text d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <p>Rating</p>
                                <Rating name="read-only" value={5} style={{ color: "green" }} readOnly className="rating" />
                            </div>
                        </div>
                        <p>I specialize in architectural design and planning, turning your vision into stunning structures. From residential homes to commercial spaces, I bring creativity and precision to every project.
                            My collaboration with Aashray Homes has been transformative. They connect architects like me with projects that match our expertise seamlessly. I highly recommend their platform for design enthusiasts and project seekers alike!</p>
                    </div>
                </li>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={serviceProviderimg3} alt="" />
                    </div>
                    <div className="col-md-10 p-3 service-provider-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Project Name: Quality Builds Project</p>
                                <p>Supplier Name: Reliable Raw Materials Inc.</p>
                            </div>
                            <div className='rating-text d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <p>Rating</p>
                                <Rating name="read-only" value={4} style={{ color: "green" }} readOnly className="rating" />
                            </div>
                        </div>
                        <p>We are your trusted source for top-quality raw materials, ensuring your projects stand the test of time. From cement to steel, count on us for your building needs.
                            Our partnership with Aashray Homes has been exceptional. They link suppliers with projects in need of our materials efficiently. For reliable supplies and top-notch service, connect with Aashray Homes today!</p>
                    </div>
                </li>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={serviceProviderimg4} alt="" />
                    </div>
                    <div className="col-md-10 p-3 service-provider-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Project Name: Secure Investments Project</p>
                                <p>Broker Name: Financial Expert Aman Mehta</p>
                            </div>
                            <div className='rating-text d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <p>Rating</p>
                                <Rating name="read-only" value={5} style={{ color: "green" }} readOnly className="rating" />
                            </div>
                        </div>
                        <p>We are your trusted source for top-quality raw materials, ensuring your projects stand the test of time. From cement to steel, count on us for your building needs.
                            Our partnership with Aashray Homes has been exceptional. They link suppliers with projects in need of our materials efficiently. For reliable supplies and top-notch service, connect with Aashray Homes today!</p>
                    </div>
                </li>
            </>
        );
    }
}


