import React, { Component } from "react";
import Spinner from 'react-bootstrap/Spinner';
class Loader extends Component<{}, {}> {
  state = {};
  render() {
    return (
        <div className="loading">
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    );
  }
}
export default Loader;
