import React, { Component } from 'react'
import Footer from '../../../common-components/Footer/Footer'
import Header from '../../../common-components/Header/Header'
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import "./ViewProject.scss"
import { constants } from '../../../../constants/constants';
import { blobService } from '../../../common-components/Blob';
import { Link, RouteComponentProps } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import axios from 'axios';
// import Modal from 'react-awesome-modal';
import Modal from "react-bootstrap/Modal";
import CreateQuote from '../../../common-components/CreateQuote/CreateQuote';
import { userService } from '../../../Services/CommonService';
import Check from '../../../Services/Check';

interface ViewProjectProps {
    name?: any;
    value?: any;
}
interface ViewProjectState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    errors: {};
    project: any,
    nextIcon: any,
    prevIcon: any,
    showModal: boolean,
    header: string,
    body: any,
    name: string,
    builderName:string,
    quoteModal:boolean,
    showUpload: boolean,
    contactPhone: string,
    addressLine1: string, 
    addressLine2: string,
    state: string, 
    country: string, 
    pincode: number,
    city: string,
    nextDisabledIcon: any,
    prevDisabledIcon: any,
    projectId: any[],
    isEditQuote: boolean,
    quoteDetails: any[],
    projectName: string,
    address: any,
    mobile: string,
    description: string,
    builderType: string,
    landSize: string,
    measurementUnit: string,
    landSaleContract: string,
    architectualDrawings: string,
    engineeringPlans: string,
    LandScapingDesign: string,
    projectCreatedBy: string,
    quoteStatus: string
}

export default class ViewProject extends React.Component<ViewProjectProps & RouteComponentProps, ViewProjectState> {
    premium: boolean = false;
    constructor(props: any) {
        super(props);
        const initialState = {
            error:false,
            ErrMsg:"",
            isLoading: false,
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            nextDisabledIcon: <span className="icon-button-disabled "><img src={RightArrow}></img></span>,
            prevDisabledIcon: <span className="icon-button-disabled "><img src={LeftArrow}></img></span>,
            errors: {},
            project:{},
            showModal: false,
            header: '',
            body:{},
            name:'',
            builderName:'',
            quoteModal: false,
            showUpload: false,
            contactPhone: '',
            addressLine1: "",
            addressLine2: "", 
            state: "", 
            country: "",
            pincode: 0,
            city: '',
            projectId: [],
            isEditQuote: false,
            quoteDetails: [],
            projectName: '',
            address: [],
            mobile: '',
            description: '',
            builderType: '',
            landSize: '',
            measurementUnit: '',
            landSaleContract: '',
            architectualDrawings: '',
            engineeringPlans: '',
            LandScapingDesign: '',
            projectCreatedBy: '',
            quoteStatus: ''
        };
        this.state = initialState;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
       this.init();
       
        // if(localStorage.id){
        // this.fetchBuilder(localStorage.id);
        // }
    }

    init(){
        let p = this.props.location.state ? this.props.location.state.data: "";
        this.viewProject(p.id? p.id : p.projectId); 
        this.state.projectId.push(p.id? p.id : p.projectId);
        this.setState({
            projectCreatedBy: p.projectCreatedBy ? p.projectCreatedBy : this.props.location.state.projectCreatedBy
        })
    }
//////////////////
async fetchBuilder(id: any): Promise<any> {
    const key = constants.subscriptionKey;          
    const url =  constants.fetchBuilderAPI + id;
    const user = JSON.parse(localStorage.getItem('user')!); 
    let token;
     if(user){
             token = 'Bearer ' + user;
           
        }
    axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
        .then((response) => {
            let b = response.data;
            this.setState({builderName: b.builderName});
            //this.setState({builderName: builderName});
            //this.premium = response.data.membership == 1 ? true : false;
            // let b = response.data;
            // this.setState({ builder: b, builderLogo: b.logoPath, builderName: b.builderName, city: b.address ? b.address.city : '', twitterLink: b.socialLinks ? b.socialLinks.twitterLink :"", facebookLink: b.socialLinks ?  b.socialLinks.facebookLink : "" , about:b.description,
            //     bannerImages: b.bannerImages
            // });
            // this.fetchProjects(id);
            // this.fetchPortfolio(id);
        })
        .catch((error) => {
            this.setState({ ErrMsg: error, error:true });
        });
};
    /////////////
    async viewProject(id: any): Promise<any> {
        const key = constants.subscriptionKey;
        const url =  constants.fetchProjectAPI + id;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user ){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                this.setState({ 
                    project: response.data,
                     isLoading: false,
                     //projectImages = response.data.projectDetails.projectImages;
                     projectName: response.data.name,
                     address: response.data.address ? response.data.address.addressLine1+", "+response.data.address.addressLine2+", "+response.data.address.city+", "+response.data.address.state+", "+response.data.address.country: "",
                     mobile: response.data.mobile,
                     description: response.data.description,
                     builderType: response.data.projectDetails.builderType.builderType,
                     landSize: response.data.projectDetails.landSize,
                     measurementUnit: response.data.projectDetails.measurementUnit.description,
                     landSaleContract: response.data.projectDetails.projectDocs.landSaleContract,
                     architectualDrawings: response.data.projectDetails.projectDocs.architectDrawings,
                     engineeringPlans: response.data.projectDetails.projectDocs.engineeringPlans,
                     LandScapingDesign: response.data.projectDetails.projectDocs.landscapingDesigns,
                    });
                this.findUserById(this.state.project.createdBy);
                if(localStorage.userType && localStorage.userType == "Builder") {
                    let pId = this.props.location.state.data;
                    this.fetchQuoteDetails(pId.id? pId.id : pId.projectId);
            }
               
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    };

    async findUserById(e: any): Promise<any> {
        let p = this.props.location.state ? this.props.location.state.data: "";
        let id= p.createdBy;
        const key = constants.subscriptionKey;
        const url =  constants.fetchUserDetailsAPI + id;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                let name;
                if(JSON.stringify(response.data.detail.fullName)){
                    name = response.data.detail.fullName;
                }
                
                this.setState({
                    name: name,
                    contactPhone: response.data.detail.phone
                })
                let res = response.data.detail;
                if( Object.keys(res.address).length !== 0 || res.address.addressLine1 !== null || res.address.addressLine2 !== null || res.address.state !== null ||  res.address.country !== null || res.address.city !== null ){
                    this.setState({
                         city:  res.address.city , 
                        addressLine1:  res.address.addressLine1, 
                        addressLine2:  res.address.addressLine2 , 
                        state:  res.address.state, 
                        country: res.address.country ,
                        // pincode: res.address.pincode,
                    })
                }
            })

            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    };

    onShowModal(event:any){
        this.setState({ showModal: true, header: "Contact Details" });
    }
    onHideModal(event:any){
        this.setState({ showModal: false });
    }
    openQuoteModal = () =>{
        this.setState({
            quoteModal: true
        })
        
    }
    success(event: any) {
        if(event == true) {
            this.setState({ quoteModal: false  })
        }else{
            this.setState({ quoteModal: false, showUpload: true, body: event })    
        }  
      }
      onHideUpload = async(event:any) => {
        this.setState({
            showUpload:false, 
            isEditQuote: true,
        });
        this.init();
       
    }
//     quoteFailure = (event: any) =>{
//         this.setState({ quoteModal: false, showUpload:true, header: "Failure", body: event });
//   }
    fetchQuoteDetails =(projectId: any)=>{
    //     let pId = this.props.location.state.data;
    // let projectId = pId.id? pId.id : pId.projectId ;
      let  userId = localStorage.getItem("id");
       let userType = localStorage.getItem("userType");
        Check.checkToken()
        .then(() => {
            const options = {
                url: constants.fetchQuoteDetails,
                data: {
                        "projectId": projectId,
                        "createdBy": userType,
                        "userId": userId,
                        "status": ""
                }
            };
           
            userService
            .postApiRequest(options)
            .then((response) => {
                if(response !== "quote not found"){
                    this.setState({
                        isEditQuote: true,
                        quoteDetails: response,
                        quoteStatus: response.quoteStatus,
                    })
                }
               
            })
            .catch((error) => {
            });

        })

    }
    sendContract=()=>{

    }
    render() {
        let items:any=[];
        let subItems:any=[];
        let projectImages:any=[];
        const{
            isLoading, 
            nextIcon,
            prevIcon,
            showModal,
            header, 
            showUpload, 
            body,
            contactPhone,
            addressLine1, 
            addressLine2, 
            state,
            country, 
            pincode, 
            city,
            prevDisabledIcon, 
            nextDisabledIcon,
            projectId, 
            isEditQuote, 
            quoteDetails , 
            project, 
            projectName, 
            address, 
            mobile, 
            description, 
            builderType, 
            landSize, 
            measurementUnit, 
            projectCreatedBy,
            quoteStatus
        } = this.state;
        let contactAddress:any;
       
        if( addressLine1 == null || addressLine1 == ""){
            contactAddress = []
        }else{
            contactAddress =  addressLine1+", "+addressLine2 +", "+city+", "+state+", "+country;
        }
       
        if(projectImages.length>0){
            items = projectImages.map(
                (item:any) => (
                    (<Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={blobService.createUrl(item)}
                        width="100%" height="350px"
                        />
                    </Carousel.Item>)          
                ),
            );
        }else{
            items = (<Carousel.Item>
            <img
            className="d-block w-100"
            src={ProjectPlaceholder}
            width="100%" height="350px"
            />
            </Carousel.Item>)
        }
        for(let i = 0; i<4; i++){
            if(projectImages[i]){
                subItems.push(
                    <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                        <img src={blobService.createUrl(projectImages[i])} alt="" width="100%" height="100px"/>
                    </div>
                )               
            }
        }
            
        return !project ? (<><Header/><br/><div className="text-center">No Data</div><br/><Footer/></>): 
           true ? (
                <>
            <Header/>
            {/* {
                       error ?  <ApiErrorHandling error={ErrMsg}/> : ""
                   } */}
                <div className="bg-img">
                <div className="overlay">
                <div className="container">
                <div className="col-md-6 col-12"> 
                    <h1 style={{fontWeight:600}}>{projectName}</h1>
                    <p className="my-3"><Link to="FindProjects" className="link-text">Find Projects</Link> / {projectName}</p>
                </div>
                </div>
                </div>
                        </div>
    
    
    
    
                <div className="aboutProject">
                <div className="container py-5">
                <h4 className="aboutpro">About</h4>
                    <div className="row mt-5">
                    <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <span>
                                            {(projectImages.length>1)?
                                                <Carousel  nextIcon={nextIcon} prevIcon={prevIcon} >
                                                    {items}
                                                </Carousel>
                                                :
                                                <Carousel className = "disabled-arrow" nextIcon={nextDisabledIcon} prevIcon={prevDisabledIcon} >
                                                    {items}
                                                </Carousel>
                                            }
                                            <br/>
                                        </span>
                                        <div className="row">
                                                {subItems}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        
                        <div className="col-md-6">
                        <div className="projectInfo">
                            <h3>{projectName}</h3>
                            <p>by {this.state.name}</p>
    
                            <h5 className="mt-4">Address &amp; Contact</h5>
                            <div>{address}</div>
                            <div>{mobile}</div>
     
                            <h5 className="mt-4">Builder Assigned</h5>
                            <div>{"None"}</div>
    
                            <h5 className="mt-4">Architect Assigned</h5>
                            <div>{"None"}</div>
    
                            <br/>
                            <div>
                                <button className="button1 contact" onClick={(event)=>this.onShowModal(event)}>Contact <img src={RightArrow} className="contactbut"/></button>
                                {(projectCreatedBy == "CUSTOMER" && localStorage.userType && localStorage.userType == "Builder") ?
                                    (isEditQuote == true ? 
                                    <button 
                                        disabled = {quoteStatus == "accept"}
                                        className="button2 ml-5 hire" 
                                        onClick={this.openQuoteModal}>
                                        Edit Quote 
                                        <img src={RightArrow} className="ml-3"/>
                                    </button>
                                    :
                                    <>
                                    <button 
                                        className="button2 ml-5 hire" 
                                        onClick={this.openQuoteModal}>
                                        Give Quote 
                                        <img src={RightArrow} className="ml-3"/>
                                    </button>
                                     {/* <button
                                      className="button2 ml-5 hire"
                                      onClick={this.sendContract} >
                                         Send Contract
                                     </button> */}
                                     </>
                                    )
                                : ""}
                            </div>
    
                        </div>
                        </div>
                    </div>
    
                    {/* <div className="my-5">
                        <h4>About the Project</h4>
                       
                    </div> */}
    
                    <div className="builder">
                        <h4>Project Details</h4>    
                        <h5>Description</h5>
                        <p style={{lineHeight:"30px"}}>{description}</p>
                        <div className="row mt-4">
                            <div className="col-md-3">
                                <h5>Builder Type</h5>
                                <p>{builderType}</p>
                            </div>
                            <div className="col-md-3">
                                <h5>Land Size</h5>
                                <p>{landSize} {measurementUnit}</p>
                            </div>
                        </div>
                    </div>
    
                    {/* <div className="my-5">
                    <h4>Documents</h4>  
                    <div className="row mt-4">
                    <div className="col-md-6">
                        <h6>Contract of Sale of Land</h6>
                        <div className="pdfblock file-name">
                            <img src={DownloadIcon} />  <a href={blobService.createUrl(landSaleContract)} target="_blank"> Contract of Sale of Land.pdf</a>
                        </div>
                    </div>
                    {builderType ==="Custom Builder" &&
                    <div className="col-md-6">
                        <h6>Architechtural Drawings</h6>
                        <div className="pdfblock file-name">
                            <img src={DownloadIcon} />  <a href={blobService.createUrl(architectualDrawings)} target="_blank"> Architechtural Drawings.pdf</a>
                        </div>
                    </div>
                    }
                    </div>
                    {builderType ==="Custom Builder" &&
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <h6>Engineering Plans</h6>
                            <div className="pdfblock file-name">
                                <img src={DownloadIcon} />  <a href={blobService.createUrl(engineeringPlans)} target="_blank"> Engineering Plans.pdf</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>LandScaping Designs</h6>
                            <div className="pdfblock file-name">
                                <img src={DownloadIcon} />  <a href={blobService.createUrl(LandScapingDesign)} target="_blank"> LandScaping Designs.pdf</a>
                            </div>
                        </div>
                    </div>
                    }
                    </div> */}
                </div>
                </div>
                <div>
        </div>
            <CustomModal isAlert={true} show={showModal} header={header} body={ (contactAddress.length == 0  && contactPhone == null) ? "Address & Contact Details Are Not Available."  : <div>{( contactAddress.length == 0 || contactAddress == null) ? "Address Is Not Available.": <p>Address : {contactAddress}</p> }{( contactPhone == 'Not available' || contactPhone == null) ?"Contact Details Is Not Available." : <p>Call on : {contactPhone}</p> }</div> }  onHide={(event:any)=>this.onHideModal(event)}/>

            {(localStorage.userType && localStorage.userType == "Builder") ?
            <Modal 
                show={this.state.quoteModal} 
                // effect="fadeInUp"
                // width = "40%"
              >
                <CreateQuote 
                    isEditQuoteCheck = {this.state.isEditQuote} 
                    quoteDetailsData= {quoteDetails} 
                    id={projectId} 
                    projectName ={this.state.projectName} 
                    // value={this.openQuoteModal} 
                    result={(event: any) => this.success(event)} 
                />
            </Modal>
            : ""}

            <CustomModal isAlert={true} show={showUpload} header= "Success" body={body} onHide={(event: any)=>this.onHideUpload(event)}  /> 
            <Footer/>
            </> ): <>
            <Header/>
            {/* {
                       error ?  <ApiErrorHandling error={ErrMsg}/> : ""
                   } */}
                <div className="bg-img">
                <div className="overlay">
                <div className="container">
                <div className="col-md-6 offset-md-2 col-12"> 
                    <h1>{projectName}</h1>
                    <p className="my-3">Find Projects / {projectName}</p>
                </div>
                </div>
                </div>
                        </div>
                    
                    
    
                       {/* <div className="container py-5">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <img src={PadLock} width="50" height="50" alt="" />
                                        <h4>Get Aashray Plus</h4>
                                        <p>Get Aashray Plus and find projects to work on</p>
                                        <div>
                                            <button className="button1 contact"><Link className="text-white" to={{ pathname: "/Pricing" }}>Continue<img src={RightArrow} className="ml-3" /></Link></button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            <CustomModal isAlert={true} show={showModal} header={header} body={<div>Address : {address}<br/>Call on : {mobile}</div>} onHide={(event:any)=>this.onHideModal(event)}/>
            <Footer/>
            </> ;
    }
}
