import React from 'react';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import Linkedinwithround from '../../../../assets/images/Linkedinwithround.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import Loader from '../../../common-components/Loader';
import Edit from '../../../../assets/images/edit.svg';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Image from "../../../common-components/Image";
import Delete from "../../../../assets/images/delete.svg"
import { blobService } from '../../../common-components/Blob';
import Upload from "../../../../assets/images/upload.svg"
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import { Link } from "react-router-dom";
import Check from '../../../Services/Check';
import BankofAmericaIcon from '../../../../assets/images/BankofAmericaIcon.svg';
import Line from '../../../../assets/images/line-vertical.svg';
import './Profile.scss';
import Modal from 'react-awesome-modal';
import { Multiselect } from 'multiselect-react-dropdown';
import { Button } from '@material-ui/core';
import VectorCross from '../../../../assets/images/VectorCross.png';
import passwordLock from '../../../../assets/images/passwordLock.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface ProfileProps {
    name?: any;
    value?: any;
}
interface ProfileState {
    error: boolean;
    ErrMsg: any;
    checked: boolean;
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    isLoading: boolean;
    isEdit: boolean;
    type: string;
    adLine1: string;
    adLine2: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    errors: {
        oldPassword: string,
        newPassword: boolean,
        confirmNewPassword: string,
        description: string,
        mobile: string,
        adLine1: string,
        adLine2: string,
        financialBrokerName: string,
        twitterLink: string,
        facebookLink: string,
        instagramLink: string,
        alternateEmail: string,
        alternateMobile: string,
        serviceLocation: string,
        pincode: string,
        newupperCase: boolean,
        newlowerCase: boolean,
        newdigit: boolean,
        newspecialChar: boolean,
        newminLength: boolean,
        file: string,
    };
    cityList: any,
    stateList: any,
    pincodeList: any,
    twitterLink: string,
    facebookLink: string,
    instagramLink: string,
    youtubeLink: string,
    financialBroker: any;
    financialBrokerName: string;
    financeBrokersId: string;
    description: string;
    address: string;
    mobile: string;
    email: string;
    profileImage: string,
    showUpload: boolean,
    header: string,
    body: string,
    newsletter: boolean,
    id: string,
    pageNo: any,
    alternateEmail: string,
    alternateMobile: string,
    user: any,
    formAddressData: any,
    formOtherData: any,
    addBankModel: boolean,
    userSelectBank:any,
    // AddButton : any,
    allBank : any,
    addBanks : any,
    addBanks1 : any,
    selectedItem : any,
    bankName : string,
    bankLogoPath : string,
    removeBank : any,
    isRemoveBank : boolean,
    serviceLocation: string,
    isEnablePassword: boolean,
    isBankAdded: boolean
    
}
const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
const RegexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class Profile extends React.Component<ProfileProps, ProfileState> {

    constructor(props: ProfileProps) {
        super(props);

        const initialState = {
            error: false,
            ErrMsg: "",
            checked: true,
            profileTab: true,
            settingTab: false,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            isLoading: true,
            isEdit: false,
            type: 'text',
            projectModal: false,
            projectHeader: '',
            projectBody: '',
            builderType: '',
            measurementUnit: '',
            adLine1: '',
            adLine2: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            errors: {
                oldPassword: '',
                newPassword: false,
                confirmNewPassword: '',
                description: '',
                mobile: '',
                adLine1: '',
                adLine2: '',
                financialBrokerName: '',
                twitterLink: '',
                facebookLink: '',
                instagramLink: '',
                alternateEmail: '',
                alternateMobile: '',
                serviceLocation: '',
                pincode: '',
                newupperCase: false,
                newlowerCase: false,
                newdigit: false,
                newspecialChar: false,
                newminLength: false,
                file: '',
            },


            twitterLink: '',
            facebookLink: '',
            instagramLink: '',
            youtubeLink: '',
            financialBroker: {},
            financialBrokerName: '',
            financeBrokersId : '',
            description: '',
            address: '',
            mobile: '',
            email: '',
            profileImage: '',
            showUpload: false,
            header: '',
            body: '',
            newsletter: false,
            id: '',
            alternateEmail: '',
            alternateMobile: '',    
            pageNo: 0,
            pincodeList: [],
            stateList: [],
            cityList: [],
            user: {},
            formAddressData: [],
            formOtherData: [],
            addBankModel : false,
            userSelectBank : [],
            // AddButton : [],
            allBank : [],
            addBanks : [],
            addBanks1 : [],
            selectedItem : [],
            bankName : '',
            bankLogoPath : '',
            removeBank : [],
            isRemoveBank : false,
            serviceLocation: '',
            isEnablePassword: true,
            isBankAdded: true

        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }



    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        const urlRegEx = RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        let errors = this.state.errors;

        const uppercaseRegExp   = /(?=.*?[A-Z])/;
        const lowercaseRegExp   = /(?=.*?[a-z])/;
        const digitsRegExp      = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp   = /.{8,}/;
        
        switch (name) {
            case 'oldPassword':
                errors.oldPassword  = value.length > 0 ? '' :  "Please Enter Password";
                break;
            case 'newPassword':
                errors.newupperCase = uppercaseRegExp.test(value) ? true : false;
                errors.newlowerCase = lowercaseRegExp.test(value)? true : false;
                errors.newdigit = digitsRegExp.test(value) ? true : false;
                errors.newspecialChar = specialCharRegExp.test(value) ? true : false;
                errors.newminLength = minLengthRegExp.test(value)? true : false;
                break;
            case 'confirmNewPassword':
                errors.confirmNewPassword = this.state.newPassword != value ? 'Make Sure Your Password is Same as New Password' : '';
                break;
            
            case 'twitterLink':
                errors.twitterLink = (urlRegEx.test(value)|| value.length == 0 ) ? '' : 'Please Enter A Valid Link';
                break;
            case 'instagramLink':
                errors.instagramLink = (urlRegEx.test(value)|| value.length == 0 ) ? '' : 'Please Enter A Valid Link';
                break;
            case 'facebookLink':
                errors.facebookLink = (urlRegEx.test(value)|| value.length == 0 ) ? '' : 'Please Enter A Valid Link';
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
        if((errors.newupperCase && 
            errors.newlowerCase && errors.newdigit && 
            errors.newspecialChar && errors.newminLength &&
            (this.state.confirmNewPassword == this.state.newPassword )
            ) == true && this.state.oldPassword !== ''){
                    this.setState({
                        isEnablePassword: false
                    })
        }else{
            this.setState({
                isEnablePassword: true
            })
        }
    };

    componentDidMount() {
        this.init();
    }

    init() {
        let userId = localStorage.getItem("id");
        this.viewProfile(userId);
        this.viewUser(userId);
        this.fetchStateList();
        this.fetchCityList();  
    }
    async viewUser(id: any): Promise<any> {
        Check.checkToken()
            .then(() => {
                const options = {
                    url: constants.fetchUserAPI + id,
                };
                userService
                    .getApiRequest(options)
                    .then((response) => {
                        let u = response.data;
                        this.setState({
                            user: u,
                            email: u.detail.email,
                            mobile: u.detail.phone,
                        });
                    })
                    .catch((error) => {
                    });
            })
    }
    async fetchStateList(): Promise<any> {
        Check.checkToken()
            .then(() => {
                const options = {
                    url: constants.fetchStatesListAPI,
                };
                userService
                    .getApiRequest(options)
                    .then((response) => {
                        this.setState({ stateList: response.data })

                    })
                    .catch((error) => {
                        this.setState({ ErrMsg: error, error: true });
                    });
            })
    }
    async fetchCityList(): Promise<any> {
            const options = {
                url: constants.fetchAllCity,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    if(response.status == 200 ){
                        let cityList = response.data.list;
                        cityList.map((item:any)=>{
                            this.state.cityList.push(item);
                        })
                        this.setState({cityList: this.state.cityList});
                    }
                })
                .catch((error) => {
                    this.setState({ cityList: [] })
                    this.setState({ ErrMsg: error, error: true });
                });

    }


    async viewProfile(id: any): Promise<any> {
        Check.checkToken()
            .then(() => {
                const options = {
                    url: constants.fetchFinancialBrokerAPI + id,
                };
                userService
                    .getApiRequest(options)
                    .then((response) => {
                        let b = response.data;
                        this.setState({
                            financialBroker: b,
                            isLoading: false,
                            financialBrokerName: b.financeBrokersName,
                            description: b.description,
                            adLine1: b.address.addressLine1,
                            adLine2: b.address.addressLine2,
                            city: b.address.city,
                            state: b.address.state,
                            pincode: b.address.pincode,
                            country : b.address.country,
                            twitterLink: b.socialLinks.twitterLink,
                            facebookLink: b.socialLinks.facebookLink,
                            instagramLink: b.socialLinks.instagramLink,
                            alternateEmail: b.alternateEmail,
                            alternateMobile: b.alternateMobile,        
                            youtubeLink: b.socialLinks.youtubeLink,
                            profileImage: b.profileImage,
                            formAddressData: b.address,
                            userSelectBank : b.userSelectBank,
                            financeBrokersId: b.financeBrokersId,
                            serviceLocation: b.serviceLocation,
                            formOtherData: b,
                        });
                    })
                    .catch((error) => {
                        this.setState({ ErrMsg: error, error: true });
                    });
                    
                    
            })


    }

    async fetchAllBank() : Promise<any>{
        Check.checkToken()
        .then(() => {
            const options = {
                url : constants.fetchBankAPI + parseInt(this.state.financeBrokersId)
            };
            userService.getApiRequest(options)
            .then((response) => {
                let b = response.data
                this.setState({
                    allBank : b
                })
                
            })
            .catch((error) => {})
            
        })
    }
    updateProfile = async () => {
        Check.checkToken()
            .then(
                async () => {
                    let validity = true;
                    Object.values(this.state.errors).forEach((val: any) => val.length > 0 && (validity = false));
                    if (validity == true) {
                        var b = this.state.financialBroker;
                        b.financeBrokersName = this.state.financialBrokerName;
                        const options = {
                            url: constants.updateFinancialBrokerAPI,
                            data: {
                                "userId": b.userId,
                                "financeBrokersName": this.state.financialBrokerName,
                                "address": {
                                    "id": b.address.id,
                                    "addressLine1": this.state.adLine1,
                                    "addressLine2": this.state.adLine2,
                                    "city": this.state.city,
                                    "state": this.state.state,
                                    "country": "India",
                                    "pincode": this.state.pincode,
                                    "latitude": b.address.latitude,
                                    "longitude": b.address.longitude,
                                    "active": b.address.active
                                },
                                "profileImage": this.state.profileImage,
                                "description": this.state.description,
                                "alternateMobile": this.state.alternateMobile,
                                "alternateEmail": this.state.alternateEmail,            
                                "mobile": this.state.mobile,
                                "email": b.email,
                                "socialLinks": {
                                    "id": b.socialLinks.id,
                                    "facebookLink": this.state.facebookLink,
                                    "instagramLink": this.state.instagramLink,
                                    "twitterLink": this.state.twitterLink,
                                    "youtubeLink": this.state.youtubeLink,
                                },
                                "addBanks": this.state.addBanks1,
                                "removeBanks" : this.state.removeBank,
                                "serviceLocation": this.state.serviceLocation,
                                "active": b.active
                            }
                            
                            
                        };
                        var response = await userService.updateApiRequest(options);
                        if (response) {
                            const options = {
                                url: constants.updateUserAPI + b.userId,
                                data: {
                                    "fullName": b.fullName,
                                    "userName": b.userName,
                                    "email": this.state.email || b.email,
                                    "phone": this.state.mobile || b.phone,
                                    "emailModified": (this.state.email === b.email) ? false : true,
                                    "phoneModified": (this.state.mobile === b.phone) ? false : true,
                                }
                            };
                            var response = await userService.postApiRequest(options);
                            if (response) {
                                this.openUpload('Success', 'Profile Updated Successfully');
                                this.init();
                                this.edit();
                            } else {
                                this.openUpload('Failed', 'Failed to Update Profile');
                            }

                        } else {
                            this.openUpload('Failed', 'Failed to Update Profile');
                        }
                    } else {
                    }
                }
            )

        this.state.selectedItem.length = 0;
        this.state.addBanks.length = 0;
        
    };

    hasChangedSinceInitialState = () => {
        const { formAddressData, formOtherData } = this.state;
        return (formAddressData.city === this.state.city &&
            formAddressData.state === this.state.state &&
            formAddressData.pincode == this.state.pincode &&
            formAddressData.addressLine1 === this.state.adLine1 &&
            formAddressData.addressLine2 === this.state.adLine2 &&
            formOtherData.financeBrokersName === this.state.financialBrokerName &&
            formOtherData.description === this.state.description &&
            formOtherData.socialLinks.twitterLink === this.state.twitterLink &&
            formOtherData.socialLinks.facebookLink === this.state.facebookLink &&
            formOtherData.socialLinks.instagramLink === this.state.instagramLink &&
            formOtherData.alternateEmail === this.state.alternateEmail &&
            formOtherData.alternateMobile == this.state.alternateMobile &&
            formOtherData.mobile == this.state.mobile &&
            formOtherData.profileImage == this.state.profileImage &&
            formOtherData.userSelectBank == this.state.userSelectBank &&
            this.state.isBankAdded &&
            formOtherData.serviceLocation == this.state.serviceLocation

        );
    }

    cancel() {
        this.clearErrorMsg();
        this.init();
        this.edit();

    }
    clearErrorMsg = () => {
        let errors = this.state.errors;
        errors.description = '';
        errors.mobile = '';
        errors.adLine1 = '';
        errors.adLine2 = '';
        errors.financialBrokerName = '';
        errors.twitterLink = '';
        errors.facebookLink = '';
        errors.instagramLink = '';
        errors.alternateEmail= '';
        errors.alternateMobile= '';
        errors.serviceLocation = '';
    }

    
    edit = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        });
        
        this.state.selectedItem.length = 0;
        this.state.addBanks.length = 0;
    };

    
    handleChangeProfile = (event: any) => {

        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'name':
                //  errors.name = value.length == 0 ? 'Please enter valid Name': '';
                break;
            case 'description':
                errors.description = (value.length < 251 || value.length == 0) ? '': 'You Have Reached Your maximum Limit Of Characters Allowed';
                break;
            case 'mobile':
                errors.mobile = (Regex.test(value) || value.length == 0) ? '' : 'Please Enter A Valid Number';
                break;
            case 'adLine1':
                errors.adLine1 = value.length  > 100 ? 'You Have Reached Your maximum Limit Of Characters Allowed' : '';
                break;
            case 'adLine2':
                errors.adLine2 = value.length  > 150 ? 'You Have Reached Your maximum Limit Of Characters Allowed' : '';
                break;
            case 'financialBrokerName':
                errors.financialBrokerName = value.length > 70 ? 'You Have Reached Your maximum Limit Of Characters Allowed' : '';
                break;
            case 'alternateEmail':
                errors.alternateEmail = (RegexEmail.test(value) || value.length == 0)? '': 'Please enter valid Email';
            break;
            case 'alternateMobile':
                    errors.alternateMobile = (Regex.test(value) || value.length == 0) ? '' :  'Please Enter A Valid Number' ;
               break;
            case 'pincode':
                errors.pincode = (value.length < 6 || value.length > 6) ? 'Pincode must be 6 digit long' : '';
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    };
    removeImage = async (event: any) => {
        let id = event?.target.id;
        this.setState({ profileImage: '' })
    }

    openUpload = async (header: any, body: any) => {
        this.setState({ showUpload: true, header: header, body: body });
    }
    onHideUpload = async (event: any) => {
        this.setState({ 
            showUpload: false,
            isBankAdded: true
         });
    }

    onFileChange = async (event: any) => {
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        let errors = this.state.errors;
        if (file) {
            formData.append('id', userId ? userId : '');
            formData.append('type', "Profile");
            if (file && file.type != 'image/gif' && file && file.type !=  'image/jpeg' && file && file.type !=  'image/png') {
                
                errors.file = 'Please upload only proper file type';
                this.setState({ errors: errors });
            } else if (file && file.size > 2000000) {
                errors.file = 'Please upload file below 2MB';
                this.setState({ errors: errors });
            } else {
                errors.file = '';
                formData.append('file', file);
            }
            const options = {
                url: constants.uploadFilesAPI,
                data: formData
            }
            Check.checkToken();
            const response = await userService.postFileApiRequest(options);
            if (response) {

                this.setState({ profileImage: response });
                this.openUpload("Success", "File Uploaded SuccessFully");
            } else {
                this.setState({ profileImage: '' });
                this.openUpload("Failed", "Failed to Upload File");
            }
        } else {
            // let errors = this.state.errors;
            // errors.file1 = 'Please Select File to Upload';
            // this.setState({errors: errors});
        }
    };
    onNewsletterChange(event: any) {
        this.setState({ newsletter: !this.state.newsletter });
    }

    async changePassword(event: any) {
        event.preventDefault();
        Check.checkToken()
            .then(() => {
                let errors = this.state.errors;
                let userId = localStorage.getItem("id");
                let validity = true;
                Object.values(this.state.errors).forEach((val: any) => val.length > 0 && (validity = false));
                if (!this.state.oldPassword || !this.state.newPassword) {
                    return;
                }
                if (validity == true) {
                    const options = {
                        url: constants.resetPasswordAPI + userId,
                        data: {
                            "oldPassword": this.state.oldPassword,
                            "newPassword": this.state.newPassword,
                        }
                    };
                    userService.updateApiRequest(options)
                        .then(response => {
                            this.clearValues();
                            this.openUpload('Success', 'Password Changed Successfully');
                            this.init();
                        })
                        .catch(error => {
                            this.openUpload('Failed to Change Password', error.message);
                        });
                }
            }
            )
    }

    clearValues() {
        this.setState({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        });
    }


    changeTab(index: any) {
        this.setState({
            pageNo: index,
        })
    }

    closeModal() {   
        this.setState({
            addBankModel: false
        });
        this.state.selectedItem.length = 0;
        this.state.addBanks.length = 0;
        this.state.removeBank.length = 0;
    }

    
    addBankButton = () => {
        
        if(!this.state.addBanks.includes(JSON.stringify(this.state.selectedItem))){
            this.state.addBanks.push(JSON.stringify(this.state.selectedItem));
            this.setState({
                isBankAdded: false
            })
        }
        if(this.state.addBanks != null)
        {
            this.setState({addBanks1 : JSON.parse(this.state.addBanks)})
            for(let i =0; i<JSON.parse(this.state.addBanks).length; i++){
                this.state.userSelectBank.push(JSON.parse(this.state.addBanks)[i]);
               
            }
            
        }else{
            this.setState({addBanks1 : null})
        }
        
        this.closeModal();
    }

    removeBankButton = (itemId:any) => {
        if(!this.state.removeBank.includes( Number(JSON.stringify(itemId.id)))){
            this.state.removeBank.push( Number(JSON.stringify(itemId.id)))
        }
        if(this.state.userSelectBank.includes(itemId) === true){
            for(var k in this.state.userSelectBank){
                if(this.state.userSelectBank[k] == itemId){
                    delete this.state.userSelectBank[k]
                }
            }
            
            this.setState({
                userSelectBank : this.state.userSelectBank.filter((b:any) => ((b) !== null ))        
            })
            
        }
        this.setState({
            isRemoveBank : true
        })
        this.setState({
            isRemoveBank : false
        })
    }

    selectCity = (value: any)=>{
        if(value){
            this.setState({
                city: value,
               
            })
        }else{
            this.setState({
                city: ''
            })
        }
    }

    render() {
        let {
            errors,
            isEdit,
            isLoading,
            description,
            adLine1,
            adLine2,
            state,
            city,
            pincode,
            country,
            twitterLink,
            facebookLink,
            instagramLink,
            financialBroker,
            financialBrokerName,
            address,
            mobile,
            email,
            profileImage,
            showUpload,
            header,
            body,
            newsletter,
            pageNo,
            stateList,
            cityList,
            oldPassword,
            newPassword,
            confirmNewPassword,
            userSelectBank,
            allBank,
            addBanks,
            alternateEmail,
            alternateMobile,
            serviceLocation,
            isEnablePassword,
            selectedItem
        } = this.state;
        address = adLine1 + ", " + adLine2 + ", " + city + ", " + state + ", " + country+ "-"+ pincode ;
        let isEnabled = true;
        if( this.hasChangedSinceInitialState() == false && financialBrokerName !== "" && 
            city !== "" && state !== "" && adLine1 !== "" && adLine2 !== "" &&  serviceLocation !== "" &&
            mobile !== "" && pincode !== "" && description !== "" && alternateMobile !== "" && userSelectBank.length > 0 
        ){
            isEnabled = false
        }
        const addBank = <div className="grid ">
                                        <div className="row bank-managed">
                                            <div>
                                                <img src={Line} style={{ filter: ' brightness(0) invert(1)' }} alt='' />         
                                            </div>
                                            <div style={{ margin: 'auto' }}>
                                                <div onClick={ () => {this.fetchAllBank(); this.setState({ addBankModel : true })} }>
                                                    <h6 style={{ cursor : 'pointer' }}>+ Add Bank</h6>
                                                </div> 
                                            </div>
                                    </div> 
                        </div>
    

        
        const listBanks = userSelectBank.map((item:any, i:any) => (
                                    
                                        <div className="grid " key={i}>
                                            
                                            {isEdit ? 
                                            <div className="row bank-managed">
                                            <div>
                                                <img src={Line} alt='' />
                                                <img src={item.img} alt="" />
                                            </div>
                                            <div style={{ margin: 'auto' }}>
                                                <h6>{item.bankName}</h6>
                                            </div>
                                            <div>
                                                <span className='cross-icon' onClick={ (() =>  this.removeBankButton(item)  )  }><img src={VectorCross} alt="" /></span>
                                            </div>
                                        </div> 
                                            : 
                                            <div className="row bank-managed">
                                                <div>
                                                    <img src={Line} alt='' />
                                                    <img src={item.img} alt="" />
                                                </div>
                                                <div style={{ margin: 'auto' }}>
                                                    <h6>{item.bankName}</h6>
                                                </div>
                                            </div> 
                                            
                                            }   
                                        </div>
        ))

        return isLoading ? (
            <Loader />
        ) : (
            <>
                <Header />
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 profile-offset-md-2  col-12">
                                    <h1>Profile</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(pageNo == 0) &&
                        <>
                            {!isEdit ? (
                                <div className="col-md">
                                    <button
                                        className="button3 contact"
                                        onClick={() => {
                                            this.edit();
                                        }}
                                    >
                                        Edit Profile
                                        <img src={Edit} className="ml-1" />
                                    </button>
                                </div>
                            ) : (
                                <div className="col-md">
                                    <button
                                        className= {isEnabled == true ? "button3 contact save-btn disabled-button" :  "button3 contact save-btn"} 
                                        onClick={() => {
                                            this.updateProfile();
                                        }} disabled={isEnabled} >
                                        Save
                                    </button>
                                    <button
                                        className="button3 contact cancel-btn"
                                        onClick={() => {
                                            this.cancel();
                                        }}>
                                        Cancel
                                    </button>
                                </div>
                            )}
                        </>
                    }
                </div>

                <Tabs className="profile-container" defaultIndex={0} onSelect={(index: any) => this.changeTab(index)} selectedTabClassName="TabProfileTrue">
                    <TabList>
                        <div className="container">
                            <div className="row">
                                <div className="heading">
                                    <Tab
                                        id="profile"
                                        className="title tab-select"
                                    >
                                        Profile
                                    </Tab>
                                    <Tab
                                        id="setting"
                                        className="title left-tab tab-select"
                                    >
                                        Settings
                                    </Tab>
                                </div>
                            </div>
                        </div>
                    </TabList>

                    <TabPanel>
                        <div className="aboutProject">
                            <div className="container ">

                                <div className="row ">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* */}
                                                {isEdit &&
                                                    <>
                                                        {profileImage &&
                                                            <>
                                                                <div className="profilecard">
                                                                    <span>
                                                                        <Image id="logo" src={blobService.createUrl(profileImage)} errorImg={Upload} alt="" width="100%" height="300px" />
                                                                        <Image className="close x-icon tab-select" id="logo" src={Delete} onClick={(event) => { this.removeImage(event) }} width="100%" height="20px" />
                                                                    </span>
                                                                </div>
                                                            </>
                                                            ||
                                                            <>
                                                                <div className="profilecard content-center">
                                                                    <span>
                                                                        <label htmlFor="logo">
                                                                            <span><h6 data-toggle="tooltip" data-html="true" title="Image must be less than 2MB and file types must be png, jpeg,jpg or gif. "><img src={Upload} />Photo</h6></span>
                                                                        </label>
                                                                        <input accept="image/gif, image/jpeg, image/png," id="logo" type="file" style={{ display: "none" }} onChange={(event) => this.onFileChange(event)} width="100%" height="auto" />
                                                                    </span>
                                                                </div>
                                                                {errors.file.length > 0 && <span style={{ color: 'red' }}>{errors.file}</span>}
                                                            </>
                                                        }
                                                    </>
                                                    ||
                                                    <div className="profilecard">
                                                        <span>
                                                            <Image id="logo" src={blobService.createUrl(profileImage)} errorImg={Upload} alt="" width="100%" height="300px" />
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="projectInfo profileInfo">
                                            {(isEdit === true && (
                                                <>
                                                    <input
                                                        name="financialBrokerName"
                                                        type={this.state.type}
                                                        value={financialBrokerName}
                                                        autoFocus
                                                        placeholder="Name*"
                                                        onChange={this.handleChangeProfile}
                                                    />
                                                    {errors.financialBrokerName.length > 0 && (
                                                        <p style={{ color: 'red' }}>{errors.financialBrokerName}</p>
                                                    )}
                                                </>
                                            )) || (
                                                    <span>
                                                        <h4 className="profile-name">{financialBrokerName}</h4>
                                                    </span>
                                                )}
                                            <div style={{ marginTop: "15px" }}>
                                                <p className="city-name city-name-1">{city}</p>
                                            </div>
                                            <div>
                                                {isEdit ? (
                                                    <div className="aboutProject">
                                                        <textarea
                                                            id="about"
                                                            name="description"
                                                            className="px-3"
                                                            placeholder="About Me*"
                                                            rows={8}
                                                            cols={50}
                                                            onChange={this.handleChangeProfile}
                                                            value={description}
                                                            required
                                                        />
                                                        {errors.description.length > 0 && (
                                                            <span style={{ color: 'red' }}>{errors.description}</span>
                                                        )}
                                                    </div>

                                                ) : (
                                                    <p className="profile-description">
                                                        {description}
                                                    </p>
                                                )}
                                            </div>
                                            {isEdit === true &&
                                                <div className="socialmedia-section">
                                                    <br />
                                                    <div className="twitter-container social-icon-cntr">
                                                        <img src={Twitterwithround} alt="" />
                                                        <input
                                                            className="left-buffer"
                                                            name="twitterLink"
                                                            type={this.state.type}
                                                            value={twitterLink}
                                                            placeholder="Twitter Link"
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.twitterLink.length > 0 && (
                                                            <p style={{ color: 'red', marginLeft: '50px' }}>{errors.twitterLink}</p>
                                                        )}
                                                    </div>
                                                    <br />
                                                    <div className="facebook-container social-icon-cntr">
                                                        <img src={FBwithround} alt="" />
                                                        <input
                                                            className="left-buffer"
                                                            name="facebookLink"
                                                            type={this.state.type}
                                                            value={facebookLink}
                                                            placeholder="Facebook Link"
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.facebookLink.length > 0 && (
                                                            <p style={{ color: 'red', marginLeft: '50px' }}>{errors.facebookLink}</p>
                                                        )}
                                                    </div>
                                                    <br />
                                                    <div className="linkedin-container social-icon-cntr">
                                                        <img src={Linkedinwithround} alt="" />
                                                        <input
                                                            className="left-buffer"
                                                            name="instagramLink"
                                                            type={this.state.type}
                                                            value={instagramLink}
                                                            placeholder="LinkedIn Link"
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.instagramLink.length > 0 && (
                                                            <p style={{ color: 'red', marginLeft: '50px' }}>{errors.instagramLink}</p>
                                                        )}
                                                    </div>
                                                    <br />
                                                </div>
                                                ||
                                                <div>
                                                    {(twitterLink == null || twitterLink == "") ?
                                                        <a target="_blank" >
                                                            <img src={Twitterwithround} alt="" />
                                                        </a>
                                                        :
                                                        <a href={financialBroker.socialLinks.twitterLink} target="_blank" >
                                                            <img src={Twitterwithround} alt="" />
                                                        </a>
                                                    }
                                                    {(facebookLink == null || facebookLink == "") ?
                                                        <a target="_blank" className="left-buffer">
                                                            <img src={FBwithround} alt="" />
                                                        </a>
                                                        :
                                                        <a href={financialBroker.socialLinks.facebookLink} target="_blank" className="left-buffer">
                                                            <img src={FBwithround} alt="" />
                                                        </a>
                                                    }
                                                    {(instagramLink == null || instagramLink == "") ?
                                                        <a target="_blank" className="left-buffer">
                                                            <img src={Linkedinwithround} alt="" />
                                                        </a>
                                                        :
                                                        <a href={financialBroker.socialLinks.instagramLink} target="_blank" className="left-buffer">
                                                            <img src={Linkedinwithround} alt="" />
                                                        </a>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><br />
                        <div className="container profile-about">
                            <h4 className="title">About</h4>
                            <div className="row">
                                <div className="col-md-4" >
                                    <h6>Address</h6>
                                    {(isEdit && (
                                        <div>
                                            <div className="address" >
                                                <input
                                                    type="text"
                                                    name="adLine1"
                                                    className="px-3"
                                                    placeholder="Door No*"
                                                    onChange={this.handleChangeProfile}
                                                    value={adLine1}
                                                    required
                                                />
                                                {errors.adLine1.length > 0 && (
                                                    <p style={{ color: 'red', width: '56%' }}>{errors.adLine1}</p>
                                                )}
                                            </div>
                                            <br />
                                            <div className="adLine2" >
                                                <input
                                                    type="text"
                                                    name="adLine2"
                                                    className="px-3"
                                                    placeholder="Street*"
                                                    onChange={this.handleChangeProfile}
                                                    value={adLine2}
                                                    required
                                                />
                                                {errors.adLine2.length > 0 && (
                                                    <p style={{ color: 'red', width: '56%' }}>{errors.adLine2}</p>
                                                )}
                                            </div>
                                            <br />
                                            <div>
                                                <Autocomplete
                                                        id="combo-box-demo"
                                                        onChange={(event, newValue) => {
                                                            this.selectCity(newValue);
                                                        }}
                                                        style = {{width : "68%"}}
                                                        options={cityList}
                                                        getOptionLabel={(option:any) => option}
                                                        value = {city}
                                                        renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" name = "city"/>}
                                                    />
                                            </div>
                                            <br />
                                            <div className="state" style={{ display: 'flex' }}>
                                                    <select name="state" id="state" className="py-1" onChange={this.handleChange} value={state} required>
                                                        <option value="">State</option>
                                                        {stateList.map((item: any) => (
                                                            <option value={item.name} key={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                           <br/>
                                            <div className="py-1 ">
                                                <input type = "number" name="pincode" id="pincode"  className="px-3" onChange={this.handleChangeProfile} value={pincode} placeholder = "Pincode*" required  onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() } ></input>
                                                {errors.pincode.length > 0 && (
                                                    <p style={{ color: 'red'}}>{errors.pincode}</p>
                                                )}
                                            </div>
                                            <br />
                                        </div>
                                    )) || (
                                            <div>
                                                <div className="profile-description">{adLine1 ? address : ' '}</div>
                                            </div>
                                        )}{' '}
                                </div>
                                <div className=" col-md-4">

                                    <h6>Email</h6>
                                    <div className="email-section">
                                        <p className="profile-description">{email ? email : " "}</p>
                                        <br/>
                                    <h6>Alternate Email</h6>
                                    {(isEdit && (
                                            <div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="alternateEmail"
                                                        className="px-3"
                                                        placeholder="Alternate Email"
                                                        value={alternateEmail}
                                                        onChange={this.handleChangeProfile}
                                                        required
                                                    />
                                                    {errors.alternateEmail.length > 0 &&  <p style={{color: "red"}}>{errors.alternateEmail}</p>}
                                                </div>
                                            </div>
                                        )) || (
                                            <div>
                                                <div className="profile-description">{alternateEmail ? alternateEmail :" "}</div>
                                            </div>
                                        )}{' '}

                                    <br/>
                                    <h6>Service Location*</h6>
                                    {(isEdit && (
                                            <div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="serviceLocation"
                                                        className="px-3"
                                                        placeholder="Service Location"
                                                        value={serviceLocation}
                                                        onChange={this.handleChangeProfile}
                                                        required
                                                    />
                                                    {errors.serviceLocation.length > 0 &&  <p style={{color: "red"}}>{errors.serviceLocation}</p>}
                                                </div>
                                            </div>
                                        )) || (
                                            <div>
                                                <div className="profile-description">{serviceLocation? serviceLocation : "No Service Location" }</div>
                                            </div>
                                        )}{' '}
                                    </div>


                                </div>
                                <div className="col-md-4">
                                    <h6>Contact*</h6>
                                    {(isEdit && (
                                        <div>
                                            <div className="phoneNumber">
                                                <input
                                                    type="number"
                                                    name="mobile"
                                                    className="px-3"
                                                    placeholder="Phone"
                                                    value={mobile}
                                                    onChange={this.handleChangeProfile}
                                                    maxLength={10}
                                                    onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                                />
                                                {errors.mobile.length > 0 && (
                                                    <p style={{ color: 'red' }}>{errors.mobile}</p>
                                                )}
                                            </div>
                                        </div>
                                    )) || (
                                            <div>
                                                <div className="profile-description">{mobile ? mobile : " "}</div>
                                            </div>
                                        )}{' '}
                                        <br />
                                        <h6>Alternate Contact*</h6>
                                        {(isEdit && (
                                            <div>
                                                <div className="phoneNumber">
                                                    <input
                                                        type="number"
                                                        name="alternateMobile"
                                                        className="px-3"
                                                        placeholder="Phone"
                                                        value={alternateMobile}
                                                        onChange={this.handleChangeProfile}
                                                        required
                                                        maxLength = {10}
                                                        onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                                    />
                                                    {errors.alternateMobile.length > 0 && (
                                                        <p style={{ color: 'red' }}>{errors.alternateMobile}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )) || (
                                            <div>
                                                <div className="profile-description">{alternateMobile ? alternateMobile :" "}</div>
                                            </div>
                                        )}{' '}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mt-5">
                                    <h6>Banks Managed</h6>
                                </div>
                            </div>
                            <div className="row">
                                    { isEdit ? addBank : " "}
                                    {listBanks}
                                    {userSelectBank.length === 0 && !isEdit ? <div>There Is No Service Bank Available As Of Now</div> : ""}
                            </div>
                            
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div>
                            <div className="container profile-about">
                                <h4 className="title">Account Preferences</h4>
                                <h6>Newsletters</h6>

                                <ToggleSwitch
                                    id="newsletter"
                                    checked={newsletter}
                                    onChange={(event: any) => this.onNewsletterChange(event)}
                                />

                                <div className="my-4 password-section">
                                    <form>
                                        <div className="password py-1">
                                            <h6>Change Password</h6>
                                            <input
                                                className="mt-2 password-input "
                                                type="password"
                                                name="oldPassword"
                                                placeholder="Old Password"
                                                onChange={this.handleChange}
                                                required
                                                value={oldPassword}
                                            />
                                            <br />
                                            {errors.oldPassword.length > 0 && (
                                                <span style={{ color: 'red' }}>{errors.oldPassword}</span>
                                            )}
                                        </div>

                                        <div className="password py-1">
                                            <input
                                                className="password-input"
                                                type="password"
                                                name="newPassword"
                                                placeholder="New Password"
                                                onChange={this.handleChange}
                                                required
                                                value={newPassword}
                                                onFocus = {()=>{errors.newPassword = true}}
                                                onBlur = {()=>{errors.newPassword = false}}
                                            />
                                            <br />
                                            {(errors.newPassword == true)?
                                                <div className='error-message mt-2 border p-3 '>
                                                    <p className='invalid'><img style = {{marginRight: "10px"}} src= {passwordLock}/><b>Your password needs to:</b></p>
                                                    <div className='p-1'>   
                                                        <p  className= {(errors.newupperCase == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newupperCase == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Uppercase
                                                        </p>
                                                        <p  className= {(errors.newlowerCase == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newlowerCase == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Lowercase
                                                        </p>
                                                        <p  className= {(errors.newdigit == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newdigit == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one digit
                                                        </p>
                                                        <p  className= {(errors.newspecialChar == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newspecialChar == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Special Characters
                                                        </p>
                                                        <p  className= {(errors.newminLength == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newminLength == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least minimum 8 characters
                                                        </p>
                                                    </div>
                                             </div>
                                             :
                                             ""
                                            }
                                        </div>

                                        <div className="password py-1">
                                            <input
                                                className="password-input"
                                                type="password"
                                                name="confirmNewPassword"
                                                placeholder=" Confirm Password"
                                                onChange={this.handleChange}
                                                required
                                                value={confirmNewPassword}
                                            />
                                            <br />
                                            {errors.confirmNewPassword.length > 0 && (
                                                <span style={{ color: 'red' }}>{errors.confirmNewPassword}</span>
                                            )}
                                        </div>
                                        <br />
                                        <button className= {isEnablePassword ? "change-password-button-disable": "change-password-button"} disabled = {isEnablePassword} onClick={(event: any) => this.changePassword(event)}>Change Password</button>
                                        <br />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs><br /><br />
                <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any) => this.onHideUpload(event)} />
                <Modal visible={this.state.addBankModel} width="400" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                    <div style={{ height: '400px', width:'330px' ,alignItems:'center' }}>
                        <h3 className='dropdown-heading'>Select Bank</h3>
                        <Multiselect
                            className='multiselect'
                            options={allBank}
                            displayValue='bankName'
                            showCheckbox={true}
                            placeholder='Select Bank'
                            disablePreSelectedValues={false}
                            emptyRecordMsg='No Bank Is Available'
                            onSelect={((selectedItem) => { this.setState({ selectedItem : selectedItem }) }) }
                            onRemove={((removedItem) => {addBanks.pop(removedItem)} )}
                        />
                    </div>
                    <div className="row justify-content-center mb-4">
                        <div className="col-4">
                        <Button variant="contained" color="primary" onClick={this.addBankButton}>
                            Add Bank
                        </Button>
                        </div>
                        <div className="col-4">
                        <Button variant="contained" color="secondary" onClick={() => this.closeModal()}>
                            cancel
                        </Button>
                        </div>
                    </div>
                </Modal>
                <Footer />
            </>
        );
    }
}
