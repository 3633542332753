import React from 'react';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';

class TermsConditions extends React.Component {
    constructor(props: any){
        super(props);
    }

    render(){
        return(
            <>
             <Header />
            <div className="bg-img">
                        <div className="overlay">
                            <div className="container">
                                <div className="col-md-6 col-12">
                                    <h1 style={{fontWeight:600}}>Terms & Conditions</h1>
                                </div>
                            </div>
                        </div>
            </div>
            <div className = "container p-5" >
                <h4 >Terms and Conditions for Aashray Homes</h4>
                <h4>Your Account</h4>
                <p >
                    You will be at risk to keep up the secrecy of your record which is made by AASHRAY HOMES and 
                    you should take all the potential measures to protect your accreditations.
                </p>
                <h4 >The Privacy</h4>
                <p >
                    At the point when you decide to utilize our administrations, 
                    you consent to the mix of both our terms and conditions and protection arrangements 
                    which are encircled by AASHRAY HOMES under the law and locale.
                </p>
                <h4 >Openness of AASHRAY HOMES</h4>
                <p >
                    It may happen that because of some upkeep going on the site, the openness of AASHRAY HOMES can be obstructed yet we will do 
                    our absolute best to convey you with an issue free encounter of being on our site.
                </p>
                <h4 >Your Conduct</h4>
                <p >
                   Any abuse of the data present on the site, any altering, pirating, or copyright 
                   infringement is carefully restricted and won't be engaged under any conditions.
                </p>
                <h4 >Shocking conditions and claims</h4>
                <p >
                    With extravagant merchants, vendors, and clients, it turns into an extreme errand to 
                    monitor every one of the substances being shown, so in the event that you go over any shocking 
                    substance on the site, we demand you to report it to us at the earliest opportunity.
                </p>
                <h4 >Youngsters' Policy</h4>
                <p >
                   On the off chance that you are a minor or under 18 years old, at that point you are disallowed from utilizing this site. 
                   By utilizing our administrations, you concur that you are over 18 years old and any fabrication in this data will prompt the end of your record
                </p>
                <h4 >Changes</h4>
                <p >
                    AASHRAY HOMES holds all the rights to change/adjust/modify any of the terms and conditions with no earlier data.
                </p>
                <h4 >Contact Us</h4>
                <p >If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at <a href="mailto:info@ashrayhome.com">info@ashrayhome.com</a>.</p>
            </div>
            <Footer />
    
            </>
        )
    }

}
export default TermsConditions;

