import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import './Builders.scss';
import Rating from '@material-ui/lab/Rating';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import Loader from '../../../common-components/Loader';
import { blobService } from '../../../common-components/Blob';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import SearchIcon from '../../../../assets/images/search.svg';
import axios from 'axios';
import Buildershape from '../../../../assets/images/Buildershape.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BuilderBanner from "../../../../assets/images/BuilderBanner.svg";
import BuilderBannerContent from "../../../../assets/images/BuilderBannerContent.png";
import Vector from  "../../../../assets/images/Vector.svg";

interface BuildersProps {
    name?: any;
    value?: any;
}
interface BuildersState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    builders: any,
    searchTxt: string,
    errors: {},
    locationList: any,
    searchLocationText: string,
    stateCode: any,
}

export default class Builders extends React.Component<BuildersProps, BuildersState> {
    constructor(props: BuildersProps) {
        super(props);
        const initialState = {
            isLoading: true,
            builders:[],
            searchTxt: '',
            errors: {},
            error:false,
            ErrMsg:"",
            locationList: [],
            searchLocationText: '',
            stateCode: [],
            
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.init();
    }
    init(){
        this.fetchBuilders();
        this.fetchStateList();
        this.fetchCityList();
    }
    
    async fetchBuilders(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
            const key = constants.subscriptionKey;
            const url =  constants.getBuilderListAPI + "?cityName=&stateName=" ;
            axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response: any) => {
                this.setState({ builders: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    }
    searchBuilder(event:any){
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
            let value = this.state.searchTxt? this.state.searchTxt : "";
            const key = constants.subscriptionKey;
            const url =  constants.searchBuilderAPI+"?customQuery="+ value;
            axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                this.setState({ builders: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch(name){
            case "searchTxt":
                if(value == "") { this.fetchBuilders() } ;
            default:
            break;    

        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    };

    // viewBuilder = (id: string) => {
    //     this.props.history.push('/ViewProject')
    // }

    async fetchStateList(): Promise<any> {
        const key = constants.subscriptionKey;           
        const url =  constants.fetchStatesListAPI;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    let stateList = response.data;
                    stateList.map((item:any)=>{
                        this.state.locationList.push(item.name);
                        this.state.stateCode.push(item.name);
                    })
                    
                    this.setState({
                        locationList: this.state.locationList,
                        stateCode: this.state.stateCode,
    
                    });
                }
                   
            })
            .catch((error) => {
            });
      
    }
    async fetchCityList(): Promise<any> {
        const key = constants.subscriptionKey;           
        const url =  constants.fetchAllCity;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                let cityList = response.data.list;
                cityList.map((item:any)=>{
                    this.state.locationList.push(item);
                })
                this.setState({locationList: this.state.locationList});
            }
                
            })
            .catch((error) => {
            });
      
    }
    searchBuilderByLocation = (value:any)=>{
        let selectedValue;
        if(value){
            selectedValue = value
        }else{
            selectedValue = ""
        }
        let statelist = this.state.stateCode;
        const isFound = statelist.includes(selectedValue);
        let url;
        if(isFound == true){
            url =  constants.getBuilderListAPI + "?cityName=&stateName="+ selectedValue.replace('&', '%26');
        }else{
            url = constants.getBuilderListAPI + "?cityName=" + selectedValue+"&stateName=";
        }
        const key = constants.subscriptionKey;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    this.setState({ builders: response.data, isLoading: false });
                }
                
            })
            .catch((error) => {
            });
    }

    clearSearchField = ()=>{
        this.setState({
            searchTxt: ''
        })
        this.fetchBuilders();
    }

    render() {
        const { errors, isLoading, builders, error, ErrMsg , locationList, searchLocationText, searchTxt} = this.state;
      
        
        const listItems = builders.map(
            (item:any) => (
                <li className="col-md-4">
                    <div className="buildercard">
                    {/* <div className="buildercard" onClick={(event:any)=>this.viewBuilder(item.id)}> */}
                        <Link to={{ pathname: "/ViewBuilder", state:{id: item.userId}}}>
                            { item.logoPath && 
                                <img src={blobService.createUrl(item.logoPath)} alt="" width="100%" height="200px" />
                            ||
                                <img src={ProjectPlaceholder} alt=""  width="100%" height="200px"/>
                            }
                        </Link>
                    </div>
                    <div className="buildername">{item.builderName}</div>
                    <div className="location">{item.address ? item.address.city : ""}</div>
                    {/* <Rating name="read-only" value={item.rating} readOnly className="rating" /> */}
                </li>
            )
        );
        return isLoading ? (<Loader/>): 
        (
            <>
                <Header />
                {/* <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}}>Builders</h1>
                            </div>   
                            <div  className="col-md-6 col-12">
                                <div className="input-cntr">
                                    <input 
                                        type="text" 
                                        placeholder="Search" 
                                        value={searchTxt} 
                                        name="searchTxt" 
                                        onChange={this.handleChange} 
                                        className="px-3 py-2 search-bar search-icon"
                                        onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                              this.searchBuilder(event)
                                            }
                                          }}
                                    />
                                    {(searchTxt.length)>0 ?
                                            <img className = "close-search" onClick={this.clearSearchField} src = {Vector}/>
                                            :
                                            ""
                                        }
                                    
                                </div>
                            </div>          
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="Builders">
                    <div className="">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-12 mt-5 bannerContent">
                                    <img className='w-100' src = {BuilderBannerContent} />
                                    <Link to = "/builderContentPage">
                                        <button className='px-4 py-2 mt-5'>
                                            Why Hire our builders?
                                        </button>
                                    </Link>
                                </div>   
                                <div  className="col-md-6 col-12 mt-5 d-flex justify-content-center">
                                    <img className='w-75' src = {BuilderBanner} />
                                </div>          
                            </div>
                        </div>
                    </div>  
                </div>
               

                <div className="buildersBlock py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <h4>Filters</h4>
                                <h5 className="my-3">Sort by Location</h5>
                                <div className="search-bar-section">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        onChange={(event, newValue) => {
                                            this.searchBuilderByLocation(newValue);
                                        }}
                                        style = {{width : "100%"}}
                                        options={locationList}
                                        getOptionLabel={(option:any) => option}
                                        renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" name = "searchLocationText" />}
                                        />
                                   
                                    {/* <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#filterItems">...</button> */}
                                </div>
                                {/* <h5 className="mt-5">Sort by Rating</h5> */}
                                <ul className="RatingList">
                                    {/* <li>
                                        <Rating name="read-only" value={5} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={4} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={3} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={2} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={1} readOnly className="rating" /> & Up
                                    </li> */}
                                </ul>
                            </div>
                            <div className="offset-md-1 col-md-8">
                                <div className="input-cntr mb-3">
                                            <input 
                                                type="text" 
                                                placeholder="Search" 
                                                value={searchTxt} 
                                                name="searchTxt" 
                                                onChange={this.handleChange} 
                                                className="px-3 py-2 search-bar search-icon"
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter') {
                                                    this.searchBuilder(event)
                                                    }
                                                }}
                                            />
                                            {(searchTxt.length)>0 ?
                                                    <img className = "close-search" onClick={this.clearSearchField} src = {Vector}/>
                                                    :
                                                    ""
                                                }
                                            
                                </div>
                                {builders.length > 0 &&  

                                <ul className="builderscard row">
                                        
                                    {listItems}
                                </ul> 
                                
                                || 
                                 <div className="buildersBlock py-5 empty-Message-Container">
                                    <div className="container-fluid empty-Message-Content">
                                        <img src={Buildershape} alt="" />
                                        <p className="text-center my-3">There are no builders on the platform yet. We will soon have some for you.</p>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
