import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import { Link } from 'react-router-dom';
import RightArrow from '../../../../assets/images/rightarrow1.svg';
import OurMissionHome from '../../../../assets/images/ourMissionImage.svg';
import Footer from '../../../common-components/Footer/Footer';
import './OurMission.scss';


interface OurMissionProps {
    name?: any;
    value?: any;
}
interface OurMissionState {
    errors: {};
    activeIndex: string;
}

export default class OurMission extends React.Component<OurMissionProps, OurMissionState> {
    constructor(props: OurMissionProps) {
        super(props);
        const initialState = {
            errors: {},
            activeIndex: '',
        };
        this.state = initialState;
    }
    componentDidMount() {
        let activeIndex = localStorage.setItem("tab",'0');
        this.setState({
			activeIndex: '0'
		});
        window.scrollTo(0, 0);
      }
    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 offset-md-2 col-12">
                                <h1  className="ourMissionHead">Our Mission</h1>
                            </div>             
                            </div>
                        </div>
                    </div>
                </div>
            <br/><br/>
            <div className='container'>
					<div className='row my-4'>
						<div className='col-md-7 ourmission px-5'>
							<h1>"We will help you build </h1>
                        	<h1>your dream house from your couch."</h1>
							<div className="ourmission-para">We at Aashray Homes believe in Quality. At every stage of our 
                                relationship, we strive to offer personalization and unambiguous
                                 engagement.We envision to create lasting bonds and help customers 
                                 make the most on their investment.</div>
							<Link className='link-text' to='/AboutCompany'>
								<button className='ourMissinBtn my-3'>
									About Us<img src={RightArrow} className='ml-3 learnmore' />
								</button>
							</Link>
						</div>
						<div className='col-md-4 ourMission'>
							<img src={OurMissionHome} alt='' width='100%' height='100%' />
						</div>
					</div>
				</div>
            <Footer/>
            </>
         );
    }
}
