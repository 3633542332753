const { BlobServiceClient } = require('@azure/storage-blob');
const BLOB_URL = process.env.REACT_APP_BLOB_URL;
const BLOB_CONTAINER_NAME = process.env.REACT_APP_BLOB_CONTAINER_NAME;
const BLOB_SAS = process.env.REACT_APP_BLOB_SAS;

export const blobService = {
    async fetch(path: string): Promise<any> {
        const blobServiceClient = new BlobServiceClient(`${BLOB_URL}${BLOB_SAS}`);
        const containerClient = blobServiceClient.getContainerClient(`${BLOB_CONTAINER_NAME}`);
        const blobClient = containerClient.getBlobClient(path);
        const downloadBlockBlobResponse = await blobClient.download();
        const downloaded = await blobToBase64(await downloadBlockBlobResponse.blobBody);
        return downloaded;
    },

    createUrl(path: string){
        return  path ? `${BLOB_URL}/${BLOB_CONTAINER_NAME}/`+path+`${BLOB_SAS}`:"404";
    }
};

const blobToBase64 = (blobData: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blobData);
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};
