import React from 'react';
import Modal from "react-bootstrap/Modal";

class Error extends React.Component {
  render() {
    return <div>
    <Modal show={false}>
        <Modal.Header>
        <Modal.Title className="text-center">Error Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>Show Error message</Modal.Body>
        <Modal.Footer>
        <button className="ok-button" > 
            OK
        </button>
        </Modal.Footer>
    </Modal>
</div>
   
    }
}

export default Error;