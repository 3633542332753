import React, { Component, Fragment } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import './FinancialBrokers.scss';
import Rating from '@material-ui/lab/Rating';
import { constants } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import Loader from '../../../common-components/Loader';
import { blobService } from '../../../common-components/Blob';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import SearchIcon from '../../../../assets/images/search.svg';
import axios from 'axios';
import Buildershape from '../../../../assets/images/Buildershape.svg';
import Modal from "react-bootstrap/Modal";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Vector from  "../../../../assets/images/Vector.svg";
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import asterisk from '../../../../assets/images/asterisk.svg';

interface FinancialBrokersProps {
    name?: any;
    value?: any;
}
interface FinancialBrokersState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    builders: any,
    searchTxt: string,
    locationList: any,
    searchLocationText: string,
    stateCode: any,
    name: string;
    emailId: string;
    contactNumber: string;
    preferredBank: string;
    propertyLocation: string;
    checked: boolean;
    showApplyLoanModal: boolean;
    onboardingChecked: boolean;
    errors: {
        email: string;
        contactNumber: string;
        terms: string;
    };
    bankList: any;
    selectedBank: string;
    banks: any;
    showUpload: boolean,
    header: string,
    body: string,
    isSubmitted: boolean,
}

const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
const RegexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class FinancialBrokers extends React.Component<FinancialBrokersProps, FinancialBrokersState> {
    constructor(props: FinancialBrokersProps) {
        super(props);
        const initialState = {
            isLoading: true,
            builders:[],
            searchTxt: '',
            error:false,
            ErrMsg:"",
            locationList: [],
            searchLocationText: '',
            stateCode: [],
            showApplyLoanModal: false,
            name: '',
            emailId: '',
            contactNumber: '',
            preferredBank: '',
            propertyLocation: '',
            checked: false,
            onboardingChecked: false,
            errors: {
                email: "",
                contactNumber: "",
                terms: '',
            },
            bankList: [],
            selectedBank: '',
            banks: [],
            showUpload: false,
            header: '',
            body: '',
            isSubmitted: false,
            
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.init();
    }
    init(){
        this.fetchFinancialBroker();
        this.fetchBanks();
    }
    
    async fetchFinancialBroker(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
            const key = constants.subscriptionKey;
            const url =  constants.getFinancialBrokerListAPI;
            axios.get(url ,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key}})
            .then((response: any) => {
                this.setState({ builders: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    }

    openApplyLoanModal = ()=>{
        this.setState({ showApplyLoanModal: true });
    }

    hideApplyLoanModal = ()=>{
        this.clearFields();
        this.setState({ showApplyLoanModal: false });
    }

    clearFields = ()=>{
        let errors = this.state.errors;
        errors.terms = '';
        errors.contactNumber = '';
        errors.email = '';
        this.setState({
            contactNumber: "",
            emailId: "",
            name: "",
            preferredBank: "",
            propertyLocation: "",
            checked: false,
            onboardingChecked: false,
            isSubmitted: false,
            selectedBank: ""
        })
    }

    async fetchBanks (){
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        const key = constants.subscriptionKey;
        const url = constants.fetchBankAPI;
        axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key}})
            .then((response) => {
                if(response){
                    let bankList = response.data;
                    bankList.map((item:any)=>{
                        this.state.bankList.push(item.bankName);
                    })
                    this.setState({
                        bankList: this.state.bankList,
                        banks: bankList
                    });
                }
            })
    }
    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
       
        switch (name) {
            case 'emailId':
                errors.email = RegexEmail.test(value) ? '' : 'Please Enter Valid Email';
                break;
            case 'contactNumber':
                errors.contactNumber = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
                break;
            default:
                break;
            }
        this.setState(Object.assign(this.state, {  [name]: value }));
        const { contactNumber,emailId,propertyLocation, selectedBank } = this.state;
        if(localStorage.getItem("id")){
            if ( propertyLocation != "") {
                this.setState({
                    isSubmitted: true,
                     
                })
               
            }else{
                this.setState({
                    isSubmitted: false,
                     
                })
            }
 
        }else{
            if ( this.state.name != "" && contactNumber != "" && emailId != ""&& propertyLocation != "") {
                this.setState({
                    isSubmitted: true,
                     
                })
               
            }else{
                this.setState({
                    isSubmitted: false,
                     
                })
            }
        } 
    };

    handleCheckBox = () => {
        this.setState({ 
            checked: !this.state.checked,
         });
    };

    handleOnboardingCheckBox = () => {
        this.setState({ 
            onboardingChecked: !this.state.onboardingChecked,

         });
    };

    searchBanks = (value:any)=>{      
        if(value){
            this.setState({
                selectedBank: value,
               
            })
        }else{
            this.setState({
                selectedBank: ''
            })
        }

    }

    submitLoanDetails = ()=>{
        const {name, contactNumber,emailId,preferredBank,propertyLocation, selectedBank, onboardingChecked } = this.state;
        let userId = localStorage.getItem("id");
        this.state.errors.terms = !this.state.checked ? 'Please click on the check box' : '';
        this.setState({ errors: this.state.errors });
        let validity = true;
        Object.values(this.state.errors).forEach((val) => val.length > 0 && (validity = false));
        if (validity == true ) {
           
           
            let bankId; 
            this.state.banks.map((item:any)=>{ 
                if(item.bankName == selectedBank){
                    bankId= item.id
                }
                
            }) 
            let data = {
                "name" : name,
                "phoneNumber": contactNumber,
                "emailId": emailId,
                "banks": {
                    "id": bankId, 
                    "bankName": "",
                    "bankLogoPath": ""
                },
                "propertyLocation": propertyLocation,
                "userId": userId,
                "register": onboardingChecked,
                "financeBrokerUserId": ''
            }
            const user = JSON.parse(localStorage.getItem('user')!);
            let token;
            if (user) {
                token = 'Bearer ' + user;
    
            }
            const key = constants.subscriptionKey;
            const url = constants.applyForLoan;
            axios.post(url,data, { headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key}})
                .then((response) => {
                    if(response){
                        this.hideApplyLoanModal();
                        this.openUpload("Success", "Information Sent SuccessFully to the Broker");
                    }
                })
                .catch((error)=>{
                        this.hideApplyLoanModal();
                        this.openUpload("Failed", " Failed to sent Information to the Broker");
                })
        } else {
            
        }
    
    }



    openUpload = async (header: any, body: any) => {
        this.setState({ showUpload: true, header: header, body: body });
    }
    onHideUpload = async (event: any) => {
        this.clearFields();
        this.setState({ showUpload: false });
    }

    render() {
        const { 
            errors, 
            isLoading, 
            builders, 
            error, 
            ErrMsg , 
            locationList, 
            searchLocationText, 
            showApplyLoanModal,
            name,
            emailId,
            contactNumber,
            preferredBank,
            propertyLocation,
            checked,
            onboardingChecked,
            bankList,
            showUpload,
            header,
            body,
            isSubmitted,
            selectedBank
        } = this.state;
      
        let isEnabled = true;
        if(isSubmitted == true && selectedBank != ""){
            isEnabled = false
        };
        const listItems = builders.map(
            (item:any) => (
                <li className="col-md-3">
                    <div className="buildercard">
                    {/* <div className="buildercard" onClick={(event:any)=>this.viewBuilder(item.id)}> */}
                        <Link to={{ pathname: "/ViewFinancialBroker", state:{id: item.userId}}}>
                            { item.profileImage && 
                                <img src={blobService.createUrl(item.profileImage)} alt="" width="100%" height="200px" />
                            ||
                                <img src={ProjectPlaceholder} alt=""  width="100%" height="200px"/>
                            }
                        </Link>
                    </div>
                    <div className="buildername">{item.financeBrokersName}</div>
                    <div className="location">{item.address ? item.address.city : ""}</div>
                    {/* <Rating name="read-only" value={item.rating} readOnly className="rating" /> */}
                </li>
            )
        );
        return isLoading ? (<Loader/>): 
        (
            <>
                <Header />
                <div className="FinanceBroker d-block w-100">
                    <div className="bannerButtons">
                        <Link to = "/brokerContentPage">
                            <button className='py-2 mr-3'>Why Hire our Finance Brokers?</button>
                        </Link>
                            {( localStorage.userType == "FinanceBrokers") ?
                                ""
                                :
                                    <button
                                        className="py-2 "
                                        onClick={this.openApplyLoanModal} 
                                    >
                                        Apply For Loan
                                    </button>
                            }
                    </div>
                   
                </div>


               

                <div className="buildersBlock py-3">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-md-3">
                               
                            </div> */}
                            <div className=" col-md-12">
                                {builders.length > 0 &&  
                                    <ul className="builderscard row">{listItems}</ul> 
                                
                                || 
                                 <div className="buildersBlock py-5 empty-Message-Container">
                                    <div className="container-fluid empty-Message-Content">
                                        <img src={Buildershape} alt="" />
                                        <p className="text-center my-3">There are no FinancialBrokers on the platform yet. We will soon have some for you.</p>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any) => this.onHideUpload(event)} />
                <Modal show = {showApplyLoanModal} onHide = {this.hideApplyLoanModal}>
                    <Fragment>
                        <div className='container p-4 projectInfo apply-loan-modal'>
                            <div className='d-flex' style={{justifyContent:"space-between"}}>
                                <h3>Apply for Loan</h3>
                                <img src = {Vector}  onClick={this.hideApplyLoanModal}/>
                            </div>
                            <div className='mt-2 apply-loan-container'>
                                {(localStorage.getItem("id"))?
                                    <>
                                        <p>
                                            My Property Location 
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='text'
                                            name = "propertyLocation"
                                            value = {propertyLocation}
                                            placeholder='Location'
                                            className='w-80  px-3'
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <p>
                                            Preferred Bank 
                                            <img src= {asterisk}/>
                                        </p>
                                        <Autocomplete
                                                // multiple
                                                id="tags-outlined"
                                                onChange={(event, newValue) => {
                                                    this.searchBanks(newValue);
                                                }}
                                                // style = {{width : "100%"}}
                                                options={bankList}
                                                getOptionLabel={(option:any) => option}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Bank" variant="outlined" name = "preferredBank" />}
                                                />
                                                 <div className='mt-2 '>
                                                        <input
                                                            type= "checkbox"
                                                            onChange={this.handleCheckBox}
                                                            checked={checked}
                                                        />
                                                        <span className='left-buffer'/>
                                                        <span>The above information will be shared with the Broker.</span>
                                                </div>
                                                    {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                                                <button className = {isEnabled == true ? "disabled-button mt-2 hire" : "mt-2 hire"}   disabled = {isEnabled}    onClick={this.submitLoanDetails}>Submit</button>
                                    </>
                                    :
                                    <>
                                        <p>
                                            Name 
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='text'
                                            name = "name"
                                            value =  {name}
                                            className='w-80  px-3'
                                            placeholder='Name'
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <p>
                                            Email Id 
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='text'
                                            name = "emailId"
                                            value = {emailId}
                                            className='w-80  px-3'
                                            placeholder='Email Id'
                                            onChange={this.handleChange}
                                            required
                                        />
                                         {errors.email.length > 0 &&  <p style={{color: "red"}}>{errors.email}</p>}
                                        <p>
                                            Contact Number
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='number'
                                            name = "contactNumber"
                                            value = {contactNumber}
                                            className='w-80  px-3'
                                            placeholder='Contact Number'
                                            onChange={this.handleChange}
                                            required
                                            maxLength = {10}
                                            onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                        />
                                        {errors.contactNumber.length > 0 && (
                                                        <p style={{ color: 'red' }}>{errors.contactNumber}</p>
                                                    )}
                                        <p>
                                            My Property Location
                                            <img src= {asterisk}/>
                                        </p>
                                        <input
                                            type='text'
                                            name = "propertyLocation"
                                            value = {propertyLocation}
                                            placeholder='Location'
                                            className='w-80  px-3'
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <p>
                                            Preferred Bank
                                            <img src= {asterisk}/>
                                        </p>
                                        <Autocomplete
                                                // multiple
                                                id="tags-outlined"
                                                onChange={(event, newValue) => {
                                                    this.searchBanks(newValue);
                                                }}
                                                // style = {{width : "100%"}}
                                                options={bankList}
                                                getOptionLabel={(option:any) => option}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Bank" variant="outlined" name = "preferredBank" />}
                                                />
                                                <div className='mt-2 '>
                                                        <input
                                                            type= "checkbox"
                                                            onChange={this.handleCheckBox}
                                                            checked={checked}
                                                        />
                                                        <span className='left-buffer'/>
                                                        <span>The above information will be shared with the Broker.</span>
                                                </div>
                                                    {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                                                 <div className='mt-2 '>
                                                    <input
                                                        type= "checkbox"
                                                        onChange={this.handleOnboardingCheckBox}
                                                        checked={onboardingChecked}
                                                    />
                                                    <span className='left-buffer'/>
                                                    <span>Create account with above information</span>
                                                </div>
                                                <button className = {isEnabled == true ? "disabled-button mt-2 hire" : "mt-2 hire"}   disabled = {isEnabled}   onClick={this.submitLoanDetails}>Submit</button>
                                    </>
                                }
                                
                            </div>
                        </div>
                    </Fragment>
                    </Modal>
                <Footer />
            </>
        );
    }
}
