import React, { Component, useEffect } from 'react';
import customerServiceimg1 from '../../../../assets/images/customerServiceimg1.png';
import customerServiceimg2 from '../../../../assets/images/customerServiceimg2.png';
import customerServiceimg3 from '../../../../assets/images/customerServiceimg3.png';
import customerServiceimg4 from '../../../../assets/images/customerServiceimg4.png';
import customerServiceimg5 from '../../../../assets/images/customerServiceimg5.png';
import customerServiceimg6 from '../../../../assets/images/customerServiceimg6.png';
import customerServiceimg7 from '../../../../assets/images/customerServiceimg7.png';
import Rating from '@material-ui/lab/Rating';
interface customerServiceProps {

}
interface customerServiceState {

}


export default class CustomerService extends React.Component<customerServiceProps, customerServiceState> {
    constructor(props: any) {
        super(props);
        const initialState = {

        };
        this.state = initialState;
    }


    render() {
        return (
            <>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={customerServiceimg1} alt="" />
                    </div>
                    <div className="col-md-10 p-3 customer-service-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Customer Name: Jaggedhesh Varma</p>
                                <p className='headings'>Services Opted: </p>
                                <p>● Hire Builder/Construction Team</p>
                                <p>● Raw Materials Procurement</p>
                                <p className='headings'>Customer Requirements:</p>
                                <p>I'm in charge of our company's expansion project and need a reliable construction team and raw materials to bring our vision to life. Quality and timeliness are paramount. Aashray Homes has made our search for a construction team and raw materials effortless. Their services connected us with skilled builders and dependable suppliers who understand our project's unique needs. Highly recommended for anyone seeking construction services and raw materials procurement!</p>
                                <p className='headings'>Service Ratings</p>

                            </div>
                            <div className='w-50' >
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Hire Builder/Construction Team</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={4} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Raw Materials Procurement</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={5} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                            </div>

                        </div>

                    </div>
                </li>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={customerServiceimg2} alt="" />
                    </div>
                    <div className="col-md-10 p-3 customer-service-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Customer Name: Homeowner - Sana ali Khan</p>
                                <p className='headings'>Services Opted: </p>
                                <p>● Architectural Design</p>
                                <p className='headings'>Customer Requirements:</p>
                                <p>We're planning to build our dream home and need an architect to bring our vision to life. Creativity and attention to detail are crucial.Our experience with Aashray Homes has been outstanding. Their architectural design service connected us with a talented architect who understood our vision perfectly. Highly recommended for homeowners seeking architectural expertise!</p>
                                <p className='headings'>Service Ratings</p>

                            </div>
                            <div className='w-50'>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Architectural Design</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={5} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                            </div>

                        </div>

                    </div>
                </li>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={customerServiceimg3} alt="" />
                    </div>
                    <div className="col-md-10 p-3 customer-service-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Customer Name: Gourav Shah</p>
                                <p className='headings'>Services Opted: </p>
                                <p>● Financial Broker Services</p>
                                <p>● Real Estate Investment Consultation</p>
                                <p className='headings'>Customer Requirements:</p>
                                <p>I'm looking for a financial broker to help me make informed investment decisions in the real estate market.My experience with Aashray Homes has been exceptional. Their services connected me with knowledgeable experts who provided invaluable guidance. Highly recommended for property investors!</p>
                                <p className='headings'>Service Ratings</p>

                            </div>
                            <div className='w-50'>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Financial Broker Services</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={4} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Real Estate Investment Consultation</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={5} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                            </div>
                        </div>

                    </div>
                </li>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={customerServiceimg4} alt="" />
                    </div>
                    <div className="col-md-10 p-3 customer-service-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Customer Name: Raghav Naryan</p>
                                <p className='headings'>Services Opted: </p>
                                <p>● Hire Builder/Construction Team</p>
                                <p>● Architectural Design</p>
                                <p className='headings'>Customer Requirements:</p>
                                <p>I want to renovate my home and need the right builder and architect to achieve my renovation goals. Aashray Homes made the process of hiring a construction team and architect for my renovation project simple and efficient. Their services connected me with skilled professionals who transformed my home. Highly recommended for renovation enthusiasts!</p>
                                <p className='headings'>Service Ratings</p>

                            </div>
                            <div className='w-50'>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Hire Builder/Construction Team</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={5} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Architectural Design</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={4} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                            </div>
                        </div>

                    </div>
                </li>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={customerServiceimg5} alt="" />
                    </div>
                    <div className="col-md-10 p-3 customer-service-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Customer Name: Procurement Manager - James Wilson</p>
                                <p className='headings'>Services Opted: </p>
                                <p>● Raw Materials Procurement</p>
                                <p className='headings'>Customer Requirements:</p>
                                <p>We require a reliable supplier for raw materials to support our construction projects. Timeliness and quality are non-negotiable. Our partnership with Aashray Homes in sourcing raw materials has been exceptional. Their raw materials procurement service connected us with dependable suppliers who meet our strict quality standards. Highly recommended for raw material sourcing!</p>
                                <p className='headings'>Service Ratings</p>
                            </div>
                            <div className='w-50'>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Raw Materials Procurement</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={5} style={{ color: "green" }} readOnly className="rating" />
                                </div>

                            </div>
                        </div>

                    </div>
                </li>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={customerServiceimg6} alt="" />
                    </div>
                    <div className="col-md-10 p-3 customer-service-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Customer Name: Property Developer - Samantha Collins</p>
                                <p className='headings'>Services Opted: </p>
                                <p>● Architectural Design</p>
                                <p>● Real Estate Investment Consultation</p>
                                <p className='headings'>Customer Requirements:</p>
                                <p>We're planning a new residential development and need an architect for innovative designs and financial guidance.Our experience with Aashray Homes has been fantastic. Their services connected us with a visionary architect and a financial expert who exceeded our expectations. Highly recommended for property developers seeking innovative designs and investment advice!</p>
                                <p className='headings'>Service Ratings</p>

                            </div>
                            <div className='w-50'>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Architectural Design</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={4} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Real Estate Investment Consultation</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={5} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                            </div>
                        </div>

                    </div>
                </li>
                <li className="row no-gutters">
                    <div className="col-md-2">
                        <img src={customerServiceimg7} alt="" />
                    </div>
                    <div className="col-md-10 p-3 customer-service-container">
                        <div className='row d-flex p-3' style={{ justifyContent: "space-between" }}>
                            <div>
                                <p>Customer Name: Aadhinath Pillai</p>
                                <p className='headings'>Services Opted: </p>
                                <p>● Financial Broker Services</p>
                                <p className='headings'>Customer Requirements:</p>
                                <p>I'm an experienced real estate investor looking for expert financial guidance to optimize my investment portfolio. My experience with Aashray Homes has been outstanding. Their financial broker service connected me with a seasoned expert who provided tailored investment strategies. Highly recommended for real estate investors!</p>
                                <p className='headings'>Service Ratings</p>

                            </div>
                            <div className='w-50'>
                                <div className='service-rating d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <p>● Financial Broker Services</p>
                                    <p>Rating</p>
                                    <Rating name="read-only" value={4} style={{ color: "green" }} readOnly className="rating" />
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </li>
            </>
        );
    }
}


