import React from "react";
import './Header.scss'
import { Link, withRouter, useHistory } from 'react-router-dom';
import logo1 from '../../../assets/images/logo1.svg'
import CloseToggleBtn from '../../../assets/images/mobiletoggle.png'
import Fb from "../../../assets/images/facebook.svg"
import LinkedIn from "../../../assets/images/linkdin.svg"
import Twitter from "../../../assets/images/twitter.svg"
import Youtube from "../../../assets/images/youtube.svg"
import Modal from 'react-awesome-modal';
import LoginModel from '../Login/Login';
import RegisterModal from '../Register/Register';
import CustomModal from '../CustomModal/CustomModal';
import menuBar from '../../../assets/images/menubar.png';
import Headerdots from '../../../assets/images/Headerdots.svg';
import Downarrow from '../../../assets/images/Downarrow.svg';
import HeaderRightArrow from '../../../assets/images/HeaderRightArrow.svg';
import { constants } from '../../../constants/constants';
import axios from 'axios';
import SolidDownArrow from '../../../assets/images/SolidDownArrow.svg';
import BuilderIcon from '../../../assets/images/BuilderIcon.svg';
import ArchitectIcon from '../../../assets/images/ArchitectIcon.svg';
import FinanceBrokerIcon from '../../../assets/images/FinanceBrokerIcon.svg';
import TownPlannerIcon from '../../../assets/images/TownPlannerIcon.svg';

interface HeaderProps {
  name?: any;
  value?: any;
}
interface HeaderState {
  navActiveClass: any,
  navShow: string,
  toggle: boolean,
  loginModel: boolean,
  RegisterModel: boolean,
  isVisible: boolean,
  loginErr: string,
  checked: boolean,
  show: boolean,
  errors: {};
  userType: string,
  body: string,
  activeIndex: string,
  rawMaterialList: any
 
}

export default class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: any) {
    super(props);
    const initialState = {
      navActiveClass: [],
      navShow: "none",
      toggle: true,
      loginModel: false,
      RegisterModel: false,
      isVisible: false,
      loginErr: '',
      checked: false,
      show: false,
      userType: '',
      errors: {},
      body: '',
      activeIndex: '',
      rawMaterialList: []
    };
    this.state = initialState;
    this.closeModal = this.closeModal.bind(this);
  }

  signup = () => {
    this.setState({
      loginModel: false,
      RegisterModel: true
    })
  }


  login = (event: any) => {
    this.setState({
      loginModel: true,
      RegisterModel: false,
      show: false
    })
  }

  logout(event: any) {
    window.localStorage.clear();
    window.location.replace("/");
    window.sessionStorage.clear();
  }




  setActiveLink = (e: any) => {
    if (e === "login") {  
      this.setState({
        loginModel: true
      });
    }else{
      this.setState({
        RegisterModel: true,
      });
    }
  }

  togglebtn = () => {
    this.setState({
      toggle: !this.state.toggle,
      navShow: this.state.navShow === "none" ? "" : "none"
    })
  }

  closeLoginModal(event: any){   
    this.setState({
      loginModel: false,
      RegisterModel: false
    });
  }

  closeModal(event: any) {     
    this.setState({
      loginModel: event,
      RegisterModel: event
    });
    

  }

  closeRegisterModal = (event: any) => {
    this.setState({
      RegisterModel: false,
      loginModel: false
    });
  }

  success(event: any) {
    this.setState({ RegisterModel: false, loginModel: false, show: !this.state.show, body: event })
  }

  componentDidMount() {
    let userType = localStorage.getItem("userType");
    let activeIndex = localStorage.getItem("tab");
    this.setState({
      userType: userType || "",
      activeIndex: activeIndex || "0",
      
    })
    this.fetchRawMaterialList();
  }

  handleClick = (event: any) => {
    localStorage.setItem('tab', event.target.id);
    this.setState({ activeIndex: event.target.id })
  }

  fetchRawMaterialList = ()=>{
        let token;
        const user = JSON.parse(localStorage.getItem('user')!); 
        if(user){
                token = 'Bearer ' + user;
              
          }
       const key = constants.subscriptionKey;
       const url = constants.fetchRawMaterialList;
       axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                if(response){
                    this.setState({
                        rawMaterialList: response.data
                    })
                }
            })
            .catch((error)=>{

            });
    }

    redirectToRawMaterial = ()=>{
        window.location.replace("/RawMaterialSuppliers")
    }
 
  render() {
    const { isVisible, errors, loginErr, checked, show, userType, body, rawMaterialList } = this.state;

    let materialList = rawMaterialList.map((items:any)=>{
      return(  
        <>
          <li   className={this.state.activeIndex ==="4"?'active':""} id="4" onClick={(event) => this.handleClick(event)}>
            <Link onClick={this.redirectToRawMaterial}  to={{ pathname: "/RawMaterialSuppliers", state:{materialName: items.materialName}}} >
                {items.materialName}
            </Link>
          </li>
          <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
        </>  
      )
  })
    return (
      <div>
        <div className= "header py-4">
          <div className="container">
            <div className="row headerBox">
              <div className={"col-sm-6 col-md-2 col-2 d-flex align-items-center logoTitle"}>
                <Link to="/" id="0" onClick={(event) => this.handleClick(event)}>
                  <img src={logo1} />
                </Link>
              </div>

              <div className={"col-sm-6 offset-md-1 col-md-9 col-9 d-flex justify-content-end align-items-center mb-1"}>

                {userType === "" &&
                  <div className="d-none d-md-block">
                    <ul className="menu" >
                      <li> 
                          <Link to="/" className={this.state.activeIndex === "0" ? "active ml-4 mr-4" : "ml-4 mr-4"} id="0" onClick={(event) => this.handleClick(event)}>  Home </Link>
                      </li>
                      <li className="dropdown-wrapper">
                        <li className={this.state.activeIndex === "1" ? "active ml-3 mr-3" : " ml-3 mr-3"} id="1"  >
                            Hire Professionals
                            <img className = "ml-1" style={{margin: "0"}} src={SolidDownArrow} alt=""  />
                        </li>
                        <div  className="dropdown-container w-75">
                                <div className="dropdown-container-box container py-2 " >
                                  <div className="d-flex p-1 dropdown-Subcontainer">
                                    <Link to="/Builders">
                                      <p  className="d-flex">
                                        <img style={{width: "50%"}} src= {BuilderIcon}/>
                                        Builder
                                      </p>
                                    </Link>
                                    <Link to="/Architects">
                                        <p  className="d-flex">
                                        <img style={{width: "50%"}} src= {ArchitectIcon}/>
                                        Architect
                                        </p>
                                      </Link>
                                        <p  className="d-flex">
                                        <img style={{width: "12%"}} src= {TownPlannerIcon}/>
                                          <div className="dropdown-wrapper-supplier">
                                            <div>
                                              Raw Materials Supplier
                                              <img style={{margin: "0"}} src={Downarrow} alt=""  />
                                            </div>
                                            {(rawMaterialList.length !== 0)?
                                                    <div className="dropdown-container" style={{right: "25%"}}>
                                                      <div className=" dropdown-container-supplier ">
                                                        <div className=" dropdown-container-supplier-box container  py-3 scrollablematerial" >
                                                                {materialList}
                                                        </div>
                                                      </div>
                                                    </div>  
                                                      :
                                                      ""
                                            }
                                          </div>             
                                        </p>
                                      <Link to="/FinancialBrokers">
                                        <p className="d-flex">
                                        <img style={{width: "30%"}} src= {FinanceBrokerIcon}/>
                                          Financial Broker
                                        </p>
                                      </Link>
                                  </div>
                                </div>
                          </div>
                      </li>
                      <li>
                        <Link to="/FindProjects" className={this.state.activeIndex == "2" ? "active  ml-3 mr-4" : " ml-3 mr-4"} id="2" onClick={(event) => this.handleClick(event)}>
                          Projects
                        </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "3" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="3" onClick={(event) => this.handleClick(event)} to='/support'>
                            Support
                          </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "4" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="4" onClick={(event) => this.handleClick(event)} to='/partners'>
                            Partner
                          </Link>
                      </li>
                      <li>
                        <Link className={this.state.activeIndex == "5" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="5" onClick={(event) => this.handleClick(event)} to='/blog'>
                            Blog
                        </Link>
                      </li>
                      <li className="cursor ml-1 py-1 loginSignupPill" onClick={() => this.setActiveLink("login")}><a>Login   <span onClick={() => this.setActiveLink("")}>Signup</span> </a></li>
                    </ul>
                  </div>
                }
                {userType === "Customer" &&
                  <div className="d-none d-md-block">
                    <ul className="menu row ">
                      <li>
                        <Link to="/" className={this.state.activeIndex == "0" ? "active ml-4 mr-4" : "ml-4 mr-4"} id="0" onClick={(event) => this.handleClick(event)}>Home</Link>
                      </li>
                      <li className="dropdown-wrapper">
                        <li className={this.state.activeIndex === "1" ? "active ml-3 mr-3" : " ml-3 mr-3"} id="1"  >
                            Hire Professionals
                            <img className = "ml-1" style={{margin: "0"}} src={SolidDownArrow} alt=""  />
                        </li>
                        <div  className="dropdown-container w-75">
                                <div className="dropdown-container-box container py-2 " >
                                  <div className="d-flex p-2 dropdown-Subcontainer">
                                    <Link to="/Builders">
                                      <p  className="d-flex">
                                        <img style={{width: "60%"}} src= {BuilderIcon}/>
                                        Builder
                                      </p>
                                    </Link>
                                    <Link to="/Architects">
                                        <p  className="d-flex">
                                        <img style={{width: "50%"}} src= {ArchitectIcon}/>
                                        Architect
                                        </p>
                                      </Link>
                                        <p  className="d-flex">
                                        <img style={{width: "16%"}} src= {TownPlannerIcon}/>
                                          <div className="dropdown-wrapper-supplier">
                                            <div>
                                              Raw Materials Supplier
                                              <img style={{margin: "0"}} src={Downarrow} alt=""  />
                                            </div>
                                            {(rawMaterialList.length !== 0)?
                                                      <div className=" dropdown-container-supplier ">
                                                        <div className=" dropdown-container-supplier-box container  py-3 scrollablematerial" >
                                                                {materialList}
                                                        </div>
                                                      </div>
                                                      :
                                                      ""
                                            }
                                          </div>             
                                        </p>
                                      <Link to="/FinancialBrokers">
                                        <p className="d-flex">
                                        <img style={{width: "30%"}} src= {FinanceBrokerIcon}/>
                                          Financial Broker
                                        </p>
                                      </Link>
                                  </div>
                                </div>
                          </div>
                      </li>
                      <li>
                        <Link to="/FindProjects" className={this.state.activeIndex == "2" ? "active  ml-3 mr-4" : " ml-3 mr-4"} id="2" onClick={(event) => this.handleClick(event)}>
                          Projects
                        </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "3" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="3" onClick={(event) => this.handleClick(event)} to='/support'>
                            Support
                          </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "4" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="4" onClick={(event) => this.handleClick(event)} to='/partners'>
                            Partner
                          </Link>
                      </li>
                      <li>
                        <Link className={this.state.activeIndex == "5" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="5" onClick={(event) => this.handleClick(event)} to='/blog'>
                            Blog
                        </Link>
                      </li>
                      <li className="dropdown-profile-wrapper">
                            <li>
                                Profile <img style={{margin: "0"}} src={Downarrow} alt=""  />
                            </li>
                            <div  className=" dropdown-profile-container">
                                <div className="dropdown-profile-container-box container  py-3 " >
                                    <li>
                                        <Link to="/CustomerProfile" className={this.state.activeIndex == "6" ? "active" : ""} id="6" onClick={(event) => this.handleClick(event)}>My Profile </Link>
                                    </li>
                                    <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                    <li>
                                        <Link to="/MyProjects" className={this.state.activeIndex == "5" ? "active" : ""} id="5" onClick={(event) => this.handleClick(event)}>My Projects</Link>
                                    </li>
                                    <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                    <li>
                                        <Link to="" onClick={(event) => this.logout(event)}>Logout</Link>
                                    </li>
                                </div>
                            </div>
                      </li>
                     
                    </ul>
                  </div>
                }
                {userType === "Builder" &&
                  <div className="d-none d-md-block">
                    <ul className="menu row ">
                      <li>
                        <Link to="/" className={this.state.activeIndex == "0" ? "active ml-4 mr-4" : "ml-4 mr-4"} id="0" onClick={(event) => this.handleClick(event)}>
                          Home</Link>
                      </li>
                      <li className="dropdown-wrapper">
                        <li className={this.state.activeIndex === "1" ? "active ml-3 mr-3" : " ml-3 mr-3"} id="1"  >
                            Hire Professionals
                            <img className = "ml-1" style={{margin: "0"}} src={SolidDownArrow} alt=""  />
                        </li>
                        <div  className="dropdown-container w-75">
                                <div className="dropdown-container-box container py-2 " >
                                  <div className="d-flex p-2 dropdown-Subcontainer">
                                    <Link to="/Builders">
                                      <p  className="d-flex">
                                        <img style={{width: "60%"}} src= {BuilderIcon}/>
                                        Builder
                                      </p>
                                    </Link>
                                    <Link to="/Architects">
                                        <p  className="d-flex">
                                        <img style={{width: "50%"}} src= {ArchitectIcon}/>
                                        Architect
                                        </p>
                                      </Link>
                                        <p  className="d-flex">
                                        <img style={{width: "16%"}} src= {TownPlannerIcon}/>
                                          <div className="dropdown-wrapper-supplier">
                                            <div>
                                              Raw Materials Supplier
                                              <img style={{margin: "0"}} src={Downarrow} alt=""  />
                                            </div>
                                            {(rawMaterialList.length !== 0)?
                                                      <div className=" dropdown-container-supplier ">
                                                        <div className=" dropdown-container-supplier-box container  py-3 scrollablematerial" >
                                                                {materialList}
                                                        </div>
                                                      </div>
                                                      :
                                                      ""
                                            }
                                          </div>             
                                        </p>
                                      <Link to="/FinancialBrokers">
                                        <p className="d-flex">
                                        <img style={{width: "30%"}} src= {FinanceBrokerIcon}/>
                                          Financial Broker
                                        </p>
                                      </Link>
                                  </div>
                                </div>
                          </div>
                      </li>
                      <li>
                        <Link to="/FindProjects" className={this.state.activeIndex == "2" ? "active  ml-3 mr-4" : " ml-3 mr-4"} id="2" onClick={(event) => this.handleClick(event)}>
                          Projects
                        </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "3" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="3" onClick={(event) => this.handleClick(event)} to='/support'>
                            Support
                          </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "4" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="4" onClick={(event) => this.handleClick(event)} to='/partners'>
                            Partner
                          </Link>
                      </li>
                      <li>
                        <Link className={this.state.activeIndex == "5" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="5" onClick={(event) => this.handleClick(event)} to='/blog'>
                            Blog
                        </Link>
                      </li>
                      <li className="dropdown-profile-wrapper">
                          <li>
                               Profile <img style={{margin: "0"}} src={Downarrow} alt=""  />
                          </li>
                          <div  className="dropdown-profile-container">
                                <div className="dropdown-profile-container-box container  py-3 " >
                                    <li>
                                        <Link to="/BuilderProfile" className={this.state.activeIndex == "9" ? "active" : ""} id="9" onClick={(event) => this.handleClick(event)}> My Profile</Link>
                                    </li>
                                    <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                    <li>
                                      <Link to="/MyBuilderProjects" className={this.state.activeIndex == "7" ? "active" : ""} id="7" onClick={(event) => this.handleClick(event)}>My Projects</Link>
                                    </li>
                                    <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                    <li>
                                      <Link to="/Portfolio" className={this.state.activeIndex == "8" ? "active" : ""} id="8" onClick={(event) => this.handleClick(event)}>Portfolio</Link>
                                    </li>
                                    <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                    <li>
                                      <Link to="" onClick={(event) => this.logout(event)}>Logout</Link>
                                    </li>
                                </div>
                          </div>
                      </li>         
                    </ul>
                  </div>
                }
                {userType === "Architect" &&
                  <div className="d-none d-md-block">
                    <ul className="menu row ">
                      <li>
                        <Link to="/" className={this.state.activeIndex == "0" ? "active ml-4 mr-4" : "ml-4 mr-4"} id="0" onClick={(event) => this.handleClick(event)}>
                          Home</Link>
                      </li>
                       <li className="dropdown-wrapper">
                        <li className={this.state.activeIndex === "1" ? "active ml-3 mr-3" : " ml-3 mr-3"} id="1"  >
                            Hire Professionals
                            <img className = "ml-1" style={{margin: "0"}} src={SolidDownArrow} alt=""  />
                        </li>
                        <div  className="dropdown-container w-75">
                                <div className="dropdown-container-box container py-2 " >
                                  <div className="d-flex p-2 dropdown-Subcontainer">
                                    <Link to="/Builders">
                                      <p  className="d-flex">
                                        <img style={{width: "60%"}} src= {BuilderIcon}/>
                                        Builder
                                      </p>
                                    </Link>
                                    <Link to="/Architects">
                                        <p  className="d-flex">
                                        <img style={{width: "50%"}} src= {ArchitectIcon}/>
                                        Architect
                                        </p>
                                      </Link>
                                        <p  className="d-flex">
                                        <img style={{width: "16%"}} src= {TownPlannerIcon}/>
                                          <div className="dropdown-wrapper-supplier">
                                            <div>
                                              Raw Materials Supplier
                                              <img style={{margin: "0"}} src={Downarrow} alt=""  />
                                            </div>
                                            {(rawMaterialList.length !== 0)?
                                                      <div className=" dropdown-container-supplier ">
                                                        <div className=" dropdown-container-supplier-box container  py-3 scrollablematerial" >
                                                                {materialList}
                                                        </div>
                                                      </div>
                                                      :
                                                      ""
                                            }
                                          </div>             
                                        </p>
                                      <Link to="/FinancialBrokers">
                                        <p className="d-flex">
                                        <img style={{width: "30%"}} src= {FinanceBrokerIcon}/>
                                          Financial Broker
                                        </p>
                                      </Link>
                                  </div>
                                </div>
                          </div>
                      </li>
                      <li>
                        <Link to="/FindProjects" className={this.state.activeIndex == "2" ? "active  ml-3 mr-4" : " ml-3 mr-4"} id="2" onClick={(event) => this.handleClick(event)}>
                          Projects
                        </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "3" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="3" onClick={(event) => this.handleClick(event)} to='/support'>
                            Support
                          </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "4" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="4" onClick={(event) => this.handleClick(event)} to='/partners'>
                            Partner
                          </Link>
                      </li>
                      <li>
                        <Link className={this.state.activeIndex == "5" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="5" onClick={(event) => this.handleClick(event)} to='/blog'>
                            Blog
                        </Link>
                      </li>
                      <li className="dropdown-profile-wrapper">
                            <li>
                                Profile <img style={{margin: "0"}} src={Downarrow} alt=""  />
                            </li>
                            <div  className=" dropdown-profile-container">
                                <div className=" dropdown-profile-container-box container  py-3 " >
                                    <li>
                                        <Link to="/ArchitectProfile" className={this.state.activeIndex == "11" ? "active" : ""} id="11" onClick={(event) => this.handleClick(event)}> My Profile </Link>
                                    </li>
                                    <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                    <li>
                                        <Link to="/MyArchitectProjects" className={this.state.activeIndex == "7" ? "active" : ""} id="7" onClick={(event) => this.handleClick(event)}>My Projects</Link>
                                    </li>
                                    <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                    <li>
                                        <Link to="/ArchitectPortfolio" className={this.state.activeIndex == "10" ? "active" : ""} id="10" onClick={(event) => this.handleClick(event)}>Portfolio</Link>
                                    </li>
                                    <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                    <li>
                                      <Link to="" onClick={(event) => this.logout(event)}>Logout</Link>
                                    </li>
                                </div>
                            </div>
                      </li>
                    </ul>
                  </div>
                }

                {userType === "Raw Material Supplier" &&
                  <div className="d-none d-md-block">
                    <ul className="menu row ">
                      <li>
                        <Link to="/" className={this.state.activeIndex == "0" ? "active ml-4 mr-4" : "ml-4 mr-4"} id="0" onClick={(event) => this.handleClick(event)}>
                          Home</Link>
                      </li>
                      <li className="dropdown-wrapper">
                        <li className={this.state.activeIndex === "1" ? "active ml-3 mr-3" : " ml-3 mr-3"} id="1"  >
                            Hire Professionals
                            <img className = "ml-1" style={{margin: "0"}} src={SolidDownArrow} alt=""  />
                        </li>
                        <div  className="dropdown-container w-75">
                                <div className="dropdown-container-box container py-2 " >
                                  <div className="d-flex p-2 dropdown-Subcontainer">
                                    <Link to="/Builders">
                                      <p  className="d-flex">
                                        <img style={{width: "60%"}} src= {BuilderIcon}/>
                                        Builder
                                      </p>
                                    </Link>
                                    <Link to="/Architects">
                                        <p  className="d-flex">
                                        <img style={{width: "50%"}} src= {ArchitectIcon}/>
                                        Architect
                                        </p>
                                      </Link>
                                        <p  className="d-flex">
                                        <img style={{width: "16%"}} src= {TownPlannerIcon}/>
                                          <div className="dropdown-wrapper-supplier">
                                            <div>
                                              Raw Materials Supplier
                                              <img style={{margin: "0"}} src={Downarrow} alt=""  />
                                            </div>
                                            {(rawMaterialList.length !== 0)?
                                                      <div className=" dropdown-container-supplier ">
                                                        <div className=" dropdown-container-supplier-box container  py-3 scrollablematerial" >
                                                                {materialList}
                                                        </div>
                                                      </div>
                                                      :
                                                      ""
                                            }
                                          </div>             
                                        </p>
                                      <Link to="/FinancialBrokers">
                                        <p className="d-flex">
                                        <img style={{width: "30%"}} src= {FinanceBrokerIcon}/>
                                          Financial Broker
                                        </p>
                                      </Link>
                                  </div>
                                </div>
                          </div>
                      </li>
                      <li>
                        <Link to="/FindProjects" className={this.state.activeIndex == "2" ? "active  ml-3 mr-4" : " ml-3 mr-4"} id="2" onClick={(event) => this.handleClick(event)}>
                          Projects
                        </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "3" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="3" onClick={(event) => this.handleClick(event)} to='/support'>
                            Support
                          </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "4" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="4" onClick={(event) => this.handleClick(event)} to='/partners'>
                            Partner
                          </Link>
                      </li>
                      <li>
                        <Link className={this.state.activeIndex == "5" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="5" onClick={(event) => this.handleClick(event)} to='/blog'>
                            Blog
                        </Link>
                      </li>
                      <li className="dropdown-profile-wrapper">
                          <li > 
                                Profile  <img style={{margin: "0"}} src={Downarrow} alt=""  />
                          </li>
                          <div  className="dropdown-profile-container">
                          <div className="dropdown-profile-container-box container  py-3 " >
                                  <li >
                                      <Link to="/rawMaterialSupplierProfile" className={this.state.activeIndex == "11" ? "active" : ""} id="11" onClick={(event) => this.handleClick(event)}>My Profile </Link>
                                  </li>
                                  <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                  <li>
                                      <Link to="" onClick={(event) => this.logout(event)}>Logout</Link>
                                  </li> 
                            </div>
                            </div>
                      </li>
                     
                    </ul>
                  </div>
                }

                {userType === "FinanceBrokers" &&
                  <div className="d-none d-md-block">
                    <ul className="menu row ">
                      <li>
                        <Link to="/" className={this.state.activeIndex == "0" ? "active ml-4 mr-4" : "ml-4 mr-4"} id="0" onClick={(event) => this.handleClick(event)}>
                          Home</Link>
                      </li>
                      <li className="dropdown-wrapper">
                        <li className={this.state.activeIndex === "1" ? "active ml-3 mr-3" : " ml-3 mr-3"} id="1"  >
                            Hire Professionals
                            <img className = "ml-1" style={{margin: "0"}} src={SolidDownArrow} alt=""  />
                        </li>
                        <div  className="dropdown-container w-75">
                                <div className="dropdown-container-box container py-2 " >
                                  <div className="d-flex p-2 dropdown-Subcontainer">
                                    <Link to="/Builders">
                                      <p  className="d-flex">
                                        <img style={{width: "60%"}} src= {BuilderIcon}/>
                                        Builder
                                      </p>
                                    </Link>
                                    <Link to="/Architects">
                                        <p  className="d-flex">
                                        <img style={{width: "50%"}} src= {ArchitectIcon}/>
                                        Architect
                                        </p>
                                      </Link>
                                        <p  className="d-flex">
                                        <img style={{width: "16%"}} src= {TownPlannerIcon}/>
                                          <div className="dropdown-wrapper-supplier">
                                            <div>
                                              Raw Materials Supplier
                                              <img style={{margin: "0"}} src={Downarrow} alt=""  />
                                            </div>
                                            {(rawMaterialList.length !== 0)?
                                                      <div className=" dropdown-container-supplier ">
                                                        <div className=" dropdown-container-supplier-box container  py-3 scrollablematerial" >
                                                                {materialList}
                                                        </div>
                                                      </div>
                                                      :
                                                      ""
                                            }
                                          </div>             
                                        </p>
                                      <Link to="/FinancialBrokers">
                                        <p className="d-flex">
                                        <img style={{width: "30%"}} src= {FinanceBrokerIcon}/>
                                          Financial Broker
                                        </p>
                                      </Link>
                                  </div>
                                </div>
                          </div>
                      </li>
                      <li>
                        <Link to="/FindProjects" className={this.state.activeIndex == "2" ? "active  ml-3 mr-4" : " ml-3 mr-4"} id="2" onClick={(event) => this.handleClick(event)}>
                          Projects
                        </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "3" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="3" onClick={(event) => this.handleClick(event)} to='/support'>
                            Support
                          </Link>
                      </li>
                      <li>
                          <Link className={this.state.activeIndex == "4" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="4" onClick={(event) => this.handleClick(event)} to='/partners'>
                            Partner
                          </Link>
                      </li>
                      <li>
                        <Link className={this.state.activeIndex == "5" ? "active  ml-4 mr-4" : " ml-4 mr-4"} id="5" onClick={(event) => this.handleClick(event)} to='/blog'>
                            Blog
                        </Link>
                      </li>
                     
                      <li className="dropdown-profile-wrapper ml-4 mr-4">
                          <li > 
                                Profile  <img style={{margin: "0"}} src={Downarrow} alt=""  />
                          </li>
                          <div  className="dropdown-profile-container">
                          <div className="dropdown-profile-container-box container  py-3 " >
                                  <li >
                                      <Link to="/FinancialBrokerProfile" className={this.state.activeIndex == "11" ? "active" : ""} id="11" onClick={(event) => this.handleClick(event)}>My Profile </Link>
                                  </li>
                                  <hr style={{ border: "1px solid #E1E1E1", width: "90%" }} />
                                  <li>
                                      <Link to="" onClick={(event) => this.logout(event)}>Logout</Link>
                                  </li> 
                            </div>
                            </div>
                      </li>
                    </ul>
                  </div>
                }
                
                <div className='menu-icon'>
                  {this.state.toggle &&
                    <button className="togglebtn" onClick={this.togglebtn}>
                      <img  src={menuBar} alt="" />
                
                      {/* <i className="far fa-bars"></i> */}
                    </button>}
                </div>

                <div className="d-block d-md-none">

                  {!this.state.toggle &&
                    <button className="togglebtn" onClick={this.togglebtn}>
                      <img src={CloseToggleBtn} alt="" width="60px" />
                    </button>}
                </div>

              </div>

            </div>
          </div>

          <div style={{ display: this.state.navShow }} >
          <ul className="menu  d-block d-md-none mobilemenu">
          {/* <ul className="menu"> */}
            <div className="list-item">
            <li className="py-2">
                                    {' '}
                                    <Link to="/"> Home </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/FindProjects"
                                        className={this.state.activeIndex == '1' ? 'active' : ''}
                                        id="1"
                                        onClick={(event) => this.handleClick(event)}
                                    >
                                        Find Projects
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/Builders"
                                        className={this.state.activeIndex == '2' ? 'active' : ''}
                                        id="2"
                                        onClick={(event) => this.handleClick(event)}
                                    >
                                        Builders
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/Architects"
                                        className={this.state.activeIndex === '3' ? 'active' : ''}
                                        id="3"
                                        onClick={(event) => this.handleClick(event)}
                                    >
                                        Architects
                                    </Link>
                                </li>
                                <li className="dropdown-wrapper-supplier">
                                    <li >Raw Materials <img style={{margin: "0"}} src={HeaderRightArrow} alt=""  /></li>
                                    <div className=" dropdown-container-supplier ">
                                            <div className=" dropdown-container-supplier-box container  py-3 scrollablematerial" >
                                                    {materialList}
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <Link
                                        to="/FinancialBrokers"
                                        className={this.state.activeIndex == '5' ? 'active' : ''}
                                        id="5"
                                        onClick={(event) => this.handleClick(event)}
                                    >
                                        Financial Brokers
                                    </Link>
                                </li>
                                {userType === 'Customer' && (
                                    <li>
                                        <Link
                                            to="/MyProjects"
                                            className={this.state.activeIndex == '5' ? 'active' : ''}
                                            id="5"
                                            onClick={(event) => this.handleClick(event)}
                                        >
                                            My Projects
                                        </Link>
                                    </li>
                                )}
                                {userType === 'Customer' && (
                                    <li>
                                        <Link
                                            to="/CustomerProfile"
                                            className={this.state.activeIndex == '6' ? 'active' : ''}
                                            id="6"
                                            onClick={(event) => this.handleClick(event)}
                                        >
                                            Profile
                                        </Link>
                                    </li>
                                )}
                                {userType === 'Builder' && (
                                    <li>
                                        <Link
                                            to="/MyBuilderProjects"
                                            className={this.state.activeIndex == '7' ? 'active' : ''}
                                            id="7"
                                            onClick={(event) => this.handleClick(event)}
                                        >
                                            My Projects
                                        </Link>
                                    </li>
                                )}
                                 {userType==="Architect" &&
                                 <li>
                                 <Link to="/MyArchitectProjects" className={this.state.activeIndex == "7" ? "active" : ""} id="7" onClick={(event) => this.handleClick(event)}>My Projects</Link>
                               </li>
                                }
                                {(userType === 'Builder') && (
                                    <li>
                                        <Link
                                            to="/Portfolio"
                                            className={this.state.activeIndex == '8' ? 'active' : ''}
                                            id="8"
                                            onClick={(event) => this.handleClick(event)}
                                        >
                                            Portfolio
                                        </Link>
                                    </li>
                                )}
                                    { userType === 'Architect' && (
                                    <li>
                                        <Link
                                            to="/ArchitectPortfolio"
                                            className={this.state.activeIndex == '10' ? 'active' : ''}
                                            id="10"
                                            onClick={(event) => this.handleClick(event)}
                                        >
                                            Portfolio
                                        </Link>
                                    </li>
                                )}
                                 {userType==='Architect' &&  
                                <li><Link to="/ArchitectProfile" className={this.state.activeIndex == "11" ? "active" : ""} id="11" 
                                    onClick={(event) => this.handleClick(event)}>Profile</Link>
                                </li>}
                                {userType === 'Builder' && (
                                    <li>
                                        <Link
                                            to="/BuilderProfile"
                                            className={this.state.activeIndex == '9' ? 'active' : ''}
                                            id="9"
                                            onClick={(event) => this.handleClick(event)}
                                        >
                                            Profile
                                        </Link>
                                    </li>
                                )}

                                  {userType === 'Raw Material Supplier' && (
                                       <li>
                                       <Link
                                           to="/rawMaterialSupplierProfile"
                                           className={this.state.activeIndex == '11' ? 'active' : ''}
                                           id="11"
                                           onClick={(event) => this.handleClick(event)}
                                       >
                                           Profile
                                       </Link>
                                   </li>
                                )}
                                {userType === 'FinanceBrokers' && (
                                       <li>
                                       <Link
                                           to="/FinancialBrokerProfile"
                                           className={this.state.activeIndex == '11' ? 'active' : ''}
                                           id="11"
                                           onClick={(event) => this.handleClick(event)}
                                       >
                                           Profile
                                       </Link>
                                   </li>
                                )}

                                {(userType === 'Customer' ||
                                    userType === 'Architect' ||
                                    userType === 'Builder' || 
                                    userType === 'Raw Material Supplier' || 
                                    userType === 'FinanceBrokers'
                                    ) && (
                                    <li>
                                        <Link to="" onClick={(event) => this.logout(event)}>
                                            Logout
                                        </Link>
                                    </li>
                                )}
                               
                                {userType === '' && (
                                   
                                        <li className="cursor" onClick={() => this.setActiveLink("login")}> 
                                              Login/Signup
                                        </li>
                                       
                                )} 
            </div>
          </ul>
          </div>

        </div>

        <Modal visible={this.state.loginModel} width="400" effect="fadeInUp" onClickAway={(event: any) => this.closeLoginModal(event)} >
          <LoginModel value={this.signup} closeModel={(event: any) => this.closeModal(event)}  />
        </Modal>

        <Modal visible={this.state.RegisterModel} width="400" effect="fadeInUp" onClickAway={(event: any) => this.closeRegisterModal(event)}>
          <RegisterModal  closeModel={(event: any) => this.closeModal(event)} value={this.login} result={(event: any) => this.success(event)} />
        </Modal>

        <CustomModal isAlert={true} show={show} header="Success" body={body} onHide={(event: any) => this.login(event)} />
        {/* <CustomModal isAlert={false} show={show} header="Header" body={<h1>sdfdfrere</h1>} onHide={(event)=>this.onHide(event)}/> */}
      </div>

    )
  }
}