import React, { Component } from 'react';
import Project1 from '../../../../assets/images/projects1.jpg' ;
import Project3 from '../../../../assets/images/project3.jpg' ;
import Project4 from '../../../../assets/images/project4.jpg' ;
import { blobService } from '../../../common-components/Blob';
import Carousel from "react-bootstrap/Carousel";
import Cross from "../../../../assets/images/VectorCross.png";
import CurrencyFormat from 'react-currency-format';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';

interface ViewArchitectPortfolioProps{
    name?: any;
    value?: any;
    project?: any;
    isView?: boolean;
    onHideProject?:any;
}
interface ViewArchitectPortfolioState{
    disableImage:boolean,
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    Images: any[],
    twitterLink:string,
    facebookLink:string,
    errors: {
        description: string,
        projectName: string,
        adLine1: string,
        adLine2: string,
       
        landSize: string,
        mobile : string,
        cost: string
    };
    project: any,
    type: string,
    projectName: string,
    description: string,
    adLine1: string,
    adLine2: string,
    city: string,
    state: string,
    country: string,
    pincode: string,
    mobile: string,
    cost: string,
    landSize: string,
    measurementUnit: string,
    projectImages: any[],
    isEdit: boolean,
    nextIcon: any,
    prevIcon: any,
    showUpload: boolean,
    header: string,
    body: string,
    stateList: any,
    pincodeList: any,
    cityList:any,
    formAddressData: any,
    formOtherData: any,
    nextDisabledIcon: any,
    prevDisabledIcon: any
}
 class ViewArchitectPortfolio extends Component<ViewArchitectPortfolioProps,ViewArchitectPortfolioState> {
     constructor(props:ViewArchitectPortfolioProps) {
         super(props)
         const initialState = {
            disableImage:false,
            isLoading: true,
            Images:[
                Project1,Project3,Project4,Project1
             ],
            errors: {
                description: '',
                projectName: '',
                adLine1: '',
                adLine2: '',
                landSize: '',
                mobile : '',
                cost: ''
            },
            project:{},
            twitterLink: '',
            facebookLink: '',
            type: 'text',
            projectName: "",
            description: "",
            adLine1: "",
            adLine2: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
            mobile: "",
            cost:"",
            landSize: "",
            measurementUnit: "",
            projectImages: [],
            isEdit: false,
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            nextDisabledIcon: <span className="icon-button-disabled "><img src={RightArrow}></img></span>,
            prevDisabledIcon: <span className="icon-button-disabled "><img src={LeftArrow}></img></span>,
            showUpload: false,
            header: '',
            body: '',
            stateList: [],
            pincodeList: [],
            cityList:[],
            error:false,
            ErrMsg:"",
            formAddressData: [],
            formOtherData: []
    }
    this.state = initialState;
    }
    
    componentDidMount(){
        let p = this.props.project;
        let images;
        images = JSON.stringify(p.projectDetails.projectImages);
        if(p){
        this.setState({
            projectName: p.name,
            description: p.description,
            adLine1: p.address.addressLine1,
            adLine2: p.address.addressLine2,
            city: p.address.city,
            state: p.address.state,
            country: p.address.country,
            // pincode: p.address.pincode,
            mobile : p.mobile,
            cost: p.cost,
            facebookLink:p.socialLinks?p.socialLinks.facebookLink:"",
            twitterLink:p.socialLinks?p.socialLinks.twitterLink:"",
            landSize: p.projectDetails.landSize,
            measurementUnit: p.projectDetails.measurementUnit.description,
            projectImages: p.projectDetails.projectImages,
            isLoading: false,
            formAddressData: p.address, 
            formOtherData: p
        })
        if(localStorage.userType){
            localStorage.setItem("images" , images);
           
        }
       }
    
         this.setState({project: this.props.project});
    }
    closeProject=(event:any)=>{
        this.props.onHideProject();
        }
    render() {
        const { error,twitterLink,facebookLink, cost, stateList,cityList, pincodeList, isLoading, project,nextIcon, prevIcon, projectImages, 
            projectName, adLine1, adLine2, city, description, landSize,
             measurementUnit,  prevDisabledIcon, nextDisabledIcon } = this.state;
        var builder;
        var architect;
        var addressObj;
            var address;
         var items:any=[];
         var subItems:any=[];
          if(Object.keys(project).length){
             addressObj = project.address;
             address = addressObj.addressLine1 +", "+ addressObj.addressLine2 +", "+ addressObj.city +", "+ addressObj.state +", "+ addressObj.country;
             builder = project.builder;
             architect = project.architect;
         }
         
         if(projectImages.length>0){
             items = projectImages.map(
                 (item:any) => (
                     (<Carousel.Item>
                         <img
                         className="d-block w-100 buildings-image"
                         src={blobService.createUrl(item)}
                         width="100%" height="350px"
                         />
                     </Carousel.Item>)          
                 ),
             );
         }
         for(let i = 0; i<4; i++){
            if(projectImages[i]){
                subItems.push(
                    <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                      
                        <img src={blobService.createUrl(projectImages[i])} className="sub-image" alt="" width="100%" height="100px"/>
                        
                    </div>
                )
            }else{
                subItems.push(
                    <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                   
                    <div></div>
                    
                    </div>        
                )                       
            }
            
        }
        return (
            <>
         <div className="aboutProject scrollableContent non-scrollable" >
          <div className="container py-5">
          <div className="cross-img" ><img src={Cross} onClick={this.closeProject}/></div>
      
          <div className="row mt-5">
          <div className="col-md-6">
                                <div className="row">
                                <div className="col-md-12">
                                    {projectImages.length>0 &&
                                    <span>
                                     {(projectImages.length>1)?
                                             <Carousel  nextIcon={nextIcon} prevIcon={prevIcon} >
                                                     {items}
                                             </Carousel>
                                                :
                                            <Carousel className = "disabled-arrow" nextIcon={nextDisabledIcon} prevIcon={prevDisabledIcon} >
                                                     {items}
                                            </Carousel>
                                    }<br/>
                                    </span>
                                    ||<div/>
                                    }
                                    <div className="row">
                                            {subItems}
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-6 ">
                    <div className="projectInfo">
                  
                   <>  <h4  > {projectName} </h4>
                   <p  className="lighter-text" >{city} </p>
                   <div className="mt-2">
                   {(twitterLink === null || twitterLink === "") ?
                    <a target="_blank">
                   <img  src={Twitterwithround} alt="" />
                   
                   </a>
                    :
                   <a href={ project.socialLinks ? project.socialLinks.twitterLink : ""} target="_blank">
                    <img  src={Twitterwithround} alt="" />
                   </a>
                     }
                   {(facebookLink === null || facebookLink === "") ?
                    <a target="_blank">
                   <img style={{marginLeft:"7px"}} src={FBwithround} alt="" />
                   </a>
                    :
                   <a href={ project.socialLinks ? project.socialLinks.facebookLink : ""} target="_blank">
                    <img style={{marginLeft:"7px"}} src={FBwithround} alt="" />
                   </a>
                     }
                        </div> 
                        <h5 className="mt-3  " >About </h5>
                        <p className="mt-2 lighter-text">{description}</p>
                        <h5 className="mt-5">Address</h5>
                        <p className="mt-2 lighter-text">{address}</p>
                  </>
                    <div className="row mt-2">
                            <div className="col-md-9">
                             <>
                                <h5>Land Size</h5>
                                <p className="light-content">{landSize} {measurementUnit}</p>
                                </>
                            
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-9">
                             
                            
                                <>
                                <h5>Cost</h5>
                                <p className="light-content"><CurrencyFormat value={cost} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                               
                                </p>
                                </>
                            
                            </div>
                        </div>
                    </div>  
                    </div>
        </div>          
          </div>
            
            </div>
            </>
        )
    }
 }

export default ViewArchitectPortfolio;