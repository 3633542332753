import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../common-components/Footer/Footer';
import Error from '../../common-components/Error/Error';
import './HomePage.scss';
import RightArrow from '../../../assets/images/rightarrow1.svg';
// import OurmissionHome from '../../../assets/images/ourmissionHome.jpg';
import Quote from '../../../assets/images/quote-right.svg';
import Header from '../../common-components/Header/Header';
import OurMissionHome from '../../../assets/images/NewOurMissionHome.png';
import OurMissionHome2 from '../../../assets/images/OurMissionNewHome.png';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { constants } from '../../../constants/constants';
import phone from '../../../assets/images/phone.svg';
import message from '../../../assets/images/message.svg';
import emailicon from '../../../assets/images/email.svg';
import dot from '../../../assets/images/dot.svg';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import yellowRightArrow from '../../../assets/images/yellowRightArrow.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { blobService } from '../../common-components/Blob';
import ReactPlayer from 'react-player';
import homeBuilders from '../../../assets/images/homeBuilders.svg';
import homeBroker from '../../../assets/images/homeBroker.svg';
import homeSupplier from '../../../assets/images/homeSupplier.svg';
import homeArchitect from '../../../assets/images/homeArchitect.svg';
import ProjectPlaceholder from '../../../assets/images/default.svg';
import testimonialone from '../../../assets/images/testimonialone.png';
import testimonialContentone from '../../../assets/images/testimonialContentone.png';
import Modal from 'react-awesome-modal';
import LoginModel from '../../common-components/Login/Login';
import RegisterModal from '../../common-components/Register/Register';
import CustomModal from '../../common-components/CustomModal/CustomModal';
import homeVideo from '../../../assets/images/homeVideo.svg';
import UserContact from '../../common-components/UserContact/UserContact';
import userContactIcon from '../../../assets/images/userContactIcon.svg';

interface HomeProps {
	name?: any;
	value?: any;
}
interface HomeState {
	activeIndex: string;
	isVisible: boolean;
	userType: string;
	count: string;
	CreatedBy: string;
    locationList: any;
    stateCode: any;
    selectedCity: string;
    selectedState: string;
	selectedLoaction: any;
	builders: any;
	loginModel: boolean,
  	RegisterModel: boolean,
	show: boolean,
	body: string,
	userContact: boolean
}

class Home extends React.Component<RouteComponentProps & any , HomeState>{
	constructor(props: any) {
		super(props);
		const initialState = {
			activeIndex: '',
			userType: '',
			isVisible : false,
			CreatedBy: "",
            locationList: [],
			selectedLoaction: [],
            stateCode: [],
            selectedCity: '',
            selectedState: '',
			count: '',
			builders: [],
			loginModel: false,
     		RegisterModel: false,
			show: false,
			body: '',
			userContact: false
		};
		this.state = initialState;
	}
	componentDidMount() {
		var scrollComponent = this;
    	document.addEventListener("scroll", function(e) {
     		scrollComponent.toggleVisibility();
    	});
		let userType = localStorage.getItem('userType');
		let activeIndex = localStorage.getItem('tab');
		this.setState({
			userType: userType || '',
			activeIndex: activeIndex || '0'
		});
		this.init();
		localStorage.setItem("tab",'0');
	}

	init() {
		// this.fetchProjectCount();
		this.fetchStateList();
        this.fetchCityList();
		this.fetchBuilders();
	}
	toggleVisibility() {
		if (window.pageYOffset > 300) {
		  this.setState({
			isVisible: true
		  });
		} else {
		  this.setState({
			isVisible: false
		  });
		}
	  }
	// async fetchProjectCount(): Promise<any> {
	// 	const user = JSON.parse(localStorage.getItem('user')!); 
    //     let token;
    //      if(user){
    //              token = 'Bearer ' + user;
               
    //         }
	// 	const key = constants.subscriptionKey;
	// 	const url= constants.fetchProjectCountAPI;
	// 	axios.get(url, { headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
	// 		.then((response) => {
	// 			if(response.data == 0){
	// 				this.setState({ count: 3});
	// 			}else{
	// 				this.setState({ count: response.data + 3});
	// 			}
				
	// 		})
	// 		.catch((error) => {
	// 			this.setState({ ErrMsg: error, error: true });
	// 		});
	// }
	async fetchBuilders(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
            const key = constants.subscriptionKey;
            const url =  constants.getBuilderListAPI + "?cityName=&stateName=" ;
            axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response: any) => {
                this.setState({ builders: response.data });
            })
            .catch((error) => {
                // this.setState({ ErrMsg: error, error:true });
            });
    }

	handleClick = (event: any) => {
		alert('calling');
		this.props.history.push('/View Builder');
		localStorage.setItem('tab', event.target.id);
		this.setState({ activeIndex: event.target.id });
	};
	contactUsPage=()=>{
		this.props.history.push('/ContactUs')
	   }
	onClickBuilder = (event: any) => {
        localStorage.setItem("tab",'2');
    }
    onClickArchitect = (event: any) => {
        localStorage.setItem("tab",'3');
    }
    onClickTPlanner = (event: any) => {
        localStorage.setItem("tab",'4');
    }

	handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState(Object.assign(this.state, { [name]: value }));
    };

	selectLocation = (value: any)=>{
        if(value){
            this.setState({
                selectedLoaction: value,
               
            })
        }else{
            this.setState({
                selectedLoaction: ''
            })
        }
    }
	async fetchStateList(): Promise<any> {
        const key = constants.subscriptionKey;           
        const url =  constants.fetchStatesListAPI;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    let stateList = response.data;
                    stateList.map((item:any)=>{
                        this.state.locationList.push(item.name);
                        this.state.stateCode.push(item.name);
                    })
                    
                    this.setState({
                        locationList: this.state.locationList,
                        stateCode: this.state.stateCode,
    
                    });
                }
                
            })
            .catch((error) => {
            });
      
    }
    async fetchCityList(): Promise<any> {
        const key = constants.subscriptionKey;           
        const url =  constants.fetchAllCity;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    let cityList = response.data.list;
                    cityList.map((item:any)=>{
                        this.state.locationList.push(item);
                    })
                    this.setState({locationList: this.state.locationList});
                }
                
            })
            .catch((error) => {
            });
      
    }
	setActiveLink = (e: any) => {
		if (e === "login") {
		  
		  this.setState({
			loginModel: true
		  });
		}else{
		  this.setState({
			RegisterModel: true
		  });
		}
	  }
	  closeLoginModal(event: any){   
		this.setState({
		  loginModel: false,
		  RegisterModel: false
		});
	  }
	
	  closeModal(event: any) {     
		this.setState({
		  loginModel: event,
		  RegisterModel: event
		});
		
	
	  }
	  signup = () => {
		this.setState({
		  loginModel: false,
		  RegisterModel: true
		})
	  }
	  closeRegisterModal = (event: any) => {
		this.setState({
		  RegisterModel: false,
		  
		});
	  }
	
	  success(event: any) {
		this.setState({ RegisterModel: false, show: !this.state.show, body: event })
	  }

	  login = (event: any) => {
		this.setState({
		  loginModel: false,
		  RegisterModel: false,
		  show: false
		})
	  }
	//   openUserContact = ()=>{
	// 	this.setState({
	// 		userContact: true
	// 	})
	// }
	// closeUserContactModal  (event: any){
	// 	this.setState({
	// 		userContact: event
	// 	})
	// }
	render() {
	const{isVisible,  CreatedBy, locationList,selectedLoaction, builders, show, body, userContact}:any = this.state;
		var newVal;
		if (true) {
			newVal = <Error />;
		}
		let list = [];
		
			for(let i=0; i< builders.length ; i++ ){
				if(i<4){
					list.push(
						<div className="col-md-3 builderList">
						<div className="builderContentcard">
						   
								{ builders[i].logoPath && 
									<img src={blobService.createUrl(builders[i].logoPath)} alt="" width="100%" height="200px" />
								||
									<img src={ProjectPlaceholder} alt=""  width="100%" height="200px"/>
								}
						  
						</div>
						<div className="buildername">{builders[i].builderName}</div>
						
					</div>
					)	
				}
		}
		return (
			<div className="outer-container">
				<Header />
				<a id='top' />			
				<Carousel>
					<Carousel.Item>
						<div className='homepage  d-block w-100 first-banner no-gutter'>
							<Link to="/Architects" ><button className='p-2' >Know More</button></Link>
						</div>
					</Carousel.Item>
					<Carousel.Item>
							<div className='homepage d-block w-100 second-banner'>
								{/* <button className=' p-2' onClick={() => this.setActiveLink("")}>SignUp</button> */}
							</div>
					</Carousel.Item>
					<Carousel.Item>
							<div className='homepage d-block w-100 third-banner'>
							</div>
					</Carousel.Item>
					<Carousel.Item>
							<div className='homepage d-block w-100 four-banner'>
								<Link to="/Builders" ><button className='p-2' >Know More</button></Link>
							</div>
					</Carousel.Item>
					<Carousel.Item>
							<div className='homepage d-block w-100 five-banner'>
								{/* <button className='p-2' onClick={() => this.setActiveLink("")}>SignUp For Free</button> */}
							</div>
					</Carousel.Item>
					
					{/* <Carousel.Item>
							<div className='homepage d-block w-100 six-banner'>
							</div>
					</Carousel.Item> */}
				</Carousel>

				{/* <div className='container find-project-container'>
					<div className=' '>
						<div className='w-100'>
							<div className='find-project-container-box py-3'>
								<div className='subBox1 mx-5'>
									<h4>Find Project</h4>
									<div className='row'>
										<div className='col-md-7'>
											<div className='row'>
												<div className='col-md-6'>
													<select className=" py-1  w-100 mb-2" onChange={this.handleChange} name = "CreatedBy" value={CreatedBy} >
														<option value="">Select type</option>
														<option value="Builder">Builder</option>
														<option value="Customer">Customer</option>
													</select>
													<p>Filter bY</p>
												</div>
												<div className='col-md-6'>
													<Autocomplete
															id="disable-close-on-select"
															onChange={(event, newValue) => {
																this.selectLocation(newValue);
															}}
															className = "mb-2"
															options={locationList}
															value = {selectedLoaction}
															getOptionLabel={(option:any) => option}
															renderInput={(params) => <TextField {...params} placeholder="Location" variant="standard" name = "searchLocationText"  />}
														/>
														<p>Location</p>
												</div>
											</div>
										</div>
										<div className='col-md-5'>
											<Link to={{pathname: '/FindProjects', state: {data: selectedLoaction, user: CreatedBy}}}>
												<button className='py-2 px-2'>
													Search
												</button>
											</Link>	
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				{isVisible && (
				<div className='fab-icon sticky' >
					<a href='#top' title="Go to top" >
						{' '}
						<ArrowUpwardIcon />
					</a>
				</div>)}
				{/* <div className='userContactIcon ' onClick={this.openUserContact}>
							<img  src={userContactIcon}/>
							
						</div> */}
				<div className='container mb-2'>
					<div className='row my-4 d-flex home-video'>
						<h4>Highlights of Aashray Homes</h4>
						{/* <ReactPlayer 
							url='videos/india_video.MP4'
							controls
							className = "w-100 h-100"
						 /> */}
						 <img className = "w-100" src= {homeVideo}></img>
					</div>
				</div>
				<div >
					<div className='row my-new-container no-gutters'>
						<div className=' thirdFold w-100'>
						<div className='background-opacity'>
						<div className='container'>
							<div className='row no-gutters  p-4 thirdFold-container '>
								<div className='col-md-6 mt-3'>
									<p>
										Our Top Real Estate 
										Professionals
									</p>
									<p>
										Aashray Homes started as an online medium for the realestate service providers like builders, developers, 
										raw material suppliers, architects etc., and service seekers or clients who
										are having their dream homes from 
										scratch
									</p>
									<Link to= "/AboutCompany">
									<button className ="p-3">Learn More</button>
									</Link>
								</div>
								<div className='col-md-6 d-flex thirdFold-smallBox-container mt-3'>
										<Link to = "/Builders">
											<div className='thirdFold-smallBox-home mr-4 mt-4  py-3'>
													<img src = {homeBuilders}/>
													<p>Builders</p>
													<p>	
														Our Builders are skilled and 
														combined with years of 
														experience. High-rise 
														buildings...
													</p>
											</div>
										</Link>
										<Link to = "/FinancialBrokers">
											<div className='thirdFold-smallBox-home mt-4  mr-2 py-3'>
													<img src = {homeBroker}/>
													<p>Financial Broker</p>
													<p>	
														Aashray Homes Financial 
														Brokers work with you to 
														make the whole process of 
														applying and...
													</p>
											</div>
										</Link>
										<Link to = "/RawMaterialSuppliers">
											<div className='thirdFold-smallBox-home mr-4 mt-4 py-3'>
													<img src = {homeSupplier}/>
													<p>Raw Materials Supplier</p>
													<p>	
														Aashray Homes filtered the 
														best construction materials 
														supplier from your locality 
														who can...
													</p>
											</div>
										</Link>
										<Link to = "/Architects">
											<div className='thirdFold-smallBox-home mr-2 mt-4 py-3'>
													<img src = {homeArchitect}/>
													<p>Architects</p>
													<p>	
														Our architect's creations are a 
														reflection of our knowledge, 
														allowing clients to enjoy the ...
														
													</p>
											</div>
										</Link>
								</div>
							</div>
						</div>
						</div>	
					</div>
					</div>
				</div>
				<div className='fourthFold w-100 py-4'>
					<div className='d-flex fourthFold-header container mt-4 p-3'>
							<h4>Our Featured Builders</h4>
							<Link to = "/Builders">
								<button className='p-3'>View More</button>
							</Link>
					</div>
					<div className='container'>
						<div className='row'>
							{list}
						</div>	
					</div>
				</div>
				<div className='testimonial-container w-100'>
					<div className='background-opacity'>
						<div  style = {{textAlign: 'center'}}className='container p-4'>
							<h4>What The People Thinks About Us</h4>
							<div>
								<img src= {testimonialone}/>
								<img src= {testimonialContentone}/>
							</div>
						</div>
					</div>
				</div>

				{/* For map */}
				<div className='map-sctn'>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d650.9170243526261!2d78.39361150312097!3d17.436103967100557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917a0655e829%3A0x9095dc303f01d89f!2sGolden%20Future%20Technologies!5e0!3m2!1sen!2sin!4v1634121159967!5m2!1sen!2sin"  style={{width: '100%', border:'0px'}} loading="lazy"></iframe>
				</div>
				<div className='container' style ={{position : 'relative', top:'-79px', left: '20px'}} >
					<div className='row .newAddress address-sctn '>
						<div className=' col-md-4 col-12 address-container'>
							<div className='address-image-sctn'>
								{/* <img src = {line}/> */}
							</div>
							<div className = 'address-text-container'>
								<p>COMPANY ADDRESS</p>
								<p>inani House, Plot No: 264, Level, 2, Kavuri Hills Phase 2 Rd,</p>
								<p> Madhapur, Telangana</p>
								<p>500033, India</p>
								<button className="contactus-but" onClick={this.contactUsPage}>Send us a message</button>
							</div>		
						</div>
						<div className='col-md-4 col-12 phone-sctn d-flex'>
							<div className='phn-image-sctn'>
								<img src={phone} alt='phone' />
							</div>
							<div className='phn-text-sctn'>
								<p className='phn'>COMPANY PHONES</p>
								<p className='num'>+91 4035653622</p>
								<p className='num'></p>
								
							</div>
						</div>
						<div className='col-md-4 col-12 email-sctn d-flex'>
							<div className='email-image-sctn'>
								<img src={emailicon} alt='message' />
							</div>
								{/* <div className='dots'>
									<img src={dot} alt='dot' />
									<img src={dot} alt='dot' />
									<img src={dot} alt='dot' />
								</div> */}
							
							<div className='email-text-sctn'>
								<p className='phn'>Dustro Emails</p>
								<p className='num'>marketing@ahsrayhomes.com</p>
								<p className='num'>sales@ashrayhomes.com</p>
								<p className='num'>marketing@gftglobal.net</p>
								<p className='num'>marketing@gftglobal.net</p>
								<p className='num'></p>
							</div>
						</div>
					</div>
					<br /> <br />
				</div>
				<div className='keyword-container'>
				<div className='container py-2 keyword-container-box'>
					<h4>Popular Keywords</h4>
					<div className='row mt-3'>
						<div  className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a target="_blank" href = 'https://www.youtube.com/watch?v=CQ8oNFafcHw'>
									#realestate
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.youtube.com/watch?v=sP42CqOpcFM' target='_blank'>
									#realtor
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.youtube.com/watch?v=sP42CqOpcFM' target='_blank'>
									#home
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.youtube.com/watch?v=qsfz-AdIV0o' target='_blank'>
									#property
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.ashrayhome.in/realEstatePriceBlog' target='_blank'>
									#investment
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.ashrayhome.in/owningAHouse' target='_blank'>
									#dreamhome
								</a>
							</p>
						</div>
						
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.youtube.com/channel/UCMdLYbIHwo3UKVrCx5d2nfg/featured' target='_blank'>
									#homesweethome
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.ashrayhome.in/dreamHomeBlog' target='_blank'>
									#realestateinvesting
								</a>
							</p>
						</div>
						<div  className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/165477912742942/' target='_blank'>
									#design
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/165266769430723/' >
									#realestateinvestor
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.ashrayhome.in/homeLoanBlog'>
									#homeloan
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/159455350011865' target='_blank'>
									#quickhomeloan
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/158026930154707' target='_blank'>
									#mortgage
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2'>
						{/* <div  className='col-md-2 keyword-box p-2 mr-1'>
							<p>#justlisted</p>
						</div> */}
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/163644232926310/' target='_blank'>
									#broker
								</a>
							</p>
						</div>
						{/* <div className='col-md-2 keyword-box p-2 mr-1'>
							<p>#homesforsale</p>
						</div> */}
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/' target='_blank'>
									#electricals
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/158835093407224/' target='_blank'>
									#homeconstruction
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/164056046218462/' target='_blank'>
										#bricks
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/163854412905292/' target='_blank'>
										#sand
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2'>
					<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/156603276963739/' target='_blank'>
										#carpenters
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/156603276963739/' target='_blank'>
										#electricians
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/159049866719080/' target='_blank'>
										#builders
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/160104306613636/' target='_blank'>
										#architects
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box mr-1 p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/160709233219810/' target='_blank'>
										#hardware
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2'>
						<div  className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162525799704820/' target='_blank'>
										#marbles
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/156603276963739/' target='_blank'>
										#plumbers
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/163644232926310/' target='_blank'>
									#financialbrokers
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162079053082828/' target='_blank'>
										#steel
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3 '>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162525799704820/' target='_blank'>
										#cement
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2 mb-2'>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/160709233219810/' target='_blank'>
										#rawmaterialsuppliers
								</a>
							</p>
						</div>
						<div  className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162301219727278/' target='_blank'>
										#realestateprofessional
								</a>
							</p>
						</div>
					</div>
				</div>
				</div>
				<Modal visible={this.state.loginModel} width="400" effect="fadeInUp" onClickAway={(event: any) => this.closeLoginModal(event)} >
				<LoginModel value={this.signup} closeModel={(event: any) => this.closeModal(event)}  />
				</Modal>

				<Modal visible={this.state.RegisterModel} width="400" effect="fadeInUp" onClickAway={(event: any) => this.closeRegisterModal(event)}>
				<RegisterModal closeModel={(event: any) => this.closeModal(event)} value={this.login} result={(event: any) => this.success(event)} />
				</Modal>
				{/* {userContact == true && (
					<div className='userContactBackground userContact' >
						<UserContact
							closeModal ={(event: any) => this.closeUserContactModal(event)}
						/>
                    </div>
					)} */}
				<CustomModal isAlert={true} show={show} header="Success" body={body} onHide={(event: any) => this.login(event)} />
				<Footer />
			</div>
		);
	}
}

export default withRouter(Home);