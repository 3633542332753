
import { authHeader } from '../Utils/AuthHeader';
import { Headers, FormHeaders} from '../Utils/Header';
import Axios from 'axios';



export const userService = {
    postApiRequest,
    getApiRequest,
    updateApiRequest,
    delete: _delete,
    postFileApiRequest,
};


function postApiRequest(options:httpOptionsReq) {
     authHeader();
    const data=options.data;
    const url=options.url;
    const requestHeaders = {
        headers: {Headers}
    };
    return Axios.post(url, data,requestHeaders)
        .then(handleResponse)
        .catch(handleError);
}

function getApiRequest(options:httpOptionsReq) {
     authHeader();
    const url=options.url;
    const requestHeaders = {
        headers: {Headers}
    };
    // const requestOptions = {
    //     headers: { 'Content-Type': 'application/json' }
    // };
    return Axios.get(url, requestHeaders)
        .then(res=>{
            return res
        })

        
}




function updateApiRequest(options:httpOptionsReq) {
    authHeader();
    const data=options.data;
    const url=options.url;
    const requestHeaders = {
        headers: {Headers}
    };
    return Axios.put(url, data, requestHeaders)
        .then(handleResponse)
        .catch(handleError);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(options:httpOptionsReq) {
    authHeader();
    const url=options.url;
    const requestHeaders = {
        headers: {Headers}
    };
    // const requestOptions = {
    //     headers: { 'Content-Type': 'application/json' }
    // };

    return Axios.delete(url, requestHeaders).then(handleResponse);
}

function postFileApiRequest(options:httpOptionsReq) {
    authHeader();
    const data=options.data;
    const url=options.url
    const requestHeaders = {
        headers: {FormHeaders}
    };
    return Axios.post(url, data,requestHeaders)
        .then(handleResponse)
        .catch(handleError);
}

function handleResponse(response:any) {
    if (response.status === 401) {
        const error = (response && response.message) || response.statusText;
        return Promise.reject(error);
    }else{
        return response.data;
    }
}
function handleError(error :any) {
    const response = error.response;
    return Promise.reject({code: response.status, message: response.data.message});
}