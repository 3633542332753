import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {alert} from './Reducers/alert.reducer';
import  {createLogger}  from 'redux-logger';

 const loggerMiddleware = createLogger();

export const store = createStore(
    alert,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);