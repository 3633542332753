import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import RightArrow from "../../../../assets/images/right-arrow.svg"
import Info from "../../../../assets/images/info.svg"
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Modal from "react-bootstrap/Modal";
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import Check from '../../../Services/Check';
import './NewProject.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface NewProjectProps {
    name?: any;
    value?: any;
}
interface NewProjectState {
    error: boolean;
    ErrMsg: any;
    name: string,
    description: string,
    adLine1: string,
    adLine2: string,
    city: string,
    state: string,
    country: string,
    pincode: string,
    mobile: string,
    builderType: string,
    landSize: string,
    measurement: string,
    landSaleContract: string,
    architectDrawings: string,
    engineeringPlans: string,
    landscapingDesigns: string,
    selectedFile1: any,
    selectedFile2: any,
    selectedFile3: any,
    selectedFile4: any,
    checked: boolean,
    isCustomBuilder: boolean,
    isPublic: boolean,
    errors: {
        terms: string,
        file1: string,
        file2: string,
        file3: string,
        file4: string,
        description: string,
        name: string,
        adLine1: string,
        adLine2: string,
        landSize: string,
        mobile : string,
        pincode: string
    },
    projectModal: boolean,
    uploadModal: boolean,
    projectHeader: string,
    projectBody: string,
    uploadHeader: string,
    uploadBody: string,
    stateList: any,
    pincodeList: any,
    cityList: any,
}

const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
export default class NewProject extends React.Component<NewProjectProps & RouteComponentProps, NewProjectState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            name: '',
            description: '',
            adLine1: '',
            adLine2: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            mobile: '',
            builderType: 'Project Home Builder',
            landSize: '',
            measurement: 'Square Meter',
            landSaleContract: '',
            architectDrawings: '',
            engineeringPlans: '',
            landscapingDesigns: '',
            selectedFile1: '',
            selectedFile2: '',
            selectedFile3: '',
            selectedFile4: '',
            checked: false,
            errors: {
                terms: '',
                file1: '',
                file2: '',
                file3: '',
                file4: '',
                description: '',
                name: '',
                adLine1: '',
                adLine2: '',
                landSize: '',
                mobile : '',
                pincode: '',
            },
            isPublic: true,
            isCustomBuilder: false,
            projectModal: false,
            uploadModal: false,
            projectHeader: '',
            projectBody: '',
            uploadHeader: '',
            uploadBody: '',
            stateList: [],
            pincodeList: [],
            cityList: [],
            error: false,
            ErrMsg: "",
        };
        this.state = initialState;
        this.setPublic = this.setPublic.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        this.fetchStateList();
        this.fetchCityList();
    }
    async fetchStateList(): Promise<any> {
        const options = {
            url: constants.fetchStatesListAPI,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ stateList: response.data })

            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error: true });
            });
    }

    async fetchCityList(): Promise<any> {
            const options = {
                url: constants.fetchAllCity,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    if(response.status == 200 ){
                        let cityList = response.data.list;
                        cityList.map((item:any)=>{
                            this.state.cityList.push(item);
                        })
                        this.setState({cityList: this.state.cityList});
                    }
                })
                .catch((error) => {
                    this.setState({ cityList: [] })
                    this.setState({ ErrMsg: error, error: true });
                });

    }


    setPublic() {
        this.setState({ isPublic: !this.state.isPublic });
    }
    getBase64(file: any) {
        let document: any = "";
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                document = reader.result;
            };

            reader.onerror = function (error) {
            };
        }
        return document;
    }
    onFileChange = async (event: any) => {
        let file = event.target.files[0];
        let id = event.target.id;
        if (id === "file1") {
            if (file && file.type != 'application/pdf') {
                let errors = this.state.errors;
                errors.file1 = 'Please upload only PDF file';
                this.setState({ errors: errors });
            } else if (file && file.size > 4000000) {
                let errors = this.state.errors;
                errors.file1 = 'Please upload file below 4MB';
                this.setState({ errors: errors });
            } else {
                let errors = this.state.errors;
                errors.file1 = '';
                this.setState({ selectedFile1: file, errors: errors });
            }
        }
        if (id === "file2") {
            if (file && file.type != 'application/pdf') {
                let errors = this.state.errors;
                errors.file2 = 'Please upload only PDF file';
                this.setState({ errors: errors });
            } else if (file && file.size > 4000000) {
                let errors = this.state.errors;
                errors.file1 = 'Please upload file below 4MB';
                this.setState({ errors: errors });
            } else {
                let errors = this.state.errors;
                errors.file2 = '';
                this.setState({ selectedFile2: file, errors: errors });
            }
        }
        if (id === "file3") {
            if (file && file.type != 'application/pdf') {
                let errors = this.state.errors;
                errors.file3 = 'Please upload only PDF file';
                this.setState({ errors: errors });
            } else if (file && file.size > 4000000) {
                let errors = this.state.errors;
                errors.file1 = 'Please upload file below 4MB';
                this.setState({ errors: errors });
            } else {
                let errors = this.state.errors;
                errors.file3 = '';
                this.setState({ selectedFile3: file, errors: errors });
            }
        }
        if (id === "file4") {
            if (file && file.type != 'application/pdf') {
                let errors = this.state.errors;
                errors.file4 = 'Please upload only PDF file';
                this.setState({ errors: errors });
            } else if (file && file.size > 4000000) {
                let errors = this.state.errors;
                errors.file1 = 'Please upload file below 4MB';
                this.setState({ errors: errors });
            } else {
                let errors = this.state.errors;
                errors.file4 = '';
                this.setState({ selectedFile4: file, errors: errors });
            }
        }

    };

    onFileUpload = async (e: any) => {
        let userId = localStorage.getItem("id");
        let id = e.target.id;
        if (id === "file1") {
            let errors = this.state.errors;
            if (this.state.selectedFile1) {
                errors.file1 = '';
                this.setState({ errors: errors });
                const fileData = this.state.selectedFile1;
                const formData = new FormData();
                formData.append('id', userId ? userId : '');
                formData.append('type', "ProjectDocument");
                // formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.setState({ landSaleContract: response });
                    this.showUploadModal("Success", "File Uplaoded Successfully");
                } else {
                    this.showUploadModal("Failed", "Failed to Upload File");
                    this.setState({ landSaleContract: '' });
                }
            } else {
                let errors = this.state.errors;
                errors.file1 = 'Please Select File to Upload';
                this.setState({ errors: errors });
            }
        }
        if (id === "file2") {
            let errors = this.state.errors;
            if (this.state.selectedFile2) {
                errors.file2 = '';
                this.setState({ errors: errors });
                const fileData = this.state.selectedFile2;
                const formData = new FormData();
                formData.append('id', userId ? userId : '');
                formData.append('type', "ProjectDocument");
                // formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.showUploadModal("Success", "File Uplaoded Successfully");
                    this.setState({ architectDrawings: response });
                } else {
                    this.showUploadModal("Failed", "Failed to Upload File");
                    this.setState({ architectDrawings: '' });
                }
            } else {
                let errors = this.state.errors;
                errors.file2 = 'Please Select File to Upload';
                this.setState({ errors: errors });
            }
        }
        if (id === "file3") {

            let errors = this.state.errors;
            if (this.state.selectedFile3) {
                errors.file3 = '';
                this.setState({ errors: errors });
                const fileData = this.state.selectedFile3;
                const formData = new FormData();
                formData.append('id', userId ? userId : '');
                formData.append('type', "ProjectDocument");
                // formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.showUploadModal("Success", "File Uplaoded Successfully");
                    this.setState({ engineeringPlans: response });
                } else {
                    this.showUploadModal("Failed", "Failed to Upload File");
                    this.setState({ engineeringPlans: '' });
                }
            } else {
                let errors = this.state.errors;
                errors.file3 = 'Please Select File to Upload';
                this.setState({ errors: errors });
            }


        }
        if (id === "file4") {

            let errors = this.state.errors;
            if (this.state.selectedFile4) {
                errors.file4 = '';
                this.setState({ errors: errors });
                const fileData = this.state.selectedFile4;
                const formData = new FormData();
                formData.append('id', userId ? userId : '');
                formData.append('type', "ProjectDocument");
                // formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.showUploadModal("Success", "File Uplaoded Successfully");
                    this.setState({ landscapingDesigns: response });
                } else {
                    this.showUploadModal("Failed", "Failed to Upload File");
                    this.setState({ landscapingDesigns: '' });
                }

            } else {
                let errors = this.state.errors;
                errors.file4 = 'Please Select File to Upload';
                this.setState({ errors: errors });
            }


        }
    };
    remove = (fileNm: any) => {
        if (fileNm === "file1") {
            this.setState({ landSaleContract: '', selectedFile1: '' });
        }
        if (fileNm === "file2") {
            this.setState({ architectDrawings: '', selectedFile2: '' });
        }
        if (fileNm === "file3") {
            this.setState({ engineeringPlans: '', selectedFile3: '' });
        }
        if (fileNm === "file5") {
            this.setState({ landscapingDesigns: '', selectedFile4: '' });
        }
    }
    showProjectModal = (head: any, body: any) => {
        this.setState({ projectModal: true, projectHeader: head, projectBody: body });
    };
    hideProjectModal = () => {
        this.setState({ projectModal: false });
        this.props.history.push('/MyProjects')

    };
    showUploadModal = (head: any, body: any) => {
        this.setState({ uploadModal: true, uploadHeader: head, uploadBody: body });
    };
    hideUploadModal = () => {
        this.setState({ uploadModal: false });
    };


    handleSubmit = async (event: any) => {
        const { name, description, adLine1, adLine2, city, state, pincode, mobile, landSize}:any = this.state;

        if(name!==""&&description!==""&&adLine1!==""&&adLine2!==""&&city!==""&&state!=="" && mobile!== ""&& landSize!== "" &&pincode !== ""){
        let userId = localStorage.getItem("id");
        event.preventDefault();
        let errors = this.state.errors;
        errors.terms = !this.state.checked ? 'Please accept Terms & Conditions' : '';
        // errors.file1 = !this.state.landSaleContract ? 'Please Upload Contarct of Sale of Land': '';
        // if(this.state.isCustomBuilder){
        //     errors.file2 = !this.state.architectDrawings ? 'Please Upload Architectural Drawings': '';
        //     errors.file3 = !this.state.engineeringPlans ? 'Please Upload Engineering Plans': '';
        //     errors.file4 = !this.state.landscapingDesigns ? 'Please Upload Landscaping Designs': '';
        // }else{
        //     errors.file2 = '';
        //     errors.file3 = '';
        //     errors.file4 = ''; 
        // }

        this.setState({ errors: errors });
        let validity = true;
        Object.values(this.state.errors).forEach(
            (val: any) => val.length > 0 && (validity = false)
        );
        if (validity == true) {
            Check.checkToken();
            const options = {
                url: constants.createProjectAPI,
                data: {
                    "name": this.state.name,
                    "description": this.state.description,
                    "address": {
                        "addressLine1": this.state.adLine1,
                        "addressLine2": this.state.adLine2,
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": "India",
                        "pincode": this.state.pincode,
                        "latitude": 0,
                        "longitute": 0
                    },
                    "mobile": this.state.mobile,
                    "projectDetails": {
                        "builderType": this.state.builderType,
                        "landSize": this.state.landSize,
                        "measurementUnit": this.state.measurement,
                        "projectDocs": {
                            "landSaleContract": this.state.landSaleContract,
                            "architectDrawings": this.state.architectDrawings,
                            "engineeringPlans": this.state.engineeringPlans,
                            "landscapingDesigns": this.state.landscapingDesigns
                        }
                    },
                    "minBudget": 0,
                    "maxBudget": 0,
                    "durationInMonths": 0,
                    "publicProject": this.state.isPublic,
                    "active": true,
                    "createdBy": userId,
                    "typeOfProject": "Contract"
                }
            }
            var response = await userService.postApiRequest(options);

            if (response) {
                this.showProjectModal("Success", "Project Created Successfully And Went For Verification");
            }
            else {
                this.showProjectModal("Failed", "Failed to Create Project");
            }
        } else {
        }
    }
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'name':
                    errors.name = value.length > 255 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
                break;
            case 'description':
                    errors.description = value.length > 250 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'adLine1':
                    errors.adLine1 = value.length > 100 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'adLine2':
                    errors.adLine2 = value.length > 150 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'mobile':
                     errors.mobile = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
                break;
            case 'landSize':
                    errors.landSize = value.length > 4 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'usertype':
            //  errors.usertype = value.length == 0 ? 'Please select valid option': '';
            case 'builderType':
                break;
            case 'pincode':
                errors.pincode = (value.length < 6 || value.length > 6) ? 'Pincode must be 6 digit long' : '';
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
        if (name === "builderType") {
            if (value === "Custom Builder") {
                this.setState({ isCustomBuilder: true });
            } else {
                this.setState({ isCustomBuilder: false });
            }
        }
       
    }

    handleCheckBox = () => {
        this.setState({ checked: !this.state.checked });
    }

    
    selectCity = (value: any)=>{
        if(value){
            this.setState({
                city: value,
               
            })
        }else{
            this.setState({
                city: ''
            })
        }
    }

    render() {
        const {  errors, name, description, adLine1,adLine2,  mobile, checked, isPublic, projectModal, uploadModal, landSaleContract, architectDrawings, engineeringPlans, landscapingDesigns, stateList, pincodeList, cityList, city, state, measurement, landSize, pincode } = this.state;
        const uniquePincodes = Array.from(new Set(pincodeList));
        let isSubmitted = true;
        if(name !== "" && description !== "" && adLine1 !== "" 
            && adLine2 !== "" && mobile !== "" 
            &&  landSize !== "" && pincode !== "" && state !== "" && city !== ""){
                isSubmitted = false
        }
        return (
            <>
                <Header />
                {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="col-md-6 col-12">
                                <h1  style={{fontWeight:600}}>New Project</h1>
                                <p className="my-3"><Link to="/MyProjects" className="link-text">My Projects</Link> / New Project</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Projects">
                    <div className="container">
                        <form onSubmit={this.handleSubmit} method="post">
                            <div className="my-5">
                                <h4 className="newProject">Start a New Project</h4>
                                <p>These details will be shared with the builder when you ask them for a quote</p>
                                <div>
                                    <ToggleSwitch
                                        id="public"
                                        small
                                        checked={isPublic}
                                        onChange={this.setPublic}
                                    />
                                    <label className="toggletext" htmlFor="daily">Public Project</label>
                                </div>
                                <div className="px-5">
                                    <p className="light-content"><img src={Info} /> A builder will be able to view and quote to your project if selected public.</p>
                                </div>
                            </div>
                                <h5 className="details">Basic Details </h5>
                                <br />
                         <div className="customer-project">       
                            <div className='projectName'>
                                <input type="text" name="name" className="w-50 py-1 px-3" placeholder="* Project Name" onChange={this.handleChange} required />
                                {errors.name.length > 0 &&  <p style={{color: "red"}}>{errors.name}</p>}
                            </div>
                                <br />
                            <div className='aboutProject'>
                                <textarea id="about" name="description" className="py-1 px-3 w-50 textarea" placeholder="* About the Project" rows={4} cols={50} onChange={this.handleChange} required  />
                                {errors.description.length > 0 &&  <p style={{color: "red"}}>{errors.description}</p>}
                            </div>
                                <br />
                            <div>
                                <input type="text" name="adLine1" className="py-1 px-3 adLine1  w-50" placeholder="* Door No" onChange={this.handleChange} required />
                                {errors.adLine1.length > 0 &&  <p  style={{color: "red"}}>{errors.adLine1}</p>}
                            </div>
                                <br/>
                            <div>
                                <input type="text" name="adLine2" className="py-1 px-3 adLine2  w-50" placeholder="* Street" onChange={this.handleChange} required />
                                {errors.adLine2.length > 0 &&  <p style={{color: "red"}}>{errors.adLine2}</p>}
                            </div>  
                        </div>    
                                <br/>  
                            <div className='row'>
                                <div className="col-md-7 d-flex">
                                        <Autocomplete
                                                    id="combo-box-demo"
                                                    onChange={(event, newValue) => {
                                                        this.selectCity(newValue);
                                                    }}
                                                    style = {{width : "43%"}}
                                                    options={cityList}
                                                    getOptionLabel={(option:any) => option}
                                                    value = {city}
                                                    renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" name = "city"/>}
                                         />
                                    <span className="left-buffer" />
                                    <select name="state" id="state" className="py-1" onChange={this.handleChange} value={state} required  style = {{width: "43%"}}>
                                        <option value="">* State</option>
                                        {stateList.map((item: any) => (
                                            <option value={item.name} key={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                    </div>
                                    <div className="col-md-5">  
                                    </div>
                            </div>
                             <br />

                                    <div  className='row'>
                                        <div className="col-md-7 d-flex">
                                            <div style = {{width: "43%"}}>
                                                <input type = "number" name="pincode" id="pincode"  className="py-1 px-3" onChange={this.handleChange} value={pincode} placeholder = "* Pincode" required style = {{width: "100%"}} onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }></input>
                                                {errors.pincode.length > 0 && (
                                                    <p style={{ color: 'red'}}>{errors.pincode}</p>
                                                )}
                                             </div>
                                            <span className="left-buffer" />
                                            <div style = {{width: "43%"}}>
                                                <input type="number" name="mobile" className="py-1 px-3" placeholder="* Phone" onChange={this.handleChange} required  maxLength = {10} style = {{width: "100%"}} onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }/>
                                                {errors.mobile.length > 0 &&  <p style={{color: "red"}}>{errors.mobile}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">  
                                        </div>
                                    </div>
                            <br />
                            {/* {errors.terms.length > 0 &&  <span style={{color: "red"}}>{errors.terms}</span>}
                  <br/>
                  <button>Signup<img src={RightArrow}  className="ml-2"/></button> */}


                            <div className="my-3">
                                <h5 className="details">Project Details</h5>
                                <div className='builderType'>
                                    <label className="mt-2">Whom are you looking for?</label>

                                    {/* {errors.usertype.length > 0 &&  <span style={{color: "red"}}>{errors.usertype}</span>} */}
                                </div>
                                <div>
                                    <select name="builderType" id="builderType" className="px-3 py-1 builderType" onChange={this.handleChange} required>
                                    <option value="Commercial Building">Commercial Building</option>
                                    <option value="Residential Building">Residential Building</option>
                                    {/* <option value="IndependentHouse">Independent House</option> */}
                                    <option value="Apartment">Apartment</option>
                                    {/* <option value="GatedCommunity">Gated Community</option> */}
                                    </select>
                                </div>

                                <p className="light-content"><img src={Info} /> This type of builders has limitation on design and material in construction.</p>

                                <div className='landSize'>
                                    <input type="number" name="landSize" className="px-3 landsize" placeholder="* Land Size" onChange={this.handleChange} required  value = {landSize} onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }/>
                                   
                                    <span className="left-buffer">in</span>
                                    <select name="measurement" id="measurementUnit" className="px-3 py-1 no-border" onChange={this.handleChange} required value = {measurement}>
                                        <option value="Square Meter">Sq Meters</option>
                                        <option value="Square Feet">Sq Ft</option>
                                    </select>
                                </div>
                                {errors.landSize.length > 0 &&  <p style={{color: "red", width: "30%"}}>{errors.landSize}</p>}
                                {/* <div className="pdfblock file-name">
                        Contract of Sale of Land <span className="left-tab"/>
                        {!landSaleContract ?
                            <span>
                          <input type="file" accept=".pdf" id="file1" onChange={(event)=>this.onFileChange(event)} /> 
                          <input type="button" className="button2 hire" id="file1" value="Upload" onClick={(event)=>this.onFileUpload(event)}/>
                          <span className="left-buffer"/> 
                          {errors.file1.length > 0 && <span style={{ color: 'red' }}>{errors.file1}</span>}
                          </span> 
                          : <span>
                            File Uploaded Successfully  <span className="left-tab"/><span className="warning" onClick={()=>this.remove("file1")}><img src={Delete}/>Delete</span>
                          </span>}
                          
                    </div>
                    {isCustomBuilder && 
                        <div>
                        <div className="pdfblock file-name">
                            Architectural Drawings <span className="left-tab"/>
                            {!architectDrawings ?
                            <span>
                          <input type="file" accept=".pdf" id="file2" onChange={(event)=>this.onFileChange(event)} /> 
                          <input type="button" className="button2 hire" id="file2" value="Upload" onClick={(event)=>this.onFileUpload(event)}/>
                          <span className="left-buffer"/>
                          {errors.file2.length > 0 && <span style={{ color: 'red' }}>{errors.file2}</span>}
                          </span> 
                          : <span>
                           File Uploaded Successfully  <span className="left-tab"/><span className="warning" onClick={()=>this.remove("file2")}><img src={Delete}/>Delete</span>
                          </span>}
                        </div>
                        <div className="pdfblock file-name">
                            Engineering Plans <span className="left-tab"/><span className="left-buffer"/><span className="left-buffer"/><span className="left-buffer"/>
                            {!engineeringPlans ?
                            <span>
                          <input type="file" accept=".pdf" id="file3" onChange={(event)=>this.onFileChange(event)} /> 
                          <input type="button" className="button2 hire" id="file3" value="Upload" onClick={(event)=>this.onFileUpload(event)}/>
                          <span className="left-buffer"/>
                          {errors.file3.length > 0 && <span style={{ color: 'red' }}>{errors.file3}</span>}
                          </span> 
                          : <span>
                            File Uploaded Successfully  <span className="left-tab"/><span className="warning" onClick={()=>this.remove("file3")}><img src={Delete}/>Delete</span>
                          </span>}
                        </div>
                        <div className="pdfblock file-name">
                            Landscaping Designs <span className="left-tab"/>
                            {!landscapingDesigns ?
                            <span>
                          <input type="file" accept=".pdf" id="file4" onChange={(event)=>this.onFileChange(event)} /> 
                          <input type="button" className="button2 hire" id="file4" value="Upload" onClick={(event)=>this.onFileUpload(event)}/>
                          <span className="left-buffer"/>
                          {errors.file4.length > 0 && <span style={{ color: 'red' }}>{errors.file4}</span>}
                          </span> 
                          : <span>
                            File Uploaded Successfully  <span className="left-tab"/><span className="warning" onClick={()=>this.remove("file4")}><img src={Delete}/>Delete</span>
                          </span>}
                        </div>
                        </div>
                    
                    } */}

                                <div className='terms'>
                                    <input
                                        type="checkbox"
                                        className="mt-3"
                                        checked={checked}
                                        onChange={this.handleCheckBox}
                                    />
                                    <span className="mx-2">I accept the privacy and terms</span>
                                </div>
                                {errors.terms.length > 0 && <span style={{ color: 'red' }}>{errors.terms}</span>}

                                <div className="mt-4">
                                    <button 
                                        className= {isSubmitted == true ? "button2 hire disabled-button" :  "button2 hire"} 
                                        disabled = {isSubmitted}
                                    >
                                        Publish Project 
                                        <img src={RightArrow} className="contactbut" />
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                <Modal show={projectModal} onHide={this.hideProjectModal}>
                    <Modal.Header>
                        <Modal.Title className="text-center">{this.state.projectHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.projectBody}</Modal.Body>
                    <Modal.Footer>
                        <button className="ok-button" onClick={this.hideProjectModal}>
                            OK
                </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={uploadModal} onHide={this.hideUploadModal}>
                    <Modal.Header>
                        <Modal.Title className="text-center">{this.state.uploadHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.uploadBody}</Modal.Body>
                    <Modal.Footer>
                        <button className="ok-button" onClick={this.hideUploadModal}>
                            OK
                </button>
                    </Modal.Footer>
                </Modal>
                <Footer />
            </>
        );
    }
}
