import React, { Component } from 'react';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import { Link } from 'react-router-dom';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import { blobService } from '../../../common-components/Blob';
import Add from '../../../../assets/images/add.svg';
import Loader from '../../../common-components/Loader';
import Check from '../../../Services/Check';
import './MyProjects.scss';
import longarrowaltright from '../../../../assets/images/longarrowaltright.svg';
import ruler from '../../../../assets/images/ruler.svg';
// import QuotePage from '../../../common-components/QuotePage/QuotePage';
// import MyQuote from '../../../common-components/MyQuote/MyQuote';

interface ProjectsProps {
    name?: any;
    value?: any;
}
interface ProjectsState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    projects: any,
    errors: {};
    activeli: any,
    status: string,
}

export default class Projects extends React.Component<ProjectsProps, ProjectsState> {
    constructor(props: ProjectsProps) {
        super(props);
        const initialState = {
            isLoading: true,
            projects:[],
            errors: {},
            error:false,
            ErrMsg:"",
            activeli : [true,false,false],
            status: "",
        };
        this.state = initialState;
    }
    componentDidMount() {
        let userId = localStorage.getItem("id");
        this.fetchProjects(userId); 
    }
    async fetchProjects(id: any): Promise<any> {
        Check.checkToken()
        .then(() => {
        const options = {
            url: constants.getBuilderProtfolioProjectsAPI + id  +"?status=",
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ projects: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        })
    };

    changeActive=(e:any)=>{
        const {activeli}:any = this.state
       
        let newArrey = []
        for (let index = 0; index < activeli.length; index++) {
           if(index===e){
             newArrey.push(true)
           }else{
             newArrey.push(false)
           }
          
        }
        this.setState({
         activeli:newArrey
        })
      }

      handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;

        let errors = this.state.errors;
        switch (name) {
            case 'status':
                this.searchProjectByStatus(value);
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { [name]: value }));
    };

    // Search builder's projects by status
    searchProjectByStatus = (status: any)=>{
        let userId = localStorage.getItem("id");
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.getBuilderProtfolioProjectsAPI + userId +"?status="+ status,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ projects: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        }
        )
    }
    render() {
        var pList=[];
        const { error, ErrMsg, errors, projects, isLoading, activeli, status } = this.state;
        for(let i=0; i<projects.length; i++){
            var p = projects[i];
            pList.push(
                <li className="row no-gutters">
                    <div className="col-md-3">
                        <img src={p.projectDetails.projectImages[0]? blobService.createUrl(p.projectDetails.projectImages[0]):ProjectPlaceholder} alt="" width="100%" height="180px"/>
                    </div>
                    <div className="col-md-7 p-4">
                    <h5>{p.name}</h5>
                    <h6>Builder : {p.builder && p.builder.builderName || "None"}</h6>
                    <h6>Location : {p.address ? p.address.city : "NA"}</h6>
                    <p>{p.description}</p>
                    </div>
                    <div className="col-md-2 row">
                        <span className="ml-auto my-3">
                            {(p.projectStatus.projectStatus === "Pending") && 
                                <div className="btn-text pending-btn">
                                    Yet to Assign
                                </div>
                            }
                            {(p.projectStatus.projectStatus === "In Progress") && 
                                <div className="btn-text pending-btn">
                                    In Progress
                                </div>
                            }
                            {(p.projectStatus.projectStatus === "Completed") && 
                                <div className="btn-text completed-btn">
                                    Completed
                                </div>
                            }
                            {(p.projectStatus.projectStatus === "Cancelled") && 
                                <div className="btn-text cancel-btn">
                                    Cancelled
                                </div>
                            }
                            {(p.projectStatus.projectStatus === "InitiateContract") && 
                                <div className="btn-text pending-btn">
                                   Initiate Contract 
                                </div>
                            }
                        </span>
                        <span className="align-self-end ml-auto mb-3">
                            <Link className="text-center my-3" to={{ pathname: "/ViewMyProject", state:{id: p.id}}}><h6 className="text-center">View Project <img className = "view-Project-Arrow" src={longarrowaltright} alt="" /></h6></Link>
                        </span>
                    </div>
                </li>               
            )
        }
        return isLoading ? (<Loader/>): 
        (
            <>
                <Header />
                {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12">
                            {activeli[0]===true ?<h1 style={{fontWeight:600}}>My Projects</h1> : ""}
                            {/* {activeli[1]===true ?<h1>Quotes</h1> : ""}
                            {activeli[2]===true ?<h1>My Quotes</h1> : ""} */}

                            {/* {activeli[1]===true ?<p className="my-3"><Link to="#" className="link-text"  onClick={()=>{this.changeActive(0)}}>My Projects</Link> /Quotes</p>: ""}
                            {activeli[2]===true ?<p className="my-3"><Link to="#" className="link-text" onClick={()=>{this.changeActive(0)}}>My Projects</Link> /My Quotes</p>: ""} */}

                                <p className="my-3"></p>
                            </div>                          
                        </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <Link className="button2 contact" to="/NewProject">Start New Project<img src={Add} className="ml-1"/></Link>
                    </div>
                </div>
                 
                <div className="Projects">
                    <div className="container py-5">
                        <div className="my-5">
                            <div className="row align-items-center top-navbar">
                                <h4 className={activeli[0]===true ? 'active' : ''} onClick={()=>{this.changeActive(0)}}>Projects</h4>
                                {/* <h4 className={activeli[1]===true ? 'active' : ''} onClick={()=>{this.changeActive(1)}} style = {{marginLeft: "40px", marginRight: "40px"}}>Quotes</h4>
                                <h4 className={activeli[2]===true ? 'active' : ''} onClick={()=>{this.changeActive(2)}}>My Quotes</h4> */}
                                <div className="ml-auto">
                                    <span className="mx-3"> Filter by:</span>
                                    {/* <select className="px-3 py-1 select1">
                                        <option value="">Builder Associated</option>
                                        <option value="">Yes</option>
                                        <option value="">No</option>
                                    </select> */}
                                    <select className="px-3 py-1 mx-3 select2" onChange={this.handleChange} value = {status} name = "status">
                                        <option value="">Status</option>
                                        <option value="Pending">Yet to Assign</option>
                                        <option value="InitiateContract">Initiate Contract</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Closed">Cancelled</option>
                                    </select>
                                    {/* <select className="px-3 py-1 select3">
                                        <option value="">Location</option>
                                        <option value="">SA</option>
                                        <option value="">TAS</option>
                                        <option value="">VIC</option>
                                    </select> */}
                                </div>
                            </div>
                            {projects.length > 0 && 
                            <ul style={{display:activeli[0]===true?"":"none"}}>{pList}</ul>
                            || 
                            <div className="buildersBlock py-5 empty-Findproject-Container">
                                <div className="container-fluid empty-Findproject-Content">
                                    <img src={ruler} alt="" />
                                    <p className="text-center my-3">There are no live projects. We will soon have some for you.</p>
                                </div>
                            </div>
                            }
                            {/* <ul style={{display:activeli[1]===true?"":"none"}}>   
                                 <QuotePage/>    
                            </ul>
                            <ul style={{display:activeli[2]===true?"":"none"}}>   
                                <MyQuote/>    
                            </ul> */}
                            {/* {projects.length > 2 ? <h6 className="text-center my-3">See More</h6>:""} */}
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}
